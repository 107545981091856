import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import ApprovedIconSrc from '../../assets/application-approved.svg';

export const ApprovedIconMobile = () => (
  <React.Fragment>
    <ApprovedIconBox>
      <Img src={ApprovedIconSrc} />
      <CheckIcon icon={['fas', 'check-circle']} size="4x" />
    </ApprovedIconBox>
  </React.Fragment>
);

const Img = styled.img<any>`
  width: 132px;
  height: 132px;
`;

const CheckIcon = styled<any>(FontAwesomeIcon)`
  position: relative;
  left: -45px;
  color: #32ba43;
  width: 32px;
  height: 32px;
`;

const ApprovedIconBox = styled.div<any>`
  display: block;
  margin: auto;
  text-align: center;
`;
