import { ApplicationValidationResult, Language } from '@frontline/common';
import { HttpService } from '@frontline/web-common';
import { AssetInformationReportDto } from '../types/asset-information-report-dto';

export function fetchAssetInformationReport(
  applicationId: string,
  language: Language,
): Promise<AssetInformationReportDto> {
  return HttpService.getHttpInstanceDeprecated()
    .get(endPoint(applicationId, `/report?language=${language}`))
    .then(response => response.data);
}

export function fetchAssetInformationValidations(
  applicationId: string,
): Promise<ApplicationValidationResult> {
  return HttpService.getHttpInstanceDeprecated()
    .get(endPoint(applicationId, '/validate'))
    .then(response => response.data);
}

function endPoint(applicationId: string, path = ''): string {
  return `/applications/${applicationId}/asset-information${path}`;
}
