import {
  actions as webCommonActions,
  createCommonLoanProtectionEpics,
  loanProtectionActions,
} from '@frontline/web-common';
import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootState } from '../../../../../../../../../store';
import * as postSubmissionActions from '../../../../../store/post-submission.actions';

export const saveLoanProtectionSuccessEpic: Epic<Action, Action, RootState> = (
  action$: Observable<Action>,
) =>
  action$.pipe(
    filter(
      isActionOf([
        loanProtectionActions.saveLoanProtectionSuccess,
        loanProtectionActions.declineLoanProtectionSuccess,
      ]),
    ),
    map(action => action.payload),
    switchMap(application =>
      of(
        webCommonActions.applicationActions.updateApplication(application),
        loanProtectionActions.resetSelectedLoanProtection(),
        postSubmissionActions.navigateToNextStep(),
      ),
    ),
  );

export default [
  ...createCommonLoanProtectionEpics(),
  saveLoanProtectionSuccessEpic,
];
