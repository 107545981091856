import { withShowIf } from '@frontline/ui-library';
import { LoanDetailsFormTgiEnrollment } from '@frontline/web-common';
import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import React from 'react';
import { LoanDetailsFormTgiEnrollmentFieldContainerProps } from './loan-details-form-tgi-enrollment-field-container.props';
import { FieldContainer } from './loan-details-form-tgi-enrollment-field-container.styles';

export const LoanDetailsFormTgiEnrollmentFieldContainer = withShowIf(
  (props: LoanDetailsFormTgiEnrollmentFieldContainerProps) => {
    return (
      <Grid item xs={12}>
        <FieldContainer>
          <Field
            name="tgiInsuranceEnrollment"
            component={LoanDetailsFormTgiEnrollment}
            tgiInsuranceEnrollment={props.tgiInsuranceEnrollment}
            errorMessage={props.errorMessage}
            disableProtectAssetOption={props.disableProtectAssetButton}
          />
        </FieldContainer>
      </Grid>
    );
  },
);
