import { StoredDocument, StoredDocumentStatus } from '@frontline/common';
import {
  actions,
  ApplicationState,
  BooleanMap,
  selectors,
} from '@frontline/web-common';
import { createSelector, Selector } from 'reselect';
import { RootState } from '../../../../../../../../../store';

const applicationStore = {
  ...actions.applicationActions,
  ...selectors.applicationSelectors,
  ...selectors.postSubmissionSelectors,
};

export const getStoredDocuments: Selector<
  RootState,
  StoredDocument[]
> = createSelector(
  (state: RootState): ApplicationState => state.application,
  application => applicationStore.getApplicationDocuments(application) || [],
);

export const getAbleToDeleteAttachedDocuments: Selector<
  RootState,
  boolean
> = createSelector(
  (state: RootState): ApplicationState => state.application,
  application => applicationStore.enableUploadDocumentDeletion(application),
);

export const getUploadingDocuments: Selector<
  RootState,
  BooleanMap
> = createSelector(
  (state: RootState): BooleanMap => state.application.uploadingDocuments,
  uploadingDocumentsMap => uploadingDocumentsMap,
);

export const getPendingDocuments: Selector<
  RootState,
  StoredDocument[]
> = createSelector(
  (state: RootState): ApplicationState => state.application,
  application =>
    applicationStore
      .getApplicationDocuments(application)
      .filter(document => document.status === StoredDocumentStatus.Pending),
);

export const getPendingDocumentsFileNames: Selector<
  RootState,
  string[]
> = createSelector(
  (state: RootState): ApplicationState => state.application,
  application =>
    applicationStore
      .getApplicationDocuments(application)
      .filter(d => d.status === StoredDocumentStatus.Pending)
      .map(d => d.fileName),
);
