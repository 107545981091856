import { AdditionalDetails, EmploymentStatus } from '@frontline/common';
import { Dispatch } from 'react';
import { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import { RootState } from '../../../../../store';
import { getApplication } from '../../../store/application.store';
import * as actions from './additionalDetails.actions';

export const getAdditionalDetails: Selector<
  RootState,
  AdditionalDetails | undefined
> = createSelector(
  getApplication,
  application => application?.applicant?.additionalDetails,
);

export const getSelectedEmploymentStatus: Selector<
  RootState,
  EmploymentStatus
> = createSelector(
  (state: RootState) => state.applicationState.additionalDetailsState,
  additionalDetailsState =>
    additionalDetailsState.selectedEmploymentStatus || '',
);

export const updateSelectedEmploymentStatus = (dispatch: Dispatch<any>) => (
  status: EmploymentStatus,
): void => {
  dispatch(actions.updateSelectedEmploymentStatus(status));
};
