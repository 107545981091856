import { defineMessages } from 'react-intl';

export const PersonalDetailsViewMessages = defineMessages({
  applicant: {
    id: 'PersonalDetails.applicant',
    description: 'Applicant',
  },
  theApplicant: {
    id: 'PersonalDetails.theApplicant',
    description: 'the applicant',
  },
  title: {
    id: 'Applicant.PreSubmission.PersonalDetails.title',
    description: 'Personal Details Step Title',
  },
  description: {
    id: 'Applicant.PreSubmission.PersonalDetails.description',
    description: 'Personal Details Step Description',
  },
});
