import { TypographyVariant } from '@frontline/ui-library';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AssetInformationSectionHeader } from '../AssetInformationSectionHeader/AssetInformationSectionHeader';
import messages from './AssetInformationPurchaseDetails.messages';
import { AssetInformationPurchaseDetailsProps } from './AssetInformationPurchaseDetailsProps';

export const AssetInformationPurchaseDetails = (
  props: AssetInformationPurchaseDetailsProps,
) => {
  const intl = useIntl();

  return (
    <React.Fragment>
      <AssetInformationSectionHeader
        title={intl.formatMessage(messages.purchaseDetailsTitle)}
      />

      <Grid item={true} xs={6} md={6} sm={12}>
        <Typography variant={TypographyVariant.Subtitle1}>
          <FormattedMessage {...messages.itemPurchasedLabel} />
        </Typography>
      </Grid>

      <Grid item={true} xs={6} md={6} sm={12}>
        {props.assetSubTypeName}
      </Grid>
    </React.Fragment>
  );
};
