import { styledTheme } from '@frontline/ui-library';
import styled from 'styled-components';

export const CloseIconContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: ${styledTheme.darkBlueGrey};
  border-left: 1px solid ${styledTheme.color13};
  padding-left: 15px;
  height: 35px;
`;
