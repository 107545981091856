import { styled } from '@frontline/ui-library';
import { AppBar } from '@material-ui/core';

export const Spacer = styled.div<any>`
  min-height: ${props => props.minHeight || '77px'};
  margin-bottom: ${props => props.gutterBottom || '5vh'};
  width: 100%;
`;

export const LayoutContainer = styled.div<any>`
  display: flex;
  min-height: 100vh;
  position: relative;
  justify-content: center;
`;
export const LayoutNavBar = styled<any>(AppBar)`
  height: ${props => props.height || '77px'};
  && {
    box-shadow: none;
  }
`;
export const MainComponentContainer = styled.div<any>`
  flex-grow: 1;
  overflow-y: hidden;
  max-width: 100%;
  margin-bottom: ${props => props.marginBottom || '2em'};
`;
