import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Colours, useUatId } from '@frontline/ui-library';
import { ProfileNavButton } from '@frontline/web-common';
import { Grid, MenuItem, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ApplicantProfileMenuMessages } from './applicant-profile-menu.messages';
import { ApplicantProfileMenuUat } from './applicant-profile-menu.uat';
import ApplicantProfileMenuViewProps from './types';

export const ApplicantProfileMenuView: FunctionComponent<ApplicantProfileMenuViewProps> = props => {
  const uat = useUatId();

  return localStorage.length > 2 ? (
    <ProfileNavButton
      userInfo={props.userInfo}
      logout={props.logout}
      uat={{
        button: uat(ApplicantProfileMenuUat.button),
        nickname: uat(ApplicantProfileMenuUat.nickname),
      }}>
      <Content container={true} spacing={1} direction="column">
        <Grid item={true}>
          <Paragraph variant="h4" color="inherit">
            {props.name}
          </Paragraph>
        </Grid>
      </Content>
      <Item onClick={props.logout} {...uat(ApplicantProfileMenuUat.logoutBtn)}>
        <Grid container={true} spacing={1} justify="flex-start">
          <Grid item={true}>
            <CloseIcon icon={faSignOutAlt} />
          </Grid>
          <Grid item={true}>
            <Paragraph variant="body1" color="inherit">
              <FormattedMessage {...ApplicantProfileMenuMessages.logout} />
            </Paragraph>
          </Grid>
        </Grid>
      </Item>
    </ProfileNavButton>
  ) : null;
};

const Content = styled(Grid)`
  padding-bottom: 16px;
`;

const Item = styled(MenuItem)`
  && {
    padding: 8px 0;
  }
`;

const CloseIcon = styled<any>(FontAwesomeIcon)`
  color: ${Colours.PaleGrey};
`;

const Paragraph = styled(Typography)`
  color: ${Colours.PaleGrey} !important;
`;
