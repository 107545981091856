import { defineMessages } from 'react-intl';

export const AssetsViewMessages = defineMessages({
  AssetCost: {
    id: 'ApplicantPortalAssetInformation.AssetCostValidation',
  },
  totalAssetsCost: {
    id:
      'Applicant.PostSubmission.AssetsAndAddOns.AssetsAndAddOnsView.totalAssetsCost',
  },
  totalCostAfterTrade: {
    id:
      'Applicant.PostSubmission.AssetsAndAddOns.AssetsAndAddOnsView.totalCostAfterTrade',
  },
  totalCostWithTax: {
    id:
      'Applicant.PostSubmission.AssetsAndAddOns.AssetsAndAddOnsView.totalCostWithTax',
  },
});
