import { Checkbox, FormControlLabel } from '@material-ui/core';
import * as React from 'react';
import { ChangeEvent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { PersonalDetailsSameAddressBoxMessages as messages } from './personal-details-same-address-box.messages';
import { PersonalDetailsSameAddressBoxProps } from './personal-details-same-address-box.props';
import { Description } from './personal-details-same-address-box.styles';

export const PersonalDetailsSameAddressBox = (
  props: PersonalDetailsSameAddressBoxProps,
) => {
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    setChecked(props.field.value);
  }, [props.field.value]);

  const handleChanged = (event: ChangeEvent, newValue: boolean) => {
    setChecked(newValue);
    props.form.setFieldValue(props.field.name, newValue);
  };

  return (
    <React.Fragment>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            value="checked"
            color="primary"
            onChange={handleChanged}
            disabled={props.disabled}
          />
        }
        label={
          <Description>
            <FormattedMessage {...messages.checkbox} />
          </Description>
        }
      />
    </React.Fragment>
  );
};
