import {
  PrimaryButton,
  SecondaryButton,
  ShowIfFragment,
} from '@frontline/ui-library';
import { Grid, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { ApplicationTakeoverStore } from '../../../../modules/application-takeover/store/application-takeover.store';
import { ApplicationTakeoverRequestStatus } from '../../../../types/application-takeover/application-takeover-request-status.enum';
import { ApplicationTakeoverMerchantInfoFooter } from '../application-takeover-merchant-info-footer/application-takeover-merchant-info-footer.component';
import { ApplicationTakeoverReferenceCode } from '../application-takeover-reference-code/application-takeover-reference-code.component';
import { ApplicationTakeoverViewMessages } from './application-takeover-view.messages';
import { ApplicationTakeoverViewProps } from './application-takeover-view.props';
import { useApplicationTakeoverViewStyles } from './application-takeover-view.styles';
import { ApplicationTakeoverAccepted } from './components/application-takeover-accepted/application-takeover-accepted.component';
import { ApplicationTakeoverConfirm } from './components/application-takeover-confirm/application-takeover-confirm.component';
import { ApplicationTakeoverDeclined } from './components/application-takeover-declined/application-takeover-declined.component';

export const ApplicationTakeoverView = (
  props: ApplicationTakeoverViewProps,
) => {
  const classes = useApplicationTakeoverViewStyles();

  const isConfirming = useSelector(ApplicationTakeoverStore.isConfirming);
  const hasError = Boolean(useSelector(ApplicationTakeoverStore.getError));
  const applicationTakeoverStatus = useSelector(
    ApplicationTakeoverStore.getApplicationTakeoverStatus,
  );

  const hasAccepted = useMemo(
    () =>
      applicationTakeoverStatus === ApplicationTakeoverRequestStatus.Approved,
    [applicationTakeoverStatus],
  );
  const hasDeclined = useMemo(
    () =>
      applicationTakeoverStatus === ApplicationTakeoverRequestStatus.Declined,
    [applicationTakeoverStatus],
  );

  return (
    <>
      <Grid
        container
        spacing={1}
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.container}>
        <ShowIfFragment
          showIf={!isConfirming && !hasAccepted && !hasDeclined && !hasError}>
          <Grid item xs={11} md={7}>
            <Typography className={classes.title}>
              <FormattedMessage
                {...ApplicationTakeoverViewMessages.title}
                values={{
                  merchantName: props.applicationTakeoverDetails?.merchant.name,
                }}
              />
            </Typography>
          </Grid>
          <Grid item className={classes.referenceCodeContainer}>
            <ApplicationTakeoverReferenceCode
              referenceCode={
                props.applicationTakeoverDetails?.appReferenceCode ?? ''
              }
            />
          </Grid>
          <Grid item xs={11} md={7}>
            <Typography className={classes.description}>
              <FormattedMessage
                {...ApplicationTakeoverViewMessages.description}
                values={{
                  merchantName: props.applicationTakeoverDetails?.merchant.name,
                }}
              />
            </Typography>
          </Grid>
          <Grid item xs={11} sm={5} md={3} className={classes.buttonContainer}>
            <PrimaryButton
              fullWidth
              onClick={() => ApplicationTakeoverStore.setIsConfirming(true)}>
              <FormattedMessage
                {...ApplicationTakeoverViewMessages.confirmButton}
              />
            </PrimaryButton>
            <SecondaryButton
              fullWidth
              onClick={() =>
                ApplicationTakeoverStore.answerApplicationTakeoverRequest(
                  false,
                  props.takeoverCode,
                )
              }>
              <FormattedMessage
                {...ApplicationTakeoverViewMessages.declineButton}
              />
            </SecondaryButton>
          </Grid>
        </ShowIfFragment>
        <ApplicationTakeoverConfirm
          showIf={isConfirming}
          applicationTakeoverDetails={props.applicationTakeoverDetails}
          takeoverCode={props.takeoverCode}
          onConfirm={ApplicationTakeoverStore.answerApplicationTakeoverRequest}
        />
        <ApplicationTakeoverDeclined
          showIf={hasDeclined}
          applicationTakeoverDetails={props.applicationTakeoverDetails}
        />
        <ApplicationTakeoverAccepted
          showIf={hasAccepted}
          applicationTakeoverDetails={props.applicationTakeoverDetails}
        />
      </Grid>
      <ApplicationTakeoverMerchantInfoFooter
        showIf={!hasError}
        applicationTakeoverDetails={props.applicationTakeoverDetails}
      />
    </>
  );
};
