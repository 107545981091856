/* prettier-ignore-start */
// tslint:disable ordered-imports
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-numberformat/locale-data/fr';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-pluralrules/dist/locale-data/fr';
/* prettier-ignore-end */

import { Language } from '@frontline/common';
import {
  history,
  LocaleProvider,
  setLocaleInLocalStorage,
} from '@frontline/web-common';
import { UatContext } from '@frontline/ui-library';
import { ConnectedRouter } from 'connected-react-router';
import * as dotenv from 'dotenv';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import * as ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import 'react-toastify/dist/ReactToastify.css';
import { App } from './App';
import './assets/css/main.css';
import './assets/css/ReactToastify.css';
import enMessages from './i18n/locales/en.json';
import frMessages from './i18n/locales/fr.json';
import { useFraudDetection } from './modules/fraud-detection/hooks/use-fraud-detection';
import { register as registerServiceWorker } from './serviceWorker';
import StoreProvider from './store/StoreProvider';
import { getDefaultBrowserLanguage } from './types/language.functions';
import uatIds from './uat/uat.json';

dotenv.config();

const getLocaleMessages = (code: Language) => {
  return code === Language.FR ? frMessages : enMessages;
};

const Root = () => {
  const [locale, changeLocale] = useState<Language>(
    getDefaultBrowserLanguage(window.navigator.language),
  );
  const [messages, setMessages] = useState<any>(getLocaleMessages(locale));

  useEffect(() => {
    moment.locale(locale);
    setLocaleInLocalStorage(locale);
    setMessages(getLocaleMessages(locale));
  }, [locale]);

  useFraudDetection();

  return (
    <IntlProvider locale={locale} messages={messages}>
      <UatContext.Provider value={{ uatIds }}>
        <StoreProvider>
          <ConnectedRouter history={history}>
            <LocaleProvider
              value={{
                locale,
                changeLocale,
              }}>
              <App />
            </LocaleProvider>
          </ConnectedRouter>
        </StoreProvider>
      </UatContext.Provider>
    </IntlProvider>
  );
};

ReactDOM.render(<Root />, document.getElementById('root') as HTMLElement);

registerServiceWorker();
