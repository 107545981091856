import { muiTheme } from '@frontline/ui-library';
import { CoBrand, Logo } from '@frontline/ui-library';
import { useMediaQuery } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import * as applicationStore from '../../../../features/application/store/application.store';
import { ApplicationPortalLogoProps } from './applicant-portal-logo.props';
import { LogoContainer } from './applicant-portal-logo.stytles';
import { createCoBrandSrc } from './types/applicant-portal-logo.functions';

const ApplicantPortalLogo: FunctionComponent<ApplicationPortalLogoProps> = props => {
  const isMediaSmall: boolean = useMediaQuery(muiTheme.breakpoints.down('sm'));
  const merchantId: number | undefined = useSelector(
    applicationStore.getMerchantId,
  );

  return (
    <LogoContainer
      container={true}
      direction="row"
      justify={isMediaSmall ? 'flex-start' : 'center'}
      alignItems="center">
      <Logo showIf={!props.isFullBrand} />
      <CoBrand
        showIf={props.isFullBrand || props.showCoBrandedLogo}
        fullBrand={props.isFullBrand}
        logoSrc={createCoBrandSrc(merchantId)}
      />
    </LogoContainer>
  );
};

export default ApplicantPortalLogo;
