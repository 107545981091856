import { Loader } from '@frontline/ui-library';
import { LocaleContext } from '@frontline/web-common';
import React, { Fragment, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationRoutes } from './application-routes.component';
import * as store from './store/application.store';

export const ApplicationFeature = () => {
  const dispatch = useDispatch();
  const { locale } = useContext(LocaleContext);

  useEffect(() => {
    const applicationId = localStorage.getItem('application_id');
    if (applicationId && locale) {
      store.loadApplication(dispatch)(applicationId);
    }
  }, [dispatch, locale]);

  return (
    <Fragment>
      <Loader show={useSelector(store.isLoading)} />
      <ApplicationRoutes application={useSelector(store.getApplication)} />
    </Fragment>
  );
};
