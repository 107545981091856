import { AddOn, ThirdPartyAddOnTypeDto } from '@frontline/common';
import { selectors } from '@frontline/web-common';
import { Dispatch } from 'redux';
import { createSelector, Selector } from 'reselect';
import { RootState } from '../../../../../../../../../store';
import * as postSubmissionStore from '../../../../../store/post-submission.store';
import { PostSubmissionReviewState } from '../../../types/PostSubmissionReviewState';
import { ThirdPartyAddOnsState } from '../types/ThirdPartyAddOnsState/ThirdPartyAddOnsState';
import * as actions from './thirdPartyAddOns.actions';

const rootApplicationSelectors = selectors.applicationSelectors;

export const getThirdPartyAddOns = (rootState: RootState): AddOn[] =>
  rootApplicationSelectors.getThirdPartyAddOns(rootState.application);

export const getThirdPartyAddOnsMaxTotal = (
  rootState: RootState,
): number | undefined =>
  rootApplicationSelectors.getThirdPartyAddOnsMaxTotal(rootState.application);

export const getThirdPartyAddOnsState: Selector<
  RootState,
  ThirdPartyAddOnsState | undefined
> = createSelector(
  postSubmissionStore.getPostSubmissionReviewState,
  (state?: PostSubmissionReviewState) => state?.thirdPartyAddOnsState,
);

export const getThirdPartyAddOnTypes: Selector<
  RootState,
  ThirdPartyAddOnTypeDto[] | undefined
> = createSelector(
  getThirdPartyAddOnsState,
  (state?: ThirdPartyAddOnsState) => state?.types,
);

export const fetchThirdPartyAddOnTypes = (dispatch: Dispatch<any>) => () => {
  dispatch(actions.fetchTypes());
};

export const getIsLoading: Selector<
  RootState,
  boolean
> = createSelector(getThirdPartyAddOnsState, (state?: ThirdPartyAddOnsState) =>
  Boolean(state?.isLoading),
);
