import { TypographyVariant } from '@frontline/ui-library';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { messages } from './AddOnTableHeader.messages';

export const AddOnTableHeader: FunctionComponent = () => {
  const intl = useIntl();
  return (
    <TableHead>
      <TableRow>
        <TableCell />
        <TableCell>
          <Typography variant={TypographyVariant.Header5}>
            {intl.formatMessage(messages.type)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant={TypographyVariant.Header5}>
            {intl.formatMessage(messages.provider)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant={TypographyVariant.Header5}>
            {intl.formatMessage(messages.cost)}
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
