import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'PurchaseDetailsStep.title',
    description: 'Purchase Details Step Title',
    defaultMessage: 'Purchase Details',
  },
  description: {
    id: 'PurchaseDetailsStep.description',
    description: 'Purchase Details Step Description',
    defaultMessage:
      'Below are the details of your intended purchase. If you have any questions, please contact your merchant.',
  },
});
