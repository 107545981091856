export const ReviewSummaryViewUat = {
  submitButton: 'newApplication.reviewApplication.submit.button',
  editPurchaseDetailsPlan:
    'newApplication.reviewApplication.editPurchaseDetailstPlan.button',
  editPersonalDetails:
    'newApplication.reviewApplication.editPersonalDetails.button',
  editIncomeDetails:
    'newApplication.reviewApplication.editIncomeDetails.button',
  editAdditionalDetails:
    'newApplication.reviewApplication.editAdditionalDetails.button',
  removeApplicant: 'newApplication.reviewApplication.removeApplicant.button',
};
