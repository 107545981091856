import { AddOn } from '@frontline/common';
import {
  SectionItem,
  SectionPanel,
  ShowIfFragment,
} from '@frontline/ui-library';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import { getThirdPartyAddOnType } from '../addons-table-body/addons-table-body.functions';
import { AddOnTableHeaderMessages as messages } from '../addons-table-header/addons-table-header.messages';
import { AddonsViewProps } from './addons-view-mobile.props';

export const AddonsViewMobile = (props: AddonsViewProps) => {
  const intl = useIntl();
  return (
    <>
      {props.addOns.map((addOn: AddOn, index: number) => (
        <SectionPanel title="" key={index}>
          <Grid container={true} spacing={3}>
            <SectionItem
              label={intl.formatMessage(messages.type)}
              data={getThirdPartyAddOnType(addOn, props.addOnTypes).name}
            />
            <SectionItem
              label={intl.formatMessage(messages.provider)}
              data={addOn.description}
            />
            <ShowIfFragment showIf={props.taxEnabled}>
              <SectionItem
                label={intl.formatMessage(messages.postTaxcost)}
                data={
                  <NumberFormat
                    value={addOn.preTaxValue}
                    displayType={'text'}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                }
              />
            </ShowIfFragment>
            <ShowIfFragment showIf={props.taxEnabled}>
              <SectionItem
                label={intl.formatMessage(messages.taxRate)}
                data={
                  <NumberFormat
                    value={addOn.taxRate! * 100}
                    displayType={'text'}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    suffix={'%'}
                  />
                }
              />
            </ShowIfFragment>
            <SectionItem
              label={
                props.taxEnabled
                  ? intl.formatMessage(messages.postTaxcost)
                  : intl.formatMessage(messages.cost)
              }
              data={
                <NumberFormat
                  value={addOn.amount}
                  displayType={'text'}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              }
            />
          </Grid>
        </SectionPanel>
      ))}
    </>
  );
};
