import * as React from 'react';
import { useSelector } from 'react-redux';
import * as store from '../../../../common/purchase-details/store/purchase-details.store';
import * as preSubmissionStore from '../../../../store/pre-submission.store';
import { getDisplayStepPosition } from '../../../../types/StepPosition.functions';
import { PurchaseDetailsView } from './purchase-details-view.component';

export const PurchaseDetails = () => {
  const stepPosition = useSelector(preSubmissionStore.getActiveStepIndex);

  return (
    <PurchaseDetailsView
      loanAmount={useSelector(store.getLoanAmount)}
      assetSubTypeName={useSelector(store.getAssetSubTypeName)}
      assetType={useSelector(store.getAssetTypeId)}
      assetTypeName={useSelector(store.getAssetTypeName)}
      assetTypes={useSelector(store.getAssetTypes)}
      stepPosition={getDisplayStepPosition(stepPosition)}
      programId={useSelector(store.getProgramId) || -1}
      programName={useSelector(store.getProgramName)}
      onContinue={preSubmissionStore.nextStep}
    />
  );
};
