import { WorkflowStep } from '@frontline/common';
import React, { Fragment, FunctionComponent } from 'react';
import { ChecklistButton } from '../check-list-button/check-list-button.component';
import { ChecklistProps } from './check-list.props';

export const Checklist: FunctionComponent<ChecklistProps> = (
  props: ChecklistProps,
) => {
  return (
    <Fragment>
      {props.steps.map((step: WorkflowStep) => (
        <ChecklistButton
          key={step.id}
          onClick={() => {
            props.onTabSelect(step.id);
          }}
          status={step.status}
          active={props.activeTabId === step.id}
          stepName={step.name || props.getStepNameByStepType(step.type)}
          stepType={step.type}
        />
      ))}
    </Fragment>
  );
};
