import { defineMessages } from 'react-intl';

export const CoApplicantAdditionalDetailsMessages = defineMessages({
  title: {
    id: 'CoApplicantAdditionalDetails.title',
    description: 'Additional Details Step Title',
  },
  description: {
    id: 'CoApplicantAdditionalDetails.description',
    description: 'Additional Details Step Description',
  },
});
