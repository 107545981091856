import {
  ContinueButtonLabel,
  PrimaryButton,
  withShowIf,
} from '@frontline/ui-library';
import React from 'react';
import styled from 'styled-components';
import { ApprovedContinueButtonProps } from './approved-continue-button-props';

export const ApprovedContinueButton = withShowIf(
  (props: ApprovedContinueButtonProps) => {
    return (
      <ActionsRow>
        <PrimaryButton onClick={props.continueClicked}>
          <ContinueButtonLabel />
        </PrimaryButton>
      </ActionsRow>
    );
  },
);

const ActionsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 0 0;
`;
