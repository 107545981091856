import { WorkflowStepType } from '@frontline/common';
import { LoaderWithMessages } from '@frontline/ui-library';
import React, { Fragment, FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yearsWorkedCategoryStore from '../../../../../../modules/lookup/years-worked-category/years-worked-category.store';
import * as applicantStore from '../../../../modules/applicant-details/store/applicant-details.store';
import * as applicationStore from '../../../../store/application.store';
import * as coApplicantStore from '../../common/CoApplicant/store/coApplicantDetails.store';
import * as preSubmissionStore from '../../store/pre-submission.store';
import { getPreSubmissionRoutePath } from '../../types/PreSubmissionRoutes.functions';
import { getDisplayStepPosition } from '../../types/StepPosition.functions';
import { ReviewSummaryView } from './components/review-summary-view';
import { SubmissionTimeoutDialog } from './components/SubmissionTimeoutDialog/SubmissionTimeoutDialog';
import { ReviewSummaryFeatureProps as Props } from './ReviewSummaryFeatureProps';
import * as store from './store/reviewSummary.store';
import { SubmittingSpinnerMessages } from './types/SubmittingSpinner.messages.';

export const ReviewSummaryFeature: FunctionComponent<Props> = (
  props: Props,
) => {
  const dispatch = useDispatch();

  const yearsWorkedCategoriesLookup = useSelector(
    yearsWorkedCategoryStore.getYearsWorkedCategoriesLookup,
  );

  useEffect(() => {
    if (!yearsWorkedCategoriesLookup) {
      yearsWorkedCategoryStore.fetchYearsWorkedCategories();
    }
  }, [yearsWorkedCategoriesLookup]);

  const submitApplication = () =>
    store.submitApplication(dispatch)(props.applicationId);

  const additionalDetailsEditUrl = getPreSubmissionRoutePath(
    props.applicationId,
    WorkflowStepType.AdditionalDetails,
  );
  const incomeDetailsEditUrl = getPreSubmissionRoutePath(
    props.applicationId,
    WorkflowStepType.IncomeDetails,
  );
  const personalDetailsEditUrl = getPreSubmissionRoutePath(
    props.applicationId,
    WorkflowStepType.PersonalDetails,
  );
  const coApplicantAdditionalDetailsEditUrl = getPreSubmissionRoutePath(
    props.applicationId,
    WorkflowStepType.CoApplicantAdditionalDetails,
  );
  const coApplicantIncomeDetailsEditUrl = getPreSubmissionRoutePath(
    props.applicationId,
    WorkflowStepType.CoApplicantIncomeDetails,
  );
  const coApplicantPersonalDetailsEditUrl = getPreSubmissionRoutePath(
    props.applicationId,
    WorkflowStepType.CoApplicantPersonalDetails,
  );

  return (
    <Fragment>
      <ReviewSummaryView
        stepPosition={getDisplayStepPosition(
          useSelector(preSubmissionStore.getActiveStepIndex),
        )}
        onSubmit={submitApplication}
        applicantDetails={useSelector(applicantStore.getApplicantDetails)}
        coApplicantDetails={useSelector(coApplicantStore.getCoApplicant)}
        displayInterestRate={useSelector(
          applicationStore.shouldDisplayInterestRate,
        )}
        additionalDetailsEditUrl={additionalDetailsEditUrl}
        incomeDetailsEditUrl={incomeDetailsEditUrl}
        personalDetailsEditUrl={personalDetailsEditUrl}
        coApplicantAdditionalDetailsEditUrl={
          coApplicantAdditionalDetailsEditUrl
        }
        coApplicantIncomeDetailsEditUrl={coApplicantIncomeDetailsEditUrl}
        coApplicantPersonalDetailsEditUrl={coApplicantPersonalDetailsEditUrl}
        yearsWorkedCategoriesLookup={yearsWorkedCategoriesLookup || []}
      />

      <LoaderWithMessages
        messages={SubmittingSpinnerMessages}
        showIf={useSelector(store.isSubmitting)}
        timeoutPeriodInMillis={45000}
        timedOut={store.openTimeoutDialog(dispatch)}
      />

      <SubmissionTimeoutDialog
        open={useSelector(store.isTimeoutDialogOpen)}
        onClose={store.closeTimeoutDialog(dispatch)}
      />
    </Fragment>
  );
};
