import { doesFlinksStatusIndicateLoginNeeded } from '@frontline/common';
import { ShowIfFragment } from '@frontline/ui-library';
import {
  disableBankingIntegrationContinueButton,
  FlinksView,
} from '@frontline/web-common';
import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as applicationStore from '../../../../../../../../store/application.store';
import * as postSubmissionStore from '../../../../../../store/post-submission.store';
import * as postSubmissionReviewStore from '../../../../../PostSubmissionReview/store/post-submission-review.store';
import { PostSubmissionContentCard } from '../../../../components/post-submission-content-card';

export const Flinks: FunctionComponent<{}> = () => {
  const dispatch = useDispatch();

  const accountDetails = useSelector(applicationStore.getBankDetails);
  const applicationId = useSelector(applicationStore.getApplicationId);
  const edgeApplicationId = useSelector(applicationStore.getEdgeApplicationId);
  const status = useSelector(applicationStore.getFlinksStatus);
  const step = useSelector(postSubmissionStore.getActiveWorkflowStep);

  const handleUpdateAccountDetailsWithFlinks = (
    loginId: string,
    accountId: string,
  ): void => {
    applicationStore.updateAccountDetailsWithFlinks(loginId, accountId);
  };

  return (
    <ShowIfFragment showIf={Boolean(applicationId)}>
      <PostSubmissionContentCard>
        <FlinksView
          onNext={postSubmissionStore.navigateToNextStep(dispatch)}
          onBack={postSubmissionStore.navigateToPreviousStep(dispatch)}
          accountDetails={accountDetails}
          showBankLogin={doesFlinksStatusIndicateLoginNeeded(status)}
          status={status}
          applicationId={applicationId}
          edgeApplicationId={edgeApplicationId}
          step={step}
          disableContinue={disableBankingIntegrationContinueButton(status)}
          showResetFlinksButton={false}
          handleUpdateAccountDetailsWithFlinks={
            handleUpdateAccountDetailsWithFlinks
          }
          flinksViewState={useSelector(
            postSubmissionReviewStore.getFlinksViewState,
          )}
        />
      </PostSubmissionContentCard>
    </ShowIfFragment>
  );
};
