import {
  getPersonalDetailsSchema,
  PersonalDetailsForm,
} from '@frontline/common';
import { createPersonalDetailsFormValues } from '@frontline/web-common';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { PreSubmissionStepActions } from '../../../../components/pre-submission-step-actions/pre-submission-step-actions.component';
import { PersonalDetailsFormView } from './personal-details-form-view/personal-details-form-view.component';
import { PersonalDetailsFormProps } from './personal-details-form.props';

export const PersonalDetailsFormComponent = (
  props: PersonalDetailsFormProps,
) => {
  const intl = useIntl();

  const isCoApplicant = useMemo(() => props.coApplicant || false, [
    props.coApplicant,
  ]);

  const validationSchema = getPersonalDetailsSchema(props.rules, isCoApplicant);

  const preparedInitialValues = createPersonalDetailsFormValues(
    props.coApplicant,
    props.initialValues,
    props.rules?.hideResidentialLengthOfStayField,
    props.authorizedElectronicCommunication,
  );

  return (
    <Formik
      initialValues={preparedInitialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values: PersonalDetailsForm) => props.onSubmit(values)}
      render={formProps => (
        <Form>
          <PersonalDetailsFormView
            coApplicant={isCoApplicant}
            applicantAddress={props.applicantAddress}
            {...formProps}
            intl={intl}
            disabled={props.disabled}
            relationshipTypes={props.relationshipTypes}
            hideResidentialLengthOfStay={
              props.rules?.hideResidentialLengthOfStayField
            }
            showElectronicCommunicationsConsent={
              props.rules?.showElectronicCommunicationsConsent
            }
          />
          <PreSubmissionStepActions />
        </Form>
      )}
    />
  );
};
