import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useAddOnsViewStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: 'fit-content',
      marginLeft: 'auto',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 'unset',
      },
    },
  }),
);
