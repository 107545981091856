import { withShowIf } from '@frontline/ui-library';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { LoanDetailsItem } from '../../../components/loan-details-item.component';
import { LoanDetailsSectionHeader } from '../../../components/loan-details-section-header/loan-details-section-header';
import messages from './loan-details-purchase-details.messages';
import { LoanDetailsPurchaseDetailsProps } from './loan-details-purchase-details.props';

export const LoanDetailsPurchaseDetails = withShowIf(
  (props: LoanDetailsPurchaseDetailsProps) => {
    const intl = useIntl();

    const getAssetDescriptionSize = () => (props.hideAssetSubtype ? 12 : 6);

    return (
      <Fragment>
        <LoanDetailsSectionHeader
          title={intl.formatMessage(messages.purchaseDetailsTitle)}
        />
        <Box paddingTop={1}>
          <Grid container={true} spacing={2}>
            <LoanDetailsItem
              labelMessage={messages.itemPurchasedLabel}
              showIf={!props.hideAssetSubtype}
              data={props.assetSubTypeName}
              xs={6}
            />
            <LoanDetailsItem
              labelMessage={messages.descriptionLabel}
              data={props.assetDescription}
              xs={getAssetDescriptionSize()}
            />
          </Grid>
        </Box>
      </Fragment>
    );
  },
);
