import { WorkflowStepType } from '@frontline/common';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { createPostSubmissionReviewPath } from '../../../../types';
import { postSubmissionPath } from '../../../../types/PostSubmissionPathType';
import { CoApplicantRoutesProps } from './co-applicant-routes.props';
import CoApplicantAdditionalDetails from './features/co-applicant-additional-details';
import { CoApplicantIncomeDetailsFeature } from './features/CoApplicantIncomeDetails/CoApplicantIncomeDetailsFeature';
import CoApplicantPersonalDetails from './features/CoApplicantPersonalDetails';

export const CoApplicantRoutes = (props: CoApplicantRoutesProps) => {
  const history = useHistory();
  const goToOverviewPage = () =>
    history.push(postSubmissionPath('overview', props.applicationId));
  return (
    <Switch>
      <Route
        path={createPostSubmissionReviewPath(
          WorkflowStepType.CoApplicantIncomeDetails,
          props.applicationId,
        )}
        render={() => (
          <CoApplicantIncomeDetailsFeature
            goBack={goToOverviewPage}
            disabled={props.disabled}
            yearsWorkedCategoriesLookup={props.yearsWorkedCategoriesLookup}
          />
        )}
      />
      <Route
        path={createPostSubmissionReviewPath(
          WorkflowStepType.CoApplicantAdditionalDetails,
          props.applicationId,
        )}
        render={() => (
          <CoApplicantAdditionalDetails
            goBack={goToOverviewPage}
            disabled={props.disabled}
            yearsWorkedCategoriesLookup={props.yearsWorkedCategoriesLookup}
          />
        )}
      />
      <Route
        path={createPostSubmissionReviewPath(
          WorkflowStepType.CoApplicantPersonalDetails,
          props.applicationId,
        )}
        render={() => (
          <CoApplicantPersonalDetails
            goBack={goToOverviewPage}
            disabled={props.disabled}
            yearsWorkedCategoriesLookup={props.yearsWorkedCategoriesLookup}
          />
        )}
      />
      <Redirect
        to={createPostSubmissionReviewPath(
          WorkflowStepType.CoApplicantPersonalDetails,
          props.applicationId,
        )}
      />
    </Switch>
  );
};
