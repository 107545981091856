import {
  ApplicantApplicationOptionDto,
  ApplicantApplicationOptionDtoFunctions,
  WorkflowStep,
  WorkflowStepFunctions,
} from '@frontline/common';

function doesNotRequireToCheckApplicantApplicationOption(
  step?: WorkflowStep | null,
): boolean {
  return Boolean(
    step === undefined ||
      step === null ||
      WorkflowStepFunctions.isDisabledApplicantRequiresViewPermission(step),
  );
}

function hideFundingDetails(
  applicationOptions?: ApplicantApplicationOptionDto,
  step?: WorkflowStep | null,
): boolean {
  if (doesNotRequireToCheckApplicantApplicationOption(step)) {
    return false;
  }

  return !ApplicantApplicationOptionDtoFunctions.getCanViewLoanDetails(
    applicationOptions,
  );
}

function hideAddOns(
  applicationOptions?: ApplicantApplicationOptionDto,
  step?: WorkflowStep | null,
): boolean {
  if (doesNotRequireToCheckApplicantApplicationOption(step)) {
    return false;
  }

  return !ApplicantApplicationOptionDtoFunctions.getCanViewAddOns(
    applicationOptions,
  );
}

export const PostSubmissionReviewFeatureFunctions = {
  hideFundingDetails,
  hideAddOns,
};
