import { HttpError } from '@frontline/web-common';
import { ActionType, createAction } from 'typesafe-actions';

export enum PersonalDetailsAction {
  SaveApplicantPersonalDetailsError = 'PersonalDetails/SaveApplicantPersonalDetailsError',
  ResetApplicantPersonalDetailsError = 'PersonalDetails/ResetApplicantPersonalDetailsError',
  SetSalesRepIdDialogOpen = 'PersonalDetails/SetSalesRepIdDialogOpen',
  SetSalesRepIdComplete = 'PersonalDetails/SetSalesRepIdComplete',
  SubmitSalesRepId = 'PersonalDetails/SubmitSalesRepId',
  SubmitSalesRepIdSuccess = 'PersonalDetails/SubmitSalesRepIdSuccess',
  SubmitSalesRepIdFailure = 'PersonalDetails/SubmitSalesRepIdFailure',
}

const saveApplicantPersonalDetailsError = createAction(
  PersonalDetailsAction.SaveApplicantPersonalDetailsError,
  action => (error: HttpError) => action(error),
);

const resetApplicantPersonalDetailsError = createAction(
  PersonalDetailsAction.ResetApplicantPersonalDetailsError,
);

export const setSalesRepIdDialogOpen = createAction(
  PersonalDetailsAction.SetSalesRepIdDialogOpen,
  action => (open: boolean) => action(open),
);

export const setSalesRepIdComplete = createAction(
  PersonalDetailsAction.SetSalesRepIdComplete,
  action => (complete: boolean) => action(complete),
);

export const submitSalesRepId = createAction(
  PersonalDetailsAction.SubmitSalesRepId,
  action => (salesRepId: string) => action(salesRepId),
);

export const submitSalesRepIdSuccess = createAction(
  PersonalDetailsAction.SubmitSalesRepIdSuccess,
);

export const submitSalesRepIdFailure = createAction(
  PersonalDetailsAction.SubmitSalesRepIdFailure,
  action => (error: HttpError) => action(error),
);

export const PersonalDetailsActions = {
  saveApplicantPersonalDetailsError,
  resetApplicantPersonalDetailsError,
  setSalesRepIdDialogOpen,
  setSalesRepIdComplete,
  submitSalesRepId,
  submitSalesRepIdSuccess,
  submitSalesRepIdFailure,
};

export type PersonalDetailsActionType = ActionType<
  typeof PersonalDetailsActions
>;
