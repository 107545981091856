import {
  Application,
  inReview,
  isApproved,
  isConditionallyApproved,
  isDeclined,
} from '@frontline/common';

export function isApplicationApproved(application: Application): boolean {
  return isApproved(application) || isConditionallyApproved(application);
}

export function isApplicationDeclinedOrInReview(
  application: Application,
): boolean {
  return isDeclined(application) || inReview(application);
}
