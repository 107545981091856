import { HttpService } from '@frontline/web-common';
import { AxiosResponse } from 'axios';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationTakeoverDetails } from '../../../types/application-takeover/application-takeover-details.interfaces';
import { ApplicationTakeoverRequest } from '../../../types/application-takeover/application-takeover-request.interfaces';

export function fetchApplicationTakeoverDetails(
  takeoverCode: string,
): Observable<ApplicationTakeoverDetails> {
  return from(
    HttpService.getHttpInstanceDeprecated()
      .get(`/application/takeover-request/${takeoverCode}`)
      .then(
        (response: AxiosResponse<ApplicationTakeoverDetails>) => response.data,
      ),
  );
}

export function submitApplicationTakeoverResponse(
  acceptTakeover: boolean,
  appTakeoverCode: string,
): Observable<ApplicationTakeoverRequest> {
  return from(
    HttpService.getHttpInstanceDeprecated().patch(
      `/application/takeover-request/consent`,
      {
        consent: acceptTakeover,
        appTakeoverCode,
      },
    ),
  ).pipe(map(response => response.data));
}
