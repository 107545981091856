import { ReviewSummaryState } from '../types/ReviewSummaryState';
import { createReviewSummaryState } from '../types/ReviewSummaryState.functions';
import {
  ReviewSummaryActions,
  ReviewSummaryActionsType,
} from './reviewSummary.actions';

export const reviewSummaryReducer = (
  state: ReviewSummaryState = createReviewSummaryState(),
  action: ReviewSummaryActionsType,
): ReviewSummaryState => {
  switch (action.type) {
    case ReviewSummaryActions.SubmitApplication:
      return {
        ...state,
        isSubmitting: true,
      };
    case ReviewSummaryActions.OpenTimeoutDialog: {
      return {
        ...state,
        isSubmitting: false,
        isSubmissionTimeoutDialogOpen: true,
      };
    }
    case ReviewSummaryActions.CloseTimeoutDialog: {
      return {
        ...state,
        isSubmitting: false,
        isSubmissionTimeoutDialogOpen: false,
      };
    }
    default:
      return state;
  }
};
