import { Alert, withShowIf } from '@frontline/ui-library';
import React, { FunctionComponent } from 'react';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  error: {
    id: 'ThirdPartyAddOn.error',
    description:
      'Error message to applicant showing that the add on costs are invalid, and to contact their sales representative',
    defaultMessage:
      'Third party add on costs are invalid. Please contact your sales representative.',
  },
});

const AlertMessage: FunctionComponent = () => {
  const intl = useIntl();
  return (
    <div style={{ margin: '1em 0' }}>
      <Alert type="error">{intl.formatMessage(messages.error)}</Alert>
    </div>
  );
};

export const AddOnAlertMessage = withShowIf(AlertMessage);
