import { defineMessages } from 'react-intl';

export default defineMessages({
  applicant: {
    id: 'PersonalDetailsStep.applicant',
    description: 'Applicant',
    defaultMessage: 'Applicant',
  },
  theApplicant: {
    id: 'PersonalDetailsStep.theApplicant',
    description: 'the applicant',
    defaultMessage: 'the applicant',
  },
  description: {
    id: 'IncomeVerification.description',
    description: 'Income Details Step Description',
    defaultMessage:
      'Almost there! Please provide a few more details about yourself to continue',
  },
});
