import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  AssetCost: {
    id: 'ApplicantPortalAssetInformation.AssetCostValidation',
  },
  title: {
    id: 'Applicant.PostSubmission.AssetInformation.title',
    description: 'Header for Asset Information page',
  },
  description: {
    id: 'Applicant.PostSubmission.AssetInformation.description',
    description: 'Description for Asset Information page',
  },
  toolTipDescription1: {
    id:
      'Applicant.PostSubmission.AssetInformation.AssetInformationView.toolTip.description1',
    description: 'Tooltip description 1',
  },
  toolTipDescription2: {
    id:
      'Applicant.PostSubmission.AssetInformation.AssetInformationView.toolTip.description2',
    description: 'Tooltip description 2',
  },
});
