import { RepairTechFeatureModule } from '@frontline/web-common';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ProtectedRoute } from '../../modules/auth/components/ProtectedRoute/ProtectedRoute';
import { MainLayout } from '../../modules/layout/components';
import {
  getRepairTechRoute,
  getVehicleInformationRoute,
} from './types/RepairTechRoutes.functions';

const RepairTechComponent = () => (
  <RepairTechFeatureModule
    getRepairTechRoute={getRepairTechRoute}
    getVehicleInformationRoute={getVehicleInformationRoute}
    history={useHistory()}
    routeComponent={ProtectedRoute}
  />
);

export const RepairTechFeature = MainLayout(RepairTechComponent);
