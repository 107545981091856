import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'FinalizeCoApplicantStep.title',
    description: 'Applicant Step Title',
    defaultMessage: 'Review the co-applicant details',
  },
  description: {
    id: 'FinalizeCoApplicantStep.description',
    description: 'Co-Applicant Step Description',
    defaultMessage:
      'Review or edit the co-applicant details to rescore the application',
  },
  editButton: {
    id: 'FinalizeCoApplicantStep.editButton',
    description: 'Applicant Step Edit Button',
    defaultMessage: 'Review co-applicant',
  },
  removeCoApplicantButton: {
    id: 'FinalizeCoApplicantStep.removeCoApplicantButton',
    description: 'Applicant Step Add Co-Applicant Button',
    defaultMessage: 'Remove co-applicant',
  },
});
