import {
  findWorkflowStepByType,
  LoanDetailsRules,
  WorkflowStepType,
} from '@frontline/common';
import {
  FormatMoney,
  Loader,
  ShowIfFragment,
  useUatId,
} from '@frontline/ui-library';
import {
  ApplicationStepsFooter,
  ApplicationStepsHeader,
  CongratulationsApprovedAmountMessage,
  LocaleContext,
} from '@frontline/web-common';
import { Formik } from 'formik';
import isEqual from 'lodash/isEqual';
import React, { useContext, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as applicantApplicationOptionsStore from '../../../../../../../../modules/applicant-application-options/store/applicant-application-options.store';
import * as applicationStore from '../../../../../../../../store/application.store';
import * as postSubmissionStore from '../../../../../../store/post-submission.store';
import { PostSubmissionContentCard } from '../../../../components/post-submission-content-card';
import { PostSubmissionReviewFeatureFunctions } from '../../../../types/PostSubmissionReviewFeature.functions';
import { LoanDetailsForm } from '../../modules/loan-details-form/loan-details-form';
import { LoanDetailsPurchaseDetails } from '../../modules/loan-details-purchase-details/components/loan-details-purchase-details.component';
import { shouldShowPurchaseDetails } from '../../modules/loan-details-purchase-details/types/loan-details-purchase-details.functions';
import * as store from '../../store/loan-details.store';
import { shouldShowCongratulationsMessage } from '../../types/congratulations-approved-amount-message.functions';
import { LoanDetailsFormValues } from '../../types/loan-details-form-values';
import {
  createLoanDetailsFormValues,
  mapFormValuesToLoanDetails,
} from '../../types/loan-details-form-values.functions';
import { getLoanDetailsValidationSchema } from '../../types/loan-details-validation-schema.functions';
import { isProtectAssetButtonDisabled } from '../../types/loan-details.functions';
import { LoanDetailsWaitingForMerchant } from '../loan-details-waiting-for-merchant/loan-details-waiting-for-merchant';
import messages from './loan-details.messages';
import { LoanDetailsUat } from './loan-details.uat';

export const LoanDetails = () => {
  const { locale } = useContext(LocaleContext);
  const intl = useIntl();
  const uatId = useUatId();
  const dispatch = useDispatch();
  const isLoading: boolean = useSelector(store.isLoading);
  const loanDetailsDto = useSelector(store.getLoanDetailsDto);
  const formValues = useMemo(
    () => createLoanDetailsFormValues(loanDetailsDto),
    [loanDetailsDto],
  );
  const workflowSteps = useSelector(postSubmissionStore.getWorkflow) || [];
  const applicantApplicationOption = useSelector(
    applicantApplicationOptionsStore.getApplicantApplicationOption,
  );

  const application = useSelector(applicationStore.getApplication);

  const workflowStep = useMemo(() => {
    return findWorkflowStepByType(
      workflowSteps,
      WorkflowStepType.LoanAndFinancingDetails,
    );
  }, [workflowSteps]);

  useEffect(() => {
    store.fetchLoanDetails();
  }, [dispatch, locale]);

  const disableProtectAssetButton = useMemo<boolean>(() => {
    if (application) {
      return isProtectAssetButtonDisabled(application.status);
    }
    return false;
  }, [application]);

  const saveLoanDetails = (valuesToSave: LoanDetailsFormValues): void => {
    if (isEqual(formValues, valuesToSave)) {
      store.toNextStep();
    } else {
      store.setIsLoading();
      store.saveLoanDetails(
        mapFormValuesToLoanDetails(valuesToSave, loanDetailsDto?.invoice),
      );
      store.toNextStep();
    }
  };

  const congratulationsMsg = intl.formatMessage(messages.congratulations, {
    approvedAmount: (
      <FormatMoney value={Number(loanDetailsDto.approvedAmount)} />
    ),
  });

  const stepRules = workflowStep?.rules as LoanDetailsRules | null;

  const loanDetailsMessageDescriptor = stepRules?.showAcknowledgementForInvoiceEstimateAmount
    ? messages.provideEstimateAndConfirm
    : messages.loanDetailsHeaderdescription;

  const hideViewLoanDetailsPurchaseDetails: boolean = PostSubmissionReviewFeatureFunctions.hideFundingDetails(
    applicantApplicationOption,
    workflowStep,
  );

  return (
    <PostSubmissionContentCard>
      <Loader show={isLoading} />
      <CongratulationsApprovedAmountMessage
        showIf={shouldShowCongratulationsMessage(
          stepRules,
          loanDetailsDto.approvedAmount,
        )}>
        {congratulationsMsg}
      </CongratulationsApprovedAmountMessage>
      <ApplicationStepsHeader
        title={intl.formatMessage(messages.title)}
        description={intl.formatMessage(loanDetailsMessageDescriptor)}
      />
      <ShowIfFragment showIf={!hideViewLoanDetailsPurchaseDetails}>
        <LoanDetailsPurchaseDetails
          showIf={shouldShowPurchaseDetails(stepRules)}
          hideAssetSubtype={stepRules?.hideAssetSubtype}
          assetSubTypeName={loanDetailsDto.assetSubTypeName}
          assetDescription={loanDetailsDto.assetDescription}
        />
        <Formik
          initialValues={formValues}
          enableReinitialize={true}
          validationSchema={getLoanDetailsValidationSchema(stepRules)}
          onSubmit={saveLoanDetails}
          render={({ handleSubmit, values, setFieldValue }) => (
            <LoanDetailsForm
              setFieldValue={setFieldValue}
              handleSubmit={handleSubmit}
              formValues={values}
              loanDetails={loanDetailsDto}
              onBack={store.toPreviousStep}
              rules={stepRules}
              disableProtectAssetButton={disableProtectAssetButton}
            />
          )}
        />
      </ShowIfFragment>
      <ShowIfFragment showIf={hideViewLoanDetailsPurchaseDetails}>
        <LoanDetailsWaitingForMerchant />
        <ApplicationStepsFooter
          onBack={postSubmissionStore.navigateToPreviousStep(dispatch)}
          onSubmit={postSubmissionStore.navigateToNextStep(dispatch)}
          buttonUat={uatId(LoanDetailsUat.loanDetailsFormSubmitButton)}
        />
      </ShowIfFragment>
    </PostSubmissionContentCard>
  );
};
