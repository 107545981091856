import { LendCareState } from './lendcare-state.interface';

export function createBuyLendCareState(): LendCareState {
  return {
    accessKey: null,
    applicationId: null,
    refId: null,
    referrerId: null,
    eventId: null,
    campaignId: null,
  };
}
