import { ComponentSelectorState } from '../types/ComponentSelectorState';
import { createDefaultComponentSelectorState } from '../types/ComponentSelectorState.functions';
import {
  ComponentSelectorActions,
  ComponentSelectorActionType,
} from './componentSelector.actions';

export const componentSelectorReducer = (
  state: ComponentSelectorState = createDefaultComponentSelectorState(),
  action: ComponentSelectorActionType,
): ComponentSelectorState => {
  switch (action.type) {
    case ComponentSelectorActions.InitializeChoices: {
      return {
        choices: action.payload.choices,
        decisionsKey: action.payload.decisionKey,
      };
    }
    case ComponentSelectorActions.SelectDecision: {
      return {
        ...state,
        selectedDecision: action.payload,
      };
    }
    default:
      return state;
  }
};
