import { defineMessages } from 'react-intl';

export default defineMessages({
  congratulations: {
    id: 'Applicant.Approved.congratulations',
    defaultMessage: 'Congratulations, your application has been approved!',
  },
  purchaseSectionLabel: {
    id: 'Applicant.Approved.purchaseSectionLabel',
    defaultMessage: 'Purchase',
  },
  applicantSectionLabel: {
    id: 'Applicant.Approved.applicantSectionLabel',
    defaultMessage: 'Applicant',
  },
  coApplicantSectionLabel: {
    id: 'Applicant.Approved.coApplicantSectionLabel',
    defaultMessage: 'Co-Applicant',
  },
  continueButton: {
    id: 'Applicant.Approved.continueButton',
    defaultMessage: 'Continue',
  },
});
