import { ApplicantApplicationOptionDto } from '@frontline/common';
import { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import { RootState, Store } from '../../../../../store';
import { getApplicationId } from '../../../store/application.store';
import { ApplicantApplicationOptionsState } from '../types/applicant-application-options-state';
import * as actions from './applicant-application-options.actions';

export const getApplicantApplicationOptionsState: Selector<
  RootState,
  ApplicantApplicationOptionsState
> = (state: RootState): ApplicantApplicationOptionsState =>
  state.applicationState.applicantApplicationOptionsState;

export const getApplicantApplicationOption: Selector<
  RootState,
  ApplicantApplicationOptionDto | undefined
> = createSelector(
  getApplicantApplicationOptionsState,
  getApplicationId,
  (optionsState: ApplicantApplicationOptionsState, applicationId?: string) => {
    if (applicationId === undefined || optionsState === undefined) {
      return undefined;
    }

    return optionsState.options[applicationId];
  },
);

export const isLoading: Selector<RootState, boolean> = createSelector(
  getApplicantApplicationOptionsState,
  (state?: ApplicantApplicationOptionsState) => state?.isLoading || false,
);

export const goToPostAfterPostSubmission: Selector<
  RootState,
  boolean
> = createSelector(
  getApplicantApplicationOption,
  (applicationOptions?: ApplicantApplicationOptionDto) =>
    Boolean(applicationOptions?.goToPostAfterPresubmission),
);

export const fetchApplicantApplicationOptionForApplication = (
  applicationId: string,
): void => {
  Store.dispatch(
    actions.fetchApplicantApplicationOptionForApplication(applicationId),
  );
};
