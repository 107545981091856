import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'uploadDocuments.title',
    description: 'Upload documents page title',
  },
  description: {
    id: 'uploadDocuments.description',
    description: 'Upload documents page description',
  },
  requiredDocuments: {
    id: 'uploadDocuments.requiredDocuments',
    description: 'Subtitle for required documents list',
  },
  billOfSaleTitle: {
    id: 'uploadDocuments.billOfSaleTitle',
    description:
      'Title of the bill of sales to be uploaded in the Upload Documents page',
  },
  billOfSaleDescription: {
    id: 'uploadDocuments.billOfSaleDescription',
    description: 'Description of the bill of sale document to be uploaded',
  },
  additionalDocumentsRequiredTitle: {
    id: 'uploadDocuments.additionalDocumentsRequiredTitle',
    description:
      'Title of the document to be uploaded in the Upload Documents page',
  },
  additionalDocumentsOptionalTitle: {
    id: 'uploadDocuments.additionalDocumentsOptionalTitle',
    description:
      'Title of the document to be uploaded in the Upload Documents page',
  },
  additionalDocumentsDescription: {
    id: 'uploadDocuments.additionalDocumentsDescription',
    description: 'Description of the additional documents to be uploaded',
  },
  bankTransactionsTitle: {
    id: 'uploadDocuments.bankTransactionsTitle',
    description:
      'Title of the bank transaction to be uploaded in the Upload Documents page',
  },
  bankTransactionsDescription: {
    id: 'uploadDocuments.bankTransactionsDescription',
    description: 'Description of the bank transaction to be uploaded',
  },
  signedContractTitle: {
    id: 'uploadDocuments.signedContractTitle',
    description:
      'Title of the signed contract to be uploaded in the Upload Documents page',
  },
  signedContractDescription: {
    id: 'uploadDocuments.signedContractDescription',
    description: 'Description of the signed contract to be uploaded',
  },
  unsignedLoanProtectionTitle: {
    id: 'uploadDocuments.unsignedLoanProtectionTitle',
    description:
      'Title of the insighned loan protection to be uploaded in the Upload Documents page',
  },
  unsignedLoanProtectionDescription: {
    id: 'uploadDocuments.unsignedLoanProtectionDescription',
    description: 'Description of the unsigned loan protection to be uploaded',
  },
  voidChequeTitle: {
    id: 'uploadDocuments.voidChequeTitle',
    description:
      'Title of the void cheque to be uploaded in the Upload Documents page',
  },
  voidChequeDescription: {
    id: 'uploadDocuments.voidChequeDescription',
    description: 'Description of the void cheque to be uploaded',
  },
  signedAgreementTitle: {
    id: 'uploadDocuments.signedAgreementTitle',
    description:
      'Title of the signed agreement to be uploaded in the Upload Documents page',
  },
  signedAgreementDescription: {
    id: 'uploadDocuments.signedAgreementDescription',
    description: 'Description of the signed agreement to be uploaded',
  },
  finalFundingPackageTitle: {
    id: 'uploadDocuments.finalFundingPackageTitle',
    description:
      'Title of the final funding package to be uploaded in the Upload Documents page',
  },
  finalFundingPackageDescription: {
    id: 'uploadDocuments.finalFundingPackageDescription',
    description: 'Description of the final funding package to be uploaded',
  },
  unknownDocument: {
    id: 'uploadDocuments.unknownDocument',
    description: 'The title of the document is unknown',
  },
  attachDocSuccessMessage: {
    id: 'uploadDocuments.attachDocSuccessMessage',
    description:
      'Message do be displayed when applicant successfully attaches the documents',
  },
  attachDocErrorMessage: {
    id: 'uploadDocuments.attachDocErrorMessage',
    description:
      'Message do be displayed when applicant fails on attaching the documents',
  },
  documentsPendingSubmissionWarningMessage: {
    id: 'uploadDocuments.documentsPendingSubmissionWarningMessage',
  },
});
