import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'CoApplicantPersonalDetails.title',
    description: 'Personal Details Step Title',
    defaultMessage: "Co-applicant's Personal Details",
  },
  description: {
    id: 'CoApplicantPersonalDetails.description',
    description: 'Personal Details Step Description',
    defaultMessage:
      'Welcome to your LendCare application! Please provide some details about yourself to continue. To ensure accuracy, enter the details exactly as they appear on your government issued ID.',
  },
});
