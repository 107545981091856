import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as applicantApplicationOptionsStore from '../../../../../../../../modules/applicant-application-options/store/applicant-application-options.store';
import * as postSubmissionReviewStore from '../../../../../../store/post-submission.store';
import * as postSubmissionStore from '../../../../../../store/post-submission.store';
import { PostSubmissionContentCard } from '../../../../components/post-submission-content-card';
import { PostSubmissionReviewFeatureFunctions } from '../../../../types/PostSubmissionReviewFeature.functions';
import * as store from '../../store/assets-and-addons.store';
import { AssetsAndAddOnView } from '../assets-and-addons-view/assets-and-addons-view.component';

export const AssetsAndAddOns = () => {
  const dispatch = useDispatch();

  const assets = useSelector(store.getAssetInformation);
  const purchaseDetails = useSelector(store.getPurchaseDetails);
  const validations = useSelector(store.getValidations);
  const addOns = useSelector(store.getAddOns);
  const taxDetails = useSelector(store.getTaxDetails);

  const addOnTypes = useSelector(store.getAddOnTypes);
  const applicantApplicationOption = useSelector(
    applicantApplicationOptionsStore.getApplicantApplicationOption,
  );
  const workflowStep = useSelector(postSubmissionStore.getActiveWorkflowStep);

  useEffect(() => {
    if (!addOnTypes || addOnTypes.length === 0) {
      store.fetchAddOnTypes(dispatch)();
    }
  }, [dispatch, addOnTypes]);

  const hideAddOnsDetails: boolean = PostSubmissionReviewFeatureFunctions.hideAddOns(
    applicantApplicationOption,
    workflowStep,
  );

  useEffect(() => {
    if (!validations) {
      store.fetchValidations();
    }
  }, [validations]);

  return (
    <PostSubmissionContentCard>
      <AssetsAndAddOnView
        assets={assets || []}
        addOns={addOns}
        taxDetails={taxDetails}
        tradeInAmount={purchaseDetails.tradeInAmount}
        addOnTypes={addOnTypes}
        validations={validations}
        hideAddOnsDetails={hideAddOnsDetails}
        assetSubTypeName={purchaseDetails?.assetSubTypeName || ''}
        onNextStep={postSubmissionReviewStore.navigateToNextStep(dispatch)}
        onPreviousStep={postSubmissionReviewStore.navigateToPreviousStep(
          dispatch,
        )}
      />
    </PostSubmissionContentCard>
  );
};
