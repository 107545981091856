import {
  Applicant,
  Application,
  BankDetails,
  FlinksStatus,
  getActiveWorkflowSteps,
  getFirstIncompleteStep,
  LoanDetails,
  Merchant,
  ProgramType,
  StoredDocument,
  WorkflowDecisions,
  WorkflowStep,
} from '@frontline/common';
import capitalize from 'lodash/capitalize';
import { Dispatch } from 'redux';
import { createSelector, Selector } from 'reselect';
import { RootState } from '../../../store';
import { Store } from '../../../store';
import * as rootApplicationActions from '../../../store/application/actions';
import * as rootApplicationSelectors from '../../../store/application/selectors';

export const getApplicationState = (state: RootState) => state.applicationState;

export const getApplications = (state: RootState) =>
  state.application.applications;

export const getApplicationId: Selector<RootState, string> = (
  state: RootState,
) => state.application.currentApplication || '';

export const getApplicantPersonalDetails = (state: RootState) =>
  rootApplicationSelectors.getApplicantPersonalDetails(state.application);

export const getApplication: Selector<
  RootState,
  Application | undefined
> = createSelector(
  getApplications,
  getApplicationId,
  (applications, currentId) => {
    return currentId ? applications[currentId] : undefined;
  },
);

export const getMerchant: Selector<
  RootState,
  Merchant | undefined
> = createSelector(getApplication, (application?: Application) => {
  return application?.merchant;
});

export const getEdgeApplicationId: Selector<
  RootState,
  number | undefined
> = createSelector(
  getApplication,
  (application?: Application) => application?.externalId,
);

export const shouldDisplayInterestRate: Selector<RootState, boolean> = (
  state: RootState,
) => rootApplicationSelectors.shouldDisplayInterestRate(state.application);

export const hasCoApplicant: Selector<RootState, boolean> = (
  state: RootState,
) => rootApplicationSelectors.hasCoApplicant(state.application);

export const isLoading: Selector<RootState, boolean> = createSelector(
  ({ application }) => rootApplicationSelectors.isSaving(application),
  ({ application }) => rootApplicationSelectors.isLoading(application),
  (saving: boolean, loading: boolean): boolean => saving || loading,
);

export const loadApplication = (dispatch: Dispatch<any>) => (
  applicationId: string,
): void => {
  dispatch(rootApplicationActions.loadApplication(applicationId));
};

export const getBankDetails: Selector<RootState, BankDetails | undefined> = (
  state: RootState,
) => rootApplicationSelectors.getBankDetails(state.application);

export const getFlinksStatus: Selector<RootState, FlinksStatus> = (
  state: RootState,
) => rootApplicationSelectors.getFlinksStatus(state.application);

export const getLoanDetails: Selector<
  RootState,
  LoanDetails | undefined
> = createSelector(getApplication, application => {
  return application && application.loanDetails;
});

export const getProgramType: Selector<
  RootState,
  ProgramType | undefined
> = createSelector(getApplication, application => {
  return application?.program?.type;
});

export const getApplicant: Selector<
  RootState,
  Applicant | undefined
> = createSelector(getApplication, application => {
  return application && application.applicant;
});

export const getCoApplicants: Selector<
  RootState,
  Applicant[] | undefined
> = createSelector(getApplication, application => {
  return application && application.coApplicants;
});

export const getCoApplicant: Selector<
  RootState,
  Applicant | undefined
> = createSelector(getCoApplicants, coApplicants => {
  return coApplicants && coApplicants[0];
});

export const getApplicantFirstName: Selector<
  RootState,
  string
> = createSelector(getApplicant, applicant => {
  return capitalize(applicant?.personalDetails?.firstName || '');
});

export const getApplicantLastName: Selector<RootState, string> = createSelector(
  getApplicant,
  applicant => {
    return capitalize(applicant?.personalDetails?.lastName || '');
  },
);

export const getCoApplicantFirstName: Selector<
  RootState,
  string
> = createSelector(getCoApplicant, applicant => {
  return capitalize(applicant?.personalDetails?.firstName || '');
});

export const getMerchantId: Selector<
  RootState,
  number | undefined
> = createSelector(
  getApplication,
  (application?: Application) => application?.merchantId,
);

export const getApplicationDocuments: Selector<
  RootState,
  StoredDocument[]
> = createSelector(
  getApplication,
  (application?: Application) => application?.documents || [],
);

export const getWorkflowDecisions: Selector<
  RootState,
  WorkflowDecisions | undefined
> = createSelector(
  getApplication,
  (application?: Application) => application?.workflowDecisions,
);

export const getPostSubmissionWorkflow: Selector<
  RootState,
  WorkflowStep[]
> = createSelector(
  getApplication,
  (application?: Application) => application?.postWorkflow?.steps || [],
);

export const getActivePostSubmissionWorkflow: Selector<
  RootState,
  WorkflowStep[]
> = createSelector(
  getPostSubmissionWorkflow,
  getApplication,
  (workflow: WorkflowStep[], application?: Application) =>
    getActiveWorkflowSteps(workflow, application),
);

export const getNextPostSubmissionRoute: Selector<
  RootState,
  string | undefined
> = createSelector(
  getPostSubmissionWorkflow,
  (workflow: WorkflowStep[]) => getFirstIncompleteStep(workflow)?.routeUrl,
);

export const updateAccountDetailsWithFlinks = (
  loginId: string,
  accountId: string,
) => {
  Store.dispatch(
    rootApplicationActions.updateAccountDetailsWithFlinks({
      loginId,
      accountId,
    }),
  );
};
