import { defineMessages } from 'react-intl';

export const ApplicationTakeoverConfirmMessages = defineMessages({
  title: {
    id: 'Applicant.ApplicationTakeover.View.title',
    description: 'Application Takeover page title',
    defaultMessage:
      '{merchantName} is requesting access to your LendCare pre-qualification.',
  },
  confirmationText: {
    id: 'Applicant.ApplicationTakeover.Confirm.confirmationText',
    description: 'Application Takeover page confirmation text',
    defaultMessage: 'Please confirm you wish to proceed.',
  },
  confirmButton: {
    id: 'Applicant.ApplicationTakeover.Confirm.confirmButton',
    description: 'Application Takeover page Confirm Button',
    defaultMessage: 'Confirm, accept request',
  },
  declineButton: {
    id: 'Applicant.ApplicationTakeover.Confirm.declineButton',
    description: 'Application Takeover page cancel Button',
    defaultMessage: 'No, go back',
  },
});
