/* tslint:disable:ordered-imports */
import { HttpService } from '@frontline/web-common';
import { ReportingFieldsDto } from '../../../features/repair-tech/types/reporting-fields-dto';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

function saveReportingFields(
  applicationId: string,
  fields: ReportingFieldsDto,
): Observable<void> {
  return from(
    HttpService.getHttpInstance().post<void>(
      `/applications/${applicationId}/repair-tech/reporting-fields`,
      fields,
    ),
  ).pipe(map(response => response.data));
}

export const RepairTechApiService = {
  saveReportingFields,
};
