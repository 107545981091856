import { initializeIcons } from '@frontline/ui-library';
import { HttpService, initializeGoogleAnalytics } from '@frontline/web-common';

function setupHttpClient() {
  HttpService.addErrorResponseInterceptors();
}

function initializeApp() {
  initializeIcons();
  initializeGoogleAnalytics();
  setupHttpClient();
}

export const AppService = {
  initializeApp,
};
