import { SubmissionStatus } from '@frontline/common';
import {
  ApplicationProgress,
  decorateApplicationStepsWithProgramCustomizations,
  toApplicationSteps,
} from '@frontline/web-common';
import React from 'react';
import { useIntl } from 'react-intl';
import ApplicationPage from '../../../../common/ApplicationLayout/ApplicationLayout';
import { HighCostLicenseDetails } from '../../../../common/high-cost-license-details/high-cost-license-details.component';
import { PreSubmissionTitle } from '../PreSubmissionTitle/PreSubmissionTitle';
import { PreSubmissionLayoutProps } from './pre-submission-layout.props';
import { ElevatedBox } from './pre-submission-layout.styles';

export const PreSubmissionLayout = (props: PreSubmissionLayoutProps) => {
  const intl = useIntl();

  return (
    <ApplicationPage>
      <HighCostLicenseDetails
        showIf={Boolean(props.highCostLicenseDetails)}
        highCostLicenseDetails={props.highCostLicenseDetails!}
      />
      <PreSubmissionTitle applicationId={props.applicationId} />
      <ElevatedBox>
        <ApplicationProgress
          steps={decorateApplicationStepsWithProgramCustomizations(
            toApplicationSteps(props.workflow, intl),
            props.program!,
            intl,
          )}
          onStepClick={props.clickStep}
          showIf={props.submissionStatus !== SubmissionStatus.Submitted}
        />
        {props.children}
      </ElevatedBox>
    </ApplicationPage>
  );
};
