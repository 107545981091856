import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootState } from '../../../../../../../../../store';
import * as applicationStore from '../../../../../../../store/application.store';
import { fetchOwnerContactInfo } from '../services/application-approved.service';
import * as actions from './application-approved.actions';

export const fetchOwnerContactInfoEpic: Epic<Action, Action, RootState> = (
  action$: Observable<Action>,
  state$: any,
) =>
  action$.pipe(
    filter(isActionOf(actions.fetchOwnerContactInfo)),
    map(() => applicationStore.getApplicationId(state$.value)),
    mergeMap(applicationId =>
      fetchOwnerContactInfo(applicationId as string).pipe(
        map(ownerContactInfo =>
          actions.fetchOwnerContactInfoSuccess(ownerContactInfo),
        ),
        catchError(error => of(actions.fetchOwnerContactInfoFailure(error))),
      ),
    ),
  );

export default [fetchOwnerContactInfoEpic];
