import { CalculatorValues, EstimatedPayment } from '@frontline/common';
import debounce from 'lodash/debounce';
import React from 'react';

interface PaymentCalculatorEstimateContextValues {
  fetchEstimatedPayment?: ReturnType<typeof debounce>;
  paymentCalculatorRequestValues?: CalculatorValues;
  estimatedPayment: EstimatedPayment | null;
}

export const PaymentCalculatorEstimateContext = React.createContext<
  PaymentCalculatorEstimateContextValues
>({ estimatedPayment: null });
