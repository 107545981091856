import React from 'react';
import { ApplicantIncomeDetails } from './components/ApplicantIncomeDetails';

export interface ApplicantIncomeDetailsFeatureProps {
  disabled: boolean;
}

export const ApplicantIncomeDetailsFeature = (
  props: ApplicantIncomeDetailsFeatureProps,
) => <ApplicantIncomeDetails {...props} />;
