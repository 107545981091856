import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Applicant.PostSubmission.PostSubmissionCongratulationsDialog.title',
    defaultMessage: "What's next...",
  },
  body: {
    id: 'Applicant.PostSubmission.PostSubmissionCongratulationsDialog.title',
    defaultMessage:
      "Complete the checklist to finalize your payment plan. First, let's add the repair estimate amount and review the payment plan details",
  },
  continue: {
    id: 'Applicant.PostSubmission.PostSubmissionCongratulationsDialog.continue',
    defaultMessage: 'Continue',
  },
});
