import { SectionItem, SectionPanel, withShowIf } from '@frontline/ui-library';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import {
  formattedMoneyRounded,
  formattedSin,
} from '../../../../../../types/formatApplicationData.functions';
import messages from './income-details-info.messages';
import { IncomeDetailsInfoProps } from './income-details-info.props';

export const IncomeDetailsInfo = withShowIf((props: IncomeDetailsInfoProps) => {
  const intl = useIntl();

  const title = intl.formatMessage(props.titleMessageDescriptor);
  return (
    <SectionPanel title={title} editUrl={props.editUrl} uat={props.uat}>
      <Grid container={true} spacing={3} justify="flex-start">
        <SectionItem
          label={intl.formatMessage(messages.netMonthlyIncomeLabel)}
          data={formattedMoneyRounded(props.incomeDetails?.netMonthlyIncome)}
        />
        <SectionItem
          label={intl.formatMessage(messages.sinLabel)}
          data={formattedSin(props.incomeDetails?.sin)}
        />
      </Grid>
    </SectionPanel>
  );
});
