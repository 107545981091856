import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'SignDocumentsStep.title',
    description: 'Sign Documents Step Title',
    defaultMessage: 'Review and sign loan documents',
  },
  description: {
    id: 'SignDocumentsStep.description',
    description: 'Sign Documents Step Description',
    defaultMessage:
      'Select the digital signing method and sign all required loan documents',
  },
  editButton: {
    id: 'SignDocumentsStep.editButton',
    description: 'Sign Documents Step Edit Button',
    defaultMessage: 'Sign loan documents',
  },
});
