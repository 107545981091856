import { InvoiceDetailsFeatureModule } from '@frontline/web-common';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as applicationStore from '../../../../../../store/application.store';
import * as postSubmissionReviewStore from '../../../../store/post-submission.store';
import { PostSubmissionContentCard } from '../../components/post-submission-content-card';
import * as store from './store/invoiceDetails.store';

export const InvoiceDetailsFeature = () => {
  const dispatch = useDispatch();
  return (
    <PostSubmissionContentCard>
      <InvoiceDetailsFeatureModule
        getInvoiceDetailsState={store.getInvoiceDetailsState}
        onBack={postSubmissionReviewStore.navigateToPreviousStep(dispatch)}
        applicationId={useSelector(applicationStore.getApplicationId)}
      />
    </PostSubmissionContentCard>
  );
};
