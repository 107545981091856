import {
  ShowIfFragment,
  TypographyVariant,
  useIsMobile,
} from '@frontline/ui-library';
import {
  ApplicationStepsFooter,
  ApplicationStepsHeader,
  MoreInfoTooltip,
} from '@frontline/web-common';
import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { AccordionView } from '../accordion-view/accordion-view.component';
import { AddonsViewDesktop } from '../addons-view-desktop/addons-view-desktop.component';
import { AddonsViewMobile } from '../addons-view-mobile/addons-view-mobile.component';
import { AssetsView } from '../assets-view/assets-view.component';
import { TotalLine } from '../total-line/total-line.component';
import {
  getSumOfAddOnsCost,
  getSumOfAddOnsPreTaxCost,
  getSumOfAssetsPreTaxCost,
  getTotalAssetsAndTradeInAndAddonsWithTax,
  getTotalAssetsAndTradeInPreTaxCost,
  getTotalAssetsAndTradeWithTax,
} from './assets-and-addons-view.functions';
import { AssetsAndAddOnViewMessages as messages } from './assets-and-addons-view.messages';
import { AssetsAndAddOnViewProps } from './assets-and-addons-view.props';
import { useAssetsAndAddOnViewStyles } from './assets-and-addons-view.styles';

export const AssetsAndAddOnView = (props: AssetsAndAddOnViewProps) => {
  const intl = useIntl();
  const classes = useAssetsAndAddOnViewStyles();
  const isMobile = useIsMobile();
  const [expanded, setExpanded] = useState<string | false>('assets');
  const numberOfAssets = props.assets.length;
  const numberOfAddOns = props.addOns.length;
  const AddonsViewForDevice = isMobile ? AddonsViewMobile : AddonsViewDesktop;

  return (
    <React.Fragment>
      <ApplicationStepsHeader
        title={intl.formatMessage(messages.title)}
        description={intl.formatMessage(messages.description)}>
        <div className={classes.container}>
          <MoreInfoTooltip
            description={[
              intl.formatMessage(messages.toolTipDescription1),
              intl.formatMessage(messages.toolTipDescription2),
              intl.formatMessage(messages.toolTipDescription3),
            ]}
          />
        </div>
      </ApplicationStepsHeader>
      <AccordionView
        header={intl.formatMessage(messages.assetsAccordionTitle, {
          numberOfAssets,
        })}
        name={'assets'}
        expandedState={expanded}
        onChange={setExpanded}>
        <AssetsView
          assets={props.assets}
          assetSubTypeName={props.assetSubTypeName}
          validations={props.validations}
          totalAssetsCost={getSumOfAssetsPreTaxCost(props.assets)}
          preTaxCost={getTotalAssetsAndTradeInPreTaxCost(
            props.assets,
            props.tradeInAmount,
          )}
          totalCost={getTotalAssetsAndTradeWithTax(
            props.assets,
            props.tradeInAmount,
            props.taxDetails?.rate,
          )}
          tradeInAmount={props.tradeInAmount}
          taxDetails={props.taxDetails}
        />
      </AccordionView>
      <AccordionView
        header={intl.formatMessage(messages.addOnsAccordionTitle, {
          numberOfAddOns,
        })}
        name={'addons'}
        expandedState={expanded}
        onChange={setExpanded}>
        {props.hideAddOnsDetails ? (
          <Typography variant={TypographyVariant.Header2}>
            <FormattedMessage {...messages.waitingForMerchant} />
          </Typography>
        ) : (
          <AddonsViewForDevice
            addOns={props.addOns}
            addOnTypes={props.addOnTypes}
            taxEnabled={props.taxDetails?.enabled}
          />
        )}
        <ShowIfFragment showIf={props.taxDetails?.enabled}>
          <TotalLine
            message={intl.formatMessage(messages.totalAddOnsPreTax)}
            amount={getSumOfAddOnsPreTaxCost(props.addOns)}
          />
        </ShowIfFragment>
        <TotalLine
          message={
            props.taxDetails?.enabled
              ? intl.formatMessage(messages.totalAddOnsPostTax)
              : intl.formatMessage(messages.totalAddOns)
          }
          amount={getSumOfAddOnsCost(props.addOns)}
        />
      </AccordionView>
      <TotalLine
        message={intl.formatMessage(messages.totalAssetsAndAddOns)}
        amount={getTotalAssetsAndTradeInAndAddonsWithTax(
          props.assets,
          props.addOns,
          props.tradeInAmount,
          props.taxDetails?.rate,
        )}
      />
      <ApplicationStepsFooter
        onSubmit={props.onNextStep}
        onBack={props.onPreviousStep}
      />
    </React.Fragment>
  );
};
