import { createOverviewState, OverviewState } from '../types';
import { OverviewActions, OverviewActionType } from './overview.actions';

export const overviewReducer = (
  state: OverviewState = createOverviewState(),
  action: OverviewActionType,
) => {
  switch (action.type) {
    case OverviewActions.ToggleSummaryCardDetails:
      return {
        ...state,
        isSummaryDetailsOpen: !state.isSummaryDetailsOpen,
      };
    case OverviewActions.GoToOverviewTab:
      return {
        ...state,
        overviewPage: action.payload,
      };
    case OverviewActions.InitConditionsLookup:
      return {
        ...state,
        loading: true,
      };
    case OverviewActions.InitConditionsLookupSuccess:
      return {
        ...state,
        approvalConditionLookup: action.payload.approvalConditionsLookupList,
        approvalConditionLanguage: action.payload.locale,
        loading: false,
      };
    case OverviewActions.InitConditionsLookupError:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case OverviewActions.OpenCongratulationsDialog:
      return {
        ...state,
        isCongratulationsDialogOpen: true,
      };
    case OverviewActions.SetPostSubmissionViewed:
      return {
        ...state,
        loading: true,
      };
    case OverviewActions.SetPostSubmissionViewedSuccess:
      return {
        ...state,
        loading: false,
        isCongratulationsDialogOpen: false,
      };
    case OverviewActions.SetPostSubmissionViewedFailure:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
