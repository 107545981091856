import {
  FraudDetectionDataPoints,
  fraudDetectionEvaluateRisk,
  FraudDetectionState,
  setFraudDetectionDataPoints,
} from '@frontline/web-common';
import { createSelector, Selector } from 'reselect';
import { RootState, Store } from '../../../store';
import * as actions from './applicant-fraud-detection.actions';

export const getFraudDetectionState = (state: RootState): FraudDetectionState =>
  state.fraudDetectionState;

export const getFraudDetectionDataPoints: Selector<
  RootState,
  FraudDetectionDataPoints
> = createSelector(
  getFraudDetectionState,
  (state: FraudDetectionState) => state.dataPoints,
);

export const storeFraudDetectionDataPoints = (
  data: FraudDetectionDataPoints,
) => {
  Store.dispatch(setFraudDetectionDataPoints(data));
};

export const evaluateRiskForUniqueLinks = () => {
  Store.dispatch(actions.evaluateRisk('application-links'));
};

export const evaluateRiskForApplication = () => {
  Store.dispatch(actions.evaluateRisk('application'));
};

export const applicantFraudDetectionEvaluateRisk = () =>
  Store.dispatch(fraudDetectionEvaluateRisk());
