import { TypographyVariant } from '@frontline/ui-library';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import { AssetInformationSectionHeader } from '../assets-section-header/assets-section-header.component';
import messages from './assets-purchase-details.messages';
import { AssetInformationPurchaseDetailsProps } from './assets-purchase-details.props';

export const AssetInformationPurchaseDetails = (
  props: AssetInformationPurchaseDetailsProps,
) => {
  const intl = useIntl();

  return (
    <React.Fragment>
      <AssetInformationSectionHeader
        title={intl.formatMessage(messages.purchaseDetailsTitle)}
      />

      <Grid item={true} xs={6} md={6} sm={12}>
        <Typography variant={TypographyVariant.Subtitle1}>
          {intl.formatMessage(messages.itemPurchasedLabel)}
        </Typography>
      </Grid>

      <Grid item={true} xs={6} md={6} sm={12}>
        {props.assetSubTypeName}
      </Grid>
    </React.Fragment>
  );
};
