import { createDefaultAdditionalDetailsState } from '../../common/AdditionalDetails/types';
import { createPostSubmissionState } from '../../features/postSubmission/types';
import { createPersonalDetailsState } from '../../features/preSubmission/common/personal-details/types/personal-details-state.functions';
import { createPreSubmissionState } from '../../features/preSubmission/types/PreSubmissionState/PreSubmissionState.functions';
import { createDefaultApplicantApplicationOptionsState } from '../../modules/applicant-application-options/types/applicant-application-options-state.functions';

export const createDefaultApplicationState = () => ({
  postSubmissionState: createPostSubmissionState(),
  preSubmissionState: createPreSubmissionState(),
  additionalDetailsState: createDefaultAdditionalDetailsState(),
  applicantApplicationOptionsState: createDefaultApplicantApplicationOptionsState(),
  personalDetailsState: createPersonalDetailsState(),
});
