import { SigningDocumentAction } from '@frontline/common';
import {
  createSignDocumentsState,
  SignDocumentsState,
} from '@frontline/web-common';
import {
  SignDocumentsActions,
  SignDocumentsActionType,
} from './sign-documents.actions';

export const signDocumentsReducer = (
  state: SignDocumentsState = createSignDocumentsState(),
  action: SignDocumentsActionType,
): SignDocumentsState => {
  switch (action.type) {
    case SignDocumentsActions.CloseEmailDialog: {
      return { ...state, documentOpenedForEmailing: null };
    }
    case SignDocumentsActions.CloseSignDocumentDialog: {
      return { ...state, documentOpenForSigning: null };
    }
    case SignDocumentsActions.EmailDocument: {
      return { ...state, updating: true };
    }
    case SignDocumentsActions.EmailDocumentSuccess: {
      return {
        ...state,
        updating: false,
        documentOpenedForEmailing: null,
        error: null,
      };
    }
    case SignDocumentsActions.EmailDocumentError:
    case SignDocumentsActions.LoadSignDocumentsError: {
      return {
        ...state,
        updating: false,
        error: action.payload.error,
      };
    }
    case SignDocumentsActions.LoadSignDocuments: {
      return { ...state, updating: true };
    }
    case SignDocumentsActions.LoadSignDocumentsSuccess: {
      return { ...state, error: null };
    }
    case SignDocumentsActions.OpenEmailDialog: {
      return {
        ...state,
        documentOpenedForEmailing: action.payload,
        isEmailOrEmailAndSmsAction: SigningDocumentAction.Email,
      };
    }
    case SignDocumentsActions.OpenSignDocumentDialog: {
      return { ...state, documentOpenForSigning: action.payload };
    }
    case SignDocumentsActions.PrintSignDocument: {
      return { ...state, printing: action.payload };
    }
    case SignDocumentsActions.PrintSignDocumentSuccess: {
      return { ...state, printing: null, error: null };
    }
    case SignDocumentsActions.PrintSignDocumentError: {
      return { ...state, printing: null, error: action.payload.error };
    }
    case SignDocumentsActions.SignDocumentOnDevice: {
      return { ...state, updating: true };
    }
    case SignDocumentsActions.SignDocumentOnDeviceSuccess: {
      return { ...state, updating: false, error: null };
    }
    case SignDocumentsActions.SignDocumentOnDeviceError: {
      return { ...state, updating: false, error: action.payload.error };
    }
    case SignDocumentsActions.ResetSignDocuments: {
      return { ...state, updating: true };
    }
    case SignDocumentsActions.ResetSignDocumentsSuccess:
      return { ...state, updating: false, error: null };
    case SignDocumentsActions.ResetSignDocumentsError: {
      return { ...state, updating: false };
    }
    case SignDocumentsActions.SelectLanguage: {
      return { ...state, language: action.payload };
    }
    case SignDocumentsActions.UpdateRedirectParameters: {
      return { ...state, redirectParameters: action.payload };
    }
    case SignDocumentsActions.SaveSignDocuments: {
      return { ...state, updating: true };
    }
    case SignDocumentsActions.SaveSignDocumentsSuccess: {
      return { ...state };
    }
    case SignDocumentsActions.SaveSignDocumentsError: {
      return { ...state, updating: false };
    }
    case SignDocumentsActions.UpdatedSuccess: {
      return { ...state, updating: false };
    }
    case SignDocumentsActions.SetSigningDocumentAction: {
      return {
        ...state,
        isEmailOrEmailAndSmsAction: action.payload,
      };
    }
    case SignDocumentsActions.ResendSignDocuments: {
      return { ...state, updating: true };
    }
    case SignDocumentsActions.ResendSignDocumentsSuccess: {
      return {
        ...state,
        updating: false,
        resendEmailSuccess: true,
        resendEmailError: false,
      };
    }
    case SignDocumentsActions.ResendSignDocumentsError: {
      return {
        ...state,
        updating: false,
        resendEmailSuccess: false,
        resendEmailError: true,
      };
    }
    default:
      return state;
  }
};
