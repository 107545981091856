import React from 'react';
import { useIntl } from 'react-intl';
import { PreSubmissionStepHeader } from '../../../../features/preSubmission/common/components/StepHeader';
import { AdditionalDetailsHeaderProps } from './additional-details-header.props';

import { AdditionalDetailsHeaderMessages as messages } from './additional-details-header.messages';

export const AdditionalDetailsHeader = (
  props: AdditionalDetailsHeaderProps,
) => {
  const intl = useIntl();

  return (
    <PreSubmissionStepHeader
      title={props.title}
      description={intl.formatMessage(messages.description)}
      stepPosition={props.stepPosition}
    />
  );
};
