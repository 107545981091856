import { defineMessages } from 'react-intl';

export default defineMessages({
  contactMerchantLabel: {
    id: 'Applicant.Approved.contactMerchantLabel',
    defaultMessage:
      'If you have questions, you can contact your merchant using the details below.',
  },
  contactPhoneLabel: {
    id: 'Applicant.Approved.contactPhoneLabel',
    defaultMessage: 'Phone',
  },
  contactEmailLabel: {
    id: 'Applicant.Approved.contactEmailLabel',
    defaultMessage: 'Email',
  },
});
