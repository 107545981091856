import {
  styled,
  TypographyColor,
  TypographyVariant,
} from '@frontline/ui-library';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent, ReactNode } from 'react';

const Icon = styled.div`
  font-size: 100px;
  color: ${props => props.theme.darkBlueGrey};
`;

export interface NotAuthenticatedLayoutProps {
  icon: ReactNode;
  primaryMessage: string;
  secondaryMessage: string;
}

export const NotAuthenticatedLayout: FunctionComponent<NotAuthenticatedLayoutProps> = (
  props: NotAuthenticatedLayoutProps,
) => {
  return (
    <Grid
      container={true}
      direction="column"
      justify="center"
      alignItems="center"
      spacing={3}>
      <Grid item={true}>
        <Icon>{props.icon}</Icon>
      </Grid>
      <Grid item={true}>
        <Typography
          variant={TypographyVariant.Header4}
          color={TypographyColor.TextPrimary}
          align="center">
          {props.primaryMessage}
        </Typography>
      </Grid>
      <Box maxWidth={400} marginTop={1} paddingX={4}>
        <Grid item={true}>
          <Typography
            variant={TypographyVariant.Header5}
            color={TypographyColor.TextPrimary}
            align="center"
            style={{ fontWeight: 'normal' }}>
            {props.secondaryMessage}
          </Typography>
        </Grid>
      </Box>
    </Grid>
  );
};
