import { defineMessages } from 'react-intl';

export default defineMessages({
  backToOverview: {
    id: 'PostSubmissionReviewHeader.backToOverview',
    description: 'Application header back to overview',
    defaultMessage: 'Back to Overview',
  },
  modifiedDate: {
    id: 'PostSubmissionReviewHeader.modifiedDate',
    description: 'Table Sort By Last Updated',
    defaultMessage: 'Last Updated',
  },
});
