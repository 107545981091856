import { GridTitle } from '@frontline/web-common';
import { Grid } from '@material-ui/core';
import React from 'react';
import { AssetInformationSectionHeaderProps } from './assets-section-header.props';

export const AssetInformationSectionHeader = (
  props: AssetInformationSectionHeaderProps,
) => (
  <Grid item={true} xs={12}>
    <GridTitle>{props.title}</GridTitle>
  </Grid>
);
