import { Colours } from '@frontline/ui-library';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useApplicationTakeoverMerchantInfoFooterStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 67,
      },
      details: {
        [theme.breakpoints.up('sm')]: {
          maxWidth: '66%',
        },
        [theme.breakpoints.up('md')]: {
          maxWidth: '40%',
        },
        [theme.breakpoints.up('md')]: {
          maxWidth: '40%',
        },
        display: 'block',
        backgroundColor: Colours.White,
        padding: '24px 24px 12px',
        width: '100%',
        maxWidth: '90%',
        borderRadius: 8,
        boxShadow:
          '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)',
        '& >p': {
          fontSize: 16,
          fontWeight: 500,
          color: Colours.Black,
          marginBottom: 29,
          display: 'flex',
          alignItems: 'center',
        },
        '& >a': {
          marginBottom: 29,
          display: 'flex',
          alignItems: 'center',
          color: '#6B7280',
          width: 'fit-content',
          textDecoration: 'none',
        },
      },
      title: {
        fontSize: 24,
        fontWeight: 600,
        marginBottom: 17,
      },
      icon: {
        marginRight: 16,
        color: '',
      },
    }),
);
