import { ApplicationValidationResult, Language } from '@frontline/common';
import { createSelector, Selector } from 'reselect';
import { RootState, Store } from '../../../../../../../../../store';
import * as postSubmissionStore from '../../../../../store/post-submission.store';
import { PostSubmissionReviewState } from '../../../types/PostSubmissionReviewState';
import { AssetInformationReportDto } from '../types/asset-information-report-dto';
import { AssetInformationState } from '../types/asset-information-state';
import * as actions from './assetInformation.actions';

export const getAssetInformationState: Selector<
  RootState,
  AssetInformationState | undefined
> = createSelector(
  postSubmissionStore.getPostSubmissionReviewState,
  (state?: PostSubmissionReviewState) => state?.assetInformationState,
);

export const getValidations: Selector<
  RootState,
  ApplicationValidationResult | null
> = createSelector(
  getAssetInformationState,
  (state?: AssetInformationState) => state?.validations || null,
);

export const getAssetInformationReport: Selector<
  RootState,
  AssetInformationReportDto | null
> = createSelector(
  getAssetInformationState,
  (state?: AssetInformationState) => state?.assetInformationReport || null,
);

export const fetchAssetInformationReport = (language: Language): void => {
  Store.dispatch(actions.fetchAssetInformationReport(language));
};

export const resetAssetInformationReport = (): void => {
  Store.dispatch(actions.resetAssetInformationReport());
};

export const fetchValidations = (): void => {
  Store.dispatch(actions.fetchValidations());
};
