import React from 'react';
import { PersonalDetailsViewGeneric } from '../../../../../common/personal-details/components/personal-details-view-generic/personal-details-view-generic.component';
import { CoApplicantPersonalDetailsViewMessages as messages } from './co-applicant-personal-details-view.messages';
import { CoApplicantPersonalDetailsViewProps } from './co-applicant-personal-details-view.props';

export const CoApplicantPersonalDetailsView = (
  props: CoApplicantPersonalDetailsViewProps,
) => (
  <PersonalDetailsViewGeneric
    messages={messages}
    coapplicantIndex={0}
    {...props}
  />
);
