import { createAssetsAndAddOnsState } from '../types/assets-and-addons-state.functions';
import { AssetsAndAddOnsState } from '../types/assets-and-addons-state.interface';
import {
  AssetsAndAddOnsActions,
  AssetsAndAddOnsActionType,
} from './assets-and-addons.actions';

export const assetsAndAddOnsReducer = (
  state: AssetsAndAddOnsState = createAssetsAndAddOnsState(),
  action: AssetsAndAddOnsActionType,
): AssetsAndAddOnsState => {
  switch (action.type) {
    case AssetsAndAddOnsActions.FetchTypes:
      return {
        ...state,
      };
    case AssetsAndAddOnsActions.FetchValidationsSuccess:
      return {
        ...state,
        assets: {
          ...state.assets,
          validations: action.payload,
        },
      };
    case AssetsAndAddOnsActions.FetchTypesSuccess:
      return {
        ...state,
        addOns: {
          ...state.addOns,
          types: action.payload,
        },
      };
    default:
      return state;
  }
};
