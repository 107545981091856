import { HttpError } from '@frontline/web-common';
import { ActionType, createAction } from 'typesafe-actions';
import { RepairTechLoginResponse } from '../types/repair-tech-login-response';

export enum NewRepairActions {
  Login = 'NewRepair/Login',
  LoginSuccess = 'NewRepair/LoginSuccess',
  LoginFailure = 'NewRepair/LoginFailure',
}

export const login = createAction(
  NewRepairActions.Login,
  action => (shareableLinkId: string) => action(shareableLinkId),
);

export const loginSuccess = createAction(
  NewRepairActions.LoginSuccess,
  action => (response: RepairTechLoginResponse) => action(response),
);

export const loginFailure = createAction(
  NewRepairActions.LoginFailure,
  action => (error: HttpError) => action(error),
);

const actionCreators = {
  login,
  loginSuccess,
  loginFailure,
};

export type NewRepairAction = ActionType<typeof actionCreators>;
