import { muiTheme } from '@frontline/ui-library';
import { useMediaQuery } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { OverviewStepperProps } from '../types';
import { OverviewStepperMobile } from './overview-stepper-mobile';
import OverviewStepperDesktop from './OverviewStepperDesktop/OverviewStepperDesktop';

export const OverviewStepper: FunctionComponent<OverviewStepperProps> = (
  props: OverviewStepperProps,
) => {
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('xs'));

  if (isMobile) {
    return <OverviewStepperMobile {...props} />;
  } else {
    return <OverviewStepperDesktop {...props} />;
  }
};
