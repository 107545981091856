import { styledTheme } from '@frontline/ui-library';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

export const GridContainer = styled(Grid)`
  background-color: ${styledTheme.darkBlueGrey};
  color: white;
  padding: 0em 1em;
  height: 56px;
`;
