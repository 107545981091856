import { FormatPercent } from '@frontline/ui-library';
import React from 'react';

interface RateProps {
  value?: number;
}

export const Rate = (props: RateProps) =>
  props.value || props.value === 0 ? (
    <FormatPercent value={props.value} />
  ) : null;
