import { defineMessages } from 'react-intl';

export const SubmittingSpinnerMessages = [
  defineMessages({
    message: {
      defaultMessage: 'Submitting Purchase Details',
      id: 'ReviewSummarySubmittingSpinner.message1',
    },
  }),
  defineMessages({
    message: {
      defaultMessage: 'Submitting Personal Details',
      id: 'ReviewSummarySubmittingSpinner.message2',
    },
  }),
  defineMessages({
    message: {
      defaultMessage: 'Submitting Income Details',
      id: 'ReviewSummarySubmittingSpinner.message3',
    },
  }),
  defineMessages({
    message: {
      defaultMessage: 'Submitting Additional Details',
      id: 'ReviewSummarySubmittingSpinner.message4',
    },
  }),
];
