import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { appLoginPath } from '../../../../modules/navigation/types/appLoginPath.functions';
import { LendcareReportingFieldService } from '../../services/lendcare-reporting-fields.service';
import * as store from '../../store/buy-lendcare.store';
import { LendcareReportingField } from '../../types/lendcare-reporting-field.enum';
import { LendCareProps } from './lendcare.props';

export const LendCare = (props: LendCareProps) => {
  const history = useHistory();

  const applicationId = useSelector(store.getApplicationId);
  const accessKey = useSelector(store.getAccessKey);
  let reportingFields: Map<LendcareReportingField, string | null> = new Map();

  useEffect(() => {
    reportingFields = LendcareReportingFieldService.getReportingFields(
      window.location.search,
    );
    if (props.shareableLinkId) {
      store.startNewApplication(props.shareableLinkId, reportingFields);
      localStorage.setItem(
        LendcareReportingField.REF_ID,
        reportingFields.get(LendcareReportingField.REF_ID) ?? '',
      );
      localStorage.setItem(
        LendcareReportingField.REFERRER_ID,
        reportingFields.get(LendcareReportingField.REFERRER_ID) ?? '',
      );
      localStorage.setItem(
        LendcareReportingField.EVENT_ID,
        reportingFields.get(LendcareReportingField.EVENT_ID) ?? '',
      );
      localStorage.setItem(
        LendcareReportingField.CAMPAIGN_ID,
        reportingFields.get(LendcareReportingField.CAMPAIGN_ID) ?? '',
      );
    }
  }, [props.shareableLinkId, window]);

  useEffect(() => {
    if (applicationId && accessKey) {
      history.push(appLoginPath(applicationId, accessKey));
    }
  }, [applicationId, accessKey, history]);

  return null;
};
