import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { filter, map } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootState } from '../../../../../../../store';
import * as rootApplicationActions from '../../../../../../../store/application/actions';
import * as actions from './reviewSummary.actions';

const submitApplication: Epic<Action, Action, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.submitApplication)),
    map(action => action.payload),
    map(applicationId =>
      rootApplicationActions.submitApplication(applicationId),
    ),
  );

export default [submitApplication];
