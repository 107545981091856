import {
  BankDocumentsState,
  createBankDocumentsState,
} from '@frontline/web-common';
import {
  BankDocumentsActions,
  BankDocumentsActionType,
} from './bank-documents.actions';

export const bankDocumentsReducer = (
  state: BankDocumentsState = createBankDocumentsState(),
  action: BankDocumentsActionType,
): BankDocumentsState => {
  switch (action.type) {
    case BankDocumentsActions.AttachPendingDocuments: {
      return {
        ...state,
        error: null,
        saving: true,
        isConfirmAttachPendingDocumentsDialogOpen: false,
      };
    }
    case BankDocumentsActions.AttachPendingDocumentsSuccess:
    case BankDocumentsActions.CloseConfirmAttachPendingDocumentsDialog:
      return {
        ...state,
        saving: false,
        isConfirmAttachPendingDocumentsDialogOpen: false,
      };
    case BankDocumentsActions.OpenConfirmAttachPendingDocumentsDialog:
      return {
        ...state,
        isConfirmAttachPendingDocumentsDialogOpen: true,
      };
    case BankDocumentsActions.AttachPendingDocumentsError:
      return {
        ...state,
        saving: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
