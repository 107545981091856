import { defineMessages } from 'react-intl';

export default defineMessages({
  newfoundlandHighCostProduct: {
    id: 'highCostLicenseDetails.newfoundlandHighCostProduct',
    description: 'Newfoundland High-Cost Product Label',
  },
  defaultHighCostProduct: {
    id: 'highCostLicenseDetails.defaultHighCostProduct',
    description: 'Default High-Cost Product Label',
  },
  licenseNumberAndExpiryDate: {
    id: 'highCostLicenseDetails.licenseNumberAndExpiryDate',
    description: 'License Number and Expiry Date Label',
  },
});
