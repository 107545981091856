import { muiTheme, ShowIfFragment, useUatId } from '@frontline/ui-library';
import { Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import * as applicationSelectors from '../../../../../../store/application/selectors';
import { PreSubmissionStepActionsMessages as messages } from './pre-submission-step-actions.messages';
import { PreSubmissionStepActionsProps } from './pre-submission-step-actions.props';
import {
  ActionsGrid,
  BackButton,
  BackButtonGrid,
  Button,
  SaveAndExitButton,
} from './pre-submission-step-actions.styles';
import { PreSubmissionStepActionsUat } from './pre-submission-step-actions.uat';

export const PreSubmissionStepActions = (
  props: PreSubmissionStepActionsProps,
) => {
  const uatId = useUatId();

  const isWorkflowComplete = useSelector((state: RootState) => {
    return applicationSelectors.isPreWorkflowCompleted(state.application);
  });

  const isMediaExtraSmall = useMediaQuery(muiTheme.breakpoints.down('xs'));
  const width = isMediaExtraSmall ? '100%' : '180px';

  const showBackButton =
    !isWorkflowComplete && Boolean(props.onBackButtonClick);

  const actionsJustifyContent = () => {
    if (showBackButton) {
      return 'space-between';
    }
    return isMediaExtraSmall ? 'center' : 'flex-end';
  };

  return (
    <ActionsGrid
      container={true}
      direction={isMediaExtraSmall ? 'column' : 'row'}
      justify={actionsJustifyContent()}
      spacing={2}>
      {showBackButton && (
        <BackButtonGrid item={true} order={isMediaExtraSmall ? '1' : '0'}>
          <BackButton
            type="button"
            onClick={props.onBackButtonClick}
            tabIndex={-1}
            width={width}>
            <FormattedMessage {...messages.backButtonLabel} />
          </BackButton>
        </BackButtonGrid>
      )}
      <Grid item={true}>
        <ShowIfFragment showIf={!isWorkflowComplete}>
          <Button
            type="submit"
            width={width}
            {...uatId(PreSubmissionStepActionsUat.nextButton)}>
            <FormattedMessage {...messages.continueButtonLabel} />
          </Button>
        </ShowIfFragment>
        <ShowIfFragment showIf={isWorkflowComplete}>
          <SaveAndExitButton
            type="submit"
            width={width}
            {...uatId(PreSubmissionStepActionsUat.saveAndExitButton)}>
            <FormattedMessage {...messages.saveAndExitButtonLabel} />
          </SaveAndExitButton>
        </ShowIfFragment>
      </Grid>
    </ActionsGrid>
  );
};
