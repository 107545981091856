import { Loader } from '@frontline/ui-library';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as applicationStore from '../../../../store/application.store';
import { ResultRoutes } from './result-summary-routes';
import * as store from './store/result-summary.store';

export const ResultFeature = () => {
  const dispatch = useDispatch();
  const application = useSelector(applicationStore.getApplication);

  const completeResult = () => {
    if (application) {
      store.completeResult(dispatch)(application.id);
    }
  };

  return (
    <React.Fragment>
      <Loader show={useSelector(store.isLoading)} />
      <ResultRoutes application={application} completeResult={completeResult} />
    </React.Fragment>
  );
};
