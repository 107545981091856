import {
  FraudDetectionDataPoints,
  fraudDetectionEvaluateRiskFailure,
  fraudDetectionEvaluateRiskSuccess,
  FraudDetectionRiskEvaluation,
  FraudDetectionRiskEvaluationFunctions,
} from '@frontline/web-common';
import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  ignoreElements,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { submitApplication as reviewSummarySubmitApplication } from '../../../features/application/features/preSubmission/features/ReviewSummary/store/reviewSummary.actions';
import { RootState } from '../../../store';
import * as authStore from '../../auth/store/auth.store';
import * as securityQuestionActions from '../../security-questions/store/security-questions.actions';
import { fetchFraudDetectionRiskAssessment } from '../services/fraud-detection-api.service';
import { FraudDetectionContext } from '../types/fraud-detection-context.type';
import * as applicantFraudDetectionActions from './applicant-fraud-detection.actions';
import * as store from './applicant-fraud-detection.store';

export const evaluateRiskEpic: Epic<Action, Action, RootState> = (
  action$: Observable<Action>,
  state$: any,
) =>
  action$.pipe(
    filter(isActionOf(applicantFraudDetectionActions.evaluateRisk)),
    map(action => [
      action.payload,
      store.getFraudDetectionDataPoints(state$.value),
      authStore.getAccessKey(state$.value),
    ]),
    filter(
      ([context, dataPoints, accessKey]) =>
        Boolean(dataPoints) && Boolean(accessKey),
    ),
    switchMap(([context, dataPoints, accessKey]) =>
      fetchFraudDetectionRiskAssessment(
        dataPoints as FraudDetectionDataPoints,
        accessKey as string,
        context as FraudDetectionContext,
      ).pipe(
        map((fraudDetectionRiskEvaluation: FraudDetectionRiskEvaluation) =>
          fraudDetectionEvaluateRiskSuccess(fraudDetectionRiskEvaluation),
        ),
        catchError(error => of(fraudDetectionEvaluateRiskFailure(error))),
      ),
    ),
  );

export const fraudDetectionEvaluateRiskAssessmentEpic: Epic<
  Action,
  Action,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(fraudDetectionEvaluateRiskSuccess)),
    map(action => action.payload),
    filter(FraudDetectionRiskEvaluationFunctions.shouldInvalidAuthentication),
    tap(() => authStore.invalidate()),
    ignoreElements(),
  );

export const fraudDetectionEvaluateRiskForSecurityQuestions: Epic<
  Action,
  Action,
  RootState
> = (action$: Observable<Action>) =>
  action$.pipe(
    filter(isActionOf(securityQuestionActions.answerSecurityQuestions)),
    map(() => store.applicantFraudDetectionEvaluateRisk()),
    map(() => store.evaluateRiskForUniqueLinks()),
    ignoreElements(),
  );

export const fraudDetectionEvaluateRiskOnApplicationSubmitEpic: Epic<
  Action,
  Action,
  RootState
> = (action$: Observable<Action>, state$: any) =>
  action$.pipe(
    filter(isActionOf(reviewSummarySubmitApplication)),
    map(() => store.applicantFraudDetectionEvaluateRisk()),
    map(() => store.evaluateRiskForApplication()),
    ignoreElements(),
  );

export const applicantFraudDetectionEpics = [
  evaluateRiskEpic,
  fraudDetectionEvaluateRiskAssessmentEpic,
  fraudDetectionEvaluateRiskForSecurityQuestions,
  fraudDetectionEvaluateRiskOnApplicationSubmitEpic,
];
