import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'LoanProtectionStep.title',
    description: 'Loan protection Step Title',
    defaultMessage: 'Loan protection',
  },
  description: {
    id: 'LoanProtectionStep.description',
    description: 'Loan Protection Step Description',
    defaultMessage: 'Review loan protection options',
  },
  editButton: {
    id: 'LoanProtectionStep.editButton',
    description: 'Loan Protection Step Edit Button',
    defaultMessage: 'Review loan protection',
  },
});
