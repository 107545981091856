import { WorkflowStep, WorkflowStepType } from '@frontline/common';
import { findStepByUrl } from '../../../common/types/WorkflowStep/WorkflowStep.functions';
import { getPreSubmissionRoutePath } from '../../../types/PreSubmissionRoutes.functions';

export const getCoApplicantWorkflowStep = (
  workflow: WorkflowStep[],
): WorkflowStep | undefined =>
  workflow.find(step => step.type === WorkflowStepType.CoApplicant);

export const getCoApplicantWorkflow = (
  workflow: WorkflowStep[],
): WorkflowStep[] => getCoApplicantWorkflowStep(workflow)?.subSteps || [];

export const hasCoApplicantWorkflow = (
  workflow: WorkflowStep[] | null,
): boolean => {
  return workflow ? getCoApplicantWorkflowStep(workflow) !== undefined : false;
};

export const shouldGoToDefaultRoute = (
  currentPath: string,
  defaultRoute: string,
  applicationId?: string,
) => {
  if (!applicationId || !defaultRoute) {
    return false;
  }
  const coApplicantRoute = getPreSubmissionRoutePath(
    applicationId,
    WorkflowStepType.CoApplicant,
  );
  return currentPath === coApplicantRoute;
};

export const currentStepIndexByRoute = (
  workflow: WorkflowStep[],
  routeUrl: string,
) => {
  const currentStep = findStepByUrl(routeUrl, workflow);
  return workflow.findIndex(step => step.type === currentStep?.type);
};

export const getPreviousStepRoute = (
  stepIndex: number | null,
  steps: WorkflowStep[],
): string | null => {
  const previousStepIndex = stepIndex !== null ? stepIndex - 1 : null;
  return getRouteUrlByActiveStepIndex(previousStepIndex, steps);
};

const getRouteUrlByActiveStepIndex = (
  stepIndex: number | null,
  steps: WorkflowStep[],
): string | null => {
  const routeUrl = stepIndex !== null && steps[stepIndex]?.routeUrl;
  return routeUrl || null;
};
