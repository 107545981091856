import { WorkflowStep } from '@frontline/common';
import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootState } from '../../../../../../../store';
import coApplicantPersonalDetailsEpics from '../../../features/CoApplicant/features/CoApplicantPersonalDetails/store/coApplicantPersonalDetails.epics';
import { getCoApplicantWorkflow } from '../../../features/CoApplicant/types/WorkflowStep.functions';
import * as preSubmissionStore from '../../../store/pre-submission.store';
import {
  findActiveStepIndex,
  setDefaultActiveStep,
} from '../../types/WorkflowStep/WorkflowStep.functions';
import * as actions from './coApplicantDetails.actions';

export const loadCoApplicantWorkflowEpic: Epic<Action, Action, RootState> = (
  action$: Observable<Action>,
  state$: any,
) =>
  action$.pipe(
    filter(isActionOf(actions.loadCoApplicantWorkflow)),
    map(() => preSubmissionStore.getWorkflow(state$.value)),
    map(workflow => getCoApplicantWorkflow(workflow as WorkflowStep[])),
    map(workflow => setDefaultActiveStep(workflow)),
    map(workflow => [workflow, findActiveStepIndex(workflow)]),
    mergeMap(([workflow, activeStepIndex]) =>
      from([
        actions.updateCoApplicantWorkflow(workflow as WorkflowStep[]),
        actions.setCoApplicantActiveStepIndex(activeStepIndex as number),
      ]),
    ),
  );

export default [
  ...coApplicantPersonalDetailsEpics,
  loadCoApplicantWorkflowEpic,
];
