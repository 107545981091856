import { RouterAction, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { createEpicMiddleware, EpicMiddleware } from 'redux-observable';
import { ActionType } from 'typesafe-actions';
import actions from './actions';
import epics from './epics';
import createRootReducer, { RootState } from './reducers';

export const history = createBrowserHistory();
type Action = ActionType<typeof actions>;

function createReduxLogger() {
  return require('redux-logger').createLogger({ collapsed: true });
}

function getAllMiddleware(
  epicMiddleware: EpicMiddleware<
    Action | RouterAction,
    Action | RouterAction,
    RootState
  >,
) {
  const middleware = [epicMiddleware, routerMiddleware(history)];

  if (process.env.REACT_APP_USE_REDUX_LOGGER) {
    return [...middleware, createReduxLogger()];
  }

  return middleware;
}

export function configureStore(preloadedState?: any) {
  const composeEnhancer: typeof compose =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const epicMiddleware = createEpicMiddleware<
    Action | RouterAction,
    Action | RouterAction,
    RootState
  >();

  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancer(applyMiddleware(...getAllMiddleware(epicMiddleware))),
  );

  if ((module as any).hot) {
    (module as any).hot.accept('./reducers', () => {
      store.replaceReducer(createRootReducer(history));
    });
  }
  epicMiddleware.run(epics);

  return store;
}
