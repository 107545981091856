import React from 'react';
import { useIntl } from 'react-intl';
import PreSubmissionPageLayout from '../../../../common/components/PreSubmissionPageLayout/PreSubmissionPageLayout';
import PreSubmissionStepHeader from '../../../../common/components/StepHeader/StepHeader';
import { PurchaseDetailsForm } from './components/purchase-details-form/purchase-details-form.component';
import messages from './purchase-details-view.messages';
import { PurchaseDetailsViewProps } from './purchase-details-view.props';

export const PurchaseDetailsView = (props: PurchaseDetailsViewProps) => {
  const intl = useIntl();

  return (
    <PreSubmissionPageLayout
      applicationHeaderComponent={
        <PreSubmissionStepHeader
          title={intl.formatMessage(messages.title)}
          description={intl.formatMessage(messages.description)}
          stepPosition={props.stepPosition}
        />
      }
      formComponent={
        <React.Fragment>
          <PurchaseDetailsForm
            loanAmount={props.loanAmount}
            assetTypeId={props.assetType}
            assetTypeName={props.assetTypeName}
            assetSubTypeName={props.assetSubTypeName}
            allAssetTypes={props.assetTypes}
            programName={props.programName}
            onContinue={props.onContinue}
          />
        </React.Fragment>
      }
    />
  );
};
