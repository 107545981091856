import { readFullName } from '@frontline/common';
import { SectionItem, SectionPanel } from '@frontline/ui-library';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import {
  formattedAddress,
  formattedDateOfBirth,
  formattedEmail,
  formattedPhone,
} from '../../../../../../types/formatApplicationData.functions';
import messages from './review-summary-personal-details.messages';
import { ReviewSummaryPersonalDetailsProps as Props } from './review-summary-personal-details.prop';

export const ReviewSummaryPersonalDetails = (props: Props) => {
  const intl = useIntl();
  if (!props.personalDetails) {
    return null;
  }
  const fullName = readFullName(props.personalDetails);
  const title = intl.formatMessage(props.titleMessageDescriptor);
  return (
    <SectionPanel title={title} editUrl={props.editUrl} uat={props.uat}>
      <Grid container={true} spacing={3}>
        <SectionItem
          showIf={Boolean(props.isCoApplicant)}
          label={intl.formatMessage(messages.relationshipTypeLabel)}
          data={props.personalDetails?.relationshipType?.name}
        />
        <SectionItem
          label={intl.formatMessage(messages.nameLabel)}
          data={fullName}
        />
        <SectionItem
          label={intl.formatMessage(messages.dateOfBirthLabel)}
          data={formattedDateOfBirth(props.personalDetails?.dob)}
        />
        <SectionItem
          label={intl.formatMessage(messages.addressLabel)}
          data={formattedAddress(props.personalDetails?.address)}
        />
        <SectionItem
          label={intl.formatMessage(messages.emailLabel)}
          data={formattedEmail(props.personalDetails?.email)}
        />
        <SectionItem
          label={intl.formatMessage(messages.primaryPhoneLabel)}
          data={formattedPhone(props.personalDetails?.primaryPhone)}
        />
      </Grid>
    </SectionPanel>
  );
};
