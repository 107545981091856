import { CustomContentDialog as Dialog } from '@frontline/ui-library';
import React, { Fragment, FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { MainLayout } from '../../modules/layout/components';
import * as securityQuestionsStore from '../../modules/security-questions/store/security-questions.store';
import { Answer } from '../../types/questions/answer.interface';
import { QuestionsDialogContent } from './components/questions-dialog-content/questions-dialog-content';
import { SecurityQuestionsLocationState } from './types/security-questions-location.state';

const SecurityQuestionsFeatureComponent: FunctionComponent = () => {
  const location = useLocation();
  const error = useSelector(securityQuestionsStore.getError);

  const {
    securityQuestionsGroupId,
    securityQuestions,
  } = (location.state as SecurityQuestionsLocationState) || {
    securityQuestionsGroupId: '',
    securityQuestions: '',
  };

  const onSubmit = (answers: Answer[]) => {
    securityQuestionsStore.answerSecurityQuestions(
      answers,
      securityQuestionsGroupId,
    );
  };

  return (
    <Fragment>
      <Dialog open={Boolean(securityQuestions)}>
        <QuestionsDialogContent
          securityQuestionsGroupId={securityQuestionsGroupId}
          securityQuestions={securityQuestions}
          onSubmit={onSubmit}
          error={error}
        />
      </Dialog>
    </Fragment>
  );
};
export const SecurityQuestionsFeature = MainLayout(
  SecurityQuestionsFeatureComponent,
);
