import { useUatId, withShowIf } from '@frontline/ui-library';
import { LoanDetailsFormPayDayDatePicker } from '@frontline/web-common';
import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import messages from './loan-details-form-pay-day-date.messages';
import { LoanDetailsFormPayDayDateProps } from './loan-details-form-pay-day-date.props';
import { usePayDayDateStyles } from './loan-details-form-pay-day-date.styles';
import { LoanDetailsFormPayDayDateUat } from './loan-details-form-pay-day-date.uat';

export const LoanDetailsFormPayDayDate = withShowIf(
  (props: LoanDetailsFormPayDayDateProps) => {
    const uatId = useUatId();
    const classes = usePayDayDateStyles();
    const intl = useIntl();

    return (
      <Grid
        item={true}
        xs={12}
        sm={12}
        md={6}
        className={classes.datePickerValuesLeft}>
        <Field
          name="payDayDate"
          component={LoanDetailsFormPayDayDatePicker}
          label={intl.formatMessage(messages.payDayDate)}
          errorMessage={props.errorMessage}
          disabled={props.disableFirstPaymentDate}
          uat={uatId(LoanDetailsFormPayDayDateUat.payDayDate)}
        />
      </Grid>
    );
  },
);
