import { push } from 'connected-react-router';
import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootState } from '../../../store';
import { getRepairTechPostLoginRoute } from '../../repair-tech/types/RepairTechRoutes.functions';
import * as newRepairService from '../services/new-repair.service';
import * as actions from './new-repair.actions';

const newRepairLoginEpic: Epic<Action, Action, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.login)),
    map(action => action.payload),
    switchMap(shareableLinkId =>
      from(newRepairService.login(shareableLinkId)).pipe(
        map(response => actions.loginSuccess(response)),
        catchError(error => of(actions.loginFailure(error))),
      ),
    ),
  );

const newRepairLoginSuccessEpic: Epic<Action, Action, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.loginSuccess)),
    map(action => action.payload),
    map(({ accessKey, merchantId }) =>
      push(getRepairTechPostLoginRoute(accessKey, merchantId)),
    ),
  );

export default [newRepairLoginEpic, newRepairLoginSuccessEpic];
