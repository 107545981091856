import {
  canResetLoanDocuments,
  canUpdateSignLoanDocuments,
  findFirstDocumentWithIncompleteSigning,
  getSignDocumentsLanguage,
  SignerType,
  updateWorkflowStepsStatus,
} from '@frontline/common';
import { errorToast, Loader, successToast } from '@frontline/ui-library';
import {
  getSignDocumentsRules,
  LocaleContext,
  SignDocuments,
  SignDocumentsApi as api,
  SignDocumentsState,
} from '@frontline/web-common';
import cloneDeep from 'lodash/cloneDeep';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { RootState } from '../../../../../../../../../../store';
import * as applicationActions from '../../../../../../../../../../store/application/actions';
import * as selectors from '../../../../../../../../../../store/selectors';
import * as postSubmissionReviewStore from '../../../../../../store/post-submission.store';
import { postSubmissionPath } from '../../../../../../types';
import { PostSubmissionContentCard } from '../../../../components/post-submission-content-card';
import * as store from '../../store/sign-documents.store';
import { setIsEmailOrEmailAndSmsAction } from '../../store/sign-documents.store';
import { isDeleteDisabled } from '../../types/sign-loan-documents.functions';
import { SignLoanDocumentsProps } from './sign-loan-documents-props';
import { SignLoanDocumentsMessages } from './sign-loan-documents.messages';

export const SignLoanDocuments: FunctionComponent<SignLoanDocumentsProps> = ({
  applicationId,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const isUpdating = useSelector(store.isUpdating);
  const errors = useSelector(store.getErrors);
  const [updateDisabled, setUpdateDisabled] = useState(true);
  const [resetDisabled, setResetDisabled] = useState(true);
  const { locale } = useContext(LocaleContext);
  const resendEmailSuccess = useSelector(store.getResendEmailSuccess);
  const resendEmailError = useSelector(store.getResendEmailError);

  const signDocuments = useSelector((state: RootState) =>
    selectors.getSignDocuments(state.application),
  );

  useEffect(() => {
    store.updateRedirectParametersFromRoute(dispatch)({
      history,
      location,
      match,
    });
  }, [history, location, match, dispatch]);

  useEffect(() => {
    store.loadSignDocuments(dispatch);
  }, [applicationId, dispatch]);

  useEffect(() => {
    const documentWithIncompleteSigning = findFirstDocumentWithIncompleteSigning(
      signDocuments,
    );
    if (documentWithIncompleteSigning) {
      store.openSignDocumentDialog(dispatch)(documentWithIncompleteSigning);
    } else {
      store.closeSignDocumentDialog(dispatch)();
    }
  }, [signDocuments, dispatch]);

  const onDelete = () => {
    return api.resetSignDocuments(applicationId).then(docs => {
      dispatch(
        applicationActions.updateSignDocuments({
          applicationId,
          signDocuments: docs,
        }),
      );
    });
  };

  const application = useSelector(
    postSubmissionReviewStore.getCurrentApplication,
  );
  const steps = useSelector(postSubmissionReviewStore.getWorkflow);

  const signDocumentsRules = steps ? getSignDocumentsRules(steps) : undefined;

  const documentOpenedForEmailing = useSelector(
    store.getDocumentOpenedForEmailing,
  );
  const documentOpenedForSigning = useSelector(
    store.getDocumentOpenedForSigning,
  );
  const printingDocument = useSelector(store.getPrintingDocument);

  useEffect(() => {
    if (!(application && steps)) {
      return;
    }
    const copyOfSteps = cloneDeep(steps);
    updateWorkflowStepsStatus(copyOfSteps, application);
    setUpdateDisabled(
      !canUpdateSignLoanDocuments(application, copyOfSteps, errors),
    );
    setResetDisabled(!canResetLoanDocuments(application, steps, errors));
  }, [application, steps, errors]);

  const disableDeleteOption = useMemo<boolean>(() => {
    if (application) {
      return isDeleteDisabled(application.status);
    }
    return false;
  }, [application]);

  const onSetIsEmailOrEmailAndSmsAction = (
    signingDocumentAction: SignDocumentsState['isEmailOrEmailAndSmsAction'],
  ) => {
    setIsEmailOrEmailAndSmsAction(dispatch)(signingDocumentAction);
  };

  const redirectForEdit = (type: SignerType) => {
    switch (type) {
      case SignerType.Applicant:
        history.push(
          postSubmissionPath('applicant-personal-details', applicationId),
        );
        break;
      case SignerType.CoApplicant:
        history.push(
          postSubmissionPath('co-applicant-personal-details', applicationId),
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (resendEmailSuccess) {
      successToast(
        intl.formatMessage(SignLoanDocumentsMessages.resendEmailSuccess),
      );
    }
  }, [resendEmailSuccess]);

  useEffect(() => {
    if (resendEmailError) {
      errorToast(
        intl.formatMessage(SignLoanDocumentsMessages.resendEmailError),
      );
    }
  }, [resendEmailError]);

  return (
    <PostSubmissionContentCard>
      <Loader show={isUpdating} />
      <SignDocuments
        applicationId={applicationId}
        documentOpenedForEmailing={documentOpenedForEmailing}
        documentOpenedForSigning={documentOpenedForSigning}
        language={getSignDocumentsLanguage(locale, signDocuments)}
        onCancelEmailDialog={store.closeEmailDialog(dispatch)}
        onCancelSignDocumentDialog={store.closeSignDocumentDialog(dispatch)}
        onBack={postSubmissionReviewStore.navigateToPreviousStep(dispatch)}
        onSubmit={postSubmissionReviewStore.navigateToNextStep(dispatch)}
        onDelete={onDelete}
        onOpenEmailDialog={store.openEmailDialog(dispatch)}
        onOpenSignDocumentDialog={store.openSignDocumentDialog(dispatch)}
        onPrint={store.printSignDocument(dispatch)}
        onReset={store.resetSignDocuments(dispatch)}
        onResend={store.resendSignDocuments(dispatch)}
        onSign={store.signDocumentOnDevice(dispatch)}
        onSelectLanguage={store.selectLanguage(dispatch)}
        onEmail={store.emailDocument(dispatch)}
        printingDocument={printingDocument}
        signDocuments={signDocuments}
        enableOnDeviceForApplicant={
          signDocumentsRules?.enableOnDeviceForApplicant
        }
        disableOnDevice={signDocumentsRules?.disableOnDevice}
        enableSms={Boolean(signDocumentsRules?.enableSms)}
        disableEmail={signDocumentsRules?.disableEmail}
        disablePrint={signDocumentsRules?.disablePrint}
        disableUploadDocument={signDocumentsRules?.disableUploadDocument}
        updatesDisabled={updateDisabled}
        disableReset={resetDisabled}
        isApplicantSigning={true}
        isUpdating={isUpdating}
        disableDelete={disableDeleteOption}
        errors={errors || undefined}
        setIsEmailOrEmailAndSmsAction={onSetIsEmailOrEmailAndSmsAction}
        redirectForEdit={redirectForEdit}
        uploadingDocuments={useSelector(store.getUploadingDocuments)}
      />
    </PostSubmissionContentCard>
  );
};
