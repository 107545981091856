import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ApplicationTakeoverReferenceCodeMessages } from './application-takeover-reference-code.messages';
import { ApplicationTakeoverReferenceCodeProps } from './application-takeover-reference-code.props';
import { useApplicationTakeoverReferenceCodeStyles } from './application-takeover-reference-code.styles';

export const ApplicationTakeoverReferenceCode = (
  props: ApplicationTakeoverReferenceCodeProps,
) => {
  const classes = useApplicationTakeoverReferenceCodeStyles();

  return (
    <div className={classes.container}>
      <Grid
        container
        spacing={0}
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.textContainer}>
        <Grid item>
          <Typography className={classes.referenceCode}>
            {props.referenceCode}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.title}>
            <FormattedMessage
              {...ApplicationTakeoverReferenceCodeMessages.title}
            />
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};
