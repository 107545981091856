import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'PersonalDetails.title',
    description: 'Personal Details Step Title',
    defaultMessage: "{applicantName}'s Personal Details",
  },
  description: {
    id: 'PersonalDetails.description',
    description: 'Personal Details Step Description',
    defaultMessage:
      "Check {applicantName}'s government-issued ID and enter the details exactly as they appear on their ID",
  },
});
