import { Formik, FormikValues } from 'formik';
import React from 'react';
import { createSecurityQuestionsFormValidationSchema } from '../../types/question-dialog-schema';
import {
  createInitialSecurityFormValues,
  mapSecurityQuestionFormValuesToAnswers,
} from '../../types/questions-dialog-form-fields.functions';
import { QuestionsDialogFormView } from '../questions-dialog-form-view/questions-dialog-form-view';
import { QuestionsDialogFormProps } from './questions-dialog-form.props';

export const QuestionsDialogForm = (props: QuestionsDialogFormProps) => {
  const handleSubmit = async (values: FormikValues) => {
    props.onSubmit(mapSecurityQuestionFormValuesToAnswers(values));
  };

  return (
    <Formik
      initialValues={createInitialSecurityFormValues(props.securityQuestions)}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validationSchema={createSecurityQuestionsFormValidationSchema(
        props.securityQuestions,
      )}
      render={(formikProps: any) => (
        <QuestionsDialogFormView {...props} {...formikProps} />
      )}
    />
  );
};
