import { defineMessages } from 'react-intl';

export const HomeAndAutoAncillaryProductStepMessages = defineMessages({
  title: {
    id: 'Merchant.PostSubmission.HomeAndAutoAncillaryProduct.title',
  },
  description: {
    id: 'Merchant.PostSubmission.HomeAndAutoAncillaryProduct.description',
  },
  editButton: {
    id: 'Merchant.PostSubmission.HomeAndAutoAncillaryProduct.editButton',
  },
});
