import { FormatMoney, withShowIf } from '@frontline/ui-library';
import { Typography, withStyles } from '@material-ui/core';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ApprovedLoanAmountProps } from './approved-loan-amount-props';
import messages from './approved-loan-amount.messages';

const ApprovedLoanAmount = withShowIf((props: ApprovedLoanAmountProps) => (
  <LoanAmount>
    <Label>
      <CustomizedTypography variant="subtitle2" color="secondary">
        <FormattedMessage {...messages.label} />
      </CustomizedTypography>
    </Label>
    <Amount>
      <CustomizedTypography variant="h1" color="secondary">
        <FormatMoney value={props.amount} roundToDollar={true} />
      </CustomizedTypography>
    </Amount>
  </LoanAmount>
));

const LoanAmount = styled.div`
  margin: 20px auto;
`;

const Label = styled.div`
  height: 16px;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
`;

const Amount = styled.div`
  height: 57px;
  font-size: 42px;
  font-weight: 600;
  text-align: center;
`;

const CustomizedTypography = withStyles({
  h1: {
    lineHeight: 1.43,
  },
  subtitle2: {
    lineHeight: 1.23,
  },
})(Typography);

export default ApprovedLoanAmount;
