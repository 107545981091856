import { useFraudDetectionModule } from '@frontline/web-common';
import * as store from '../store/applicant-fraud-detection.store';

export const useFraudDetection = () => {
  useFraudDetectionModule(
    process.env.REACT_APP_IOVATION_VERSION || '',
    process.env.REACT_APP_IOVATION_SUB_KEY || '',
    process.env.REACT_APP_IOVATION_URI_HOOK || '',
    store.storeFraudDetectionDataPoints,
  );
};
