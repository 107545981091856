import { ApplicationTakeoverRequestErrorType } from '@frontline/common';
import { Loader, ShowIfFragment } from '@frontline/ui-library';
import React, { Fragment, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ApplicationTakeoverStore } from '../../modules/application-takeover/store/application-takeover.store';
import { MainLayout } from '../../modules/layout/components';
import { ApplicationTakeoverErrorView } from './components/application-takeover-error-view/application-takeover-error-view.component';
import { ApplicationTakeoverView } from './components/application-takeover-view/application-takeover-view.component';
import { ApplicationTakeoverExpired } from './components/application-takeover-view/components/application-takeover-link-expired/application-takeover-expired.component';
import { ApplicationTakeoverInvalid } from './components/application-takeover-view/components/application-takeover-link-invalid/application-takeover-invalid.component';
import { ApplicationTakeoverParams } from './types/application-takeover-url-params.interface';

export const ApplicationTakeoverFeature = MainLayout(() => {
  const { takeoverCode } = useParams<ApplicationTakeoverParams>();
  const isLoading = useSelector(ApplicationTakeoverStore.isLoading);
  const error = useSelector(ApplicationTakeoverStore.getError);

  const isExpired = useMemo(
    () => error?.code === ApplicationTakeoverRequestErrorType.Expired,
    [error],
  );

  const isInvalid = useMemo(
    () => error?.code === ApplicationTakeoverRequestErrorType.Invalid,
    [error],
  );

  const applicationTakeoverDetails = useSelector(
    ApplicationTakeoverStore.getApplicationTakeoverDetails,
  );

  useEffect(() => {
    ApplicationTakeoverStore.fetchApplicationTakeoverDetails(takeoverCode);
  }, []);

  return (
    <Fragment>
      <ShowIfFragment
        showIf={Boolean(applicationTakeoverDetails) && !isLoading}>
        <ApplicationTakeoverView
          applicationTakeoverDetails={applicationTakeoverDetails}
          takeoverCode={takeoverCode}
        />
      </ShowIfFragment>
      <ApplicationTakeoverExpired showIf={isExpired} />
      <ApplicationTakeoverInvalid showIf={isInvalid} />
      <ShowIfFragment
        showIf={Boolean(error) && !isLoading && !isExpired && !isInvalid}>
        <ApplicationTakeoverErrorView />
      </ShowIfFragment>
      <Loader show={isLoading} position="absolute" size={100} zIndex={100} />
    </Fragment>
  );
});
