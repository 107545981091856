import { AdditionalDetailsActionType } from '../common/AdditionalDetails/store/additionalDetails.actions';
import { additionalDetailsReducer } from '../common/AdditionalDetails/store/additionalDetails.reducer';
import { PostSubmissionActionsType } from '../features/postSubmission/store/post-submission.actions';
import { postSubmissionReducer } from '../features/postSubmission/store/post-submission.reducer';
import { PersonalDetailsActionType } from '../features/preSubmission/common/personal-details/store/personal-details.actions';
import { personalDetailsReducer } from '../features/preSubmission/common/personal-details/store/personal-details.reducer';
import { PreSubmissionActionType } from '../features/preSubmission/store/pre-submission.actions';
import { preSubmissionReducer } from '../features/preSubmission/store/pre-submission.reducer';
import { ApplicantApplicationOptionsActionType } from '../modules/applicant-application-options/store/applicant-application-options.actions';
import { applicantApplicationOptionsReducer } from '../modules/applicant-application-options/store/applicant-application-options.reducer';
import { ApplicationState } from '../types/application-state/application-state';
import { createDefaultApplicationState } from '../types/application-state/application-state.functions';
import { ApplicationActionTypes } from './application.actions';

export const applicationStateReducer = (
  state: ApplicationState = createDefaultApplicationState(),
  action: ApplicationActionTypes,
) => {
  return {
    ...state,
    postSubmissionState: postSubmissionReducer(
      state.postSubmissionState,
      action as PostSubmissionActionsType,
    ),
    preSubmissionState: preSubmissionReducer(
      state.preSubmissionState,
      action as PreSubmissionActionType,
    ),
    additionalDetailsState: additionalDetailsReducer(
      state.additionalDetailsState,
      action as AdditionalDetailsActionType,
    ),
    applicantApplicationOptionsState: applicantApplicationOptionsReducer(
      state.applicantApplicationOptionsState,
      action as ApplicantApplicationOptionsActionType,
    ),
    personalDetailsState: personalDetailsReducer(
      state.personalDetailsState,
      action as PersonalDetailsActionType,
    ),
  };
};
