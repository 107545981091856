import {
  LoanDetails,
  LoanDetailsPayDayApplicantFormSchema,
  LoanDetailsPayDayTgiApplicantFormSchema,
  LoanDetailsRules,
  LoanDetailsTgiApplicantFormSchema,
} from '@frontline/common';
import * as yup from 'yup';

export function getLoanDetailsValidationSchema(
  rules: LoanDetailsRules | null,
): yup.Schema<LoanDetails> | undefined {
  if (rules && rules.enablePayDayFrequency && rules.enableTgiInsurance) {
    return LoanDetailsPayDayTgiApplicantFormSchema;
  } else if (rules && rules.enablePayDayFrequency) {
    return LoanDetailsPayDayApplicantFormSchema;
  } else if (rules && rules.enableTgiInsurance) {
    return LoanDetailsTgiApplicantFormSchema;
  }
  return undefined;
}
