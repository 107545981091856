import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import omit from 'lodash/omit';
import queryString, { ParsedQuery } from 'query-string';
import { AuthenticationQuery } from './AuthenticationQuery';

export const createAuthenticationQuery = (
  parsedQuery: ParsedQuery,
): AuthenticationQuery => {
  return {
    accessKey: isString(parsedQuery?.accessKey) ? parsedQuery.accessKey : '',
    applicationId: isString(parsedQuery?.applicationId)
      ? parsedQuery.applicationId
      : '',
    merchantId: parsedQuery?.merchantId
      ? Number(parsedQuery?.merchantId)
      : null,
  };
};

export const removeAuthenticationQueryParameters = (
  parsedQuery: ParsedQuery,
): ParsedQuery => {
  return omit(parsedQuery, ['accessKey', 'applicationId']);
};

export const appendQueryParametersToPathExcludingAuthentication = (
  path: string,
  parsedQuery: ParsedQuery,
): string => {
  const otherParams = removeAuthenticationQueryParameters(parsedQuery);
  return isEmpty(otherParams)
    ? path
    : `${path}?${queryString.stringify(otherParams)}`;
};
