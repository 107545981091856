import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import * as applicantApplicationOptionsStore from '../../modules/applicant-application-options/store/applicant-application-options.store';
import * as applicationStore from '../../store/application.store';
import PostSubmissionRoutes from './PostSubmissionRoutes';
import * as store from './store/post-submission.store';

export const PostSubmissionFeature: FunctionComponent = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const workflow = useSelector(store.getWorkflow);
  const application = useSelector(applicationStore.getApplication);
  const applicationApplicantOptions = useSelector(
    applicantApplicationOptionsStore.getApplicantApplicationOption,
  );

  useEffect(() => {
    if (application) {
      store.initPostSubmissionWorkflowSteps(dispatch)();
    }
  }, [dispatch, application]);

  useEffect(() => {
    if (application && !applicationApplicantOptions) {
      applicantApplicationOptionsStore.fetchApplicantApplicationOptionForApplication(
        application.id,
      );
    }
  }, [application, applicationApplicantOptions]);

  useEffect(() => {
    if (workflow.length) {
      store.setActiveWorkflowStepFromRoute(dispatch)(location);
    }
  }, [location, workflow, dispatch]);

  return <PostSubmissionRoutes applicationId={application?.id} />;
};
