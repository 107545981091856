// tslint:disable:ordered-imports
import {
  applicationEpics as rootApplicationEpics,
  repairTechEpics,
} from '@frontline/web-common';
import { combineEpics } from 'redux-observable';
import applicationEpics from '../features/application/store/application.epics';
import buyLendCareEpics from '../features/lendCare/store/buy-lendcare.epics';
import newRepairEpics from '../features/new-repair/store/new-repair.epics';
import applicantRepairTechEpics from '../features/repair-tech/store/repair-tech.epics';
import applicationTakeoverEpics from '../modules/application-takeover/store/application-takeover.epics';
import authEpics from '../modules/auth/store/auth.epics';
import eventEpics from '../modules/events/store/events.epics';
import { applicantFraudDetectionEpics } from '../modules/fraud-detection/store/applicant-fraud-detection.epics';
import { lookupEpics } from '../modules/lookup/lookup.epics';
import securityQuestionsEpics from '../modules/security-questions/store/security-questions.epics';
import { geoLocationEpics } from '../features/application/modules/geo-location/store/geo-location.epics';

export default combineEpics<any>(
  ...rootApplicationEpics,
  ...applicationEpics,
  ...buyLendCareEpics,
  ...authEpics,
  ...securityQuestionsEpics,
  ...applicationTakeoverEpics,
  ...newRepairEpics,
  ...repairTechEpics,
  ...applicantRepairTechEpics,
  ...applicantFraudDetectionEpics,
  ...lookupEpics,
  ...eventEpics,
  ...geoLocationEpics,
);
