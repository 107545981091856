import { defineMessages } from 'react-intl';

export default defineMessages({
  thankYou: {
    id: 'Applicant.PreSubmission.Result.DeclinedPage.thankYou',
    description: 'Main message on the decline page',
    defaultMessage:
      'Thank-you for submitting your application. To continue, please contact your merchant to get details on the next steps.',
  },
  email: {
    id: 'Applicant.PreSubmission.Result.DeclinedPage.email',
    description: 'Label for email of merchant info on the decline page',
    defaultMessage: 'Email:',
  },
  phone: {
    id: 'Applicant.PreSubmission.Result.DeclinedPage.phone',
    description: 'Label for phone number of merchant info on the decline page',
    defaultMessage: 'Phone:',
  },
});
