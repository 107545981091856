import React, { FunctionComponent } from 'react';
interface ApplicationLayoutProps {
  maxWidth?: string;
}

const ApplicationLayout: FunctionComponent<ApplicationLayoutProps> = ({
  maxWidth = '1000px',
  children,
}) => {
  const styles = {
    margin: 'auto',
    maxWidth,
  };
  return <div style={styles}>{children}</div>;
};

export default ApplicationLayout;
