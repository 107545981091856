import { PersonalDetailsRules } from '@frontline/common';
import {
  ApplicationStepsHeader,
  capitalizeFirstLetter,
  PersonalDetailsForm,
  useApplicantName,
  usePostSubmissionStepReference,
} from '@frontline/web-common';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as postSubmissionStore from '../../../../../../store/post-submission.store';
import { ApplicantDetailsContext } from '../../ApplicantDetailsContext';
import messages from './ApplicantPersonalDetails.messages';

const ApplicantPersonalDetails = (props: { disabled: boolean }) => {
  const dispatch = useDispatch();
  const { applicant } = useContext(ApplicantDetailsContext);
  const applicantName = capitalizeFirstLetter(useApplicantName({}));
  const intl = useIntl();
  const stepReference = usePostSubmissionStepReference();

  return (
    <React.Fragment>
      <ApplicationStepsHeader
        title={intl.formatMessage(messages.title, { applicantName })}
        description={intl.formatMessage(messages.description, {
          applicantName,
        })}
      />
      <PersonalDetailsForm
        disabled={props.disabled}
        initialValues={applicant?.personalDetails}
        rules={stepReference?.step.rules as PersonalDetailsRules}
        onSubmit={postSubmissionStore.navigateToOverview(dispatch)}
      />
    </React.Fragment>
  );
};

export default ApplicantPersonalDetails;
