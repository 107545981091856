// tslint:disable:ordered-imports
import {
  ApplicationState as WebCommonApplicationState,
  fraudDetectionReducer,
  FraudDetectionState,
  LookupState,
  reducers,
} from '@frontline/web-common';
import { repairTechReducer, RepairTechState } from '@frontline/web-common';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import { applicationStateReducer } from '../features/application/store/application.reducer';
import { ApplicationState } from '../features/application/types/application-state/application-state';
import { buyLendCareReducer } from '../features/lendCare/store/buy-lendcare.reducer';
import { LendCareState } from '../features/lendCare/types/LendCareState/lendcare-state.interface';
import { newRepairReducer } from '../features/new-repair/store/new-repair.reducer';
import { NewRepairState } from '../features/new-repair/types/new-repair-state';
import { applicationTakeoverReducer } from '../modules/application-takeover/store/application-takeover.reducer';
import { ApplicationTakeoverState } from '../modules/application-takeover/types/application-takeover-state.interface';
import { authReducer } from '../modules/auth/store/auth.reducer';
import { AuthState } from '../modules/auth/types/AuthState/AuthState';
import lookupReducer from '../modules/lookup/lookup.reducer';
import { securityQuestionsReducer } from '../modules/security-questions/store/security-questions.reducer';
import { SecurityQuestionsState } from '../modules/security-questions/types/security-questions-state/security-questions-state.interface';
import { userPreferencesReducer } from '../modules/userPreferences/store/userPreferences.reducer';
import { UserPreferencesState } from '../modules/userPreferences/types/UserPreferencesState';
import { GeoLocationState } from '../features/application/modules/geo-location/types/geo-location-state.interface';
import { geoLocationReducer } from '../features/application/modules/geo-location/store/geo-location.reducer';

export interface RootState {
  router: RouterState;
  applicationState: ApplicationState;
  application: WebCommonApplicationState;
  buyLendCareState: LendCareState;
  authState: AuthState;
  applicationTakeoverState: ApplicationTakeoverState;
  securityQuestionsState: SecurityQuestionsState;
  newRepairState?: NewRepairState;
  repairTechState?: RepairTechState;
  userPreferencesState: UserPreferencesState;
  fraudDetectionState: FraudDetectionState;
  lookupState: LookupState;
  geoLocationState: GeoLocationState;
}

const rootReducer = (history: History) =>
  combineReducers<RootState>({
    router: connectRouter(history),
    applicationState: applicationStateReducer,
    application: reducers.applicationReducer,
    buyLendCareState: buyLendCareReducer,
    authState: authReducer,
    applicationTakeoverState: applicationTakeoverReducer,
    securityQuestionsState: securityQuestionsReducer,
    newRepairState: newRepairReducer,
    repairTechState: repairTechReducer,
    userPreferencesState: userPreferencesReducer,
    fraudDetectionState: fraudDetectionReducer,
    lookupState: lookupReducer,
    geoLocationState: geoLocationReducer,
  });

export default rootReducer;
