import { ActionType, createAction } from 'typesafe-actions';
import { AdditionalDetailsActionType } from '../common/AdditionalDetails/store/additionalDetails.actions';
import { PostSubmissionActionsType } from '../features/postSubmission/store/post-submission.actions';
import { PersonalDetailsActionType } from '../features/preSubmission/common/personal-details/store/personal-details.actions';
import { PreSubmissionActionType } from '../features/preSubmission/store/pre-submission.actions';
import { ApplicantApplicationOptionsActionType } from '../modules/applicant-application-options/store/applicant-application-options.actions';

export enum ApplicationActions {
  Init = 'Application/Init',
}

export const initApplication = createAction(ApplicationActions.Init);

const actionCreators = {
  initApplication,
};

export type ApplicationActionTypes =
  | PreSubmissionActionType
  | PostSubmissionActionsType
  | AdditionalDetailsActionType
  | ApplicantApplicationOptionsActionType
  | PersonalDetailsActionType
  | ActionType<typeof actionCreators>;
