import {
  createHomeAndAutoAncillaryProductStore,
  HomeAndAutoAncillaryProductState,
} from '@frontline/web-common';
import { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import { RootState, Store } from '../../../../../../../../../store';
import * as postSubmissionReviewStore from '../../../store/post-submission-review.store';
import { PostSubmissionReviewState } from '../../../types/PostSubmissionReviewState';

const getHomeAndAutoAncillaryProductState: Selector<
  RootState,
  HomeAndAutoAncillaryProductState | undefined
> = createSelector(
  postSubmissionReviewStore.getPostSubmissionReviewState,
  (state?: PostSubmissionReviewState) =>
    state?.homeAndAutoAncillaryProductState,
);

export const homeAndAutoAncillaryProductStore = {
  ...createHomeAndAutoAncillaryProductStore(
    getHomeAndAutoAncillaryProductState,
    Store.dispatch,
  ),
  getHomeAndAutoAncillaryProductState,
};
