import {
  PrimaryButton,
  SecondaryButton,
  withShowIf,
} from '@frontline/ui-library';
import { Grid, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { ApplicationTakeoverStore } from '../../../../../../modules/application-takeover/store/application-takeover.store';
import { ApplicationTakeoverReferenceCode } from '../../../application-takeover-reference-code/application-takeover-reference-code.component';
import { ApplicationTakeoverConfirmMessages } from './application-takeover-confirm.messages';
import { ApplicationTakeoverConfirmProps } from './application-takeover-confirm.props';
import { useApplicationTakeoverConfirmStyles } from './application-takeover-confirm.styles';

export const ApplicationTakeoverConfirm = withShowIf(
  (props: ApplicationTakeoverConfirmProps) => {
    const classes = useApplicationTakeoverConfirmStyles();

    const goBack = useCallback(
      () => ApplicationTakeoverStore.setIsConfirming(false),
      [],
    );

    const onConfirm = useCallback(
      () => props.onConfirm(true, props.takeoverCode),
      [props.takeoverCode],
    );

    return (
      <>
        <Grid item xs={11} md={7}>
          <Typography className={classes.title}>
            <FormattedMessage
              {...ApplicationTakeoverConfirmMessages.title}
              values={{
                merchantName: props.applicationTakeoverDetails?.merchant.name,
              }}
            />
          </Typography>
        </Grid>
        <Grid item className={classes.referenceCodeContainer}>
          <ApplicationTakeoverReferenceCode
            referenceCode={
              props.applicationTakeoverDetails?.appReferenceCode ?? ''
            }
          />
        </Grid>
        <Grid item xs={11} md={7}>
          <Typography className={classes.confirmationText}>
            <FormattedMessage
              {...ApplicationTakeoverConfirmMessages.confirmationText}
              values={{
                merchantName: props.applicationTakeoverDetails?.merchant.name,
              }}
            />
          </Typography>
        </Grid>
        <Grid item xs={11} md={7} className={classes.buttonContainer}>
          <PrimaryButton fullWidth onClick={onConfirm}>
            <FormattedMessage
              {...ApplicationTakeoverConfirmMessages.confirmButton}
            />
          </PrimaryButton>
          <SecondaryButton fullWidth onClick={goBack}>
            <FormattedMessage
              {...ApplicationTakeoverConfirmMessages.declineButton}
            />
          </SecondaryButton>
        </Grid>
      </>
    );
  },
);
