import { defineMessages } from 'react-intl';

const messages = defineMessages({
  monthlyPaymentTitle: {
    id: 'PostSubmissionMobileMessages.monthlyPaymentTitle',
    description: 'Title to show montly payment',
    defaultMessage: 'Monthly',
  },
  weeklyPaymentTitle: {
    id: 'PostSubmissionMobileMessages.weeklyPaymentTitle',
    description: 'Title to show weekly payment',
    defaultMessage: 'Weekly',
  },
  biWeeklyPaymentTitle: {
    id: 'PostSubmissionMobileMessages.biWeeklyPaymentTitle',
    description: 'Title to show weekly payment',
    defaultMessage: 'Bi-weekly',
  },
});

export const getFrequencyMessageDescriptor = (frequency?: number) => {
  switch (frequency) {
    case 2:
      return messages.monthlyPaymentTitle;
    case 14:
      return messages.weeklyPaymentTitle;
    case 15:
      return messages.biWeeklyPaymentTitle;
    default:
      return messages.monthlyPaymentTitle;
  }
};
