import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { getPrivateMessages } from '../../../features/application/services/pusher.service';
import { initApplication } from '../../../features/application/store/application.actions';
import { RootState } from '../../../store';
import * as authStore from '../../auth/store/auth.store';
import { applicationEventActions } from './events.actions';

const eventsEpic: Epic<Action, Action, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(initApplication)),
    map(() => [
      authStore.getUserId(state$.value),
      authStore.getAccessToken(state$.value),
    ]),
    mergeMap(([userId, accessToken]) =>
      getPrivateMessages(userId as string, accessToken as string).pipe(
        filter(notification =>
          Boolean(applicationEventActions[notification.event]),
        ),
        map(notification => {
          const action = applicationEventActions[notification.event];
          return action(notification.data.content ?? notification.data);
        }),
      ),
    ),
  );

export default [eventsEpic];
