import {
  YearsWorkedCategoryActionCreators,
  YearsWorkedCategoryService,
} from '@frontline/web-common';
import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootState } from '../../../store/reducers';

const fetchYearsWorkedCategoriesEpic: Epic<Action, Action, RootState> = (
  action$: Observable<Action>,
) =>
  action$.pipe(
    filter(
      isActionOf(YearsWorkedCategoryActionCreators.fetchYearsWorkedCategories),
    ),
    switchMap(() =>
      YearsWorkedCategoryService.fetchYearsWorkedCategories().pipe(
        map(
          YearsWorkedCategoryActionCreators.fetchYearsWorkedCategoriesSuccess,
        ),
      ),
    ),
  );

export const yearsWorkedCategoriesEpic = [fetchYearsWorkedCategoriesEpic];
