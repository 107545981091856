import { InputField, muiTheme, useUatId } from '@frontline/ui-library';
import { Grid, useMediaQuery } from '@material-ui/core';
import { Field, FormikErrors } from 'formik';
import * as React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { AssetTypesChoices } from '../../../../../asset-types-choices/asset-types-choices.component';
import messages from './purchase-details-form-fields.messages';
import { PurchaseDetailsFormFieldsProps } from './purchase-details-form-fields.props';
import { PurchaseDetailsFormUat } from './purchase-details-form-fields.uat';

export const PurchaseDetailsFormFields = (
  props: PurchaseDetailsFormFieldsProps,
) => {
  const intl = useIntl();
  const uatId = useUatId();

  const isMediaExtraSmall = useMediaQuery(muiTheme.breakpoints.down('xs'));

  const message = (messageId: MessageDescriptor) => {
    return intl.formatMessage(messageId);
  };

  const errorMessage = (error: FormikErrors<any> | string | undefined) => {
    const msg = messages[`${error}`];
    return msg ? message(msg) : '';
  };

  return (
    <Grid container={true} spacing={isMediaExtraSmall ? 0 : 1}>
      <Grid container={true} item={true} xs={12}>
        <Field
          name="assetType"
          component={AssetTypesChoices}
          errorMessage={errorMessage}
          options={props.allAssetTypes}
          disabled={true}
        />
      </Grid>

      <Grid container={true} item={true} xs={12} sm={6}>
        <Field
          name="programName"
          component={InputField}
          errorMessage={errorMessage}
          label={message(messages.programLabel)}
          placeholder={message(messages.programPlaceholder)}
          optionValue="id"
          optionDisplay="name"
          disabled={true}
          defaultSelectionIfSingleOption={true}
          uat={uatId(
            PurchaseDetailsFormUat.purchaseDetailFinancialProgramField,
          )}
        />
      </Grid>
      <Grid container={true} item={true} xs={12} sm={6}>
        <Field
          name="assetSubTypeName"
          component={InputField}
          errorMessage={errorMessage}
          label={message(messages.itemPurchasedLabel)}
          placeholder={message(messages.itemPurchasedPlaceholder)}
          optionValue="id"
          optionDisplay="name"
          disabled={true}
          defaultSelectionIfSingleOption={true}
          uat={uatId(
            PurchaseDetailsFormUat.purchaseDetailProductServiceTypeField,
          )}
        />
      </Grid>
      <Grid container={true} item={true} xs={12}>
        <Field
          name="loanAmount"
          component={InputField}
          fieldType="number"
          numberFormat="money"
          errorMessage={errorMessage}
          label={message(messages.purchaseAmountLabel)}
          disabled={true}
          uat={uatId(PurchaseDetailsFormUat.purchaseDetailRequestedAmount)}
        />
      </Grid>
    </Grid>
  );
};
