import { LoginStatus } from '@frontline/common';
import { push } from 'connected-react-router';
import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootState } from '../../../store';
import { createNotAuthenticatedPath } from '../../navigation/types';
import * as securityQuestionsActions from '../../security-questions/store/security-questions.actions';
import {
  clearAuthenticationData,
  login,
  processAuthentication,
  setApplicationId,
  verifyAuthentication,
} from '../services/auth.service';
import { AuthInitializeForm } from '../types/AuthInitializeForm/AuthInitializeForm';
import { shouldLogin } from '../types/AuthInitializeForm/AuthInitializeForm.functions';
import { VerifyAuthenticationResponse } from '../types/VerifyAuthenticationResponse';
import * as actions from './auth.actions';

const initEpic: Epic<Action, Action, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.init)),
    map(action => action.payload),
    map((authInitializeForm: AuthInitializeForm) =>
      shouldLogin(authInitializeForm)
        ? actions.login(
            authInitializeForm.query,
            authInitializeForm.redirectUrl,
          )
        : actions.validate(authInitializeForm.redirectUrl),
    ),
  );

const loginEpic: Epic<Action, Action, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.login)),
    map(action => action.payload),
    mergeMap(({ query }) =>
      from(login(query)).pipe(
        map(result => actions.loginSuccess(query.accessKey, result)),
        catchError(error => of(actions.loginError(error))),
      ),
    ),
  );

const loginSuccessEpic: Epic<Action, Action, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.loginSuccess)),
    map(action => action.payload),
    map(payload =>
      payload.result.status === LoginStatus.SecurityQuestionsRequired
        ? securityQuestionsActions.loadSecurityQuestions(payload.accessKey)
        : actions.setToken(processAuthentication(payload.result.data)),
    ),
  );

export const setTokenEpic: Epic<Action, Action, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.setToken)),
    map(() => actions.validate()),
  );

export const validateEpic: Epic<Action, Action, RootState> = (
  action$,
  state$: any,
) =>
  action$.pipe(
    filter(isActionOf(actions.validate)),
    map((action): [VerifyAuthenticationResponse, string] => [
      verifyAuthentication(),
      action.payload || state$.value.authState.redirectUrl || '/',
    ]),
    map(([authResult, redirectUrl]: [VerifyAuthenticationResponse, string]) =>
      authResult
        ? actions.validateSuccess(authResult, redirectUrl)
        : actions.validateError(),
    ),
  );

export const validateSuccessEpic: Epic<Action, Action, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.validateSuccess)),
    map(action => action.payload),
    map(payload => payload.redirectUrl),
    map(redirectUrl => push(redirectUrl)),
  );

export const validateErrorEpic: Epic<Action, Action, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.validateError)),
    tap(() => clearAuthenticationData()),
    map(() => push(createNotAuthenticatedPath())),
  );

export const setApplicationIdEpic: Epic<Action, Action, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.setApplicationId)),
    map(action => action.payload),
    tap(setApplicationId),
    map(() => push('/')),
  );

export default [
  initEpic,
  loginEpic,
  loginSuccessEpic,
  setTokenEpic,
  validateEpic,
  validateErrorEpic,
  validateSuccessEpic,
  setApplicationIdEpic,
];
