import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { filter, map } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { applicationUpdatedAction } from '../../../modules/events/store/events.actions';
import { RootState } from '../../../store';
import { refreshApplication } from '../../../store/application/actions';
import postSubmissionEpics from '../features/postSubmission/store/post-submission.epics';
import { personalDetailsStateEpics } from '../features/preSubmission/common/personal-details/store/personal-details.epics';
import preSubmissionReviewEpics from '../features/preSubmission/store/pre-submission.epics';
import applicantApplicationOptionsEpics from '../modules/applicant-application-options/store/applicant-application-options.epics';

const updateApplicationEpic: Epic<Action, Action, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(applicationUpdatedAction)),
    map(notification => notification.payload),
    map(applicationId => refreshApplication(applicationId)),
  );

export default [
  ...postSubmissionEpics,
  ...preSubmissionReviewEpics,
  ...applicantApplicationOptionsEpics,
  ...personalDetailsStateEpics,
  updateApplicationEpic,
];
