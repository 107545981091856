import {
  Application,
  updateWorkflowStepStatus,
  WorkflowStep,
  WorkflowStepType,
} from '@frontline/common';
import cloneDeep from 'lodash/cloneDeep';
import { stepRouteParams } from '../../../../../modules/navigation/types';

const makeReviewPath = (
  applicationId: string,
  workFlowType?: WorkflowStepType,
  applicantType?: WorkflowStepType,
): string => {
  const baseUrl = `/applications/${applicationId}/pre-submission`;
  if (!workFlowType) {
    return baseUrl;
  }
  return applicantType
    ? `${baseUrl}${stepRouteParams[applicantType]}${stepRouteParams[workFlowType]}`
    : `${baseUrl}${stepRouteParams[workFlowType]}`;
};

// TODO - workflow - route assignment
export const getPreSubmissionRoutePath = (
  applicationId: string,
  type?: WorkflowStepType,
): string => {
  switch (type) {
    case WorkflowStepType.PersonalDetails:
    case WorkflowStepType.IncomeDetails:
    case WorkflowStepType.AdditionalDetails:
      return makeReviewPath(applicationId, type, WorkflowStepType.Applicant);
    case WorkflowStepType.CoApplicantPersonalDetails:
    case WorkflowStepType.CoApplicantIncomeDetails:
    case WorkflowStepType.CoApplicantAdditionalDetails:
      return makeReviewPath(applicationId, type, WorkflowStepType.CoApplicant);
    case WorkflowStepType.Result:
      return `/applications/${applicationId}/pre-submission/result`;
    default:
      return makeReviewPath(applicationId, type);
  }
};

export const getResultPageRoutePath = (applicationId: string): string =>
  getPreSubmissionRoutePath(applicationId, WorkflowStepType.Result);

export const updateStepsWithRoutes = (
  steps: WorkflowStep[],
  application: Application,
): WorkflowStep[] =>
  steps.map((step: WorkflowStep) => {
    const updatedStep = updateStep(step, application);
    if (updatedStep.subSteps) {
      updatedStep.subSteps = updateStepsWithRoutes(
        updatedStep.subSteps,
        application,
      );
    }
    return updatedStep;
  });

// TODO - workflow - route assignment
const updateStep = (
  step: WorkflowStep,
  application: Application,
): WorkflowStep => {
  const clonedStep = cloneDeep(step);
  updateWorkflowStepStatus(clonedStep, application);
  clonedStep.routeUrl = getPreSubmissionRoutePath(application.id, step.type);
  return clonedStep;
};
