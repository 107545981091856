import { defineMessages } from 'react-intl';

export const PersonalDetailsFormViewMessages = defineMessages({
  personalDetailsTitle: {
    id: 'PersonalDetailsForm.personalDetailsTitle',
    description: 'Personal details title',
  },
  addressDetailsTitle: {
    id: 'PersonalDetailsForm.addressDetailsTitle',
    description: 'Address details title',
  },
  contactDetailsTitle: {
    id: 'PersonalDetailsForm.contactDetailsTitle',
    description: 'Contact details title',
  },
  residentialLengthLabel: {
    id: 'PersonalDetailsForm.residentialLengthLabel',
    description: 'Length Of Stay Label for Input',
  },
  residentialLengthPlaceholder: {
    id: 'PersonalDetailsForm.residentialLengthPlaceholder',
    description: 'Length Of Stay Placeholder',
  },
  residentialLengthRequired: {
    id: 'PersonalDetailsForm.residentialLengthRequired',
    description: 'Length Of Stay Required',
  },
  saveError: {
    id: 'PersonalDetailsForm.saveError',
    description: 'Error message to show when save personal details fails',
  },
  personalDetailsAcknowledgmentHeader: {
    id: 'Applicant.PreSubmission.PersonalDetails.Consent.header',
  },
  personalDetailsAcknowledgmentSubHeader: {
    id: 'Applicant.PreSubmission.PersonalDetails.Consent.subHeader',
  },
  personalDetailsAcknowledgment: {
    id: 'Applicant.PreSubmission.PersonalDetails.Consent.acknowledgment',
  },
  personalDetailsAcknowledgmentLinkText: {
    id: 'Applicant.PreSubmission.PersonalDetails.Consent.linkText',
  },
});
