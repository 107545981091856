import { Colours } from '@frontline/ui-library';
import { createStyles, makeStyles } from '@material-ui/core';

const getWrapperMargin = (
  isSmallMedia: boolean = false,
  isExtraSmallMedia: boolean = false,
): string => {
  if (isExtraSmallMedia) {
    return '15px auto';
  }

  if (isSmallMedia) {
    return '0 15px 0 auto';
  }

  return '0 0 0 auto';
};

export const useHighCostLicenseDetailsStyles = makeStyles(
  createStyles({
    wrapper: {
      maxWidth: 'fit-content',
      margin: (props: { isSmallMedia: boolean; isExtraSmallMedia: boolean }) =>
        getWrapperMargin(props.isSmallMedia, props.isExtraSmallMedia),
      textAlign: 'center',
      padding: '7px',
    },
    provinceLabel: {
      fontSize: '15px',
    },
    blackText: {
      color: Colours.Black,
    },
  }),
);
