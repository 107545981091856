import {
  ApplicantApplicationOptionDto,
  CalculatorValues,
  findNextWorkflowStepById,
  findPreviousWorkflowStepById,
  findWorkflowStepById,
  findWorkflowStepByRoute,
  WorkflowStep,
} from '@frontline/common';
import { PaymentCalculatorService } from '@frontline/web-common';
import { push } from 'connected-react-router';
import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootState } from '../../../../../store';
import * as rootApplicationActions from '../../../../../store/application/actions';
import * as applicationActions from '../../../../../store/application/actions';
import * as applicantOptionsStore from '../../../modules/applicant-application-options/store/applicant-application-options.store';
import * as applicationStore from '../../../store/application.store';
import overviewEpics from '../features/Overview/store/overview.epics';
import postSubmissionReviewEpics from '../features/PostSubmissionReview/store/post-submission-review.epics';
import { createPostSubmissionWorklowSteps } from '../types';
import * as actions from './post-submission.actions';
import * as postSubmissionStore from './post-submission.store';

export const fetchCalculationEpic: Epic<Action, Action, RootState> = (
  action$: Observable<Action>,
) =>
  action$.pipe(
    filter(isActionOf(actions.fetchEstimatedPayment)),
    map(action => action.payload),
    switchMap((form: CalculatorValues) =>
      from(PaymentCalculatorService.getEstimatedPayment(form)).pipe(
        map(response => actions.fetchEstimatedPaymentSuccess(response)),
        catchError(error => of(actions.fetchEstimatedPaymentError(error))),
      ),
    ),
  );

export const navigateToNextStepEpic: Epic<Action, Action, RootState> = (
  action$: Observable<Action>,
  state$: any,
) =>
  action$.pipe(
    filter(
      action =>
        isActionOf(actions.navigateToNextStep)(action) ||
        isActionOf(rootApplicationActions.navigateToNextPostSubmissionStep)(
          action,
        ),
    ),
    map(() => [
      postSubmissionStore.getActiveWorkflowStepId(state$.value),
      postSubmissionStore.getWorkflow(state$.value),
      applicantOptionsStore.getApplicantApplicationOption(state$.value),
    ]),
    map(([activeStep, workflow, applicantOptions]) => {
      return findNextWorkflowStepById(
        workflow as WorkflowStep[],
        activeStep as string,
        applicantOptions as ApplicantApplicationOptionDto,
      );
    }),
    map(step =>
      step?.routeUrl ? push(step.routeUrl) : applicationActions.goToOverview(),
    ),
  );

export const navigateToOverviewWhenActiveStepNotFoundEpic: Epic<
  Action,
  Action,
  RootState
> = (action$: Observable<Action>) =>
  action$.pipe(
    filter(isActionOf(actions.setActiveWorkflowStep)),
    map(action => action.payload),
    distinctUntilChanged(),
    filter(activeStep => !Boolean(activeStep)),
    map(() => applicationActions.goToOverview()),
  );

export const navigateToPreviousStepEpic: Epic<Action, Action, RootState> = (
  action$: Observable<Action>,
  state$: any,
) =>
  action$.pipe(
    filter(isActionOf(actions.navigateToPreviousStep)),
    map(() => [
      postSubmissionStore.getActiveWorkflowStepId(state$.value),
      postSubmissionStore.getWorkflow(state$.value),
      applicantOptionsStore.getApplicantApplicationOption(state$.value),
    ]),
    map(([activeStep, workflow, applicantOptions]) => {
      return findPreviousWorkflowStepById(
        workflow as WorkflowStep[],
        activeStep as string,
        applicantOptions as ApplicantApplicationOptionDto,
      );
    }),
    map(step =>
      step?.routeUrl ? push(step.routeUrl) : applicationActions.goToOverview(),
    ),
  );

export const navigateToStepByIdEpic: Epic<Action, Action, RootState> = (
  action$: Observable<Action>,
  state$: any,
) =>
  action$.pipe(
    filter(isActionOf(actions.navigateToStepById)),
    map(action => action.payload),
    map(workflowStepId => [
      workflowStepId,
      postSubmissionStore.getWorkflow(state$.value),
    ]),
    map(([workflowStepId, workflow]) => {
      return findWorkflowStepById(
        workflow as WorkflowStep[],
        workflowStepId as string,
      );
    }),
    map(step =>
      step?.routeUrl ? push(step.routeUrl) : applicationActions.goToOverview(),
    ),
  );

export const initPostSubmissionWorkflowStepsEpic: Epic<
  Action,
  Action,
  RootState
> = (action$: Observable<Action>, state$: any) =>
  action$.pipe(
    filter(isActionOf(actions.initPostSubmissionWorkflowSteps)),
    map(() =>
      createPostSubmissionWorklowSteps(
        applicationStore.getPostSubmissionWorkflow(state$.value),
        applicationStore.getApplication(state$.value),
      ),
    ),
    map((workflowSteps: WorkflowStep[]) =>
      actions.setWorkflowSteps(workflowSteps),
    ),
  );

export const setActiveStepFromRouteEpic: Epic<Action, Action, RootState> = (
  action$: Observable<Action>,
  state$: any,
) =>
  action$.pipe(
    filter(isActionOf(actions.setActiveWorkflowStepFromRoute)),
    map(action => action.payload),
    map(location => location.pathname),
    map(routeUrl => [routeUrl, postSubmissionStore.getWorkflow(state$.value)]),
    map(([routeUrl, workflow]) =>
      findWorkflowStepByRoute(workflow as WorkflowStep[], routeUrl as string),
    ),
    map(step => actions.setActiveWorkflowStep(step?.id)),
  );

export default [
  ...postSubmissionReviewEpics,
  ...overviewEpics,
  fetchCalculationEpic,
  navigateToNextStepEpic,
  navigateToOverviewWhenActiveStepNotFoundEpic,
  navigateToPreviousStepEpic,
  navigateToStepByIdEpic,
  setActiveStepFromRouteEpic,
  initPostSubmissionWorkflowStepsEpic,
];
