import { ReadOnlyFormItem, withShowIf } from '@frontline/ui-library';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useIntl } from 'react-intl';
import { LoanDetailsItemProps } from './loan-details-item.props';

export const LoanDetailsItem = withShowIf((props: LoanDetailsItemProps) => {
  const {
    labelMessage,
    data,
    enabledReceipts,
    placeholder,
    ...gridProps
  } = props;
  const intl = useIntl();
  return (
    <Grid item={true} xs={12} {...gridProps}>
      <ReadOnlyFormItem label={intl.formatMessage(labelMessage)}>
        {data}
      </ReadOnlyFormItem>
    </Grid>
  );
});
