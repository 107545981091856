import * as yup from 'yup';

export const createSecurityQuestionsFormValidationSchema = (
  values: string[],
) => {
  const objValues = {};

  for (const value of values) {
    objValues[value] = yup.string().required('requiredField');
  }

  return yup.object().shape(objValues);
};
