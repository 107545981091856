import { HttpError } from '@frontline/web-common';
import { Dispatch } from 'redux';
import { createSelector, Selector } from 'reselect';
import { RootState, Store } from '../../../../../../../store';
import * as rootApplicationActions from '../../../../../../../store/application/actions';
import * as rootApplicationSelectors from '../../../../../../../store/application/selectors';
import { PersonalDetailsState } from '../types/personal-details.state';
import { PersonalDetailsActions } from './personal-details.actions';

const getPersonalDetailsState: Selector<
  RootState,
  PersonalDetailsState | undefined
> = (state: RootState) => state.applicationState.personalDetailsState;

export const getApplicantAddress = (state: RootState) =>
  rootApplicationSelectors.getApplicantAddress(state.application);

export const getApplicantFastFillAttempts = (state: RootState) =>
  rootApplicationSelectors.getApplicantFastFillAttempts(state.application);

export const saveApplicantFastFillDetails = (dispatch: Dispatch<any>) => (
  form: FormData,
): void => {
  dispatch(rootApplicationActions.saveApplicantDetailsFastFill(form));
};

export const getPersonalDetailsError: Selector<
  RootState,
  any | undefined
> = createSelector(
  getPersonalDetailsState,
  personalDetails => personalDetails?.error,
);

export const resetPersonalDetailsError = (dispatch: Dispatch) => (): void => {
  dispatch(PersonalDetailsActions.resetApplicantPersonalDetailsError());
};

export const getIsSalesRepIdDialogOpen: Selector<
  RootState,
  boolean
> = createSelector(
  getPersonalDetailsState,
  (personalDetailsState?: PersonalDetailsState) => {
    return Boolean(personalDetailsState?.isSalesRepIdDialogOpen);
  },
);

export const getIsSalesRepIdComplete: Selector<
  RootState,
  boolean
> = createSelector(
  getPersonalDetailsState,
  (personalDetailsState?: PersonalDetailsState) => {
    return Boolean(personalDetailsState?.isSalesRepIdComplete);
  },
);

export const getSubmitSalesRepIdError: Selector<
  RootState,
  HttpError | null
> = createSelector(
  getPersonalDetailsState,
  (personalDetailsState?: PersonalDetailsState) => {
    return personalDetailsState?.submitSalesRepIdError || null;
  },
);

export function setIsSalesRepIdDialogOpen(open: boolean): void {
  Store.dispatch(PersonalDetailsActions.setSalesRepIdDialogOpen(open));
}

export function setIsSalesRepIdComplete(complete: boolean): void {
  Store.dispatch(PersonalDetailsActions.setSalesRepIdComplete(complete));
}

export function submitSalesRepId(salesRepId: string): void {
  Store.dispatch(PersonalDetailsActions.submitSalesRepId(salesRepId));
}

export const getIsLoading: Selector<RootState, boolean> = createSelector(
  getPersonalDetailsState,
  (personalDetailsState?: PersonalDetailsState) => {
    return Boolean(personalDetailsState?.isLoading);
  },
);
