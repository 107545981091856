import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'LoanDetailsStep.title',
    description: 'Loan Details Step Title',
    defaultMessage: 'Review the purchase and loan details',
  },
  description: {
    id: 'LoanDetailsStep.description',
    description: 'Loan Details Step Description',
    defaultMessage: 'Review and finalize the intended purchase and loan terms',
  },
  editButton: {
    id: 'LoanDetailsStep.editButton',
    description: 'Loan Details Step Edit Button',
    defaultMessage: 'Review loan details',
  },
});
