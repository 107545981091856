import { CoApplicantPersonalDetailsActionsType } from '../../../features/CoApplicant/features/CoApplicantPersonalDetails/store/coApplicantPersonalDetails.actions';
import { coApplicantPersonalDetailsReducer } from '../../../features/CoApplicant/features/CoApplicantPersonalDetails/store/coApplicantPersonalDetails.reducer';
import { CoApplicantDetailsState } from '../../../features/CoApplicant/types/CoApplicantDetailsState';
import { createCoApplicantDetailsState } from '../../../features/CoApplicant/types/CoApplicantDetailsState.functions';
import {
  CoApplicantDetailsActions,
  CoApplicantDetailsActionType,
} from './coApplicantDetails.actions';

export const coApplicantDetailsReducer = (
  state: CoApplicantDetailsState = createCoApplicantDetailsState(),
  action: CoApplicantDetailsActionType,
): CoApplicantDetailsState => {
  switch (action.type) {
    case CoApplicantDetailsActions.SetCoApplicantActiveStepIndex:
      return {
        ...state,
        activeStepIndex: action.payload,
      };
    case CoApplicantDetailsActions.UpdateCoApplicantWorkflow:
      return {
        ...state,
        workflow: action.payload,
      };
    default:
      return {
        ...state,
        coApplicantPersonalDetailsState: coApplicantPersonalDetailsReducer(
          state.coApplicantPersonalDetailsState,
          action as CoApplicantPersonalDetailsActionsType,
        ),
      };
  }
};
