import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootState } from '../../../../../../../../../store';
import { getRelationshipTypes } from '../services/relationshipType.service';
import * as actions from './coApplicantPersonalDetails.actions';

export const fetchRelationshipTypesEpic: Epic<Action, Action, RootState> = (
  action$: Observable<Action>,
) =>
  action$.pipe(
    filter(isActionOf(actions.fetchRelationshipTypes)),
    mergeMap(() =>
      from(getRelationshipTypes()).pipe(
        map(relationshipTypes =>
          actions.fetchRelationshipTypesSuccess(relationshipTypes),
        ),
        catchError(error => of(actions.fetchRelationshipTypesError(error))),
      ),
    ),
  );

export default [fetchRelationshipTypesEpic];
