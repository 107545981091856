import { useUatId } from '@frontline/ui-library';
import { MessageValues } from '@frontline/web-common';
import { Grid, Radio } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import calendarDefault from '../../../../../../../../../../assets/calendar-default.svg';
import { getNameAndIcon } from '../../types/loan-details-form-frequency-option.functions';
import { PayDayFrequencies } from '../../types/pay-day-frequency.constants';
import { RadioOnChange } from '../../types/radio-on-change.interface';
import messages from './loan-details-form-frequency-option.messages';
import { LoanDetailsFormFrequencyOptionProps } from './loan-details-form-frequency-option.props';
import {
  Card,
  Frequency,
  RadioContainer,
  SVGContainer,
  SVGImage,
} from './loan-details-form-frequency-option.styles';
import { LoanDetailsFormFrequencyOptionUat } from './loan-details-form-frequency-option.uat';

export const LoanDetailsFormFrequencyOption = (
  props: LoanDetailsFormFrequencyOptionProps,
) => {
  const uatId = useUatId();
  const handleChange = (event: RadioOnChange) => {
    const newFrequency = Number(event.target.value);
    props.form.setFieldValue(props.field.name, newFrequency);
  };

  const typedMessages = messages as MessageValues;

  return (
    <React.Fragment>
      {PayDayFrequencies.map(frequencyOption => (
        <Grid item={true} key={frequencyOption} xs={6} sm={3}>
          <Card
            checked={props.payDayFrequencyId === frequencyOption}
            showError={props.showError}>
            <SVGContainer>
              <SVGImage
                src={
                  props.payDayFrequencyId === frequencyOption
                    ? getNameAndIcon(frequencyOption).icon
                    : calendarDefault
                }
              />
            </SVGContainer>
            <Frequency>
              <FormattedMessage
                {...typedMessages[getNameAndIcon(frequencyOption).name]}
              />
            </Frequency>
            <RadioContainer>
              <Radio
                checked={props.payDayFrequencyId === frequencyOption}
                onChange={handleChange}
                value={frequencyOption}
                name="radio-button-frequency"
                disabled={props.disabled}
                {...uatId(LoanDetailsFormFrequencyOptionUat[frequencyOption])}
              />
            </RadioContainer>
          </Card>
        </Grid>
      ))}
    </React.Fragment>
  );
};
