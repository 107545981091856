import { HttpService } from '@frontline/web-common';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RepairTechLoginResponse } from '../types/repair-tech-login-response';

export function login(
  genericLinkId: string,
): Observable<RepairTechLoginResponse> {
  return from(
    HttpService.getHttpInstanceDeprecated().post(
      `/applicant/login/repair-tech/${genericLinkId}`,
    ),
  ).pipe(map(response => response.data));
}
