import {
  FlinksViewActionType,
  flinksViewReducer,
  HomeAndAutoAncillaryProductActionType,
  homeAndAutoAncillaryProductReducer,
  InvoiceDetailsActionType,
  invoiceDetailsReducer,
  LoanProtectionActionType,
  loanProtectionReducer,
  PreAuthorizedDebitActionType,
  preAuthorizedDebitReducer,
  VerifyIdentityActionType,
  verifyIdentityReducer,
} from '@frontline/web-common';
import { AssetInformationActionType } from '../features/AssetInformation/store/assetInformation.actions';
import { assetInformationReducer } from '../features/AssetInformation/store/assetInformation.reducer';
import { AssetsAndAddOnsActionType } from '../features/AssetsAndAddOns/store/assets-and-addons.actions';
import { assetsAndAddOnsReducer } from '../features/AssetsAndAddOns/store/assets-and-addons.reducer';
import { BankDocumentsActionType } from '../features/bank-documents/store/bank-documents.actions';
import { bankDocumentsReducer } from '../features/bank-documents/store/bank-documents.reducer';
import { ComponentSelectorActionType } from '../features/ComponentSelector/store/componentSelector.actions';
import { componentSelectorReducer } from '../features/ComponentSelector/store/componentSelector.reducer';
import { LoanDetailsActionType } from '../features/loan-details/store/loan-details.actions';
import { loanDetailsReducer } from '../features/loan-details/store/loan-details.reducer';
import { SignDocumentsActionType } from '../features/sign-loan-documents/store/sign-documents.actions';
import { signDocumentsReducer } from '../features/sign-loan-documents/store/sign-documents.reducer';
import { ThirdPartyAddOnsActionType } from '../features/ThirdPartyAddOns/store/thirdPartyAddOns.actions';
import { thirdPartyAddOnsReducer } from '../features/ThirdPartyAddOns/store/thirdPartyAddOns.reducer';
import { PostSubmissionReviewState } from '../types/PostSubmissionReviewState';
import { PostSubmissionReviewActionType } from './post-submission-review.actions';

export function postSubmissionReviewReducer(
  state: PostSubmissionReviewState = {},
  action: PostSubmissionReviewActionType,
): PostSubmissionReviewState {
  return {
    ...state,
    assetInformationState: assetInformationReducer(
      state.assetInformationState,
      action as AssetInformationActionType,
    ),
    assetsAndAddOnsState: assetsAndAddOnsReducer(
      state.assetsAndAddOnsState,
      action as AssetsAndAddOnsActionType,
    ),
    loanDetailsState: loanDetailsReducer(
      state.loanDetailsState,
      action as LoanDetailsActionType,
    ),
    signDocumentsState: signDocumentsReducer(
      state.signDocumentsState,
      action as SignDocumentsActionType,
    ),
    loanProtectionState: loanProtectionReducer(
      state.loanProtectionState,
      action as LoanProtectionActionType,
    ),
    thirdPartyAddOnsState: thirdPartyAddOnsReducer(
      state.thirdPartyAddOnsState,
      action as ThirdPartyAddOnsActionType,
    ),
    componentSelectorState: componentSelectorReducer(
      state.componentSelectorState,
      action as ComponentSelectorActionType,
    ),
    invoiceDetailsState: invoiceDetailsReducer(
      state.invoiceDetailsState,
      action as InvoiceDetailsActionType,
    ),
    bankDocumentsState: bankDocumentsReducer(
      state.bankDocumentsState,
      action as BankDocumentsActionType,
    ),
    preAuthorizedDebitState: preAuthorizedDebitReducer(
      state.preAuthorizedDebitState,
      action as PreAuthorizedDebitActionType,
    ),
    verifyIdentityState: verifyIdentityReducer(
      state.verifyIdentityState,
      action as VerifyIdentityActionType,
    ),
    flinksViewState: flinksViewReducer(
      state.flinksViewState,
      action as FlinksViewActionType,
    ),
    homeAndAutoAncillaryProductState: homeAndAutoAncillaryProductReducer(
      state.homeAndAutoAncillaryProductState,
      action as HomeAndAutoAncillaryProductActionType,
    ),
  };
}
