// TODO: Deprecate this file. Should transition code to directly use web-common
import { selectors } from '@frontline/web-common';

export const postSubmissionSelectors = selectors.postSubmissionSelectors;

export const {
  getApplicantFastFillAttempts,
  getCoApplicantFastFillAttempts,
  getCurrentApplicationId,
  getApplications,
  isAddCoApplicantDialogOpen,
  isRemoveCoApplicantDialogOpen,
  getCurrentApplication,
  getApplication,
  getApplicationId,
  getProgramId,
  isLoading,
  isSaving,
  getLoanDetails,
  getAssetInformation,
  shouldDisplayInterestRate,
  getApplicant,
  getApplicantPersonalDetails,
  getApplicantAddress,
  getApplicantIncomeDetails,
  getApplicantAdditionalDetails,
  getCoApplicants,
  getCoApplicant,
  hasCoApplicant,
  getCoApplicantPersonalDetails,
  getCoApplicantIncomeDetails,
  getCoApplicantAdditionalDetails,
  getOwner,
  shouldAddCoApplicant,
  getApplicationStatus,
  getPaymentDetails,
  getApplicationDocuments,
  getVoidChequeDocuments,
  getPostWorkflowSteps,
  getPreWorkflowSteps,
  isPreWorkflowCompleted,
  isPostWorkflowCompleted,
  getPreWorkflowStepByType,
  getFirstIncompleteWorkflowStep,
  getPostWorkflowStepByType,
  getSignDocuments,
  getBankDetails,
  getFlinksStatus,
  getNetverify,
  getWorkflowDecisions,
  getVerificationMessages,
} = selectors.applicationSelectors;
