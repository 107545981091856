import { WorkflowStepReference, WorkflowStepType } from '@frontline/common';
import React from 'react';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import { OnPreSubmissionSubmitType } from '../../types/PreSubmissionForm/PreSubmissionFormPropsType';
import { getPreSubmissionRoutePath } from '../../types/PreSubmissionRoutes.functions';
import { CoApplicantAdditionalDetailsFeature } from './features/co-applicant-additional-details-view/co-applicant-additional-details-feature.component';
import { CoApplicantIncomeDetailsFeature } from './features/CoApplicantIncomeDetails/CoApplicantIncomeDetailsFeature';
import { CoApplicantPersonalDetailsFeature } from './features/CoApplicantPersonalDetails/co-applicant-personal-details.feature';

export interface CoApplicantDetailsRoutesProps {
  applicationId: string;
  onSubmit: OnPreSubmissionSubmitType<any>;
  onBack?: () => void;
  currentStep: WorkflowStepReference | null;
  stepPosition: number;
}

export const CoApplicantRoutes = (props: CoApplicantDetailsRoutesProps) => (
  <Switch>
    <Route
      path={getPreSubmissionRoutePath(
        props.applicationId,
        WorkflowStepType.CoApplicantPersonalDetails,
      )}
      render={() => (
        <CoApplicantPersonalDetailsFeature
          onSubmit={props.onSubmit}
          onBack={props.onBack}
          stepReference={props.currentStep}
          stepPosition={props.stepPosition}
        />
      )}
    />
    <Route
      path={getPreSubmissionRoutePath(
        props.applicationId,
        WorkflowStepType.CoApplicantIncomeDetails,
      )}
      render={() => (
        <CoApplicantIncomeDetailsFeature
          onSubmit={props.onSubmit}
          onBack={props.onBack}
          stepReference={props.currentStep}
          stepPosition={props.stepPosition}
        />
      )}
    />
    <Route
      path={getPreSubmissionRoutePath(
        props.applicationId,
        WorkflowStepType.CoApplicantAdditionalDetails,
      )}
      render={() => (
        <CoApplicantAdditionalDetailsFeature
          onSubmit={props.onSubmit}
          onBack={props.onBack}
          stepReference={props.currentStep}
          stepPosition={props.stepPosition}
        />
      )}
    />
  </Switch>
);
