export const ApprovedPath = 'approved';
export const DeclinedPath = 'contact-merchant';

export function getApprovedPageRoute(applicationId = ':id'): string {
  return `${getResultPageRoute(applicationId)}/${ApprovedPath}`;
}

export function getDeclinedPageRoute(applicationId = ':id'): string {
  return `${getResultPageRoute(applicationId)}/${DeclinedPath}`;
}

export function getResultPageRoute(applicationId = ':id'): string {
  return `/applications/${applicationId}/pre-submission/result`;
}

export function isApprovedPage(path: string): boolean {
  return new RegExp(`/${ApprovedPath}$/`).test(path);
}

export function isDeclinedPage(path: string): boolean {
  return new RegExp(`/${DeclinedPath.replace('-', '\\-')}/`).test(path);
}
