import { defineMessages } from 'react-intl';

export default defineMessages({
  purchaseDetailsTitle: {
    id:
      'Applicant.PostSubmission.LoanDetails.Modules.PurchaseDetails.PurchaseDetailsTitle',
    defaultMessage: 'Purchase Details',
    description:
      'Purchase Details Label for the Purchase Details Section of Loan Details in Application Portal',
  },
  itemPurchasedLabel: {
    id:
      'Applicant.PostSubmission.LoanDetails.Modules.PurchaseDetails.itemPurchasedLabel',
    defaultMessage: 'Product/Service type',
    description:
      'Product/Service type Label for the Product/Service type Section of Loan Details in Application Portal',
  },
  descriptionLabel: {
    id:
      'Applicant.PostSubmission.LoanDetails.Modules.PurchaseDetails.descriptionLabel',
    defaultMessage: 'Description',
    description:
      'Description Label for the Description Section of Loan Details in Application Portal',
  },
});
