/* tslint:disable:ordered-imports */
import { PersonalDetails } from '@frontline/common';
import { selectors, WebCommonRootState } from '@frontline/web-common';
import React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ApplicantProfileMenuView } from './applicant-profile-menu-view.component';
import { RepairTechReportingFieldService } from '../../../repair-tech/services/repair-tech-reporting-fields.service';

const ApplicantProfileMenuComponent = ({ history }: RouteComponentProps) => {
  const { firstName, lastName } =
    useSelector((state: WebCommonRootState) => {
      return selectors.applicationSelectors.getApplicantPersonalDetails(
        state.application,
      );
    }) || ({} as PersonalDetails);

  const displayName = firstName
    ? firstName.substring(0, 1).toUpperCase() +
      lastName.substring(0, 1).toUpperCase()
    : '';
  const applicantName = firstName && `${firstName} ${lastName}`;

  const userInfo = { avatar: displayName, greeting: firstName || '' };

  const logout = () => {
    localStorage.clear();
    RepairTechReportingFieldService.clearReportingFields();
    history.push('/not-authenticated');
  };

  return (
    <ApplicantProfileMenuView
      userInfo={userInfo}
      name={applicantName}
      logout={logout}
    />
  );
};

export const ApplicantProfileMenu = withRouter(ApplicantProfileMenuComponent);
