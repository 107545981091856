import { updateWorkflowStepsStatus } from '@frontline/common';
import { errorToast, Loader, useUatId } from '@frontline/ui-library';
import {
  approvalConditionMessageDefinitions,
  LocaleContext,
  PostSubmissionCongratulationsDialog,
  shouldShowCongratulations,
} from '@frontline/web-common';
import React, { FunctionComponent, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { MainLayout } from '../../../../../../modules/layout/components';
import ApplicationPage from '../../../../common/ApplicationLayout/ApplicationLayout';
import * as applicantApplicationOptionsStore from '../../../../modules/applicant-application-options/store/applicant-application-options.store';
import * as applicationStore from '../../../../store/application.store';
import * as postSubmissionStore from '../../store/post-submission.store';
import { getEnabledWorkflowSteps } from '../PostSubmissionReview/types/workflow.functions';
import { OverviewView } from './components/overview-view/overview-view.component';
import { OverviewUat } from './overview.uat';
import * as overviewStore from './store/overview.store';
import { OverviewTab } from './types';
import { allStepsCompleted } from './types/overview.functions';
import PostSubmissionCongratulationsDialogMessages from './types/postSubmissionCongratulationsDialog.messages';

const Overview: FunctionComponent = () => {
  const { locale } = useContext(LocaleContext);
  const uatId = useUatId();
  const intl = useIntl();
  const dispatch = useDispatch();
  const overviewError = useSelector(overviewStore.getOverviewError);
  const application = useSelector(applicationStore.getApplication);
  const uploadDocsStep = useSelector(
    postSubmissionStore.getUploadDocumentsStep,
  );
  const conditionsLookupLocale = useSelector(
    overviewStore.getApprovalConditionLanguage,
  );
  const overViewLoading = useSelector(overviewStore.isLoading);
  const optionsLoading = useSelector(
    applicantApplicationOptionsStore.isLoading,
  );

  const isLoading = overViewLoading || optionsLoading;

  useEffect(() => {
    if (locale !== conditionsLookupLocale) {
      overviewStore.initConditionsLookup(dispatch)(locale);
    }
  }, [dispatch, locale, conditionsLookupLocale]);

  useEffect(() => {
    if (overviewError) {
      errorToast(
        intl.formatMessage(approvalConditionMessageDefinitions.dataFetchError),
      );
    }
  }, [overviewError, intl]);

  useEffect(() => {
    if (
      shouldShowCongratulations(
        application?.submissionPostSubmissionViewed,
        application?.program,
      )
    ) {
      overviewStore.openCongratulationsDialog(dispatch)();
    }
  }, [application, dispatch]);

  const applicantFirstName = useSelector(
    applicationStore.getApplicantFirstName,
  );

  const handleTabClick = (event: any, value: OverviewTab) => {
    if (value) {
      overviewStore.goToOverviewTab(dispatch)(value);
    }
  };

  const confirmPostSubmissionViewed = () =>
    overviewStore.setPostSubmissionViewed(dispatch)(application!.id);

  const stepsForStepper = useSelector(
    postSubmissionStore.getPostSubmissionStepsForStepper,
  );

  const applicantApplicationOption = useSelector(
    applicantApplicationOptionsStore.getApplicantApplicationOption,
  );

  const enabledSteps = getEnabledWorkflowSteps(
    stepsForStepper,
    applicantApplicationOption,
  );

  updateWorkflowStepsStatus(stepsForStepper, application);

  return (
    <ApplicationPage>
      <Loader show={isLoading} />

      <PostSubmissionCongratulationsDialog
        isOpen={useSelector(overviewStore.isCongratulationsDialogOpen)}
        onContinue={confirmPostSubmissionViewed}
        messages={PostSubmissionCongratulationsDialogMessages}
        uat={uatId(OverviewUat.continue)}
      />

      <OverviewView
        showIf={!isLoading}
        loanDetails={useSelector(applicationStore.getLoanDetails)}
        applicantFirstName={applicantFirstName}
        applicantLastName={useSelector(applicationStore.getApplicantLastName)}
        coApplicantFirstName={useSelector(
          applicationStore.getCoApplicantFirstName,
        )}
        merchantName={useSelector(overviewStore.getMerchantName)}
        stepsAreCompleted={allStepsCompleted(
          enabledSteps,
          uploadDocsStep,
          applicantApplicationOption,
        )}
        workflow={enabledSteps}
        uploadDocStep={uploadDocsStep}
        overviewTab={useSelector(overviewStore.getOverviewTab)}
        handleTabClick={handleTabClick}
        applicantApplicationOptions={applicantApplicationOption}
        applicationId={application?.id}
      />
    </ApplicationPage>
  );
};

export const OverviewFeature = MainLayout(Overview);
