import Pusher, { Channel } from 'pusher-js';
import { Observable } from 'rxjs';

const logMessages =
  `${process.env.REACT_APP_PUSHER_LOG_MESSAGES}`.toLowerCase() === 'true' &&
  process.env.NODE_ENV !== 'production';

if (logMessages) {
  Pusher.log = message => {
    if (window.console && window.console.log) {
      window.console.log(message);
    }
  };
}

const PrivateUserChannelPrefix = 'private-encrypted-user-';

const getChannelName = (userId: string): string =>
  `${PrivateUserChannelPrefix}${userId}`;

function getAuthEndPoint(): string {
  return `${process.env.REACT_APP_API_BASE_URL}/notification/subscribe`;
}

const createPusherInstance = (userId: string, authToken: string) => {
  const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY || '', {
    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    forceTLS: process.env.NODE_ENV === 'production',
    authEndpoint: getAuthEndPoint(),
    encrypted: true,
    auth: {
      headers: { Authorization: `Bearer ${authToken}` },
      params: null,
    },
  });

  pusher.connection.bind('error', (err: any) => {
    if (err.error.data.code === 4004) {
      console.error('>>> Pusher detected limit error');
    } else {
      console.error(err);
    }
  });

  return pusher;
};

export function getPrivateMessages(userId: string, authToken: string) {
  const channel = getPrivateMessagesChannel(userId, authToken);
  return new Observable<any>(observer => {
    const callback = (event: any, data: any) => observer.next({ event, data });
    channel.bind_global(callback);
    return () => channel.unbind_global(callback);
  });
}

export function getPrivateMessagesChannel(
  userId: string,
  authToken: string,
): Channel {
  const pusher = createPusherInstance(userId, authToken);
  return pusher.subscribe(getChannelName(userId));
}
