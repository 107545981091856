import { defineMessages } from 'react-intl';

export default defineMessages({
  purchaseDetailsTitle: {
    id: 'AssetInformationApplicant.PurchaseDetailsTitle',
    defaultMessage: 'Purchase Details',
  },
  itemPurchasedLabel: {
    id: 'FinalizeLoanDetails.itemPurchasedLabel',
    defaultMessage: 'Product/Service type',
  },
});
