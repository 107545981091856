import { TypographyColor, TypographyVariant } from '@frontline/ui-library';
import { Grid, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

interface Props {
  title: string;
}

const PreSubmissionStepSection: FunctionComponent<Props> = ({
  title,
  children,
}) => {
  return (
    <Grid item={true} xs={12}>
      <Typography
        variant={TypographyVariant.Header5}
        color={TypographyColor.TextPrimary}>
        {title}
      </Typography>
      {children}
    </Grid>
  );
};

export default PreSubmissionStepSection;
