import { InvoiceDetailsState } from '@frontline/web-common';
import { createSelector, Selector } from 'reselect';
import { RootState } from '../../../../../../../../../store';
import * as postSubmissionStore from '../../../../../store/post-submission.store';
import { PostSubmissionReviewState } from '../../../types/PostSubmissionReviewState';

export const getInvoiceDetailsState: Selector<
  RootState,
  InvoiceDetailsState | undefined
> = createSelector(
  postSubmissionStore.getPostSubmissionReviewState,
  (state?: PostSubmissionReviewState) => state?.invoiceDetailsState,
);
