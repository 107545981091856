import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { AdditionalDetailsForm } from '../../../../../../../common/AdditionalDetails';
import { AdditionalDetailsHeader } from '../../../../../../../common/AdditionalDetails/components';
import * as postSubmissionStore from '../../../../../store/post-submission.store';
import { ApplicantDetailsContext } from '../ApplicantDetailsContext';
import { ApplicantAdditionalDetailsMessages as messages } from './applicant-additional-details.messages';
import { ApplicantAdditionalDetailsProps } from './applicant-additional-details.props';

export const ApplicantAdditionalDetails = (
  props: ApplicantAdditionalDetailsProps,
) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { applicant } = useContext(ApplicantDetailsContext);

  return (
    <>
      <AdditionalDetailsHeader title={intl.formatMessage(messages.title)} />
      <AdditionalDetailsForm
        disabled={props.disabled}
        additionalDetails={applicant?.additionalDetails}
        onSubmit={postSubmissionStore.navigateToOverview(dispatch)}
        yearsWorkedCategoriesLookup={props.yearsWorkedCategoriesLookup}
        personalDetails={applicant?.personalDetails}
      />
    </>
  );
};
