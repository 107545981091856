import { UrlSearchParamsUtil } from '@frontline/web-common';
import { RepairTechReportingField } from '../types/repair-tech-reporting-field.enum';

function setReportingFields(url: string) {
  storeReportingField(url, RepairTechReportingField.REF_ID);
  storeReportingField(url, RepairTechReportingField.REFERRER_ID);
  storeReportingField(url, RepairTechReportingField.EVENT_ID);
  storeReportingField(url, RepairTechReportingField.CAMPAIGN_ID);
}

function clearReportingFields() {
  clearReportingField(RepairTechReportingField.REF_ID);
  clearReportingField(RepairTechReportingField.REFERRER_ID);
  clearReportingField(RepairTechReportingField.EVENT_ID);
  clearReportingField(RepairTechReportingField.CAMPAIGN_ID);
}

function getReportingFields(): Map<RepairTechReportingField, string> {
  const map = new Map();
  setReportingFieldMap(map, RepairTechReportingField.REF_ID);
  setReportingFieldMap(map, RepairTechReportingField.REFERRER_ID);
  setReportingFieldMap(map, RepairTechReportingField.EVENT_ID);
  setReportingFieldMap(map, RepairTechReportingField.CAMPAIGN_ID);

  return map;
}

function setReportingFieldMap(
  map: Map<RepairTechReportingField, string>,
  key: RepairTechReportingField,
): Map<RepairTechReportingField, string> {
  const result = getReportingField(key);
  if (result === undefined || result === null) {
    return map;
  }

  return map.set(key, result);
}

function storeReportingField(url: string, key: RepairTechReportingField) {
  const result = UrlSearchParamsUtil.getParamFromUrl(url, key);

  if (result === undefined || result === null || result.trim().length === 0) {
    return;
  }

  sessionStorage.setItem(`repair-tech-${key}`, result);
}

function clearReportingField(key: RepairTechReportingField) {
  sessionStorage.removeItem(`repair-tech-${key}`);
}

function getReportingField(key: RepairTechReportingField): string | null {
  return sessionStorage.getItem(`repair-tech-${key}`);
}

export const RepairTechReportingFieldService = {
  setReportingFields,
  clearReportingFields,
  getReportingFields,
};
