import {
  errorToast,
  ShowIfFragment,
  TypographyColor,
  TypographyVariant,
  useUatId,
} from '@frontline/ui-library';
import {
  AddressSearch,
  PersonalDetailsAcknowledgment,
  ResidentialLengthOfStayField,
} from '@frontline/web-common';
import { Grid, Typography } from '@material-ui/core';
import { Field } from 'formik';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import PreSubmissionSectionDivider from '../../../../components/SectionDivider/SectionDivider';
import {
  getPersonalDetailsError,
  resetPersonalDetailsError,
} from '../../../store/personal-details.store';
import { PersonalDetailsContactDetails } from '../../personal-details-contact-details/personal-details-contact-details';
import { PersonalDetailsSameAddressBox } from '../../personal-details-same-address-box/personal-details-same-address-box.component';
import { PersonalDetailsBasicProfileSection } from '../../PersonalDetailsBasicProfileSection/personal-details-basic-profile-section.component';
import { PersonalDetailsFormViewMessages as messages } from './personal-details-form-view.messages';
import { PersonalDetailsFormViewProps } from './personal-details-form-view.props';
import { PersonalDetailsFormViewUat } from './personal-details-form-view.uat';

export const PersonalDetailsFormView = (
  props: PersonalDetailsFormViewProps,
) => {
  const intl = useIntl();
  const uatId = useUatId();
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.values.sameAddressAsApplicant) {
      props.setFieldValue('address', { ...props.applicantAddress });
    }
  }, [props.values.sameAddressAsApplicant]);

  const allowSameAddressAs =
    props.coApplicant && Boolean(props.applicantAddress);

  const personalDetailsErrors = useSelector(getPersonalDetailsError);

  const resetError = () => {
    resetPersonalDetailsError(dispatch)();
  };

  useEffect(() => {
    if (personalDetailsErrors) {
      errorToast(intl.formatMessage(messages.saveError));
      resetError();
    }
  }, [personalDetailsErrors, resetError]);

  return (
    <>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <Typography
            variant={TypographyVariant.Header5}
            color={TypographyColor.TextPrimary}>
            {intl.formatMessage(messages.personalDetailsTitle)}
          </Typography>
          <PersonalDetailsBasicProfileSection
            isCoApplicant={props.coApplicant}
            disabled={props.disabled}
            relationshipTypes={props.relationshipTypes}
          />
        </Grid>
        <PreSubmissionSectionDivider />
        <Grid item={true} xs={12}>
          <Typography
            variant={TypographyVariant.Header5}
            color={TypographyColor.TextPrimary}>
            {intl.formatMessage(messages.addressDetailsTitle)}
          </Typography>
          <ShowIfFragment showIf={allowSameAddressAs}>
            <Field
              name="sameAddressAsApplicant"
              component={PersonalDetailsSameAddressBox}
              disabled={props.disabled}
              uat={uatId(PersonalDetailsFormViewUat.personalDetailsSameAddress)}
            />
          </ShowIfFragment>
          <AddressSearch
            {...props}
            disabled={props.values.sameAddressAsApplicant || props.disabled}
          />
        </Grid>
        <Grid container={true} item={true} xs={12} md={6}>
          <ResidentialLengthOfStayField
            showIf={!props.hideResidentialLengthOfStay}
            name="residentialLengthId"
            label={intl.formatMessage(messages.residentialLengthLabel)}
            placeholder={intl.formatMessage(
              messages.residentialLengthPlaceholder,
            )}
            disabled={props.disabled}
            uatSelect={uatId(
              PersonalDetailsFormViewUat.personalDetailsLengthOfStay,
            )}
            uatMenuItem={uatId(
              PersonalDetailsFormViewUat.personalDetailsLengthOfStayMenuItem,
            )}
          />
        </Grid>

        <PreSubmissionSectionDivider />
        <Grid item={true} xs={12}>
          <Typography
            variant={TypographyVariant.Header5}
            color={TypographyColor.TextPrimary}>
            {intl.formatMessage(messages.contactDetailsTitle)}
          </Typography>
          <PersonalDetailsContactDetails
            disabled={props.disabled}
            uatPhone={uatId(
              PersonalDetailsFormViewUat.personalDetailsPrimaryPhone,
            )}
            uatEmail={uatId(PersonalDetailsFormViewUat.personalDetailsEmail)}
          />
        </Grid>
      </Grid>
      <PersonalDetailsAcknowledgment
        showIf={Boolean(props.showElectronicCommunicationsConsent)}
        header={messages.personalDetailsAcknowledgmentHeader}
        subHeader={messages.personalDetailsAcknowledgmentSubHeader}
        acknowledgment={messages.personalDetailsAcknowledgment}
        linkText={messages.personalDetailsAcknowledgmentLinkText}
      />
    </>
  );
};
