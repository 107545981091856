import { Selector } from 'react-redux';
import { Dispatch } from 'redux';
import { createSelector } from 'reselect';
import { RootState, Store } from '../../../store';
import { ApplicantSession } from '../types/ApplicantSession/ApplicantSession';
import { AuthenticationQuery } from '../types/AuthenticationQuery/AuthenticationQuery';
import { AuthState } from '../types/AuthState/AuthState';
import * as actions from './auth.actions';

export const getAuthState = (rootState: RootState) => rootState.authState;

export const getSession: Selector<RootState, ApplicantSession> = createSelector(
  getAuthState,
  (state: AuthState) => state.session,
);

export const getApplicationId: Selector<
  RootState,
  string | null
> = createSelector(
  getSession,
  (session: ApplicantSession) => session.applicationId,
);

export const getUserId: Selector<RootState, string | null> = createSelector(
  getSession,
  (session: ApplicantSession) => session.userId,
);

export const getAccessToken: Selector<
  RootState,
  string | null
> = createSelector(
  getSession,
  (session: ApplicantSession) => session.accessToken,
);

export const getAccessKey: Selector<RootState, string | null> = createSelector(
  getSession,
  (session: ApplicantSession) => session.accessKey,
);

export const isAuthenticated: Selector<RootState, boolean> = createSelector(
  getSession,
  (session: ApplicantSession) => session.isAuthenticated,
);

export const isInitialized: Selector<RootState, boolean> = createSelector(
  getAuthState,
  (state: AuthState) => state.isInitialized,
);

export const getError: Selector<RootState, Error | undefined> = createSelector(
  getAuthState,
  (state: AuthState) => state.error,
);

export const isLoading: Selector<RootState, boolean> = createSelector(
  getAuthState,
  (state: AuthState) => state.isLoading,
);

export const init = (dispatch: Dispatch<any>) => (
  query: AuthenticationQuery,
  redirectUrl: string,
) => {
  dispatch(actions.init(query, redirectUrl));
};

export const invalidate = () => {
  Store.dispatch(actions.invalidate());
};
