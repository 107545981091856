import { TypographyColor, TypographyVariant } from '@frontline/ui-library';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './StepPosition.messages';

export interface StepPositionProps {
  stepPosition?: string | number;
}

export const StepPosition: FunctionComponent<StepPositionProps> = (
  props: StepPositionProps,
) => {
  if (!props.stepPosition) {
    return null;
  }
  return (
    <Grid item={true} xs="auto">
      <Typography
        variant={TypographyVariant.Subtitle2}
        color={TypographyColor.TextPrimary}
        paragraph={true}>
        <FormattedMessage
          {...messages.step}
          values={{ stepPosition: props.stepPosition }}
        />
      </Typography>
    </Grid>
  );
};
