import { library } from '@fortawesome/fontawesome-svg-core';
import { faList as falList } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Colours,
  TypographyColor,
  TypographyVariant,
  withShowIf,
} from '@frontline/ui-library';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';
import { defineMessages, useIntl } from 'react-intl';

library.add(falList);

const message = defineMessages({
  noAddOnMessage: {
    id: 'Applicant.PostSubmission.AddOns.EmptyAddOnsList',
    description: 'message saying that there are no add on items to list',
    defaultMessage: 'There are no add ons associated with this loan',
  },
});

const EmptyList: FunctionComponent = () => {
  const intl = useIntl();
  return (
    <Box margin={4} color={Colours.OffWhite}>
      <Typography
        component="div"
        align="center"
        color={TypographyColor.Inherit}>
        <FontAwesomeIcon icon={['fal', 'list']} size="7x" />
      </Typography>
      <Typography
        variant={TypographyVariant.Header4}
        align="center"
        color={TypographyColor.Inherit}>
        {intl.formatMessage(message.noAddOnMessage)}
      </Typography>
    </Box>
  );
};

export const EmptyAddOnsList = withShowIf(EmptyList);
