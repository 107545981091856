import { WorkflowStep } from '@frontline/common';
import { ApplicationProgress, LocaleContext } from '@frontline/web-common';
import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as yearsWorkedCategoryStore from '../../../../../../../../../modules/lookup/years-worked-category/years-worked-category.store';
import * as applicationStore from '../../../../../../../store/application.store';
import { CannotEditPageMessage } from '../../../../../common/CannotEditPageMessage';
import { getStepMessageDefinition } from '../../../../../types';
import { PostSubmissionContentCard } from '../../../components/post-submission-content-card';
import { ApplicantDetailsContext } from '../ApplicantDetailsContext';
import { ApplicantDetailsRoutes } from '../ApplicantDetailsRoutes';
import { ApplicantDetailsFeatureProps } from './ApplicantDetailsFeatureProps';

export const ApplicantDetailsFeature = (
  props: ApplicantDetailsFeatureProps,
) => {
  const localeContext = useContext(LocaleContext);
  const intl = useIntl();
  // TODO: refactor ApplicationProgress to handle internationalization
  const applicantDetailsSteps = props.steps.map((step: WorkflowStep) => {
    step.name = intl.formatMessage(getStepMessageDefinition(step.type));
    return step;
  });
  const applicant = useSelector(applicationStore.getApplicant);

  const yearsWorkedCategories = useSelector(
    yearsWorkedCategoryStore.getYearsWorkedCategoriesDropdown,
  );

  useEffect(() => {
    yearsWorkedCategoryStore.fetchYearsWorkedCategories();
  }, [localeContext.locale]);

  return (
    <ApplicantDetailsContext.Provider value={{ applicant }}>
      <ApplicationProgress steps={applicantDetailsSteps} />
      <PostSubmissionContentCard>
        <CannotEditPageMessage />
        <ApplicantDetailsRoutes
          applicant={applicant}
          applicationId={props.applicationId}
          disabled={true}
          yearsWorkedCategoriesLookup={yearsWorkedCategories || []}
        />
      </PostSubmissionContentCard>
    </ApplicantDetailsContext.Provider>
  );
};
