import { Application, ApplicationStatusType } from '@frontline/common';
import { ApplicationPathType } from './ApplicationPathType';

export const applicationPath = (
  type: ApplicationPathType,
  applicationId: string,
): string => {
  switch (type) {
    case 'preSubmission':
      return `/applications/${applicationId}/pre-submission`;
    case 'postSubmission':
      return `/applications/${applicationId}/post-submission`;
    default:
      return '/';
  }
};

const isPreSubmissionComplete = (application: Application): boolean => {
  return (
    application.submissionResultPageViewed === true &&
    application.status.id !== ApplicationStatusType.Declined
  );
};

export const defaultApplicationPath = (application: Application): string => {
  if (!application.id) {
    return '/';
  }

  const goToPreSubmission = !isPreSubmissionComplete(application);
  const pathType = goToPreSubmission ? 'preSubmission' : 'postSubmission';
  return applicationPath(pathType, application.id);
};
