import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router';
import { ResultRoutesProps } from '../ResultRoutesProps';
import { ResultSummaryView } from './components/result-summary-view/result-summary-view';
import { ApplicationApprovedFeature } from './features/application-approved/application-approved-feature';
import { ContactMerchantFeature } from './features/contact-merchant/contact-merchant.feature';
import {
  isApplicationApproved,
  isApplicationDeclinedOrInReview,
} from './types/application-approval.functions';
import {
  getApprovedPageRoute,
  getDeclinedPageRoute,
  getResultPageRoute,
  isApprovedPage,
  isDeclinedPage,
} from './types/result-summary-routes.functions';

export const ResultRoutes = (props: ResultRoutesProps) => {
  const history = useHistory();
  const application = props.application;

  useEffect(() => {
    const { pathname } = history.location;

    if (!application) {
      history.push('/');
      return;
    }

    if (isApplicationApproved(application)) {
      if (!isApprovedPage(pathname)) {
        history.push(getApprovedPageRoute(application.id));
        return;
      }
    } else if (
      isApplicationDeclinedOrInReview(application) &&
      !isDeclinedPage(pathname)
    ) {
      history.push(getDeclinedPageRoute(application.id));
    }
  }, [history, application]);

  return (
    <React.Fragment>
      <Route
        path={getResultPageRoute()}
        exact={true}
        render={() => <ResultSummaryView />}
      />
      <Route
        path={getApprovedPageRoute()}
        render={() => (
          <ApplicationApprovedFeature
            continueClicked={props.completeResult}
            applicationId={props.application?.id || null}
          />
        )}
      />
      <Route
        path={getDeclinedPageRoute()}
        render={() => <ContactMerchantFeature />}
      />
    </React.Fragment>
  );
};
