import { AssetType, LoanDetails, Program } from '@frontline/common';
import { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import { RootState } from '../../../../../../../store';
import * as rootApplicationSelectors from '../../../../../../../store/application/selectors';
import { getLoanDetails } from '../../../../../store/application.store';

export const getProgramId = (state: RootState) =>
  rootApplicationSelectors.getProgramId(state.application);

export const getProgram = (state: RootState) =>
  rootApplicationSelectors.getApplication(state.application)?.program;

export const getLoanAmount: Selector<
  RootState,
  number | undefined
> = createSelector(
  getLoanDetails,
  (loanDetails?: LoanDetails) => loanDetails?.loanAmount || undefined,
);

export const getAssetTypeId: Selector<
  RootState,
  number | undefined
> = createSelector(
  getLoanDetails,
  (loanDetails?: LoanDetails) => loanDetails?.assetType || undefined,
);

export const getAssetTypeName: Selector<RootState, string> = createSelector(
  getLoanDetails,
  (loanDetails?: LoanDetails) => loanDetails?.assetTypeName || '',
);

export const getAssetSubTypeName: Selector<RootState, string> = createSelector(
  getLoanDetails,
  (loanDetails?: LoanDetails) => loanDetails?.assetSubTypeName || '',
);

export const getAssetTypes: Selector<RootState, AssetType[]> = createSelector(
  getProgram,
  (program?: Program) => program?.assetTypes || [],
);

export const getProgramName: Selector<RootState, string> = createSelector(
  getProgram,
  (program?: Program) => program?.name || '',
);
