import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'ReviewApplicationFeature.title',
    description: 'ReviewApplication Title',
    defaultMessage: 'Review Application Summary',
  },
  description: {
    id: 'ReviewApplicationFeature.description',
    description: 'ReviewApplication Description',
    defaultMessage:
      'Thanks, that’s it! Please review your application and ensure all information is correct. Make any necessary edits below. Once you are good to go, submit your application.',
  },
  submitApplicationButton: {
    id: 'ReviewApplicationFeature.submitApplicationButton',
    description: 'Submit Application Button Label',
    defaultMessage: 'Submit Application',
  },
});
