import { FormatMoney, ShowIfFragment } from '@frontline/ui-library';
import { adjustLoanAmountByInvoiceEstimate } from '@frontline/web-common';
import React, { useEffect, useMemo } from 'react';
import { LoanDetailsRequestedAmountProps } from './loan-details-requested-amount.props';

export const LoanDetailsRequestedAmount = (
  props: LoanDetailsRequestedAmountProps,
) => {
  const requestedAmount = useMemo(
    () =>
      adjustLoanAmountByInvoiceEstimate(
        props.loanAmount,
        props.approvedAmount,
        props.invoiceEstimateAmount,
      ),
    [props.loanAmount, props.approvedAmount, props.invoiceEstimateAmount],
  );

  const hideReceiptValue =
    props.rules?.enableReceipts === true && props.receipts.length === 0;

  useEffect(() => {
    if (requestedAmount !== props.loanAmount) {
      props.setFieldValue('loanAmount', requestedAmount);
    }
  }, [requestedAmount]);

  return (
    <>
      <ShowIfFragment showIf={!hideReceiptValue}>
        <FormatMoney value={requestedAmount} roundToDollar={false} />
      </ShowIfFragment>
      <ShowIfFragment showIf={hideReceiptValue}>
        {props.noReceiptMessage}
      </ShowIfFragment>
    </>
  );
};
