import { FastFill } from '@frontline/web-common';
import React from 'react';
import styled from 'styled-components';
import { PersonalDetailsFastFillProps } from './PersonalDetailsFastFillProps';

export const PersonalDetailsFastFill = (
  props: PersonalDetailsFastFillProps,
) => (
  <FastFillStylesDecorator>
    <FastFill
      showIf={props.enableFastFill}
      attempts={props.fastFillAttempts}
      getDetails={props.saveFastFillDetails}
      coapplicantIndex={props.coapplicantIndex}
    />
  </FastFillStylesDecorator>
);

const FastFillStylesDecorator = styled.div`
  margin-bottom: 5px;
`;
