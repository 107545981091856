import { LoanDetailsDto } from '@frontline/common';
import { HttpError } from '@frontline/web-common';
import { ActionType, createAction } from 'typesafe-actions';

export enum LoanDetailsActions {
  FetchLoanDetails = 'LoanDetails/FetchLoanDetails',
  FetchLoanDetailsSuccess = 'LoanDetails/FetchLoanDetailsSuccess',
  FetchLoanDetailsFailure = 'LoanDetails/FetchLoanDetailsFailure',
  SetIsLoading = 'LoanDetails/SetIsLoading',
}

export const fetchLoanDetails = createAction(
  LoanDetailsActions.FetchLoanDetails,
);

export const fetchLoanDetailsSuccess = createAction(
  LoanDetailsActions.FetchLoanDetailsSuccess,
  action => (loanDetails: LoanDetailsDto) => action(loanDetails),
);

export const fetchLoanDetailsFailure = createAction(
  LoanDetailsActions.FetchLoanDetailsFailure,
  action => (error: HttpError) => action(error),
);

export const setIsLoading = createAction(LoanDetailsActions.SetIsLoading);

const actionCreators = {
  fetchLoanDetails,
  fetchLoanDetailsSuccess,
  fetchLoanDetailsFailure,
  setIsLoading,
};

export type LoanDetailsActionType = ActionType<typeof actionCreators>;
