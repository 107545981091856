export const PersonalDetailsBasicProfileSectionUat = {
  personalDetailsFirstName:
    'newApplication.personalDetails.form.firstName.field',
  personalDetailsLastName: 'newApplication.personalDetails.form.lastName.field',
  personalDetailsDobMonth: 'newApplication.personalDetails.form.dobMonth.field',
  personalDetailsDobDay: 'newApplication.personalDetails.form.dobDay.field',
  personalDetailsDobYear: 'newApplication.personalDetails.form.dobYear.field',
  personalDetailsDobMonthMenuItem:
    'newApplication.personalDetails.form.dobMonth.menuItem',
  personalDetailsDobDayMenuItem:
    'newApplication.personalDetails.form.dobDay.menuItem',
  personalDetailsDobYearMenuItem:
    'newApplication.personalDetails.form.dobYear.menuItem',
  personalDetailsRelationshipType:
    'newApplication.personalDetails.form.relationshipType.field',
  personalDetailsRelationshipTypeMenuItem:
    'newApplication.personalDetails.form.relationshipType.menuItem',
};
