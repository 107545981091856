import {
  ApplicationStepsFooter,
  ApplicationStepsHeader,
  ApplicationValidationMessage,
  MoreInfoTooltip,
} from '@frontline/web-common';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import { AssetDetailList } from '../AssetDetailList/AssetDetailList';
import { AssetInformationPurchaseDetails } from '../AssetInformationPurchaseDetails/AssetInformationPurchaseDetails';
import { AssetInformationViewValidationMessges as validationMessages } from './asset-information-view-validation.messages';
import { messages } from './asset-information-view.messages';
import { AssetInformationViewProps } from './asset-information-view.props';
import { useAssetInformationViewStyles } from './asset-information-view.styles';

export const AssetInformationView = (props: AssetInformationViewProps) => {
  const intl = useIntl();
  const classes = useAssetInformationViewStyles();

  return (
    <React.Fragment>
      <ApplicationStepsHeader
        title={intl.formatMessage(messages.title)}
        description={intl.formatMessage(messages.description)}>
        <div className={classes.container}>
          <MoreInfoTooltip
            description={[
              intl.formatMessage(messages.toolTipDescription1),
              intl.formatMessage(messages.toolTipDescription2),
            ]}
          />
        </div>
      </ApplicationStepsHeader>
      <Grid
        container={true}
        direction="row"
        justify="space-between"
        alignItems="center">
        <ApplicationValidationMessage
          validations={props.validations}
          messages={validationMessages}
        />
        <AssetInformationPurchaseDetails
          assetSubTypeName={props.assetSubTypeName}
        />

        <AssetDetailList assets={props.assets} />
      </Grid>

      <ApplicationStepsFooter
        onSubmit={props.onNextStep}
        onBack={props.onPreviousStep}
      />
    </React.Fragment>
  );
};
