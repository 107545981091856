import {
  AdditionalDetailsState,
  createDefaultAdditionalDetailsState,
} from '../types';
import {
  AdditionalDetailsActions,
  AdditionalDetailsActionType,
} from './additionalDetails.actions';

export const additionalDetailsReducer = (
  state: AdditionalDetailsState = createDefaultAdditionalDetailsState(),
  action: AdditionalDetailsActionType,
): AdditionalDetailsState => {
  if (action.type === AdditionalDetailsActions.updateSelectedEmploymentStatus) {
    return {
      ...state,
      selectedEmploymentStatus: action.payload,
    };
  }
  return state;
};
