import { defineMessages } from 'react-intl';

export const AssetDetailListMessages = defineMessages({
  assetDetailsTitle: {
    id: 'AssetInformationApplicant.AssetDetailsTitle',
  },
  costLabel: {
    id: 'AssetInformationApplicant.costLabel',
  },
  costLabelNoTax: {
    id: 'AssetInformationApplicant.costLabelNoTax',
  },
  makeLabel: {
    id: 'AssetInformationApplicant.makeLabel',
  },
  modelLabel: {
    id: 'AssetInformationApplicant.modelLabel',
  },
  serialLabel: {
    id: 'AssetInformationApplicant.serialLabel',
  },
  conditionLabel: {
    id: 'AssetInformationApplicant.conditionLabel',
  },
  hoursOnMotorLabel: {
    id: 'AssetInformationApplicant.hoursOnMotorLabel',
  },
  yearLabel: {
    id: 'AssetInformationApplicant.yearLabel',
  },
});
