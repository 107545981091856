import { Colours } from '@frontline/ui-library';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useApplicationTakeoverNewLinkFooterStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 124,
        [theme.breakpoints.down('xs')]: {
          marginTop: 0,
          background: Colours.White,
        },
      },
      description: {
        fontSize: 20,
        marginBottom: 146,
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
          fontSize: 16,
          marginBottom: 0,
        },
      },
      title: {
        fontSize: 24,
        fontWeight: 600,
        marginBottom: 41,
        [theme.breakpoints.down('xs')]: {
          fontSize: 16,
          fontWeight: 700,
          marginBottom: 11,
        },
      },
    }),
);
