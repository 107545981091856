import { defineMessages } from 'react-intl';

export const AddOnTableHeaderMessages = defineMessages({
  type: {
    id: 'Applicant.PostSubmission.AddOnTableHeader.type',
  },
  provider: {
    id: 'Applicant.PostSubmission.AddOnTableHeader.provider',
  },
  cost: {
    id: 'Applicant.PostSubmission.AddOnTableHeader.cost',
  },
  taxRate: {
    id: 'Applicant.PostSubmission.AddOnTableHeader.taxRate',
  },
  preTaxcost: {
    id: 'Applicant.PostSubmission.AddOnTableHeader.preTaxcost',
  },
  postTaxcost: {
    id: 'Applicant.PostSubmission.AddOnTableHeader.postTaxcost',
  },
});
