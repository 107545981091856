import styled from 'styled-components';

export const Card = styled.div<any>`
  border: ${props => {
    if (props.showError) {
      return 'solid 1px #b82121';
    }
    return props.checked ? 'solid 1px #017eff' : 'solid 1px #979797';
  }};
  display: grid;
  height: 160px;
  transition: border 0.15s ease-in-out;
  max-width: 190px;
  margin: auto;
`;

export const Frequency = styled.p<any>`
  color: #203d54;
  font-size: 16px;
  margin: 0;
  justify-self: center;
  align-self: center;
`;

export const SVGContainer = styled.div<any>`
  align-items: center;
  display: grid;
  height: 60px;
  justify-content: center;
`;

export const SVGImage = styled.img<any>`
  width: 60px;
`;

export const RadioContainer = styled.span<any>`
  margin: auto;
`;
