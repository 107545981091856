import HealthCareActiveIcon from '../../../../assets/healthcare-active.svg';
import HealthCareIcon from '../../../../assets/healthcare-default.svg';
import HomeImprovementActiveIcon from '../../../../assets/home-improvement-active.svg';
import HomeImprovementIcon from '../../../../assets/home-improvement-default.svg';
import PowerSportsActiveIcon from '../../../../assets/powersports-active.svg';
import PowerSportsIcon from '../../../../assets/powersports-default.svg';
import RetailActiveIcon from '../../../../assets/retail-active.svg';
import RetailIcon from '../../../../assets/retail-default.svg';

const getActiveImageSrc = (value: number) => {
  switch (value) {
    case 116:
      return PowerSportsActiveIcon;
    case 118:
      return HealthCareActiveIcon;
    case 119:
      return HomeImprovementActiveIcon;
    default:
      return RetailActiveIcon;
  }
};

const getDefaultImageSrc = (value: number) => {
  switch (value) {
    case 116:
      return PowerSportsIcon;
    case 118:
      return HealthCareIcon;
    case 119:
      return HomeImprovementIcon;
    default:
      return RetailIcon;
  }
};

export const getImageSrc = (value: number, active: boolean) => {
  return active ? getActiveImageSrc(value) : getDefaultImageSrc(value);
};
