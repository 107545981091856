import { PersonalDetailsState } from './personal-details.state';

export function createPersonalDetailsState(): PersonalDetailsState {
  return {
    isLoading: false,
    isSalesRepIdDialogOpen: false,
    isSalesRepIdComplete: false,
    submitSalesRepIdError: null,
  };
}
