import {
  Fee,
  isStatusLeadsOrInReviewOfFundingOrBooked,
} from '@frontline/common';
import { createChargeType } from '@frontline/common/dist/models/common/Edge';
import {
  createCalculatorValuesForApplicationExcludingLoanProtectionInsuranceFees,
  HomeAndAutoAncillaryProductPage,
  HomeAndAutoAncillaryProductView,
  prepareFeesForEstimatedPaymentCalculation,
} from '@frontline/web-common';
import { HomeAndAutoAncillaryProductRequest } from '@frontline/web-common/dist/types/feature-modules/home-and-auto-ancillary-product/types/home-and-auto-ancillary-product-request.interface';
import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as applicationStore from '../../../../../../store/application.store';
import * as postSubmissionReviewStore from '../../../../store/post-submission.store';
import { PostSubmissionContentCard } from '../../components/post-submission-content-card';
import {
  getEnglishFaqUrl,
  getFrenchFaqUrl,
  isHomeAndAutoSelectionValid,
} from './home-and-auto-ancillary-product.functions';
import { homeAndAutoAncillaryProductStore } from './store/home-and-auto-ancillary-product.store';

export const HomeAndAutoAncillaryProductFeature = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const application = useSelector(applicationStore.getApplication);
  const applicationId = useSelector(applicationStore.getApplicationId);
  const currentPage = useSelector(
    homeAndAutoAncillaryProductStore.getPageSelection,
  );
  const isLoading = useSelector(homeAndAutoAncillaryProductStore.getIsLoading);
  const isCalculating = useSelector(
    homeAndAutoAncillaryProductStore.getIsCalculating,
  );
  const currentRequest = useSelector(
    homeAndAutoAncillaryProductStore.getCurrentRequest,
  );
  const error = useSelector(homeAndAutoAncillaryProductStore.getError);
  const totalCostOfPlan = useSelector(
    homeAndAutoAncillaryProductStore.getTotalCostOfPlan,
  );
  const loanDetails = useSelector(applicationStore.getLoanDetails);
  const estimatedPayment = useSelector(
    postSubmissionReviewStore.getEstimatedPayment,
  );
  const submitSuccess = useSelector(
    homeAndAutoAncillaryProductStore.getSuccess,
  );

  const chargeType = createChargeType();

  const faqUrl = useMemo(() => {
    return intl.locale.toLowerCase() === 'en'
      ? getEnglishFaqUrl()
      : getFrenchFaqUrl();
  }, [intl.locale]);

  useEffect(() => {
    if (application?.loanDetails.ancillaryProductEnrollment !== undefined) {
      const ancillaryProductEnrollment =
        application.loanDetails.ancillaryProductEnrollment;
      handleCalculationChange({
        optIn: ancillaryProductEnrollment.homeAndAutoEnrollment,
        type: ancillaryProductEnrollment.homeAndAutoType,
        years: ancillaryProductEnrollment.homeAndAutoYears,
      });

      if (ancillaryProductEnrollment.homeAndAutoEnrollment === true) {
        handlePageChange(
          HomeAndAutoAncillaryProductPage.HomeAndAutoAncillaryProductMenu,
        );
      } else if (ancillaryProductEnrollment.homeAndAutoEnrollment === false) {
        handlePageChange(HomeAndAutoAncillaryProductPage.NotInterested);
      }
    }
  }, [application?.loanDetails.ancillaryProductEnrollment]);

  useEffect(() => {
    if (totalCostOfPlan && loanDetails) {
      handleFetchEstimatedPayment(totalCostOfPlan);
    }
  }, [totalCostOfPlan]);

  useEffect(() => {
    if (submitSuccess === true) {
      homeAndAutoAncillaryProductStore.reset();
      postSubmissionReviewStore.navigateToNextStep(dispatch)();
    }
  }, [submitSuccess]);

  const handleFetchEstimatedPayment = (costOfPlan: number) => {
    if (loanDetails) {
      const homeAndAutoFee: Fee = {
        id: chargeType.homeAndAuto,
        fee: costOfPlan,
      };
      const fees = Array.isArray(loanDetails.fees)
        ? prepareFeesForEstimatedPaymentCalculation(
            homeAndAutoFee,
            loanDetails.fees,
          )
        : [homeAndAutoFee];
      postSubmissionReviewStore.fetchEstimatedPayment(dispatch)(
        createCalculatorValuesForApplicationExcludingLoanProtectionInsuranceFees(
          { ...loanDetails, fees },
          application,
          false,
        ),
      );
    }
  };

  const handleCalculationChange = (
    request: Partial<HomeAndAutoAncillaryProductRequest>,
  ) => {
    homeAndAutoAncillaryProductStore.updateCalculationRequest(
      request,
      applicationId,
    );
  };

  const handlePageChange = (page: HomeAndAutoAncillaryProductPage) => {
    if (page === HomeAndAutoAncillaryProductPage.NotInterested && loanDetails) {
      handleCalculationChange({
        optIn: false,
        years: undefined,
        type: undefined,
      });
      handleFetchEstimatedPayment(0);
    }
    homeAndAutoAncillaryProductStore.changePage(page);
  };

  const handleOptIn = () => {
    if (
      currentRequest &&
      application?.status &&
      !isStatusLeadsOrInReviewOfFundingOrBooked(application.status.id)
    ) {
      homeAndAutoAncillaryProductStore.saveCalculationRequest(
        true,
        applicationId,
        currentRequest,
      );
    } else {
      postSubmissionReviewStore.navigateToNextStep(dispatch)();
    }
  };

  const handleOptOut = () => {
    if (
      application?.status &&
      !isStatusLeadsOrInReviewOfFundingOrBooked(application.status.id)
    ) {
      homeAndAutoAncillaryProductStore.saveCalculationRequest(
        false,
        applicationId,
        {},
      );
    } else {
      postSubmissionReviewStore.navigateToNextStep(dispatch)();
    }
  };

  const handleResetError = () => {
    homeAndAutoAncillaryProductStore.resetError();
  };

  const shouldDisableContinueButton = useMemo(
    () =>
      !isHomeAndAutoSelectionValid(
        currentRequest,
        currentPage,
        totalCostOfPlan,
      ),
    [totalCostOfPlan, currentRequest, currentPage],
  );

  return (
    <PostSubmissionContentCard>
      <HomeAndAutoAncillaryProductView
        application={application}
        page={currentPage}
        currentRequest={currentRequest}
        isLoading={isLoading}
        error={error}
        handleChange={handleCalculationChange}
        onPageClick={handlePageChange}
        navigateBack={postSubmissionReviewStore.navigateToPreviousStep(
          dispatch,
        )}
        totalPrice={totalCostOfPlan}
        paymentPrice={estimatedPayment?.payment || null}
        isCalculating={isCalculating}
        disableContinueButton={shouldDisableContinueButton}
        handleOptIn={handleOptIn}
        handleOptOut={handleOptOut}
        handleResetError={handleResetError}
        faqUrl={faqUrl}
      />
    </PostSubmissionContentCard>
  );
};
