import { defineMessages } from 'react-intl';

export default defineMessages({
  monthlyIncomeLabel: {
    id: 'PersonalDetailsInfo.monthlyIncomeLabel',
    description: 'Monthly income label',
  },
  netMonthlyIncomeLabel: {
    id: 'PersonalDetailsInfo.netMonthlyIncomeLabel',
    description: 'Net monthly income label',
  },
  sinLabel: {
    id: 'PersonalDetailsInfo.sinLabel',
    description: 'SIN label',
  },
});
