import { Grid } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { OverviewStepperProps } from '../../types';
import { Checklist } from '../check-list/check-list.component';
import { OverviewMenu } from './overview-menu/overview-menu.component';

const OverviewStepperDesktop: FunctionComponent<OverviewStepperProps> = ({
  steps,
  defaultStepType,
  onClickAddCoApplicant,
  onClickRemoveCoApplicant,
  showRemoveCoApplicant,
  showAddCoApplicant,
  getStepNameByStepType,
  ...rest
}) => {
  const [selectedTab, setSelectedTab] = useState('');

  useEffect(() => {
    if (defaultStepType) {
      const activeStep = steps.find(s => s.type === defaultStepType);
      if (activeStep) {
        setSelectedTab(activeStep.id);
      }
    }
  }, [defaultStepType, steps]);

  return (
    <Grid item={true} {...rest}>
      <Grid container={true}>
        <Grid item={true} xs={4}>
          <Checklist
            steps={steps}
            getStepNameByStepType={getStepNameByStepType}
            onTabSelect={setSelectedTab}
            activeTabId={selectedTab}
          />
        </Grid>
        <Grid item={true} xs={8}>
          <OverviewMenu
            step={steps.find(s => s.id === selectedTab)}
            onClickAddCoApplicant={onClickAddCoApplicant}
            onClickRemoveCoApplicant={onClickRemoveCoApplicant}
            showRemoveCoApplicant={showRemoveCoApplicant}
            showAddCoApplicant={showAddCoApplicant}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default OverviewStepperDesktop;
