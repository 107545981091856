import { defineMessages } from 'react-intl';

export const ApplicationTakeoverDeclinedMessages = defineMessages({
  title: {
    id: 'Applicant.ApplicationTakeover.Declined.title',
    description: 'Application Takeover declined page title',
    defaultMessage:
      'You have {status} access to your LendCare pre-qualification.',
  },
  description: {
    id: 'Applicant.ApplicationTakeover.Declined.description',
    description: 'Application Takeover declined page meaning description',
    defaultMessage:
      'The requesting business will not get access to your pre-qualification. If you change your mind, then please reach out to them to send a new request.',
  },
  meaning: {
    id: 'Applicant.ApplicationTakeover.Declined.meaning',
    description: 'Application Takeover declined page meaning title',
    defaultMessage: 'What does this mean?',
  },
  status: {
    id: 'Applicant.ApplicationTakeover.Declined.status',
    description: 'Application Takeover declined page status',
    defaultMessage: 'declined',
  },
});
