import {
  createIncomeDetailsForm,
  IncomeDetailsForm as WebCommonIncomeDetailsForm,
} from '@frontline/web-common';
import * as React from 'react';
import { createIncomeDetailsDefaultValues } from '../../types/income-details-form.functions';
import { IncomeDetailsFormProps } from './income-details-form.props';

export const IncomeDetailsForm = (props: IncomeDetailsFormProps) => {
  return (
    <WebCommonIncomeDetailsForm
      initialValues={createIncomeDetailsForm(
        Boolean(props.authorizedElectronicCommunication),
        props?.incomeDetails || createIncomeDetailsDefaultValues(),
      )}
      disabled={props.disabled}
      onSubmit={props.onSubmit}
      onBackButtonClick={props.onBackButtonClick}
    />
  );
};
