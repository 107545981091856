import { ShowIfFragment } from '@frontline/ui-library';
import { ApplicationValidationMessage } from '@frontline/web-common';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import { AssetDetailList } from '../asset-detail-list/asset-detail-list.component';
import { AssetInformationPurchaseDetails } from '../assets-purchase-details/assets-purchase-details.component';
import { TotalLine } from '../total-line/total-line.component';
import { TradeInView } from '../trade-in-view/trade-in-view.component';
import { AssetsViewMessages as messages } from './assets-view.messages';
import { AssetsViewProps } from './assets-view.props';
import { useAssetsViewStyles } from './assets-view.styles';

export const AssetsView = (props: AssetsViewProps) => {
  const classes = useAssetsViewStyles();
  const intl = useIntl();
  return (
    <Grid
      container={true}
      direction="row"
      justify="space-between"
      alignItems="center">
      <ApplicationValidationMessage
        validations={props.validations}
        messages={messages}
      />
      <AssetInformationPurchaseDetails
        assetSubTypeName={props.assetSubTypeName}
      />
      <AssetDetailList assets={props.assets} taxDetails={props.taxDetails} />
      <TotalLine
        message={intl.formatMessage(messages.totalAssetsCost)}
        amount={props.totalAssetsCost}
      />
      <TradeInView
        tradeInAmount={props.tradeInAmount}
        taxDetails={props.taxDetails}
      />
      <div className={classes.container}>
        <TotalLine
          message={intl.formatMessage(messages.totalCostAfterTrade)}
          amount={props.preTaxCost}
        />
        <ShowIfFragment
          showIf={props.taxDetails?.enabled && !props.taxDetails?.exempt}>
          <TotalLine
            message={intl.formatMessage(messages.totalCostWithTax)}
            amount={props.totalCost}
          />
        </ShowIfFragment>
      </div>
    </Grid>
  );
};
