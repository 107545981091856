import * as React from 'react';
import { useIntl } from 'react-intl';
import { OverviewStepMenuItemGenericProps } from './overview-step-menu-item-generic.props';
import { OverviewStepMenuPage } from './overview-step-menu-page.component';

export const OverviewStepMenuPageGeneric = (
  props: OverviewStepMenuItemGenericProps,
) => {
  const intl = useIntl();
  intl.formatMessage(props.title);
  return (
    <OverviewStepMenuPage
      title={intl.formatMessage(props.title)}
      description={intl.formatMessage(props.description)}
      routeUrl={props.routeUrl}
      status={props.status}
      editButtonText={intl.formatMessage(props.editButtonText)}
      editButtonUat={props.editButtonUat}
    />
  );
};
