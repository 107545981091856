import { Application } from '@frontline/common';
import { HttpService } from '@frontline/web-common';

export function updateApplicationResultPageViewed(
  applicationId: string,
): Promise<Application> {
  return HttpService.getHttpInstanceDeprecated()
    .patch(`/applications/${applicationId}/view-result-page`)
    .then(response => response.data);
}
