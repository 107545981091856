import { getIn } from 'formik';
import React, { Fragment, useEffect } from 'react';
import { setPaymentFrequencyId } from '../../types/loan-details-form-pay-day-frequency-card.functions';
import { LoanDetailsFormFrequencyOption } from '../loan-details-form-frequency-option/loan-details-form-frequency-option.component';
import { LoanDetailsFormPayDayFrequencyCardProps } from './loan-details-form-pay-day-frequency-card.props';
import {
  CardGrid,
  ErrorSpan,
} from './loan-details-form-pay-day-frequency-card.styles';

export const LoanDetailsPayDayFrequencyCard = (
  props: LoanDetailsFormPayDayFrequencyCardProps,
) => {
  const fieldError = getIn(props.form.errors, props.field.name);
  const errorText = props.errorMessage
    ? props.errorMessage(fieldError)
    : fieldError;
  const showError = getIn(props.form.touched, props.field.name) && !!fieldError;

  useEffect(() => {
    props.form.setFieldValue(
      'frequencyId',
      setPaymentFrequencyId(props.form.values.payDayFrequencyId),
    );
  }, [props.form.values.payDayFrequencyId]);

  return (
    <Fragment>
      <CardGrid
        container={true}
        direction="row"
        alignItems="center"
        spacing={2}>
        <LoanDetailsFormFrequencyOption
          field={props.field}
          form={props.form}
          payDayFrequencyId={props.form.values.payDayFrequencyId}
          showError={showError}
          disabled={props.disabled}
        />
      </CardGrid>
      <ErrorSpan>{showError && errorText}</ErrorSpan>
    </Fragment>
  );
};
