import { defineMessages } from 'react-intl';

export default defineMessages({
  purchaseDetailsTitle: {
    id: 'AssetInformationApplicant.PurchaseDetailsTitle',
  },
  itemPurchasedLabel: {
    id: 'FinalizeLoanDetails.itemPurchasedLabel',
  },
});
