import { NewRepairState } from '../types/new-repair-state';
import { createNewRepairState } from '../types/new-repair-state.functions';
import { NewRepairAction, NewRepairActions } from './new-repair.actions';

export const newRepairReducer = (
  state: NewRepairState = createNewRepairState(),
  action: NewRepairAction,
): NewRepairState => {
  switch (action.type) {
    case NewRepairActions.Login:
      return {
        ...state,
        isLoading: true,
      };
    case NewRepairActions.LoginSuccess:
      return {
        ...state,
        isLoading: false,
      };
    case NewRepairActions.LoginFailure:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
