import { HttpService } from '@frontline/web-common';
import { AxiosResponse } from 'axios';
import { LendcareReportingField } from '../types/lendcare-reporting-field.enum';
import { StartNewApplicationResponse } from '../types/StartNewApplication';

export function createApplicationWithShareableLinkId(
  shareableLinkId: string,
  reportingFields: Map<LendcareReportingField, string | null>,
): Promise<AxiosResponse<StartNewApplicationResponse>> {
  const body = {
    refId: '',
    referrerId: '',
    eventId: '',
    campaignId: '',
  };
  reportingFields.forEach((value, key) => {
    switch (key) {
      case LendcareReportingField.REF_ID:
        body.refId = value ?? '';
        break;
      case LendcareReportingField.REFERRER_ID:
        body.referrerId = value ?? '';
        break;
      case LendcareReportingField.EVENT_ID:
        body.eventId = value ?? '';
        break;
      case LendcareReportingField.CAMPAIGN_ID:
        body.campaignId = value ?? '';
        break;
    }
  });
  return HttpService.getHttpInstanceDeprecated().post(
    `/applicant/login/generic-link/${shareableLinkId}`,
    body,
  );
}
