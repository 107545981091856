import { SecondaryButton } from '@frontline/ui-library';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { OverviewStepMenuPageGeneric } from '../OverviewStepMenuPageGeneric/overview-step-menu-item-generic.component';
import messages from './co-applicant-step.messages';
import { CoApplicantStepProps } from './co-applicant-step.props';

export const CoApplicantStep = (props: CoApplicantStepProps) => (
  <OverviewStepMenuPageGeneric
    routeUrl={props.routeUrl}
    title={messages.title}
    description={messages.description}
    editButtonText={messages.editButton}
    status={props.status}>
    {props.showRemoveCoApplicant && props.onClickRemoveCoApplicant && (
      <SecondaryButton onClick={props.onClickRemoveCoApplicant}>
        <FormattedMessage {...messages.removeCoApplicantButton} />
      </SecondaryButton>
    )}
  </OverviewStepMenuPageGeneric>
);
