import { push } from 'connected-react-router';
import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootState } from '../../../store';
import * as api from '../services/shareable-link.service';
import * as actions from './buy-lendcare.actions';

const startNewApplicationEpic: Epic<Action, Action, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.startNewApplication)),
    map(action => action.payload),
    switchMap(({ shareableLinkId, reportingFields }) =>
      from(
        api.createApplicationWithShareableLinkId(
          shareableLinkId,
          reportingFields,
        ),
      ).pipe(
        map(response => actions.startNewApplicationSuccess(response.data)),
        catchError(error => of(actions.startNewApplicationFailure(error))),
      ),
    ),
  );

const startNewApplicationFailureEpic: Epic<
  Action,
  Action,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.startNewApplicationFailure)),
    map(action => action.payload),
    switchMap(error =>
      error.status === 403
        ? of(push('/lendCare/disabled'))
        : of(push('/not-authorized')),
    ),
  );

export default [startNewApplicationEpic, startNewApplicationFailureEpic];
