import { ApplicationStepsHeader } from '@frontline/web-common';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as coApplicantStore from '../../../../../../../../../modules/co-applicant-details/store/co-applicant-details.store';
import { IncomeDetailsForm } from '../../../../../../../../../modules/income-details';
import * as postSubmissionStore from '../../../../../../../store/post-submission.store';
import { CoApplicantDetailsPageProps } from '../../../types';
import messages from './CoApplicantIncomeDetails.messages';

export const CoApplicantIncomeDetails = (
  props: CoApplicantDetailsPageProps,
) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <ApplicationStepsHeader
        title={intl.formatMessage(messages.title)}
        description={intl.formatMessage(messages.description)}
      />
      <IncomeDetailsForm
        disabled={props.disabled}
        incomeDetails={useSelector(
          coApplicantStore.getCoApplicantIncomeDetails,
        )}
        authorizedElectronicCommunication={useSelector(
          coApplicantStore.getAuthorizedElectronicCommunication,
        )}
        onSubmit={postSubmissionStore.navigateToOverview(dispatch)}
      />
    </React.Fragment>
  );
};
