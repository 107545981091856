import {
  ChecklistIcon,
  ErrorContainer,
  TypographyColor,
  TypographyVariant,
} from '@frontline/ui-library';
import { Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { LanguageSelectionMenu } from '../../../../common/localization';
import { QuestionsDialogForm } from '../questions-dialog-form/questions-dialog-form';
import { messages } from './questions-dialog-content.messages';
import { QuestionsDialogContentProps } from './questions-dialog-content.props';

export const QuestionsDialogContent = ({
  securityQuestionsGroupId,
  securityQuestions,
  onSubmit,
  error,
}: QuestionsDialogContentProps) => {
  return (
    <Content>
      <Grid container={true} justify="flex-start" direction="column">
        <HeadingContainer>
          <Typography variant={TypographyVariant.Header2}>
            <FormattedMessage {...messages.dialogTitle} />
          </Typography>
          <LanguageSelectionMenu />
        </HeadingContainer>
        {error && (
          <ErrorSpacing>
            <ErrorContainer>
              <ChecklistIcon icon={['fas', 'exclamation-circle']} />
              <Typography variant={TypographyVariant.Body1}>
                <FormattedMessage
                  {...messages.dialogSecurityQuestionsAttemptError}
                />
              </Typography>
            </ErrorContainer>
          </ErrorSpacing>
        )}
        <HtmlTooltip
          title={
            <React.Fragment>
              <Typography color={TypographyColor.Inherit} gutterBottom={true}>
                <FormattedMessage {...messages.dialogTooltipDescription} />
              </Typography>
              <Typography variant={TypographyVariant.Body1} gutterBottom={true}>
                <FormattedMessage {...messages.dialogTooltipReasons} />
              </Typography>
              <ul>
                <li>
                  <Typography
                    variant={TypographyVariant.Body1}
                    gutterBottom={true}>
                    <FormattedMessage {...messages.dialogTooltipReasonOne} />
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant={TypographyVariant.Body1}
                    gutterBottom={true}>
                    <FormattedMessage {...messages.dialogTooltipReasonTwo} />
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant={TypographyVariant.Body1}
                    gutterBottom={true}>
                    <FormattedMessage {...messages.dialogTooltipReasonThree} />
                  </Typography>
                </li>
              </ul>
              <Typography variant={TypographyVariant.Body1} gutterBottom={true}>
                <FormattedMessage {...messages.dialogTooltipNewLink} />
              </Typography>
            </React.Fragment>
          }>
          <u>
            <Typography variant={TypographyVariant.Body1} gutterBottom={true}>
              <FormattedMessage {...messages.dialogTooltipTitle} />
            </Typography>
          </u>
        </HtmlTooltip>
        <Typography variant={TypographyVariant.Body1} gutterBottom={true}>
          <FormattedMessage {...messages.dialogDescription} />
        </Typography>
        <QuestionsDialogForm
          securityQuestionsGroupId={securityQuestionsGroupId}
          securityQuestions={securityQuestions}
          onSubmit={onSubmit}
        />
      </Grid>
    </Content>
  );
};

const Content = styled.div`
  padding: 0 20px 20px 20px;
`;

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
`;

const ErrorSpacing = styled.div`
  margin-bottom: 20px;
`;

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    padding: '20px',
  },
}))(Tooltip);
