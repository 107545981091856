import { WorkflowStep } from '@frontline/common';
import { Grid, GridProps } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { ChecklistButton } from '../check-list-button/check-list-button.component';
import { OverviewMenu } from '../OverviewStepperDesktop/overview-menu/overview-menu.component';
import { ChecklistMobileProps } from './checklist-mobile.props';

export const ChecklistMobile: FunctionComponent<ChecklistMobileProps &
  GridProps> = ({
  steps,
  activeTabId,
  onTabSelect,
  getStepNameByStepType,
  onClickAddCoApplicant,
  onClickRemoveCoApplicant,
  showRemoveCoApplicant,
  showAddCoApplicant,
  ...rest
}) => {
  return (
    <Grid item={true} {...rest} xs={12}>
      {steps.map((step: WorkflowStep) => (
        <React.Fragment key={step.id}>
          <ChecklistButton
            onClick={() => {
              onTabSelect(step.id);
            }}
            status={step.status}
            active={activeTabId === step.id}
            stepName={step.name || getStepNameByStepType(step.type)}
          />
          <OverviewMenu
            showIf={activeTabId === step.id}
            step={steps.find(s => s.id === activeTabId)}
            onClickAddCoApplicant={onClickAddCoApplicant}
            onClickRemoveCoApplicant={onClickRemoveCoApplicant}
            showRemoveCoApplicant={showRemoveCoApplicant}
            showAddCoApplicant={showAddCoApplicant}
          />
        </React.Fragment>
      ))}
    </Grid>
  );
};
