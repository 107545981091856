import { ActionType, createAction } from 'typesafe-actions';
import { FraudDetectionContext } from '../types/fraud-detection-context.type';

export enum ApplicantFraudDetectionActions {
  EvaluateRisk = 'ApplicantFraudDetection/EvaluateRisk',
}

export const evaluateRisk = createAction(
  ApplicantFraudDetectionActions.EvaluateRisk,
  action => (context: FraudDetectionContext) => action(context),
);

const actionCreators = {
  evaluateRisk,
};

export type ApplicantFraudDetectionActionsType = ActionType<
  typeof actionCreators
>;
