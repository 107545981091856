import { defineMessages } from 'react-intl';

export const PersonalDetailsViewGenericMessages = defineMessages({
  title: {
    id: 'PersonalDetails.title',
    description: 'generic title for peronal details applicant portal',
  },
  description: {
    id: 'PersonalDetails.description',
    description: 'generic title for peronal details description',
  },
});
