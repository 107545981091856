import {
  getFirstIncompleteStep,
  PreSubmissionFormType,
  ProvinceAbbreviation,
  WorkflowStepType,
} from '@frontline/common';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { MainLayout } from '../../../../modules/layout/components';
import { getHighCostLicenseDetailsForProvince } from '../../common/high-cost-license-details/high-cost-license-details.functions';
import * as applicantStore from '../../modules/applicant-details/store/applicant-details.store';
import * as geoLocationStore from '../../modules/geo-location/store/geo-location.store';
import * as applicationStore from '../../store/application.store';
import { getPrevWorkflowStepIndex } from './common/types/WorkflowStep/WorkflowStep.functions';
import { PreSubmissionLayout } from './components/pre-submission-layout/pre-submission-layout.component';
import { PreSubmissionRoutes } from './pre-submission-routes/pre-submission-routes.component';
import * as store from './store/pre-submission.store';
import { shouldAdvanceToResultPage } from './types/ApplicationProgress.functions';
import { ShouldSaveCallbackType } from './types/PreSubmissionForm/PreSubmissionFormPropsType';
import {
  getPreSubmissionRoutePath,
  getResultPageRoutePath,
} from './types/PreSubmissionRoutes.functions';

export const PreSubmissionFeature = MainLayout(() => {
  const dispatch = useDispatch();
  const history = useHistory();

  const workflow = useSelector(store.getWorkflow);

  const isWorkflowLoaded = useSelector(store.isWorkflowLoaded);

  const currentStep = useSelector(store.getCurrentWorkflowStep);

  const stepReference = useSelector(store.getWorkflowStepReference);

  const stepPosition = useSelector(store.getActiveStepIndex) || -1;

  const application = useSelector(applicationStore.getApplication);

  const nextRoute = stepReference?.next?.step.routeUrl;

  const currentPath = history.location.pathname;

  const applicantDetails = useSelector(applicantStore.getApplicantDetails);
  const applicantProvince = useSelector(geoLocationStore.getProvince);
  const applicantCoordinates = useSelector(geoLocationStore.getCoordinates);
  const highCostLicenseDetails = useSelector(
    geoLocationStore.getHighCostLicenseDetails,
  );

  useEffect(() => {
    if (Boolean(navigator.geolocation)) {
      navigator.geolocation.getCurrentPosition(position => {
        geoLocationStore.setConsumerCoordinates({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    }
  }, []);

  useEffect(() => {
    if (
      Boolean(applicantCoordinates) &&
      !Boolean(applicantDetails?.personalDetails?.address?.province)
    ) {
      geoLocationStore.fetchProvince(applicantCoordinates!);
    }
  }, [applicantCoordinates, applicantDetails]);

  useEffect(() => {
    const applicantDetailsProvince =
      applicantDetails?.personalDetails?.address?.province;

    if (applicantDetailsProvince !== undefined || applicantProvince !== null) {
      geoLocationStore.setHighCostLicenseDetails(
        getHighCostLicenseDetailsForProvince(
          (applicantDetailsProvince as ProvinceAbbreviation) ??
            applicantProvince?.abbreviation!,
        ),
      );
    } else {
      geoLocationStore.setHighCostLicenseDetails(null);
    }
  }, [applicantDetails, applicantProvince]);

  useEffect(() => {
    if (!isWorkflowLoaded) {
      store.loadWorkflow();
    }
  }, [dispatch, isWorkflowLoaded]);

  useEffect(() => {
    if (application && isWorkflowLoaded && !currentStep) {
      const nextStep = getFirstIncompleteStep(workflow || []);
      const stepType = nextStep?.type;
      if (stepType) {
        history.push(getPreSubmissionRoutePath(application.id, stepType));
      }
    }
  }, [application, isWorkflowLoaded, history, workflow, currentStep]);

  useEffect(() => {
    if (!application || !currentStep) {
      return;
    }

    if (
      shouldAdvanceToResultPage(
        application,
        currentStep,
        history.location.pathname,
      )
    ) {
      history.push(getResultPageRoutePath(application.id));
    }
  }, [currentStep, application, history]);

  useEffect(() => {
    if (isWorkflowLoaded) {
      store.setActiveStep(currentPath);
    }
  }, [dispatch, currentPath, isWorkflowLoaded]);

  if (!workflow || workflow.length === 0 || !currentStep) {
    return null;
  }

  if (!application) {
    return null;
  }

  const onSubmit = (
    changes: PreSubmissionFormType,
    shouldSave: ShouldSaveCallbackType<PreSubmissionFormType>,
  ) => {
    if (shouldSave(changes)) {
      store.saveStep(changes);
    } else if (nextRoute) {
      history.push(nextRoute);
    }
  };

  const goToStep = (stepType: WorkflowStepType) => {
    history.push(getPreSubmissionRoutePath(application.id, stepType));
  };

  const goToPrevStep = () => {
    const prevStep = workflow[getPrevWorkflowStepIndex(stepPosition)];
    return prevStep.routeUrl && history.push(prevStep.routeUrl);
  };

  return (
    <PreSubmissionLayout
      applicationId={application.id}
      submissionStatus={application.submissionStatus}
      workflow={workflow}
      program={application.program}
      clickStep={goToStep}
      highCostLicenseDetails={highCostLicenseDetails}>
      <PreSubmissionRoutes
        onSubmit={onSubmit}
        applicationId={application.id}
        currentStep={currentStep}
        stepPosition={stepPosition}
        stepReference={stepReference}
        onBack={goToPrevStep}
        application={application}
      />
    </PreSubmissionLayout>
  );
});
