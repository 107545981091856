import { LoanDetailsRules } from '@frontline/common';

export function shouldShowPurchaseDetails(
  rules: LoanDetailsRules | null,
): boolean {
  if (!rules) {
    return true;
  }
  return !Boolean(rules?.hidePurchaseDetails);
}
