import Toolbar from '@material-ui/core/Toolbar';
import styled from 'styled-components';

export const LayoutToolbar = styled(Toolbar)`
  position: relative;
  && {
    padding: 0px;
    height: 100%;
  }
`;
