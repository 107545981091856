import { HttpError } from '@frontline/web-common';
import { ActionType, createAction } from 'typesafe-actions';
import { ApplicationTakeoverDetails } from '../../../types/application-takeover/application-takeover-details.interfaces';
import { ApplicationTakeoverRequestStatus } from '../../../types/application-takeover/application-takeover-request-status.enum';

export enum ApplicationTakeoverAction {
  FetchApplicationTakeoverDetails = 'ApplicationTakeover/FetchApplicationTakeoverDetails',
  FetchApplicationTakeoverDetailsSuccess = 'ApplicationTakeover/FetchApplicationTakeoverDetailsSuccess',
  FetchApplicationTakeoverDetailsError = 'ApplicationTakeover/FetchApplicationTakeoverDetailsError',
  AnswerApplicationTakeoverRequest = 'ApplicationTakeover/AnswerApplicationTakeoverRequest',
  AnswerApplicationTakeoverRequestSuccess = 'ApplicationTakeover/AnswerApplicationTakeoverRequestSuccess',
  AnswerApplicationTakeoverRequestError = 'ApplicationTakeover/AnswerApplicationTakeoverRequestError',
  SetIsConfirming = 'ApplicationTakeover/SetIsConfirming',
  SetApplicationTakeoverStatus = 'ApplicationTakeover/SetApplicationTakeoverStatus',
}

const fetchApplicationTakeoverDetails = createAction(
  ApplicationTakeoverAction.FetchApplicationTakeoverDetails,
  action => (takeoverCode: string) => action(takeoverCode),
);

const fetchApplicationTakeoverDetailsSuccess = createAction(
  ApplicationTakeoverAction.FetchApplicationTakeoverDetailsSuccess,
  action => (details: ApplicationTakeoverDetails) => action(details),
);

const fetchApplicationTakeoverDetailsError = createAction(
  ApplicationTakeoverAction.FetchApplicationTakeoverDetailsError,
  action => (error: HttpError) => action(error),
);

const answerApplicationTakeoverRequest = createAction(
  ApplicationTakeoverAction.AnswerApplicationTakeoverRequest,
  action => (acceptTakeover: boolean, appTakeoverCode: string) =>
    action({ acceptTakeover, appTakeoverCode }),
);

const answerApplicationTakeoverRequestSuccess = createAction(
  ApplicationTakeoverAction.AnswerApplicationTakeoverRequestSuccess,
  action => (status: ApplicationTakeoverRequestStatus) => action(status),
);

const answerApplicationTakeoverRequestError = createAction(
  ApplicationTakeoverAction.AnswerApplicationTakeoverRequestError,
  action => (error: HttpError) => action(error),
);

const setIsConfirming = createAction(
  ApplicationTakeoverAction.SetIsConfirming,
  action => (isConfirming: boolean) => action(isConfirming),
);

const setApplicationTakeoverStatus = createAction(
  ApplicationTakeoverAction.SetApplicationTakeoverStatus,
  action => (status: ApplicationTakeoverRequestStatus) => action(status),
);

export const ApplicationTakeoverActions = {
  fetchApplicationTakeoverDetails,
  fetchApplicationTakeoverDetailsSuccess,
  fetchApplicationTakeoverDetailsError,
  answerApplicationTakeoverRequest,
  answerApplicationTakeoverRequestSuccess,
  answerApplicationTakeoverRequestError,
  setIsConfirming,
  setApplicationTakeoverStatus,
};

export type ApplicationTakeoverActionType = ActionType<
  typeof ApplicationTakeoverActions
>;
