import { withShowIf } from '@frontline/ui-library';
import { Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import sadFaceIcon from '../../../../../../assets/sad-face.svg';
import { ApplicationTakeoverNewLinkFooter } from '../../../application-takeover-new-link-footer/application-takeover-new-link-footer.component';
import { ApplicationTakeoverInvalidMessages } from './application-takeover-invalid.messages';
import { useApplicationTakeoverInvalidStyles } from './application-takeover-invalid.styles';

export const ApplicationTakeoverInvalid = withShowIf(() => {
  const classes = useApplicationTakeoverInvalidStyles();

  return (
    <div className={classes.container}>
      <div className={classes.invalidContainer}>
        <img src={sadFaceIcon} alt="" className={classes.icon} />
        <Typography className={classes.title}>
          <FormattedMessage {...ApplicationTakeoverInvalidMessages.title} />
        </Typography>
      </div>
      <ApplicationTakeoverNewLinkFooter />
    </div>
  );
});
