import { defineMessages } from 'react-intl';

export default defineMessages({
  assetDetailsTitle: {
    id: 'AssetInformationApplicant.AssetDetailsTitle',
    defaultMessage: 'Assets',
  },
  costLabel: {
    id: 'AssetInformationApplicant.costLabel',
    defaultMessage: 'Cost',
  },
  makeLabel: {
    id: 'AssetInformationApplicant.makeLabel',
    defaultMessage: 'Make',
  },
  modelLabel: {
    id: 'AssetInformationApplicant.modelLabel',
    defaultMessage: 'Model',
  },
  serialLabel: {
    id: 'AssetInformationApplicant.serialLabel',
    defaultMessage: 'VIN / Serial Number',
  },
  conditionLabel: {
    id: 'AssetInformationApplicant.conditionLabel',
    defaultMessage: 'Condition',
  },
  hoursOnMotorLabel: {
    id: 'AssetInformationApplicant.hoursOnMotorLabel',
    defaultMessage: 'Km / Hours on Motor',
  },
  yearLabel: {
    id: 'AssetInformationApplicant.yearLabel',
    defaultMessage: 'Year',
  },
});
