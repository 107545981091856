import {
  Application,
  PreSubmissionFormType,
  WorkflowDecisions,
  WorkflowStep,
  WorkflowStepReference,
} from '@frontline/common';
import { push } from 'connected-react-router';
import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootState } from '../../../../../store';
import * as rootApplicationActions from '../../../../../store/application/actions';
import * as applicationStore from '../../../store/application.store';
import coApplicantDetailsEpics from '../common/CoApplicant/store/coApplicantDetails.epics';
import {
  addResultPageStep,
  findActiveStepIndex,
  getEnabledSteps,
  setActiveStepInWorkflow,
} from '../common/types/WorkflowStep/WorkflowStep.functions';
import { createWorkflowStepReference } from '../common/types/WorkflowStep/WorkflowStepReference.functions';
import applicationApprovedEpics from '../features/result-summary/features/application-approved/store/application-approved.epics';
import resultEpics from '../features/result-summary/store/result-summary.epics';
import reviewSummaryEpics from '../features/ReviewSummary/store/reviewSummary.epics';
import * as actions from './pre-submission.actions';
import * as store from './pre-submission.store';

export const createWorkflowStepReferenceEpic: Epic<
  Action,
  Action,
  RootState
> = (action$: Observable<Action>, state$: any) =>
  action$.pipe(
    filter(isActionOf(actions.createStepReference)),
    map(action => [
      action.payload,
      store.getWorkflow(state$.value),
      applicationStore.getWorkflowDecisions(state$.value),
    ]),
    map(([routeUrl, workflow, workflowDecisions]) =>
      createWorkflowStepReference(
        workflow as WorkflowStep[],
        routeUrl as string,
        workflowDecisions as WorkflowDecisions,
      ),
    ),
    map(reference => actions.createStepReferenceSuccess(reference)),
  );

export const loadWorkflowEpic: Epic<Action, Action, RootState> = (
  action$: Observable<Action>,
  state$: any,
) =>
  action$.pipe(
    filter(
      action =>
        isActionOf(actions.loadWorkflow)(action) ||
        isActionOf(rootApplicationActions.refreshApplicationSuccess)(action),
    ),
    map(() => [
      applicationStore.getApplication(state$.value),
      store.getPreWorkflowSteps(state$.value),
    ]),
    map(([application, steps]) =>
      addResultPageStep(
        getEnabledSteps(steps as WorkflowStep[]),
        application as Application,
      ),
    ),
    map(workflow => actions.updateWorkflow(workflow)),
  );

export const savePreSubmissionStepEpic: Epic<Action, Action, RootState> = (
  action$: Observable<Action>,
  state$: any,
) =>
  action$.pipe(
    filter(isActionOf(actions.saveStep)),
    map(action => action.payload),
    map(details => [details, store.getWorkflowStepReference(state$.value)]),
    map(([details, stepReference]) =>
      rootApplicationActions.savePreSubmissionStep({
        stepReference: stepReference as WorkflowStepReference,
        data: details as PreSubmissionFormType,
      }),
    ),
  );

export const savePreSubmissionStepSuccessEpic: Epic<
  Action,
  Action,
  RootState
> = (action$: Observable<Action>) =>
  action$.pipe(
    filter(isActionOf(rootApplicationActions.savePreSubmissionStepSuccess)),
    map(() => actions.nextStep()),
  );

export const setActiveStepEpic: Epic<Action, Action, RootState> = (
  action$: Observable<Action>,
  state$: any,
) =>
  action$.pipe(
    filter(isActionOf(actions.setActiveStep)),
    map(action => action.payload),
    map((routeUrl: string): [WorkflowStep[], string] => [
      setActiveStepInWorkflow(
        store.getPreWorkflowSteps(state$.value),
        routeUrl,
        applicationStore.getApplication(state$.value),
      ),
      routeUrl,
    ]),
    mergeMap(([updatedWorkflow, routeUrl]: [WorkflowStep[], string]) =>
      setActiveStepIndexAndUpdateWorkflow(
        updatedWorkflow,
        findActiveStepIndex(updatedWorkflow),
        routeUrl,
      ),
    ),
  );

export const nextStepEpic: Epic<Action, Action, RootState> = (
  action$: Observable<Action>,
  state$: any,
) =>
  action$.pipe(
    filter(isActionOf(actions.nextStep)),
    map(() => store.getWorkflowStepReference(state$.value)),
    filter((reference: WorkflowStepReference | null) =>
      Boolean(reference?.next?.step.routeUrl),
    ),
    map(
      (reference: WorkflowStepReference | null): string =>
        reference?.next?.step.routeUrl || '',
    ),
    mergeMap((url: string) => of(push(url))),
  );

/*
 * Helpers
 */

function setActiveStepIndexAndUpdateWorkflow(
  workflow: WorkflowStep[],
  index: number,
  routeUrl: string,
): Observable<Action> {
  return from([
    actions.setActiveStepIndex(index),
    actions.updateWorkflow(workflow),
    actions.createStepReference(routeUrl || ''),
  ]);
}

export default [
  ...coApplicantDetailsEpics,
  ...reviewSummaryEpics,
  ...resultEpics,
  ...applicationApprovedEpics,
  loadWorkflowEpic,
  nextStepEpic,
  savePreSubmissionStepEpic,
  savePreSubmissionStepSuccessEpic,
  createWorkflowStepReferenceEpic,
  setActiveStepEpic,
];
