import { defineMessages } from 'react-intl';

export default defineMessages({
  financeAmountLabel: {
    id:
      'Applicant.PostSubmission.LoanDetails.Modules.PaymentPlan.financeAmountLabel',
    defaultMessage: 'Total Amount to be Financed',
    description:
      'Finance Amount Label for the Finance Amount Section of Loan Details in Application Portal',
  },
  financeAmountNoAssetAdded: {
    id:
      'Applicant.PostSubmission.LoanDetails.Modules.PaymentPlan.financeAmountLabelNoAssetAdded',
    description: 'Finance Amount placeholder if no asset is added',
  },
});
