import { styledTheme, TypographyVariant } from '@frontline/ui-library';
import { Grid, GridProps, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles({
  root: {
    color: styledTheme.darkBlueGrey,
  },
});

interface OverviewHeaderProps {
  applicationId?: string;
}

const OverviewHeader: FunctionComponent<OverviewHeaderProps & GridProps> = ({
  applicationId,
  ...rest
}) => {
  const { root } = useStyles();
  return applicationId ? (
    <Grid item={true} {...rest}>
      <Typography classes={{ root }} variant={TypographyVariant.Header3}>
        {applicationId}
      </Typography>
    </Grid>
  ) : null;
};
export default OverviewHeader;
