import { PersonalDetailsSchemaValues } from '@frontline/common';
import { InputField } from '@frontline/ui-library';
import { muiTheme } from '@frontline/ui-library';
import { Grid } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { Field, FormikErrors } from 'formik';
import React, { Fragment } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import messages from './personal-details-contact-details.messages';
import { PersonalDetailsContactDetailsProps } from './personal-details-contact-details.props';

export const PersonalDetailsContactDetails = (
  props: PersonalDetailsContactDetailsProps,
) => {
  const intl = useIntl();
  const message = (
    messageId: MessageDescriptor,
    values?: { [key: string]: any },
  ) => {
    return intl.formatMessage(messageId, values);
  };

  type Messages = typeof messages;

  const errorMessage = (
    error: FormikErrors<keyof Messages>,
    values?: { [key: string]: any },
  ) => {
    const msg = messages[error];
    return msg ? message(msg, values) : '';
  };

  const isMediaExtraSmall = useMediaQuery(muiTheme.breakpoints.down('xs'));

  return (
    <Fragment>
      <Grid container={true} spacing={isMediaExtraSmall ? 0 : 1}>
        <Grid container={true} item={true} xs={12} md={6}>
          <Field
            name="primaryPhone"
            label={message(messages.primaryPhoneLabel)}
            component={InputField}
            fieldType="phone"
            errorMessage={errorMessage}
            disabled={props.disabled}
            uat={props.uatPhone}
          />
        </Grid>
        <Grid container={true} item={true} xs={12} md={6}>
          <Field
            name="email"
            label={message(messages.emailLabel)}
            fieldType="email"
            component={InputField}
            errorMessage={(error: any) =>
              errorMessage(error, {
                max: PersonalDetailsSchemaValues.maxEmailLength,
              })
            }
            disabled={props.disabled}
            uat={props.uatEmail}
          />
        </Grid>
        <Grid item={true} xs={12} md={6} />
      </Grid>
    </Fragment>
  );
};
