import { ApplicationApprovedState, createLoanDetailsState } from '../types';
import {
  ApplicationApprovedActions,
  ApplicationApprovedActionType,
} from './application-approved.actions';

export const applicationApprovedReducer = (
  state: ApplicationApprovedState = createLoanDetailsState(),
  action: ApplicationApprovedActionType,
) => {
  switch (action.type) {
    case ApplicationApprovedActions.FetchOwnerContactInfo:
      return {
        ...state,
        isLoading: true,
      };
    case ApplicationApprovedActions.FetchOwnerContactInfoSuccess:
      return {
        ...state,
        ownerContactInfo: action.payload,
        isLoading: false,
      };
    case ApplicationApprovedActions.FetchOwnerContactInfoFailure:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};
