import { PrimaryButton } from '@frontline/ui-library';
import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import messages from './submit-button.messages';
import { SubmitButtonProps as Props } from './submit-button.props';

export const SubmitButton: FunctionComponent<Props> = (props: Props) => {
  const intl = useIntl();
  const buttonTitle = intl.formatMessage(messages.submitApplicationButton);
  if (!props.showSubmitButton && props.showSubmitButton !== undefined) {
    return null;
  }
  return (
    <PrimaryButton onClick={props.onSubmit} {...props.uat}>
      {buttonTitle}
    </PrimaryButton>
  );
};
