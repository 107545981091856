import { defineMessages } from 'react-intl';

export const AssetsAndAddOnsStepMessages = defineMessages({
  title: {
    id: 'AssetsAndAddOnsStep.title',
  },
  description: {
    id: 'AssetsAndAddOnsStep.description',
  },
  editButton: {
    id: 'AssetsAndAddOnsStep.editButton',
  },
});
