import { Colours } from '@frontline/ui-library';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useAccordionViewStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordion: {
      marginBottom: theme.spacing(2),
    },
    summary: {
      backgroundColor: `${Colours.AliceBlue}`,
    },
    details: {
      flexDirection: 'column',
    },
  }),
);
