import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  dialogTitle: {
    id: 'Applicant.SecurityQuestions.QuestionsDialogContent.dialogTitle',
    description: 'Questions dialog title',
    defaultMessage: 'Security questions',
  },
  dialogDescription: {
    id: 'Applicant.SecurityQuestions.QuestionsDialogContent.dialogDescription',
    description: 'Questions dialog description',
    defaultMessage: 'Please answer the security questions below:',
  },
  dialogSecurityQuestionsAttemptError: {
    id:
      'Applicant.SecurityQuestions.QuestionsDialogContent.dialogSecurityQuestionsAttemptError',
    description: 'Error for invalid answers',
    defaultMessage: 'One or more questions are incorrect',
  },
  dialogTooltipTitle: {
    id: 'Applicant.SecurityQuestions.QuestionsDialogContent.dialogTooltipTitle',
    description: 'Questions dialog tooltip title',
    defaultMessage: 'About this link',
  },
  dialogTooltipDescription: {
    id:
      'Applicant.SecurityQuestions.QuestionsDialogContent.dialogTooltipDescription',
    description: 'Questions dialog tooltip description',
    defaultMessage:
      'This secure link provides direct access to your LendCare application so you can view and complete steps. To open your application, you need to verify your identity by answering the security questions.',
  },
  dialogTooltipReasons: {
    id:
      'Applicant.SecurityQuestions.QuestionsDialogContent.dialogTooltipReasons',
    description: 'Questions dialog tooltip reasons',
    defaultMessage:
      'This link may expire or become unauthenticated for the following reasons:',
  },
  dialogTooltipReasonOne: {
    id:
      'Applicant.SecurityQuestions.QuestionsDialogContent.dialogTooltipReasonOne',
    description: 'Questions dialog tooltip reason one',
    defaultMessage: 'The link has reached it’s 7 day expiration',
  },
  dialogTooltipReasonTwo: {
    id:
      'Applicant.SecurityQuestions.QuestionsDialogContent.dialogTooltipReasonTwo',
    description: 'Questions dialog tooltip reason two',
    defaultMessage:
      'The link is clicked more than 3 times without answering the security questions',
  },
  dialogTooltipReasonThree: {
    id:
      'Applicant.SecurityQuestions.QuestionsDialogContent.dialogTooltipReasonThree',
    description: 'Questions dialog tooltip reason three',
    defaultMessage:
      'The security questions are failed, due to answers not matching the application',
  },
  dialogTooltipNewLink: {
    id:
      'Applicant.SecurityQuestions.QuestionsDialogContent.dialogTooltipNewLink',
    description: 'Questions dialog tooltip new link',
    defaultMessage:
      'If the link expires or becomes unauthenticated, reach out to your sales rep to send you a new link.',
  },
});
