import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withShowIf } from '@frontline/ui-library';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ApplicationTakeoverReferenceCode } from '../../../application-takeover-reference-code/application-takeover-reference-code.component';
import { ApplicationTakeoverDeclinedMessages } from './application-takeover-declined.messages';
import { ApplicationTakeoverDeclinedProps } from './application-takeover-declined.props';
import { useApplicationTakeoverDeclinedStyles } from './application-takeover-declined.styles';

export const ApplicationTakeoverDeclined = withShowIf(
  (props: ApplicationTakeoverDeclinedProps) => {
    const classes = useApplicationTakeoverDeclinedStyles();
    const intl = useIntl();

    return (
      <>
        <Grid item>
          <FontAwesomeIcon
            icon={['fas', 'check-circle']}
            className={classes.icon}
          />
        </Grid>
        <Grid item xs={11} sm={7}>
          <Typography className={classes.title}>
            <FormattedMessage
              {...ApplicationTakeoverDeclinedMessages.title}
              values={{
                status: (
                  <b>
                    {intl.formatMessage(
                      ApplicationTakeoverDeclinedMessages.status,
                    )}
                  </b>
                ),
                merchantName: props.applicationTakeoverDetails?.merchant.name,
              }}
            />
          </Typography>
        </Grid>
        <Grid item className={classes.referenceCodeContainer}>
          <ApplicationTakeoverReferenceCode
            referenceCode={
              props.applicationTakeoverDetails?.appReferenceCode ?? ''
            }
          />
        </Grid>
        <Grid item>
          <Typography className={classes.meaning}>
            <FormattedMessage
              {...ApplicationTakeoverDeclinedMessages.meaning}
            />
          </Typography>
        </Grid>
        <Grid item xs={11} sm={7}>
          <Typography className={classes.description}>
            <FormattedMessage
              {...ApplicationTakeoverDeclinedMessages.description}
            />
          </Typography>
        </Grid>
      </>
    );
  },
);
