import { getApplicantFullName } from '@frontline/common';
import {
  AdditionalDetailsInfo,
  IncomeDetailsInfo,
  PersonalDetailsInfoPanel,
} from '@frontline/web-common';
import * as React from 'react';
import { useSelector } from 'react-redux';
import * as store from '../../../../../../store/pre-submission.store';
import ApprovedDetailsSection from '../approved-details-section/approved-details-section';
import {
  shouldShowAdditionalDetails,
  shouldShowIncomeDetails,
} from './applicant-section-function';
import { ApplicantSectionProps } from './applicant-section-props';

const ApplicantSection = (props: ApplicantSectionProps) => {
  const workflow = useSelector(store.getWorkflow);
  const showAdditionalDetails = shouldShowAdditionalDetails(workflow);
  const showIncomeDetails = shouldShowIncomeDetails(workflow);

  if (!props.applicant) {
    return null;
  }

  return (
    <ApprovedDetailsSection
      label={props.label}
      description={getApplicantFullName(props.applicant)}>
      <PersonalDetailsInfoPanel details={props.applicant.personalDetails} />
      <IncomeDetailsInfo
        showIf={showIncomeDetails}
        details={props.applicant.incomeDetails}
      />
      <AdditionalDetailsInfo
        showIf={showAdditionalDetails}
        details={props.applicant.additionalDetails}
        yearsWorkedCategoriesLookup={props.yearsWorkedCategoriesLookup}
      />
    </ApprovedDetailsSection>
  );
};

export default ApplicantSection;
