import {
  FraudDetectionDataPoints,
  FraudDetectionRiskEvaluation,
  HttpService,
} from '@frontline/web-common';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicantFraudDetectionDataPointsBuilder } from '../types/applicant-fraud-detection-data-points/applicant-fraud-detection-data-points-builder';
import { FraudDetectionContext } from '../types/fraud-detection-context.type';

export function fetchFraudDetectionRiskAssessment(
  dataPoints: FraudDetectionDataPoints,
  accessKey: string,
  context: FraudDetectionContext,
): Observable<FraudDetectionRiskEvaluation> {
  return from(
    HttpService.getHttpInstanceDeprecated().post(
      `applicant/fraud-detection/${context}`,
      ApplicantFraudDetectionDataPointsBuilder.createAplicantFraudDetectionDataPoints(
        dataPoints,
        accessKey,
      ),
    ),
  ).pipe(map(response => response.data));
}
