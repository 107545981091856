import {
  styledTheme,
  TypographyColor,
  TypographyVariant,
  withShowIf,
} from '@frontline/ui-library';
import { applicationRouteBuilders } from '@frontline/web-common';
import { Grid, Typography } from '@material-ui/core';
import { KeyboardArrowLeft } from '@material-ui/icons';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import messages from './post-submission-review-header.messages';
import { PostSubmissionReviewHeaderProps } from './post-submission-review-header.props';

const PostSubmissionReviewHeader = (props: PostSubmissionReviewHeaderProps) => {
  if (!props.applicationId) {
    return null;
  }

  const BackButton = () => {
    return props.showBackToOverviewButton ? (
      <BackIcon
        to={applicationRouteBuilders.getPostSubmissionOverviewRoute(
          props.applicationId ?? '/',
        )}>
        <KeyboardArrowLeft />
        <FormattedMessage {...messages.backToOverview} />
      </BackIcon>
    ) : null;
  };

  return (
    <React.Fragment>
      <BackButton />
      <Header>
        <Grid container={true} justify="space-between">
          <Grid item={true}>
            <Typography
              variant={TypographyVariant.Header2}
              color={TypographyColor.TextPrimary}>
              {props.applicationId}
            </Typography>
          </Grid>
        </Grid>
      </Header>
    </React.Fragment>
  );
};

export default withShowIf(PostSubmissionReviewHeader);

const Header = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
`;

const BackIcon = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: ${styledTheme.darkBlueGrey};
`;
