import { FormatMoney, TypographyVariant } from '@frontline/ui-library';
import { Typography } from '@material-ui/core';
import React from 'react';
import { TotalLineProps } from './total-line.props';
import { useTotalLineStyles } from './total-line.styles';

export const TotalLine = (props: TotalLineProps) => {
  const classes = useTotalLineStyles();
  return (
    <div className={classes.container}>
      <Typography variant={TypographyVariant.Header5}>
        {props.message}: &nbsp;
        <FormatMoney value={Number(props.amount)} />
      </Typography>
    </div>
  );
};
