import { Applicant, PersonalDetails, WorkflowStep } from '@frontline/common';
import { Dispatch } from 'react';
import { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import { RootState } from '../../../../../../../store';
import * as rootApplicationSelectors from '../../../../../../../store/application/selectors';
import { CoApplicantDetailsState } from '../../../features/CoApplicant/types/CoApplicantDetailsState';
import * as preSubmissionStore from '../../../store/pre-submission.store';
import { PreSubmissionState } from '../../../types/PreSubmissionState/pre-submission.state';
import * as actions from './coApplicantDetails.actions';

export const getCoApplicantDetailsState: Selector<
  RootState,
  CoApplicantDetailsState | undefined
> = createSelector(
  preSubmissionStore.getPreSubmissionState,
  (state: PreSubmissionState) => state.coApplicantDetailsState,
);

export const getCoApplicant: Selector<RootState, Applicant | undefined> = (
  state: RootState,
) => rootApplicationSelectors.getCoApplicant(state.application);

export const getCoApplicantPersonalDetails: Selector<
  RootState,
  PersonalDetails | undefined
> = (state: RootState) =>
  rootApplicationSelectors.getCoApplicantPersonalDetails(state.application);

export const getCoApplicantFirstName = (state: RootState) =>
  rootApplicationSelectors.getCoApplicantPersonalDetails(state.application)
    ?.firstName;

export const getCoApplicantCurrentStep: Selector<
  RootState,
  WorkflowStep | null
> = createSelector(
  getCoApplicantDetailsState,
  (state?: CoApplicantDetailsState) =>
    state?.workflow ? state.workflow[state.activeStepIndex] : null,
);

export const getCoApplicantWorflow: Selector<
  RootState,
  WorkflowStep[]
> = createSelector(
  getCoApplicantDetailsState,
  (state?: CoApplicantDetailsState) => (state?.workflow ? state.workflow : []),
);

export const getCoApplicantActiveStepIndex: Selector<
  RootState,
  number | null
> = createSelector(
  getCoApplicantDetailsState,
  (state?: CoApplicantDetailsState) =>
    state?.activeStepIndex !== undefined ? state?.activeStepIndex : null,
);

export const getDefaultCoApplicantRoute: Selector<
  RootState,
  string
> = createSelector(
  getCoApplicantCurrentStep,
  getCoApplicantWorflow,
  (workflowStep, workflow) =>
    workflowStep?.routeUrl || workflow[0]?.routeUrl || '',
);

export const loadCoApplicantWorkflow = (
  dispatch: Dispatch<any>,
) => (): void => {
  dispatch(actions.loadCoApplicantWorkflow());
};

export const setCoApplicantActiveStepIndex = (dispatch: Dispatch<any>) => (
  activeStepIndex: number,
): void => {
  dispatch(actions.setCoApplicantActiveStepIndex(activeStepIndex));
};
