import { defineMessages } from 'react-intl';

export const PreAuthorizedDebitMessages = defineMessages({
  title: {
    id: 'Applicant.PreAuthorizedDebitStep.title',
    description: 'Pre-authorized debit step Title',
  },
  description: {
    id: 'Applicant.PreAuthorizedDebitStep.description',
    description: 'Pre-authorized step Description',
  },
  editButton: {
    id: 'Applicant.PreAuthorizedDebitStep.editButton',
    description: 'Pre-authorized Step Edit Button',
  },
});
