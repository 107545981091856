import { getThirdPartyAddOnTypes } from '@frontline/web-common';
import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootState } from '../../../../../../../../../store';
import * as applicationStore from '../../../../../../../store/application.store';
import { fetchAssetInformationValidations } from '../services/assets-and-addons.service';
import * as actions from './assets-and-addons.actions';

export const fetchValidationsEpic: Epic<Action, Action, RootState> = (
  action$: Observable<Action>,
  state$: any,
) =>
  action$.pipe(
    filter(isActionOf(actions.fetchValidations)),
    map(() => applicationStore.getApplicationId(state$.value)),
    mergeMap(applicationId =>
      from(fetchAssetInformationValidations(applicationId as string)).pipe(
        map(validations => actions.fetchValidationsSuccess(validations)),
        catchError(error => of(actions.fetchValidationsError(error))),
      ),
    ),
  );

export const fetchTypesEpic: Epic<Action, Action, RootState> = (
  action$: Observable<Action>,
  state$: any,
) =>
  action$.pipe(
    filter(isActionOf(actions.fetchTypes)),
    switchMap(() =>
      from(getThirdPartyAddOnTypes()).pipe(
        map(types => actions.fetchTypesSuccess(types)),
        catchError(error => of(actions.fetchTypesFailure(error))),
      ),
    ),
  );

export default [fetchValidationsEpic, fetchTypesEpic];
