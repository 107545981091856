import { EventType } from '@frontline/common';
import {
  preAuthorizedDebitCancelResponse,
  preAuthorizedDebitErrorResponse,
  preAuthorizedDebitSuccessResponse,
  resetVerifyIdentityStepSuccess,
} from '@frontline/web-common';
import { createAction } from 'typesafe-actions';

export const applicationUpdatedAction = createAction(
  EventType.ApplicationUpdated,
  action => (payload: { applicationId: string }) =>
    action(payload.applicationId),
);

export const applicationEventActions = {
  [EventType.ApplicationUpdated]: applicationUpdatedAction,
  [EventType.ApplicationPreAuthorizedDebitSuccessResponse]: preAuthorizedDebitSuccessResponse,
  [EventType.ApplicationPreAuthorizedDebitCancelResponse]: preAuthorizedDebitCancelResponse,
  [EventType.ApplicationPreAuthorizedDebitErrorResponse]: preAuthorizedDebitErrorResponse,
  [EventType.ApplicationIdentityVerificationReset]: resetVerifyIdentityStepSuccess,
};
