import { Colours } from '@frontline/ui-library';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useApplicationTakeoverReferenceCodeStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      container: {
        width: '100%',
        margin: '31px 0',
        display: 'flex',
        justifyContent: 'center',
      },
      textContainer: {
        background: Colours.BackgroundColor,
        maxWidth: '90%',
        width: '100%',
        padding: '14px 0',
        [theme.breakpoints.up('sm')]: {
          maxWidth: '66%',
          padding: '31px 0',
        },
      },
      referenceCode: {
        color: Colours.NavyBlue,
        fontWeight: 700,
        fontSize: 20,
        marginBottom: 9,
        [theme.breakpoints.up('sm')]: {
          fontSize: 28,
        },
      },
      title: {
        fontSize: 14,
        fontWeight: 700,
        [theme.breakpoints.up('sm')]: {
          fontSize: 20,
          fontWeight: 400,
        },
      },
    }),
);
