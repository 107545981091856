import { CoApplicantPersonalDetailsState } from '../types/CoApplicantPersonalDetailsState';
import { createCoApplicantPersonalDetailsState } from '../types/CoApplicantPersonalDetailsState.functions';
import {
  CoApplicantPersonalDetailsActions,
  CoApplicantPersonalDetailsActionsType,
} from './coApplicantPersonalDetails.actions';

export const coApplicantPersonalDetailsReducer = (
  state: CoApplicantPersonalDetailsState = createCoApplicantPersonalDetailsState(),
  action: CoApplicantPersonalDetailsActionsType,
): CoApplicantPersonalDetailsState => {
  if (
    action.type ===
    CoApplicantPersonalDetailsActions.FetchRelationshipTypesSuccess
  ) {
    return {
      relationshipTypes: action.payload,
    };
  }

  return state;
};
