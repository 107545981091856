import { PrimaryButton } from '@frontline/ui-library';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { useIntl } from 'react-intl';
import messages from './SubmissionTimeoutDialog.messages';
import { SubmissionTimeoutDialogProps } from './SubmissionTimeoutDialogProps';

export const SubmissionTimeoutDialog = (
  props: SubmissionTimeoutDialogProps,
) => {
  const intl = useIntl();

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{intl.formatMessage(messages.title)}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {intl.formatMessage(messages.body)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <PrimaryButton onClick={props.onClose}>
          {intl.formatMessage(messages.okButton)}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
