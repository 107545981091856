import { IncomeDetailsForm } from '@frontline/common';
import * as React from 'react';
import { useSelector } from 'react-redux';
import * as applicantStore from '../../../../modules/applicant-details/store/applicant-details.store';
import * as incomeDetailsStore from '../../../../modules/income-details/store/income-details.store';
import { PreSubmissionFormPropsType } from '../../types/PreSubmissionForm/PreSubmissionFormPropsType';
import { IncomeDetails } from './components/income-details/income-details.component';

export const IncomeDetailsFeature = (
  props: PreSubmissionFormPropsType<IncomeDetailsForm>,
) => (
  <IncomeDetails
    incomeDetails={useSelector(incomeDetailsStore.getIncomeDetails)}
    authorizedElectronicCommunication={useSelector(
      applicantStore.hasAuthorizedElectronicCommunication,
    )}
    {...props}
  />
);
