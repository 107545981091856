import { Coordinates, Province } from '@frontline/common';
import { HttpError } from '@frontline/web-common';
import { ActionType, createAction } from 'typesafe-actions';
import { HighCostLicenseDetails } from '../../../common/high-cost-license-details/types/high-cost-license-details.interface';

export enum GeoLocationActions {
  FetchProvince = 'GeoLocation/FetchProvince',
  FetchProvinceSuccess = 'GeoLocation/FetchProvinceSuccess',
  FetchProvinceFailure = 'GeoLocation/FetchProvinceFailure',
  SetConsumerCoordinates = 'ConsumerProfile/SetConsumerCoordinates',
  SetHighCostLicenseDetails = 'ConsumerProfile/SetHighCostLicenseDetails',
}

export const fetchProvince = createAction(
  GeoLocationActions.FetchProvince,
  action => (coordinates: Coordinates) => action(coordinates),
);

export const fetchProvinceSuccess = createAction(
  GeoLocationActions.FetchProvinceSuccess,
  action => (province: Province) => action(province),
);

export const fetchProvinceFailure = createAction(
  GeoLocationActions.FetchProvinceFailure,
  action => (error: HttpError) => action(error),
);

export const setConsumerCoordinates = createAction(
  GeoLocationActions.SetConsumerCoordinates,
  action => (coordinates: Coordinates) => action(coordinates),
);

export const setHighCostLicenseDetails = createAction(
  GeoLocationActions.SetHighCostLicenseDetails,
  action => (highCostLicenseDetails: HighCostLicenseDetails | null) =>
    action(highCostLicenseDetails),
);

const actionCreators = {
  fetchProvince,
  fetchProvinceSuccess,
  fetchProvinceFailure,
  setConsumerCoordinates,
  setHighCostLicenseDetails,
};

export type GeoLocationAction = ActionType<typeof actionCreators>;
