import { PayDayFrequencies } from '../../types/pay-day-frequency.constants';

export const LoanDetailsFormFrequencyOptionUat = {
  [PayDayFrequencies[0]]:
    'postSubmission.loanDetails.form.frequency.weekly.option',
  [PayDayFrequencies[1]]:
    'postSubmission.loanDetails.form.frequency.biWeekly.option',
  [PayDayFrequencies[2]]:
    'postSubmission.loanDetails.form.frequency.semiMonthly.option',
  [PayDayFrequencies[3]]:
    'postSubmission.loanDetails.form.frequency.monthly.option',
};
