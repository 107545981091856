import { HomeAndAutoAncillaryProductPage } from '@frontline/web-common';
import { HomeAndAutoAncillaryProductRequest } from '@frontline/web-common/dist/types/feature-modules/home-and-auto-ancillary-product/types/home-and-auto-ancillary-product-request.interface';

export const isHomeAndAutoSelectionValid = (
  request: HomeAndAutoAncillaryProductRequest | null,
  page: HomeAndAutoAncillaryProductPage | null,
  planCost: number | null,
): boolean => {
  if (
    page === HomeAndAutoAncillaryProductPage.HomeAndAutoAncillaryProductMenu &&
    (!planCost || !request?.type || !request.years)
  ) {
    return false;
  }
  return true;
};

export const getEnglishFaqUrl = (): string | undefined => {
  return process.env.REACT_APP_HOME_AND_AUTO_ANCILLARY_PRODUCT_FAQ_ENGLISH;
};

export const getFrenchFaqUrl = (): string | undefined => {
  return process.env.REACT_APP_HOME_AND_AUTO_ANCILLARY_PRODUCT_FAQ_FRENCH;
};
