import { WorkflowStepType } from '@frontline/common';
import { useUatId } from '@frontline/ui-library';
import React, { FunctionComponent } from 'react';
import {
  AddOnsStep,
  ApplicantStep,
  AssetInformationStep,
  AssetsAndAddOnsStep,
  BankDocumentsStep,
  CoApplicantStep,
  ComponentSelectorStep,
  FlinksStep,
  InvoiceDetailsStep,
  LoanDetailsStep,
  LoanProtectionStep,
  PaymentDetailsStep,
  SignDocumentsStep,
  VerifyIdentityStep,
} from '../OverviewStepperDesktop/OverviewStepMenuPages';
import { HomeAndAutoAncillaryProductStep } from '../OverviewStepperDesktop/OverviewStepMenuPages/home-and-auto-ancillary-product-step/home-and-auto-ancillary-product-step.component';
import { PreAuthorizedDebitStep } from '../OverviewStepperDesktop/OverviewStepMenuPages/pre-authorized-debit-step/pre-authorized-debit-step';
import { MenuPageSelectorProps } from './menu-page-selector.props';
import { MenuPageSelectorUat } from './menu-page-selector.uat';

export const MenuPageSelector: FunctionComponent<MenuPageSelectorProps> = ({
  activeTab,
  step,
  onClickAddCoApplicant,
  onClickRemoveCoApplicant,
  showRemoveCoApplicant,
  showAddCoApplicant,
}) => {
  const uatId = useUatId();
  const status = step.status;
  const routeUrl = step.routeUrl || '';

  switch (activeTab) {
    case WorkflowStepType.AddOns: {
      return <AddOnsStep routeUrl={routeUrl} status={status} />;
    }
    case WorkflowStepType.Applicant: {
      return (
        <ApplicantStep
          showAddCoApplicant={showAddCoApplicant}
          onClickAddCoApplicant={onClickAddCoApplicant}
          routeUrl={routeUrl}
          status={status}
        />
      );
    }
    case WorkflowStepType.CoApplicant: {
      return (
        <CoApplicantStep
          showRemoveCoApplicant={showRemoveCoApplicant}
          onClickRemoveCoApplicant={onClickRemoveCoApplicant}
          routeUrl={routeUrl}
          status={status}
        />
      );
    }
    case WorkflowStepType.AssetInformation:
      return (
        <AssetInformationStep
          routeUrl={routeUrl}
          status={status}
          uat={uatId(MenuPageSelectorUat.AssetInformation)}
        />
      );
    case WorkflowStepType.AssetsAndAddOns:
      return (
        <AssetsAndAddOnsStep
          routeUrl={routeUrl}
          status={status}
          uat={uatId(MenuPageSelectorUat.AssetsAndAddOns)}
        />
      );
    case WorkflowStepType.BankDocuments:
      return (
        <BankDocumentsStep
          routeUrl={routeUrl}
          status={status}
          uat={uatId(MenuPageSelectorUat.BankDocuments)}
        />
      );
    case WorkflowStepType.LoanAndFinancingDetails:
      return (
        <LoanDetailsStep
          routeUrl={routeUrl}
          status={status}
          uat={uatId(MenuPageSelectorUat.LoanAndFinancingDetails)}
        />
      );
    case WorkflowStepType.Flinks:
      return <FlinksStep step={step} uat={uatId(MenuPageSelectorUat.Flinks)} />;
    case WorkflowStepType.PaymentDetails:
      return (
        <PaymentDetailsStep
          routeUrl={routeUrl}
          status={status}
          uat={uatId(MenuPageSelectorUat.PaymentDetails)}
        />
      );
    case WorkflowStepType.SignDocuments:
      return (
        <SignDocumentsStep
          routeUrl={routeUrl}
          status={status}
          uat={uatId(MenuPageSelectorUat.SignDocuments)}
        />
      );
    case WorkflowStepType.VerifyIdentity:
      return (
        <VerifyIdentityStep
          routeUrl={routeUrl}
          status={status}
          uat={uatId(MenuPageSelectorUat.VerifyIdentity)}
        />
      );
    case WorkflowStepType.LoanProtection:
      return (
        <LoanProtectionStep
          routeUrl={routeUrl}
          status={status}
          uat={uatId(MenuPageSelectorUat.LoanProtection)}
        />
      );
    case WorkflowStepType.InvoiceDetails:
      return (
        <InvoiceDetailsStep
          routeUrl={routeUrl}
          status={status}
          uat={uatId(MenuPageSelectorUat.InvoiceDetails)}
        />
      );
    case WorkflowStepType.ComponentSelector:
      return (
        <ComponentSelectorStep
          step={step}
          uat={uatId(MenuPageSelectorUat.ComponentSelector)}
        />
      );
    case WorkflowStepType.PreAuthorizedDebit:
      return (
        <PreAuthorizedDebitStep
          step={step}
          uat={uatId(MenuPageSelectorUat.PreAuthorizedDebit)}
        />
      );
    case WorkflowStepType.HomeAndAutoAncillaryProduct:
      return (
        <HomeAndAutoAncillaryProductStep
          routeUrl={routeUrl}
          status={status}
          uat={uatId(MenuPageSelectorUat.HomeAndAutoAncillaryProduct)}
        />
      );
    default:
      return <ApplicantStep routeUrl={routeUrl} status={status} />;
  }
};
