import { addOnsValidateTotal } from '@frontline/common';
import { ApplicationStepsFooter } from '@frontline/web-common';
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as applicantApplicationOptionsStore from '../../../../../../modules/applicant-application-options/store/applicant-application-options.store';
import * as postSubmissionStore from '../../../../store/post-submission.store';
import { PostSubmissionContentCard } from '../../components/post-submission-content-card';
import { PostSubmissionReviewFeatureFunctions } from '../../types/PostSubmissionReviewFeature.functions';
import { AddOnAlertMessage } from './components/AddOnAlertMessage';
import { ThirdPartyAddOnsHeader } from './components/third-party-add-ons-header';
import { ThirdPartyAddOnsView } from './components/ThirdPartyAddOnsView';
import { ThirdPartyAddOnsWaitingForMerchant } from './components/ThirdPartyAddOnsWaitingForMerchant/ThirdPartyAddOnsWaitingForMerchant';
import * as store from './store/thirdPartyAddOns.store';

export const ThirdPartyAddOnsFeature: FunctionComponent = () => {
  const dispatch = useDispatch();
  const maxAddOnPrice = useSelector(store.getThirdPartyAddOnsMaxTotal);
  const addOns = useSelector(store.getThirdPartyAddOns);
  const isValid = Boolean(addOnsValidateTotal(addOns, maxAddOnPrice));
  const isLoading: boolean = useSelector(store.getIsLoading);
  const thirdPartyAddOnTypes = useSelector(store.getThirdPartyAddOnTypes);
  const applicantApplicationOption = useSelector(
    applicantApplicationOptionsStore.getApplicantApplicationOption,
  );
  const workflowStep = useSelector(postSubmissionStore.getActiveWorkflowStep);

  useEffect(() => {
    if (!thirdPartyAddOnTypes || thirdPartyAddOnTypes.length === 0) {
      store.fetchThirdPartyAddOnTypes(dispatch)();
    }
  }, [dispatch, thirdPartyAddOnTypes]);

  const hideThirdPartyAddOnsDetails: boolean = PostSubmissionReviewFeatureFunctions.hideAddOns(
    applicantApplicationOption,
    workflowStep,
  );

  return (
    <PostSubmissionContentCard>
      <AddOnAlertMessage showIf={!isValid} />
      <ThirdPartyAddOnsHeader isLoading={isLoading} />
      {!hideThirdPartyAddOnsDetails ? (
        <ThirdPartyAddOnsView
          addOns={addOns}
          thirdPartyAddOnTypes={thirdPartyAddOnTypes}
        />
      ) : (
        <ThirdPartyAddOnsWaitingForMerchant />
      )}
      <ApplicationStepsFooter
        onBack={postSubmissionStore.navigateToPreviousStep(dispatch)}
        onSubmit={postSubmissionStore.navigateToNextStep(dispatch)}
      />
    </PostSubmissionContentCard>
  );
};
