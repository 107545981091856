import { defineMessages } from 'react-intl';

export const ThirdPartyAddonsHeaderMessages = defineMessages({
  title: {
    id: 'Applicant.PostSubmission.ThirdPartyAddOnsHeader.title',
    description: 'Header for Third Party Add Ons page',
  },
  description: {
    id: 'Applicant.PostSubmission.ThirdPartyAddOnsHeader.description',
    description: 'Description for Third Party Add Ons page',
  },
  toolTipDescription1: {
    id:
      'Applicant.PostSubmission.AssetInformation.AddonView.toolTip.description1',
    description: 'Tooltip description 1',
  },
  toolTipDescription2: {
    id:
      'Applicant.PostSubmission.AssetInformation.AddonView.toolTip.description2',
    description: 'Tooltip description 2',
  },
});
