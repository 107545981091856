import { TypographyVariant } from '@frontline/ui-library';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { AccordionViewProps } from './accordion-view.props';
import { useAccordionViewStyles } from './accordion-view.styles';

export const AccordionView = (props: AccordionViewProps) => {
  const classes = useAccordionViewStyles();
  const handlePanelChange = (
    event: React.ChangeEvent<{}>,
    expanded: boolean,
  ) => {
    props.onChange(expanded ? props.name : '');
  };
  return (
    <ExpansionPanel
      key={props.name}
      expanded={props.expandedState === props.name}
      className={classes.accordion}
      onChange={handlePanelChange}>
      <ExpansionPanelSummary
        classes={{
          root: props.expandedState === props.name ? classes.summary : '',
        }}
        expandIcon={<ExpandMoreIcon />}>
        <Typography variant={TypographyVariant.Header5}>
          {props.header}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails classes={{ root: classes.details }}>
        {props.children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};
