import * as React from 'react';
import { useIntl } from 'react-intl';
import { IncomeDetailsGeneric } from '../../../../common/IncomeDetails/components/IncomeDetailsGeneric/IncomeDetailsGeneric';
import messages from './income-details.messages';
import { IncomeDetailsProps } from './Income-details.props';

export const IncomeDetails = (props: IncomeDetailsProps) => {
  const intl = useIntl();

  return (
    <IncomeDetailsGeneric
      incomeDetails={props.incomeDetails}
      title={intl.formatMessage(messages.title)}
      {...props}
    />
  );
};
