import {
  InputField,
  PrimaryButton,
  TypographyVariant,
} from '@frontline/ui-library';
import { Grid, Typography } from '@material-ui/core';
import { Field, Form, FormikErrors } from 'formik';
import React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { QuestionType } from '../../../../types/questions/question-type.enum';
import { QuestionsDialogFormViewFunctions } from '../../types/questions-dialog-form-view.functions';
import { messages } from './questions-dialog-form-fields.messages';
import { QuestionsDialogFormViewProps } from './questions-dialog-form-view.props';

export const QuestionsDialogFormView = (
  props: QuestionsDialogFormViewProps,
) => {
  const intl = useIntl();

  const message = (
    messageId: MessageDescriptor,
    value?: { [key: string]: any },
  ) => {
    return intl.formatMessage(messageId, value);
  };

  const errorMessage = (
    error: FormikErrors<any> | string | undefined,
    value?: { [key: string]: any },
  ) => {
    const msg = messages[`${error}`];
    return msg ? message(msg, value) : '';
  };

  return (
    <Form>
      <Grid container={true} spacing={1}>
        {props.securityQuestions.map((question: QuestionType) => (
          <Grid item={true} xs={12} key={question}>
            <Typography variant={TypographyVariant.Body1} gutterBottom={true}>
              {message(
                QuestionsDialogFormViewFunctions.getFieldMessage(
                  question,
                  'Question',
                  messages,
                ),
              )}
            </Typography>
            <Field
              name={question}
              component={InputField}
              fieldType={QuestionsDialogFormViewFunctions.getFieldType(
                question,
              )}
              placeholder={message(
                QuestionsDialogFormViewFunctions.getFieldMessage(
                  question,
                  'Placeholder',
                  messages,
                ),
              )}
              isSecurityQuestion={true}
              errorMessage={errorMessage}
              inputAttributes={{ maxlength: 4 }}
            />
          </Grid>
        ))}
        <Button type="submit">
          <FormattedMessage {...messages.buttonLabel} />
        </Button>
      </Grid>
    </Form>
  );
};

const Button = styled(PrimaryButton)`
  width: 90px;
  margin-top: 8px;
`;
