import { ComponentSelectorStep, WorkflowStepType } from '@frontline/common';
import { ComponentSelectorView } from '@frontline/web-common';
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as postSubmissionStore from '../../../../../../store/post-submission.store';
import { PostSubmissionContentCard } from '../../../../components/post-submission-content-card';
import * as store from './../../store/componentSelector.store';

const ComponentSelector: FunctionComponent = () => {
  const dispatch = useDispatch();
  const step = useSelector(postSubmissionStore.getActiveWorkflowStep);
  const hasSelectedDecision = useSelector(store.hasSelectedDecision);
  const selectedChoice = useSelector(store.getSelectedDecision);
  const selectedChoiceDescription = useSelector(
    store.getSelectedChoiceDescription,
  );
  const choices = useSelector(store.getChoices);

  useEffect(() => {
    if (step && step.type === WorkflowStepType.ComponentSelector) {
      const componentSelectorStep = step as ComponentSelectorStep;
      store.initializeChoices(dispatch)(
        componentSelectorStep.decisionKey,
        componentSelectorStep.choices,
      );
    }
  }, [dispatch, step]);

  const saveDecision = () => {
    if (hasSelectedDecision) {
      store.saveDecision(dispatch)();
    }
  };

  return (
    <PostSubmissionContentCard>
      <ComponentSelectorView
        choices={choices}
        onDecisionSelected={store.selectDecision(dispatch)}
        selectedChoice={selectedChoice}
        selectedChoiceDescription={selectedChoiceDescription}
        title={step?.title || ''}
        description={step?.description || ''}
        onSubmit={saveDecision}
        onBack={postSubmissionStore.navigateToPreviousStep(dispatch)}
      />
    </PostSubmissionContentCard>
  );
};

export default ComponentSelector;
