import { createPersonalDetailsState } from '../types/personal-details-state.functions';
import { PersonalDetailsState } from '../types/personal-details.state';
import {
  PersonalDetailsAction,
  PersonalDetailsActionType,
} from './personal-details.actions';

export function personalDetailsReducer(
  state: PersonalDetailsState = createPersonalDetailsState(),
  action: PersonalDetailsActionType,
): PersonalDetailsState {
  switch (action.type) {
    case PersonalDetailsAction.SaveApplicantPersonalDetailsError:
      return {
        ...state,
        error: action.payload,
      };
    case PersonalDetailsAction.ResetApplicantPersonalDetailsError:
      return {
        ...state,
        error: null,
      };
    case PersonalDetailsAction.SetSalesRepIdDialogOpen:
      return {
        ...state,
        isSalesRepIdDialogOpen: action.payload,
      };
    case PersonalDetailsAction.SetSalesRepIdComplete:
      return {
        ...state,
        isSalesRepIdComplete: action.payload,
      };
    case PersonalDetailsAction.SubmitSalesRepId:
      return {
        ...state,
        isLoading: true,
      };
    case PersonalDetailsAction.SubmitSalesRepIdSuccess:
      return {
        ...state,
        isLoading: false,
        isSalesRepIdComplete: true,
        isSalesRepIdDialogOpen: false,
        error: null,
      };
    case PersonalDetailsAction.SubmitSalesRepIdFailure:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
