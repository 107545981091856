import {
  canDeleteUploadDocuments,
  getPendingDocuments,
  isBankTransactionsDocument,
  StoredDocument,
} from '@frontline/common';
import { BankDocumentsState, WebCommonRootState } from '@frontline/web-common';
import { createSelector, Selector } from 'reselect';
import { RootState } from '../../../../../../../../../store';
import * as applicationStore from '../../../../../../../store/application.store';
import * as postSubmissionStore from '../../../../../store/post-submission.store';
import { PostSubmissionReviewState } from '../../../types/PostSubmissionReviewState';
import * as actions from './bank-documents.actions';

export const getBankDocumentsState: Selector<
  RootState,
  BankDocumentsState | undefined
> = createSelector(
  postSubmissionStore.getPostSubmissionReviewState,
  (state?: PostSubmissionReviewState) => state?.bankDocumentsState,
);

export const errorAttachingDocuments: Selector<
  RootState,
  boolean
> = createSelector(getBankDocumentsState, (state?: BankDocumentsState) =>
  Boolean(state?.error),
);

export const isConfirmAttachPendingDocumentsDialogOpen: Selector<
  RootState,
  boolean
> = createSelector(getBankDocumentsState, (state?: BankDocumentsState) =>
  Boolean(state?.isConfirmAttachPendingDocumentsDialogOpen),
);

export const isSaving: Selector<
  RootState,
  boolean
> = createSelector(getBankDocumentsState, (state?: BankDocumentsState) =>
  Boolean(state?.saving),
);

export const canDeleteAttachedDocument: Selector<
  RootState,
  boolean
> = createSelector(applicationStore.getApplication, canDeleteUploadDocuments);

export const getBankDocuments: Selector<
  RootState,
  StoredDocument[]
> = createSelector(
  applicationStore.getApplicationDocuments,
  (documents: StoredDocument[] = []) =>
    documents.filter(isBankTransactionsDocument),
);

export const getPendingBankDocuments: Selector<
  RootState,
  StoredDocument[]
> = createSelector(getBankDocuments, getPendingDocuments);

export const attachPendingDocuments = (dispatch: any) => (
  applicationId: string,
  documents: StoredDocument[],
) => {
  dispatch(actions.attachPendingDocuments(applicationId, documents));
};

export const openConfirmAttachPendingDocumentsDialog = (
  dispatch: any,
) => () => {
  dispatch(actions.openConfirmAttachPendingDocumentsDialog());
};

export const closeConfirmAttachPendingDocumentsDialog = (
  dispatch: any,
) => () => {
  dispatch(actions.closeConfirmAttachPendingDocumentsDialog());
};

export const getUploadingDocuments = ({ application }: WebCommonRootState) =>
  application.uploadingDocuments;
