import * as React from 'react';
import { ReactNode } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Provider } from 'react-redux';
import { Store } from './Store';

interface Props extends WrappedComponentProps {
  children?: ReactNode;
}

const StoreProvider = ({ children }: Props) => {
  return <Provider store={Store}>{children}</Provider>;
};

export default injectIntl(StoreProvider);
