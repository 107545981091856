import { PdfViewPage } from '@frontline/web-common';
import React from 'react';
import { Route, Switch } from 'react-router';
import { ApplicationTakeoverFeature } from './features/application-takeover-request/application-takeover-feature';
import { ApplicationFeature } from './features/application/application.feature';
import { LendCareRoutes } from './features/lendCare/lendcare.routes';
import { NewRepairFeature } from './features/new-repair/new-repair.feature';
import { NotAuthenticatedRoutes } from './features/notAuthenticated/NotAuthenticatedRoutes';
import { RepairTechFeature } from './features/repair-tech/RepairTechFeature';
import { SecurityQuestionsFeature } from './features/security-questions/security-questions-feature';
import { ProtectedRoute } from './modules/auth/components/ProtectedRoute/ProtectedRoute';

export const Routes = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route
          path="/lendcare/:shareableLinkId"
          exact={true}
          component={LendCareRoutes}
        />
        <Route
          path="/new-repair/:shareableLinkId"
          exact={true}
          component={NewRepairFeature}
        />
        <Route path="/not-authenticated" component={NotAuthenticatedRoutes} />
        <Route
          path="/security-questions"
          exact={true}
          component={SecurityQuestionsFeature}
        />

        <Route
          path="/takeover/:takeoverCode"
          exact={true}
          component={ApplicationTakeoverFeature}
        />

        <Route path="/pdf/:fileUrl" exact={true} component={PdfViewPage} />
        <ProtectedRoute path="/repair-tech" component={RepairTechFeature} />
        <ProtectedRoute path="/" component={ApplicationFeature} />
      </Switch>
    </React.Fragment>
  );
};
