import { DocumentType } from '@frontline/common';
import { Loader, TypographyVariant, useUatId } from '@frontline/ui-library';
import {
  ApplicationStepsFooter,
  ApplicationStepsHeader,
  ConfirmAttachPendingDocumentsDialog,
  DocumentsToUploadList,
  getRequiredDocumentTypes,
  PostSubmissionDocumentsWarning,
  sortDocumentTypes,
  SubmitButtonTexts,
} from '@frontline/web-common';
import { Typography } from '@material-ui/core';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as postSubmissionReviewStore from '../../../../../store/post-submission.store';
import { PostSubmissionContentCard } from '../../../components/post-submission-content-card';
import * as store from '../store/upload-documents.store';
import { getPendingDocuments } from '../store/upload-documents.store';
import { createDefaultUploadDocuments } from '../types';
import {
  getMessageDefinitionForDocumentDescription,
  getMessageDefinitionForDocumentTitle,
} from '../types';
import messages from './upload-documents.messages';
import { UploadDocumentsUat } from './upload-documents.uat';

export const UploadDocuments: FunctionComponent = () => {
  const intl = useIntl();
  const uatId = useUatId();
  const dispatch = useDispatch();
  const application = useSelector(
    postSubmissionReviewStore.getCurrentApplication,
  );
  const pendingDocuments = useSelector(getPendingDocuments);
  const documentList = useMemo(() => {
    return application?.requiredDocuments?.length
      ? getRequiredDocumentTypes(application.requiredDocuments)
      : createDefaultUploadDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application?.requiredDocuments]);
  const requiredDocumentTypes = useMemo(() => {
    return application?.requiredDocuments?.length
      ? getRequiredDocumentTypes(application.requiredDocuments)
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application?.requiredDocuments]);
  const [openConfirmAttachDialog, setOpenConfirmAttachDialog] = useState(false);
  const [isConfirmAttach, setIsConfirmAttach] = useState(false);
  const uploadedDocuments = useSelector(store.getStoredDocuments);
  const canDeleteAttachedDocuments = useSelector(
    store.getAbleToDeleteAttachedDocuments,
  );
  const uploadingDocuments = useSelector(store.getUploadingDocuments);
  const pendingDocumentsFileNames = useSelector(
    store.getPendingDocumentsFileNames,
  );

  const lookupIsDocumentUploading = (docStep: DocumentType): boolean =>
    uploadingDocuments[docStep];

  const getDocumentDescription = (docStep: DocumentType): string =>
    intl.formatMessage(getMessageDefinitionForDocumentDescription(docStep));

  const getDocumentTitle = (
    docStep: DocumentType,
    documentTypeRequired: boolean,
  ): string =>
    intl.formatMessage(
      getMessageDefinitionForDocumentTitle(docStep, documentTypeRequired),
    );

  const nextStep = () =>
    postSubmissionReviewStore.navigateToNextStep(dispatch)();

  const submitApplication = () =>
    pendingDocumentsFileNames.length
      ? setOpenConfirmAttachDialog(true)
      : nextStep();

  const onConfirmAttach = () => {
    setOpenConfirmAttachDialog(false);
    setIsConfirmAttach(true);
    postSubmissionReviewStore.attachPendingDocuments(dispatch)({
      fileNames: pendingDocumentsFileNames,
      successMessage: intl.formatMessage(messages.attachDocSuccessMessage),
      errorMessage: intl.formatMessage(messages.attachDocErrorMessage),
      onSuccess: () => {
        setIsConfirmAttach(false);
        nextStep();
      },
      onError: () => {
        setIsConfirmAttach(false);
      },
    });
  };

  const hasNewDocuments = useMemo(() => {
    return Boolean(pendingDocuments.length);
  }, [pendingDocuments]);

  return (
    <PostSubmissionContentCard>
      <ApplicationStepsHeader
        title={intl.formatMessage(messages.title)}
        description={intl.formatMessage(messages.description)}
      />
      <PostSubmissionDocumentsWarning
        showIf={hasNewDocuments}
        warningMessage={intl.formatMessage(
          messages.documentsPendingSubmissionWarningMessage,
        )}
      />
      <Typography gutterBottom={true} variant={TypographyVariant.Header5}>
        {intl.formatMessage(messages.requiredDocuments)}
      </Typography>
      <DocumentsToUploadList
        uploadedDocuments={uploadedDocuments}
        documentList={sortDocumentTypes(documentList)}
        canDeleteAttachedDocuments={canDeleteAttachedDocuments}
        lookupIsDocumentUploading={lookupIsDocumentUploading}
        getDocumentDescription={getDocumentDescription}
        getDocumentTitle={getDocumentTitle}
        requiredDocumentTypes={requiredDocumentTypes}
      />
      <ApplicationStepsFooter
        onSubmit={submitApplication}
        onBack={postSubmissionReviewStore.navigateToPreviousStep(dispatch)}
        alternativeSubmitButtonText={SubmitButtonTexts.SubmitDocuments}
        buttonUat={uatId(UploadDocumentsUat.submitDocumentsButton)}
      />
      <Loader
        show={isConfirmAttach}
        position="absolute"
        size={100}
        zIndex={100}
      />
      <ConfirmAttachPendingDocumentsDialog
        isOpen={openConfirmAttachDialog}
        onCancel={() => setOpenConfirmAttachDialog(false)}
        onConfirm={onConfirmAttach}
      />
    </PostSubmissionContentCard>
  );
};
