import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WorkflowStepType } from '@frontline/common';
import {
  PaperCard,
  styledTheme,
  TypographyColor,
  TypographyVariant,
  withShowIf,
} from '@frontline/ui-library';
import { getWorkflowStepName, WorkflowStepIcon } from '@frontline/web-common';
import { Grid, GridProps, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { history } from '../../../../../../store';
import { UploadDocumentsButtonProps } from './upload-documents-button.props';
import {
  IconWrapper,
  UploadButton,
  UploadDocumentsRow,
} from './upload-documents-button.styles';

const UploadDocumentsButtonComponent: FunctionComponent<UploadDocumentsButtonProps &
  GridProps> = ({ uploadDocsStep, underlineText, ...rest }) => {
  if (!uploadDocsStep) {
    return null;
  }
  const goToUploadDocs = () => {
    if (uploadDocsStep?.routeUrl) {
      history.push(uploadDocsStep.routeUrl);
    }
  };
  return (
    <Grid item={true} {...rest}>
      <PaperCard
        padding="0em"
        background="rgba(1, 126, 255, 0.10)"
        border={`1px solid ${styledTheme.deepSkyBlue}`}>
        <UploadButton onClick={goToUploadDocs}>
          <UploadDocumentsRow>
            <IconWrapper>
              <WorkflowStepIcon status={uploadDocsStep.status} />
            </IconWrapper>
            <Typography
              variant={TypographyVariant.Subtitle1}
              color={TypographyColor.Primary}
              style={{ textDecoration: underlineText ? 'underline' : 'none' }}>
              <FormattedMessage
                {...getWorkflowStepName(WorkflowStepType.UploadDocuments)}
              />
            </Typography>
          </UploadDocumentsRow>
          <FontAwesomeIcon icon={faUpload} />
        </UploadButton>
      </PaperCard>
    </Grid>
  );
};

export const UploadDocumentsButton = withShowIf(UploadDocumentsButtonComponent);
