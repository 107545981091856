import { Colours } from '@frontline/ui-library';
import {
  createStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';
import styled from 'styled-components';
import { ApprovedDetailsSectionProps } from './approved-details-section-props';

const ApprovedDetailsSection = (props: ApprovedDetailsSectionProps) => {
  const classes = useStyles();
  return (
    <ExpansionPanel classes={{ root: classes.panel }}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon classes={{ root: classes.icon }} />}>
        <SectionHeader>
          <Label>{props.label}</Label>
          <Description>{props.description}</Description>
        </SectionHeader>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails classes={{ root: classes.details }}>
        {props.children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const Label = styled.div<any>`
  height: 16px;
  font-size: 13px;
  font-weight: bold;
`;

const Description = styled.div<any>`
  height: 20px;
  font-size: 14px;
  font-weight: normal;
`;

const SectionHeader = styled.div<any>``;

const useStyles = makeStyles(
  createStyles({
    panel: {
      border: `1px solid ${Colours.PaleBlue}`,
      margin: '20px 0',
      boxShadow: 'none',
    },
    details: {
      display: 'block',
      backgroundColor: Colours.BackgroundColor,
      padding: '24px 24px 12px',
    },
    icon: {
      color: Colours.DeepSkyBlue,
    },
  }),
);

export default ApprovedDetailsSection;
