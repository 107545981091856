import {
  actions as commonActions,
  saveInvoiceDetailsSuccess,
} from '@frontline/web-common';
import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import * as postSubmissionActions from '../../../../../store/post-submission.actions';

const saveInvoiceDetailsSuccessEpic: Epic<Action, Action> = (
  action$: Observable<Action>,
) =>
  action$.pipe(
    filter(isActionOf(saveInvoiceDetailsSuccess)),
    map(action => action.payload),
    mergeMap(application => [
      commonActions.applicationActions.updateApplication(application),
      postSubmissionActions.navigateToNextStep(),
    ]),
  );

export default [saveInvoiceDetailsSuccessEpic];
