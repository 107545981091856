import React from 'react';
import { ApprovedIconDesktop } from './approved-icon-desktop';
import { ApprovedIconMobile } from './approved-icon-mobile';
import { ApprovedIconProps } from './approved-icon-props';

export const ApprovedIcon = (props: ApprovedIconProps) => {
  if (!props.isMobile) {
    return <ApprovedIconDesktop />;
  }

  return <ApprovedIconMobile />;
};
