import {
  muiTheme,
  TypographyColor,
  TypographyVariant,
} from '@frontline/ui-library';
import { Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import PreSubmissionPageLayout from '../../../../../../common/components/PreSubmissionPageLayout/PreSubmissionPageLayout';
import ApplicantSection from '../applicant-section/applicant-section';
import { ApplicantWillBeContactedMessage } from '../applicant-will-be-contacted-message';
import { ApplicationIdHeader } from '../application-id-header/application-id-header';
import { ApprovedContactInfo } from '../approved-contact-info';
import { ApprovedContinueButton } from '../approved-continue-button/approved-continue-button';
import { ApprovedIcon } from '../approved-icon/approved-icon';
import ApprovedLoanAmount from '../approved-loan-amount/approved-loan-amount';
import { ApprovedViewProps } from './approved-view-props';
import messages from './approved-view.messages';

export const ApprovedView = (props: ApprovedViewProps) => {
  const intl = useIntl();
  const approvedAmount = props.loanDetails?.approvedAmount || 0;
  const isMediaExtraSmall = useMediaQuery(muiTheme.breakpoints.down('xs'));

  const hasContactInformation = () =>
    !props.goToPostAfterPresubmission && Boolean(props.ownerContactInfo);

  return (
    <PreSubmissionPageLayout
      formComponent={
        <React.Fragment>
          <ApplicationIdHeader
            showIf={isMediaExtraSmall}
            applicationId={props.applicationId}
          />

          <ApprovedIcon isMobile={isMediaExtraSmall} />

          <Congratulations>
            <Typography
              variant={TypographyVariant.Header2}
              color={TypographyColor.Secondary}>
              <FormattedMessage {...messages.congratulations} />
            </Typography>
          </Congratulations>

          <ApprovedLoanAmount
            showIf={props.goToPostAfterPresubmission}
            amount={approvedAmount}
          />

          <ApplicantWillBeContactedMessage
            showIf={!props.goToPostAfterPresubmission}
          />

          <ApprovedContactInfo
            showIf={hasContactInformation()}
            ownerContactInfo={props.ownerContactInfo}
          />

          <ApplicantSection
            label={intl.formatMessage(messages.applicantSectionLabel)}
            applicant={props.applicant}
            yearsWorkedCategoriesLookup={props.yearsWorkedCategoriesLookup}
          />

          <ApplicantSection
            label={intl.formatMessage(messages.coApplicantSectionLabel)}
            applicant={props.coApplicant}
            yearsWorkedCategoriesLookup={props.yearsWorkedCategoriesLookup}
          />

          <ApprovedContinueButton
            showIf={props.goToPostAfterPresubmission}
            continueClicked={props.continueClicked}
          />
        </React.Fragment>
      }
    />
  );
};

const Congratulations = styled.div`
  text-align: center;
`;
