import { CoApplicantDetailsActionType } from '../common/CoApplicant/store/coApplicantDetails.actions';
import { coApplicantDetailsReducer } from '../common/CoApplicant/store/coApplicantDetails.reducer';
import { ApplicationApprovedActionType } from '../features/result-summary/features/application-approved/store/application-approved.actions';
import { applicationApprovedReducer } from '../features/result-summary/features/application-approved/store/application-approved.reducer';
import { ResultSummaryActionsType } from '../features/result-summary/store/result-summary.actions';
import { resultReducer } from '../features/result-summary/store/result-summary.reducer';
import { ReviewSummaryActionsType } from '../features/ReviewSummary/store/reviewSummary.actions';
import { reviewSummaryReducer } from '../features/ReviewSummary/store/reviewSummary.reducer';
import { PreSubmissionState } from '../types/PreSubmissionState/pre-submission.state';
import { createPreSubmissionState } from '../types/PreSubmissionState/PreSubmissionState.functions';
import {
  PreSubmissionActions,
  PreSubmissionActionType,
} from './pre-submission.actions';

export const preSubmissionReducer = (
  state: PreSubmissionState = createPreSubmissionState(),
  action: PreSubmissionActionType,
) => {
  switch (action.type) {
    case PreSubmissionActions.UpdateWorkflow:
      return {
        ...state,
        workflow: action.payload,
      };
    case PreSubmissionActions.SetActiveStepIndex:
      return {
        ...state,
        activeStepIndex: action.payload,
      };
    case PreSubmissionActions.CreateStepReferenceSuccess:
      return {
        ...state,
        workflowStepReference: action.payload,
      };
    default:
      return {
        ...state,
        applicationApprovedState: applicationApprovedReducer(
          state.applicationApprovedState,
          action as ApplicationApprovedActionType,
        ),
        coApplicantDetailsState: coApplicantDetailsReducer(
          state.coApplicantDetailsState,
          action as CoApplicantDetailsActionType,
        ),
        reviewSummaryState: reviewSummaryReducer(
          state.reviewSummaryState,
          action as ReviewSummaryActionsType,
        ),
        resultState: resultReducer(
          state.resultSummaryState,
          action as ResultSummaryActionsType,
        ),
      };
  }
};
