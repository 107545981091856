import { Colours, LoaderWithMessages } from '@frontline/ui-library';
import React from 'react';
import styled from 'styled-components';
import messages from './result-summary-view.messages';

export const ResultSummaryView = () => {
  return (
    <Content>
      <LoaderWithMessages messages={messages} />
    </Content>
  );
};

const Content = styled.div`
  background-color: ${Colours.PrimaryColorInverted};
  min-height: 600px;
`;
