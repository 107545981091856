import { ApplicantApplicationOptionDto } from '@frontline/common';
import { HttpError } from '@frontline/web-common';
import { ActionType, createAction } from 'typesafe-actions';

export enum ApplicantApplicationOptionsActions {
  FetchApplicantApplicationOptionForApplication = 'ApplicantApplicationOption/FetchApplicantApplicationOptionForApplication',
  FetchApplicantApplicationOptionForLoanCalculation = 'ApplicantApplicationOption/FetchApplicantApplicationOptionForLoanCalculation',
  FetchApplicantApplicationOptionSuccess = 'ApplicantApplicationOption/FetchApplicantApplicationOptionSuccess',
  FetchApplicantApplicationOptionFailure = 'ApplicantApplicationOption/FetchApplicantApplicationOptionFailure',
}

export const fetchApplicantApplicationOptionForApplication = createAction(
  ApplicantApplicationOptionsActions.FetchApplicantApplicationOptionForApplication,
  action => (applicationId: string) => action(applicationId),
);

export const fetchApplicantApplicationOptionForLoanCalculation = createAction(
  ApplicantApplicationOptionsActions.FetchApplicantApplicationOptionForLoanCalculation,
);

export const fetchApplicantApplicationOptionSuccess = createAction(
  ApplicantApplicationOptionsActions.FetchApplicantApplicationOptionSuccess,
  action => (option: ApplicantApplicationOptionDto, applicationId: string) =>
    action({ option, applicationId }),
);

export const fetchApplicantApplicationOptionFailure = createAction(
  ApplicantApplicationOptionsActions.FetchApplicantApplicationOptionFailure,
  action => (error: HttpError) => action(error),
);

const actionCreators = {
  fetchApplicantApplicationOptionForApplication,
  fetchApplicantApplicationOptionForLoanCalculation,
  fetchApplicantApplicationOptionSuccess,
  fetchApplicantApplicationOptionFailure,
};

export type ApplicantApplicationOptionsActionType = ActionType<
  typeof actionCreators
>;
