import { AddOn } from '@frontline/common';
import {
  FormatMoney,
  ShowIfFragment,
  TypographyVariant,
} from '@frontline/ui-library';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import NumberFormat from 'react-number-format';
import { getThirdPartyAddOnType } from './addons-table-body.functions';
import { AddOnTableBodyProps } from './addons-table-body.props';

export const AddOnTableBody = (props: AddOnTableBodyProps) => {
  return (
    <TableBody>
      {props.addOns.map((addOn: AddOn, index: number) => (
        <TableRow key={`${addOn.typeId}-${index}`}>
          <TableCell component="th">
            <Typography variant={TypographyVariant.Body1}>
              {index + 1}
            </Typography>
          </TableCell>
          <TableCell component="th">
            <Typography variant={TypographyVariant.Body1}>
              {getThirdPartyAddOnType(addOn, props.addOnTypes).name}
            </Typography>
          </TableCell>
          <TableCell component="th">
            <Typography variant={TypographyVariant.Body1}>
              {addOn.description}
            </Typography>
          </TableCell>
          <ShowIfFragment showIf={props.taxEnabled}>
            <TableCell component="th">
              <Typography variant={TypographyVariant.Body1}>
                <FormatMoney value={addOn.preTaxValue} />
              </Typography>
            </TableCell>
          </ShowIfFragment>
          <ShowIfFragment showIf={props.taxEnabled}>
            <TableCell component="th">
              <Typography variant={TypographyVariant.Body1}>
                <NumberFormat
                  value={addOn.taxRate! * 100}
                  displayType={'text'}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                  suffix={'%'}
                />
              </Typography>
            </TableCell>
          </ShowIfFragment>
          <TableCell component="th">
            <Typography variant={TypographyVariant.Body1}>
              <FormatMoney value={addOn.amount} />
            </Typography>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
