import { FormikValues } from 'formik';
import { Answer } from '../../../types/questions/answer.interface';
import { QuestionType } from '../../../types/questions/question-type.enum';

export const mapSecurityQuestionFormValuesToAnswers = (
  values: FormikValues,
): Answer[] => {
  const answers: Answer[] = [];

  Object.entries(values).forEach(([key, value]) => {
    answers.push({
      questionType: key as QuestionType,
      answer: value,
    });
  });

  return answers;
};

export const createInitialSecurityFormValues = (questions: QuestionType[]) =>
  questions.reduce(
    (formValues: any, key: string) => ({ ...formValues, [key]: '' }),
    {},
  );
