import {
  allCompleted,
  ApplicantApplicationOptionDto,
  isUploadDocumentsSubmittedAndRestComplete,
  WorkflowStep,
} from '@frontline/common';
import { isUploadDocumentsVisible } from '../../PostSubmissionReview/types/workflow.functions';

export function allStepsCompleted(
  workflowSteps: WorkflowStep[],
  uploadDocsStep?: WorkflowStep,
  applicantApplicationOptions?: ApplicantApplicationOptionDto,
): boolean {
  if (uploadDocsStep && isUploadDocumentsVisible(applicantApplicationOptions)) {
    return (
      allCompleted([...workflowSteps, uploadDocsStep]) ||
      isUploadDocumentsSubmittedAndRestComplete([
        ...workflowSteps,
        uploadDocsStep,
      ])
    );
  }
  return allCompleted(workflowSteps);
}
