import { ProgramType } from '@frontline/common';
import { StepMessageDefinitionOptions } from './StepMessageDefinitionOptions';

export function createStepMessageDefinitionOptions(
  programType?: ProgramType,
): StepMessageDefinitionOptions {
  const options = {} as StepMessageDefinitionOptions;
  if (programType === ProgramType.RepairTech) {
    options.finalizePaymentPlan = true;
    options.confirmInvoiceDetails = true;
  }
  return options;
}
