import { RadioButtonWithIcon } from '@frontline/ui-library';
import { ApplicationStepsHeader } from '@frontline/web-common';
import { Grid } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import VoidChequeActiveImg from '../../../../../../../../../../assets/void-cheque-active.svg';
import VoidChequeDefaultImg from '../../../../../../../../../../assets/void-cheque-default.svg';
import { PostSubmissionContentCard } from '../../../../components/post-submission-content-card';
import { PaymentOptions } from '../../types';
import { VoidChequeForm } from '../void-cheque-form/void-cheque-form';

const messages = defineMessages({
  title: {
    id: 'PaymentDetails.title',
    description: 'Payment details step title',
    defaultMessage: 'Payment details',
  },
  description: {
    id: 'PaymentDetails.description',
    description: 'Payment details step description',
    defaultMessage:
      'Please provide your banking details where the loan payments will be debited.\n\nBy completing this step, you acknowledge that the bank details provided will be used to debit your regular scheduled loan payments as set out in your Credit Agreement and used to verify that you are the registered owner of the bank account.',
  },
  cheque: {
    id: 'PaymentDetails.cheque',
    description: 'Payment details choice: void cheque',
    defaultMessage: 'Void cheque',
  },
});

export const PaymentDetails: FunctionComponent = () => {
  const intl = useIntl();
  const [selectedPayment, setSelectedPayment] = useState(PaymentOptions.Cheque);
  return (
    <PostSubmissionContentCard>
      <ApplicationStepsHeader
        title={intl.formatMessage(messages.title)}
        description={intl.formatMessage(messages.description)}
      />
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12} sm={6}>
          <RadioButtonWithIcon
            label={intl.formatMessage(
              messages[PaymentOptions.Cheque.toLowerCase()],
            )}
            handleClick={() => setSelectedPayment(PaymentOptions.Cheque)}
            selected={selectedPayment === PaymentOptions.Cheque}
            defaultImg={VoidChequeDefaultImg}
            activeImg={VoidChequeActiveImg}
          />
        </Grid>
      </Grid>
      <VoidChequeForm />
    </PostSubmissionContentCard>
  );
};
