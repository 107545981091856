import {
  getFirstIncompleteStep,
  WorkflowStep,
  WorkflowStepType,
} from '@frontline/common';

export const defaultStepTypeForStepper = (
  steps: WorkflowStep[],
): WorkflowStepType | null => {
  const defaultStep = getFirstIncompleteStep(steps) || steps[0];
  return defaultStep?.type || null;
};
