import { BankDetails } from '@frontline/common';
import { ShowIfFragment } from '@frontline/ui-library';
import {
  UploadVoidCheque,
  VoidChequeExample,
  VoidChequeFormView,
  VoidChequeHeader,
} from '@frontline/web-common';
import { Formik } from 'formik';
import React, { Fragment, FunctionComponent, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as applicationStore from '../../../../../../../../store/application.store';
import * as postSubmissionReviewStore from '../../../../../../store/post-submission.store';
import * as store from '../../store';
import {
  createVoidChequeFormValidator,
  initializeVoidChequeFormValues,
} from '../../types/voidChequeForm';
import { isDeleteAttachedChequeEnabled } from '../../types/voidChequeForm/void-cheque-form.functions';
import { VoidChequeErrorContainer } from './void-cheque-error-container.component';
import { messages } from './void-cheque-form.messages';

export const VoidChequeForm: FunctionComponent<{}> = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isValid, setIsValid] = useState(true);
  const [showCheque, setShowCheque] = useState(false);

  const storedDocuments = useSelector(store.getApplicationDocuments);
  const paymentDetails = useSelector(store.getPaymentDetails);
  const voidChequeDocuments = useSelector(store.getVoidChequeDocuments);
  const uploadingDocuments = useSelector(store.getUploadingDocuments);
  const isSaving = useSelector(store.getIsSaving);
  const disabled = useSelector(store.getDisabled);
  const application = useSelector(applicationStore.getApplication);

  const canDeleteAttachDocument = useMemo<boolean>(() => {
    if (application) {
      return isDeleteAttachedChequeEnabled(application.status);
    }
    return false;
  }, [application]);

  const validate = () => {
    if (voidChequeDocuments.length) {
      return {};
    }
    setIsValid(false);
    return {
      voidChequeDocuments: intl.formatMessage(messages.voidChequeRequired),
    };
  };
  const initialValues = initializeVoidChequeFormValues(paymentDetails);
  const ValidationSchema = createVoidChequeFormValidator(intl);
  const saveDetails = (bankDetails: BankDetails) =>
    store.savePaymentDetails(dispatch)(
      bankDetails,
      initialValues,
      storedDocuments,
      intl.formatMessage(messages.attachDocumentErrorMessage),
    );

  if (!isValid && voidChequeDocuments.length) {
    setIsValid(true);
  }

  return (
    <Fragment>
      <UploadVoidCheque
        voidChequeDocuments={voidChequeDocuments}
        uploadingDocuments={uploadingDocuments}
        canDeleteAttachedDocument={canDeleteAttachDocument}
      />

      <ShowIfFragment showIf={!isValid}>
        <VoidChequeErrorContainer>
          <FormattedMessage {...messages.voidChequeRequired} />
        </VoidChequeErrorContainer>
      </ShowIfFragment>

      <VoidChequeHeader
        show={showCheque}
        onToggleShow={() => setShowCheque(showing => !showing)}
      />
      <VoidChequeExample show={showCheque} />
      <Formik
        validationSchema={ValidationSchema}
        validate={validate}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={saveDetails}
        render={({ submitForm }) => (
          <VoidChequeFormView
            disabled={disabled}
            isSaving={isSaving}
            onSubmit={submitForm}
            onBack={postSubmissionReviewStore.navigateToPreviousStep(dispatch)}
          />
        )}
      />
    </Fragment>
  );
};
