import { defineMessages } from 'react-intl';

export const TradeInViewMessages = defineMessages({
  tradeInTitle: {
    id:
      'Applicant.PostSubmission.AssetsAndAddOns.AssetsAndAddOnsView.tradeInTitle',
  },
  tradeInAmount: {
    id:
      'Applicant.PostSubmission.AssetsAndAddOns.AssetsAndAddOnsView.tradeInAmount',
  },
  taxRate: {
    id: 'Applicant.PostSubmission.AssetsAndAddOns.AssetsAndAddOnsView.taxRate',
  },
});
