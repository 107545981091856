import { AddOn, Asset } from '@frontline/common';

export function getSumOfAssetsPreTaxCost(assets: Asset[]): number {
  return assets.reduce((acc, asset) => acc + (asset.preTaxCost || 0), 0);
}

export function getSumOfAddOnsCost(addOns: AddOn[]): number {
  return addOns.reduce((acc, addOn) => acc + (addOn.amount || 0), 0);
}

export function getSumOfAddOnsPreTaxCost(addOns: AddOn[]): number {
  return addOns.reduce((acc, addOn) => acc + (addOn.preTaxValue || 0), 0);
}

export function getTotalAssetsAndTradeInPreTaxCost(
  assets: Asset[],
  tradeInAmount: number = 0,
): number {
  return getSumOfAssetsPreTaxCost(assets) - tradeInAmount;
}

export function getTotalAssetsAndTradeWithTax(
  assets: Asset[],
  tradeInAmount: number = 0,
  taxRate: number = 0,
): number {
  return getTotalCostWithTax(
    getTotalAssetsAndTradeInPreTaxCost(assets, tradeInAmount),
    taxRate,
  );
}

export function getTotalAssetsAndTradeInAndAddonsWithTax(
  assets: Asset[],
  addOns: AddOn[],
  tradeInAmount: number = 0,
  taxRate: number = 0,
): number {
  return getTotalCostWithTax(
    getTotalAssetsAndTradeInPreTaxCost(assets, tradeInAmount) +
      getSumOfAddOnsPreTaxCost(addOns),
    taxRate,
  );
}

export function getTotalCostWithTax(
  totalCost: number,
  taxRate: number = 0,
): number {
  return Math.round(totalCost * (1 + taxRate) * 100) / 100;
}
