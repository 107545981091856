import { TypographyColor, TypographyVariant } from '@frontline/ui-library';
import { ButtonBaseProps } from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { CloseIconContainer } from './CloseIconContainer';
import messages from './exit-button.messages';
import { ExitButtonBase } from './exit-button.styles';

export const ExitButton: FunctionComponent<ButtonBaseProps> = (
  props: ButtonBaseProps,
) => {
  const intl = useIntl();
  return (
    <ExitButtonBase onClick={props.onClick}>
      <Grid container={true} alignItems="center">
        <ArrowBackIcon color="primary" fontSize="small" />
        <Typography
          noWrap={true}
          variant={TypographyVariant.Header5}
          color={TypographyColor.Primary}>
          {intl.formatMessage(messages.overviewButton)}
        </Typography>
      </Grid>
      <CloseIconContainer>
        <CloseIcon />
      </CloseIconContainer>
    </ExitButtonBase>
  );
};
