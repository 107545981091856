import { WorkflowStepType } from '@frontline/common';
import { withShowIf } from '@frontline/ui-library';
import { SummaryCard } from '@frontline/web-common';
import Grid from '@material-ui/core/Grid';
import startCase from 'lodash/startCase';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getStepMessageDefinition } from '../../../../types';
import * as overviewStore from '../../store/overview.store';
import { defaultStepTypeForStepper } from '../../types';
import { OverviewDefaultTabView } from '../OverviewDefaultTabView';
import OverviewHeader from '../OverviewHeader/OverviewHeader';
import { OverviewTabs } from '../OverviewTabs';
import { OverviewViewProps } from './overview-view.props';

export const OverviewView = withShowIf((props: OverviewViewProps) => {
  const intl = useIntl();

  const getStepNameByStepType = (stepType: WorkflowStepType): string => {
    return intl.formatMessage(
      getStepMessageDefinition(stepType, props.application?.program?.type),
      {
        applicantName: props.applicantFirstName,
        coApplicantName: props.coApplicantFirstName,
      },
    );
  };

  return (
    <Grid container={true} spacing={2}>
      <OverviewHeader xs={12} applicationId={props.applicationId} />
      <Grid item={true} xs={12}>
        <OverviewTabs
          value={useSelector(overviewStore.getOverviewTab)}
          onChange={props.handleTabClick}
        />
        <SummaryCard
          firstName={props.applicantFirstName}
          lastName={props.applicantLastName}
          loanDetails={props.loanDetails}
          showSummaryDetails={false}
        />
      </Grid>
      <OverviewDefaultTabView
        showIf={props.overviewTab === 'overview'}
        stepsAreCompleted={props.stepsAreCompleted}
        merchantName={startCase(props.merchantName)}
        defaultStepType={defaultStepTypeForStepper(props.workflow)}
        getStepNameByStepType={getStepNameByStepType}
        stepsForStepper={props.workflow}
        uploadDocsStep={props.uploadDocStep}
        applicantApplicationOptions={props.applicantApplicationOptions}
      />
    </Grid>
  );
});
