import { defineMessages } from 'react-intl';

export default defineMessages({
  programLabel: {
    id: 'Applicant.PurchaseDetails.PurchaseDetailsFormFields.programLabel',
    description: 'Program Field Label',
    defaultMessage: 'Financial Program',
  },
  programPlaceholder: {
    id:
      'Applicant.PurchaseDetails.PurchaseDetailsFormFields.programPlaceholder',
    description: 'Program Field Placeholder',
    defaultMessage: 'Select',
  },
  itemPurchasedLabel: {
    id:
      'Applicant.PurchaseDetails.PurchaseDetailsFormFields.itemPurchasedLabel',
    description: 'Item Purchased Field Label',
    defaultMessage: 'Product/Service Type',
  },
  itemPurchasedPlaceholder: {
    id:
      'Applicant.PurchaseDetails.PurchaseDetailsFormFields.itemPurchasedPlaceholder',
    description: 'Item Purchased Field Placeholder',
    defaultMessage: 'Select',
  },
  purchaseAmountLabel: {
    id:
      'Applicant.PurchaseDetails.PurchaseDetailsFormFields.purchaseAmountLabel',
    description: 'Purchase Amount Field Label',
    defaultMessage: 'Requested Amount (cost incl. merchant fees and taxes)',
  },
  programIdRequired: {
    id: 'Applicant.PurchaseDetails.PurchaseDetailsFormFields.programIdRequired',
    description: 'Purchase Amount Field Required',
    defaultMessage: 'Please select a program',
  },
  assetSubTypeRequired: {
    id:
      'Applicant.PurchaseDetails.PurchaseDetailsFormFields.assetSubTypeRequired',
    description: 'Item Purchased Field Required',
    defaultMessage: 'Please select item being purchased',
  },
});
