import { ApplicationStatusBanner as StatusBanner } from '@frontline/web-common';
import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import messages from './ApplicationStatusBanner.messages';
import { ApplicationStatusBannerProps } from './ApplicationStatusBannerProps';

export const ApplicationStatusBanner: FunctionComponent<ApplicationStatusBannerProps> = (
  props: ApplicationStatusBannerProps,
) => {
  const intl = useIntl();
  const merchantName =
    props.merchantName || intl.formatMessage(messages.defaultMerchantName);
  const description = props.stepsAreComplete
    ? intl.formatMessage(messages.complete, { merchantName })
    : intl.formatMessage(messages.applicationIsIncomplete);

  return (
    <StatusBanner
      type={props.stepsAreComplete ? 'success' : 'warning'}
      description={description}
    />
  );
};
