import { Loader } from '@frontline/ui-library';
import queryString from 'query-string';
import React, { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  RouteComponentProps,
  useLocation,
} from 'react-router';
import * as securityQuestionsStore from '../../../security-questions/store/security-questions.store';
import * as authStore from '../../store/auth.store';
import {
  appendQueryParametersToPathExcludingAuthentication,
  createAuthenticationQuery,
} from '../../types/AuthenticationQuery/AuthenticationQuery.functions';
import { ProtectedRouteProps } from './ProtectedRouteProps';

export const ProtectedRoute = ({
  component: Component,
  securityRoles,
  ...rest
}: ProtectedRouteProps<any>) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const isInitialized = useSelector(authStore.isInitialized);
  const isAuthenticated = useSelector(authStore.isAuthenticated);
  const securityQuestions = useSelector(
    securityQuestionsStore.getSecurityQuestions,
  );
  const isLoading = useSelector(authStore.isLoading);

  const error = useSelector(authStore.getError);

  useEffect(() => {
    if (!isInitialized) {
      const queryParams = queryString.parse(location.search);
      const path = appendQueryParametersToPathExcludingAuthentication(
        location.pathname,
        queryParams,
      );
      authStore.init(dispatch)(createAuthenticationQuery(queryParams), path);
    }
  }, [dispatch, location, isInitialized]);

  const render = (props: RouteComponentProps<any>): ReactNode => {
    if (isLoading) {
      return <Loader />;
    }

    if (isAuthenticated) {
      return <Component {...props} />;
    }

    if (error) {
      return <Redirect to="/not-authenticated" />;
    }

    if (securityQuestions) {
      return (
        <Redirect
          to={{
            pathname: '/security-questions',
            state: {
              securityQuestionsGroupId: securityQuestions.id,
              securityQuestions: securityQuestions.questions,
            },
          }}
        />
      );
    }
    return null;
  };

  return <Route {...rest} render={render} />;
};
