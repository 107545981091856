import {
  PrimaryButton,
  SecondaryButton,
  styledTheme,
} from '@frontline/ui-library';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const ActionsGrid = styled(Grid)`
  && {
    margin-top: 30px;
  }
`;

export const BackButtonGrid = styled(Grid)(
  (props: { order: string }) => `
    order: ${props.order || 'none'};
`,
);

export const BackButton = styled(SecondaryButton)(
  (props: { width: string }) => `
    color: ${styledTheme.deepSkyBlue};
    width: ${props.width || '180px'};
    margin: 0;
`,
);
export const SaveAndExitButton = BackButton;

export const Button = styled(PrimaryButton)(
  (props: { width: string }) => `
    width: ${props.width || '180px'};
    margin: 0;
`,
);
