import {
  PreSubmissionFormType,
  WorkflowStep,
  WorkflowStepReference,
} from '@frontline/common';
import { ActionType, createAction } from 'typesafe-actions';
import { CoApplicantDetailsActionType } from '../common/CoApplicant/store/coApplicantDetails.actions';
import { ApplicationApprovedActionType } from '../features/result-summary/features/application-approved/store/application-approved.actions';
import { ResultSummaryActionsType } from '../features/result-summary/store/result-summary.actions';
import { ReviewSummaryActionsType } from '../features/ReviewSummary/store/reviewSummary.actions';

export enum PreSubmissionActions {
  CreateStepReference = 'PreSubmission/CreateStepReference',
  CreateStepReferenceSuccess = 'PreSubmission/CreateStepReferenceSuccess',
  LoadWorkflow = 'PreSubmission/LoadWorkflow',
  UpdateWorkflow = 'PreSubmission/UpdateWorkflow',
  SetActiveStepIndex = 'PreSubmission/SetActiveStepIndex',
  ClickStep = 'PreSubmission/ClickStep',
  NextStep = 'PreSubmission/NextStep',
  SaveStep = 'PreSubmission/Save',
  SetActiveStep = 'PreSubmission/SetActiveStep',
}

export const updateWorkflow = createAction(
  PreSubmissionActions.UpdateWorkflow,
  action => (workflow: WorkflowStep[]) => action(workflow),
);

export const saveStep = createAction(
  PreSubmissionActions.SaveStep,
  action => (formData: PreSubmissionFormType) => action(formData),
);

export const createStepReference = createAction(
  PreSubmissionActions.CreateStepReference,
  action => (routeUrl: string) => action(routeUrl),
);

export const createStepReferenceSuccess = createAction(
  PreSubmissionActions.CreateStepReferenceSuccess,
  action => (stepReference?: WorkflowStepReference) => action(stepReference),
);

export const nextStep = createAction(PreSubmissionActions.NextStep);

export const loadWorkflow = createAction(
  PreSubmissionActions.LoadWorkflow,
  action => (activeStep?: WorkflowStep) => action(activeStep),
);

export const setActiveStepIndex = createAction(
  PreSubmissionActions.SetActiveStepIndex,
  action => (index: number) => action(index),
);

export const setActiveStep = createAction(
  PreSubmissionActions.SetActiveStep,
  action => (routeUrl: string) => action(routeUrl),
);

const PreSubmissionActionCreators = {
  loadWorkflow,
  updateWorkflow,
  setActiveStepIndex,
  nextStep,
  createStepReference,
  createStepReferenceSuccess,
  setActiveStep,
};

export type PreSubmissionActionType =
  | ActionType<typeof PreSubmissionActionCreators>
  | CoApplicantDetailsActionType
  | ReviewSummaryActionsType
  | ResultSummaryActionsType
  | ApplicationApprovedActionType;
