import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  buttonLabel: {
    id: 'Applicant.SecurityQuestions.QuestionsDialogFormView.buttonLabel',
    description: 'Questions dialog button label',
    defaultMessage: 'Submit',
  },

  applicantPrimaryPhoneLast4DigitsQuestion: {
    id:
      'Applicant.SecurityQuestions.QuestionsDialogFormView.applicant4DigitCodeQuestion',
    description: 'Questions dialog 4 digit code',
    defaultMessage: 'What is the last 4 digits of your primary phone number?',
  },

  applicantPrimaryPhoneLast4DigitsPlaceholder: {
    id:
      'Applicant.SecurityQuestions.QuestionsDialogFormView.applicant4DigitCodePlaceholder',
    description: 'Questions dialog 4 digit code placeholder',
  },

  requiredField: {
    id: 'Applicant.SecurityQuestions.QuestionsDialogFormView.requiredField',
    description: 'Questions dialog required field',
    defaultMessage: 'Please enter a valid answer',
  },
});
