import { HttpService } from '@frontline/web-common';
import { AxiosResponse } from 'axios';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Answer } from '../../../types/questions/answer.interface';
import { QuestionGroup } from '../../../types/questions/question-group.interface';
import { createSecurityQuestionsError } from '../types/security-questions-error/security-questions-error.functions';

export function fetchSecurityQuestions(
  accessKey: string,
): Observable<QuestionGroup> {
  return from(
    HttpService.getHttpInstanceDeprecated()
      .get(`/applicant/login/questions?accessKey=${accessKey}`)
      .then((response: AxiosResponse<QuestionGroup>) => response.data),
  );
}

function submitSecurityQuestionsAnswers(
  answers: Answer[],
  questionGroupId: string,
  accessKey: string,
): Observable<any> {
  return from(
    HttpService.getHttpInstanceDeprecated().post(`/applicant/login/questions`, {
      accessKey,
      questionGroupId,
      answers,
    }),
  ).pipe(map(response => response.data));
}

export function answerSecurityQuestions(
  answers: Answer[],
  questionGroupId: string,
  numberOfAttemptsMade: number,
  accessKey: string,
): Observable<any> {
  return submitSecurityQuestionsAnswers(
    answers,
    questionGroupId,
    accessKey,
  ).pipe(
    catchError(error =>
      throwError(createSecurityQuestionsError(numberOfAttemptsMade, error)),
    ),
  );
}
