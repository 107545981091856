import { useUatId } from '@frontline/ui-library';
import { Grid } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { ApplicantDetailsReview } from '../applicant-details-review';
import { ReviewSummaryViewUat } from '../review-summary-view/review-summary-view.uat';
import { SubmitButton } from '../submit-button';
import messages from './review-summary-body.messages';
import { ReviewSummaryBodyProps } from './review-summary-body.props';

export const ReviewSummaryBody: FunctionComponent<ReviewSummaryBodyProps> = (
  props: ReviewSummaryBodyProps,
) => {
  const uatId = useUatId();

  return (
    <Grid container={true} spacing={4}>
      <Grid item={true} xs={12}>
        <ApplicantDetailsReview
          isCoApplicant={false}
          titleMessageDescriptor={messages.primaryApplicantTitle}
          applicantDetails={props.applicantDetails}
          additionalDetailsTitle={messages.additionalDetailsTitle}
          additionalDetailsEditUrl={props.additionalDetailsEditUrl}
          incomeDetailsTitle={messages.incomeDetailsTitle}
          incomeDetailsEditUrl={props.incomeDetailsEditUrl}
          personalDetailsTitle={messages.personalDetailsTitle}
          personalDetailsEditUrl={props.personalDetailsEditUrl}
          yearsWorkedCategoriesLookup={props.yearsWorkedCategoriesLookup}
        />
      </Grid>
      <Grid item={true} xs={12}>
        <ApplicantDetailsReview
          isCoApplicant={true}
          titleMessageDescriptor={messages.coApplicantTitle}
          applicantDetails={props.coApplicantDetails}
          additionalDetailsTitle={messages.additionalDetailsTitleCoApplicant}
          additionalDetailsEditUrl={props.coApplicantAdditionalDetailsEditUrl}
          incomeDetailsTitle={messages.incomeDetailsTitleCoApplicant}
          incomeDetailsEditUrl={props.coApplicantIncomeDetailsEditUrl}
          personalDetailsTitle={messages.personalDetailsTitleCoApplicant}
          personalDetailsEditUrl={props.coApplicantPersonalDetailsEditUrl}
          yearsWorkedCategoriesLookup={props.yearsWorkedCategoriesLookup}
        />
        <Grid container={true} justify="flex-end">
          <SubmitButton
            onSubmit={props.onSubmit}
            uat={uatId(ReviewSummaryViewUat.submitButton)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
