import { getThirdPartyAddOnTypes } from '@frontline/web-common';
import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootState } from '../../../../../../../../../store/reducers';
import * as actions from './thirdPartyAddOns.actions';

export const fetchTypesEpic: Epic<Action, Action, RootState> = (
  action$: Observable<Action>,
  state$: any,
) =>
  action$.pipe(
    filter(isActionOf(actions.fetchTypes)),
    switchMap(() =>
      from(getThirdPartyAddOnTypes()).pipe(
        map(types => actions.fetchTypesSuccess(types)),
        catchError(error => of(actions.fetchTypesFailure(error))),
      ),
    ),
  );

export default [fetchTypesEpic];
