import {
  AdditionalDetails,
  shouldSaveAdditionalDetails,
} from '@frontline/common';
import { LocaleContext } from '@frontline/web-common';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as yearsWorkedCategoryStore from '../../../../../../modules/lookup/years-worked-category/years-worked-category.store';
import * as additionalDetailsStore from '../../../../common/AdditionalDetails/store';
import * as applicationStore from '../../../../store/application.store';
import { PreSubmissionFormPropsType } from '../../types/PreSubmissionForm/PreSubmissionFormPropsType';
import { AdditionalDetailsView } from './components/AdditionalDetailsView/additional-details-view.component';

export const AdditionalDetailsFeature = (
  props: PreSubmissionFormPropsType<AdditionalDetails>,
) => {
  const localeContext = useContext(LocaleContext);
  const additionalDetails = useSelector(
    additionalDetailsStore.getAdditionalDetails,
  );

  const yearsWorkedCategories = useSelector(
    yearsWorkedCategoryStore.getYearsWorkedCategoriesDropdown,
  );

  useEffect(() => {
    yearsWorkedCategoryStore.fetchYearsWorkedCategories();
  }, [localeContext.locale]);

  const onSubmit = (changes: AdditionalDetails) => {
    props.onSubmit(
      changes,
      shouldSaveAdditionalDetails(props.stepReference, additionalDetails),
    );
  };

  return (
    <AdditionalDetailsView
      additionalDetails={additionalDetails}
      onSubmit={onSubmit}
      onBackButtonClick={props.onBack}
      yearsWorkedCategoriesLookup={yearsWorkedCategories || []}
      personalDetails={useSelector(
        applicationStore.getApplicantPersonalDetails,
      )}
    />
  );
};
