import { withShowIf } from '@frontline/ui-library';
import Grid from '@material-ui/core/Grid';
import React, { Fragment } from 'react';
import { UploadDocumentsButton } from '../../../../common/UploadDocumentsButton/upload-documents-button.component';
import { isUploadDocumentsVisible } from '../../../PostSubmissionReview/types/workflow.functions';
import { ApplicationStatusBanner } from '../ApplicationStatusBanner';
import { OverviewStepper } from '../overview-stepper';
import { OverviewDefaultTabViewProps } from './OverviewDefaultTabViewProps';

export const OverviewDefaultTabView = withShowIf(
  (props: OverviewDefaultTabViewProps) => {
    return (
      <Fragment>
        <Grid item={true} xs={12}>
          <ApplicationStatusBanner
            stepsAreComplete={props.stepsAreCompleted}
            merchantName={props.merchantName}
          />
          <OverviewStepper
            defaultStepType={props.defaultStepType}
            getStepNameByStepType={props.getStepNameByStepType}
            xs={12}
            steps={props.stepsForStepper}
          />
        </Grid>
        <UploadDocumentsButton
          xs={12}
          uploadDocsStep={props.uploadDocsStep}
          showIf={isUploadDocumentsVisible(props.applicantApplicationOptions)}
        />
      </Fragment>
    );
  },
);
