import { defineMessages } from 'react-intl';

export const ApplicationTakeoverAcceptedMessages = defineMessages({
  title: {
    id: 'Applicant.ApplicationTakeover.Accepted.title',
    description: 'Application Takeover accepted page title',
    defaultMessage:
      '{status}, your pre-qualification has been sent to {merchantName} so you can finalize your purchase.',
  },
  description: {
    id: 'Applicant.ApplicationTakeover.Accepted.description',
    description: 'Application Takeover accepted page next step description',
    defaultMessage:
      'You are now ready to use your pre-qualification to finalize a purchase with this business. They will work with you to finalize all remaining steps or requirements needed to apply the pre-qualification to your purchase.',
  },
  nextStep: {
    id: 'Applicant.ApplicationTakeover.Accepted.nextStep',
    description: 'Application Takeover accepted page next step title',
    defaultMessage: 'Next Steps',
  },
  status: {
    id: 'Applicant.ApplicationTakeover.Accepted.status',
    description: 'Application Takeover accepted page status',
    defaultMessage: 'Confirmed',
  },
});
