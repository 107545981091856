import {
  ApplicationStepsHeader,
  useApplicantName,
} from '@frontline/web-common';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { IncomeDetailsForm } from '../../../../../../../../../modules/income-details';
import * as postSubmissionStore from '../../../../../../../store/post-submission.store';
import { ApplicantDetailsContext } from '../../../ApplicantDetailsContext';
import messages from './ApplicantIncomeDetails.messages';

export const ApplicantIncomeDetails = (props: { disabled: boolean }) => {
  const applicantName = useApplicantName({});
  const intl = useIntl();
  const dispatch = useDispatch();
  const { applicant } = useContext(ApplicantDetailsContext);

  return (
    <React.Fragment>
      <ApplicationStepsHeader
        title={intl.formatMessage(messages.title, { applicantName })}
        description={intl.formatMessage(messages.description, {
          applicantName,
        })}
      />
      <IncomeDetailsForm
        disabled={props.disabled}
        incomeDetails={applicant?.incomeDetails}
        authorizedElectronicCommunication={Boolean(
          applicant?.authorizedElectronicCommunication,
        )}
        onSubmit={postSubmissionStore.navigateToOverview(dispatch)}
      />
    </React.Fragment>
  );
};
