import { useUatId } from '@frontline/ui-library';
import { ApplicationStepsFooter, MessageValues } from '@frontline/web-common';
import { Form } from 'formik';
import React, { useCallback, useContext, useEffect } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { PaymentCalculatorEstimateContext } from '../../../../contexts/PaymentCalculatorEstimateContext';
import { LoanDetailsUat } from '../../components/loan-details/loan-details.uat';
import { FormikErrorType } from '../../types/formik-error-message-function-type';
import { LoanDetailsPaymentPlan } from '../loan-details-payment-plan/components/loan-details-payment-plan';
import messages from './loan-details-form.messages';
import { LoanDetailsFormProps } from './loan-details-form.props';

export const LoanDetailsForm = (props: LoanDetailsFormProps) => {
  const intl = useIntl();
  const uatId = useUatId();
  const { setFieldValue, formValues } = props;
  const {
    fetchEstimatedPayment,
    paymentCalculatorRequestValues,
    estimatedPayment,
  } = useContext(PaymentCalculatorEstimateContext);

  useEffect(() => {
    if (fetchEstimatedPayment && paymentCalculatorRequestValues) {
      fetchEstimatedPayment({
        ...paymentCalculatorRequestValues,
        firstPaymentDate: formValues.firstPaymentDate,
        loanAmount: formValues.loanAmount,
        frequencyId: formValues.frequencyId,
        tgiInsuranceEnrollment: formValues.tgiInsuranceEnrollment,
      });
    }
  }, [
    fetchEstimatedPayment,
    paymentCalculatorRequestValues,
    formValues.loanAmount,
    formValues.frequencyId,
    formValues.firstPaymentDate,
    formValues.tgiInsuranceEnrollment,
  ]);

  useEffect(() => {
    if (estimatedPayment?.financedAmount) {
      setFieldValue('financeAmount', estimatedPayment?.financedAmount);
    }
    if (estimatedPayment?.payment) {
      setFieldValue('payment', estimatedPayment?.payment);
    }
  }, [estimatedPayment, setFieldValue]);

  const message = useCallback(
    (messageId: MessageDescriptor, values?: MessageValues) => {
      return intl.formatMessage(messageId, values);
    },
    [intl],
  );

  const errorMessage = useCallback(
    (error: FormikErrorType, values?: MessageValues) => {
      const typedMessages = messages as MessageValues;
      const msg = typedMessages[error as string];
      return msg ? message(msg, values) : '';
    },
    [messages],
  );

  return (
    <Form>
      <LoanDetailsPaymentPlan
        setFieldValue={setFieldValue}
        formValues={formValues}
        fees={props.loanDetails.fees}
        rate={props.loanDetails.rate}
        term={props.loanDetails.term}
        amort={props.loanDetails.amort}
        deferral={props.loanDetails.deferral}
        frequencyId={props.loanDetails.frequencyId}
        contractDate={props.loanDetails.contractDate}
        firstPaymentDate={props.loanDetails.firstPaymentDate}
        financeAmount={props.loanDetails.financeAmount}
        tgiInsuranceEnrollment={props.loanDetails.tgiInsuranceEnrollment}
        rules={props.rules}
        errorMessage={errorMessage}
        disableProtectAssetButton={props.disableProtectAssetButton}
        receipts={props.loanDetails.receipts}
      />
      <ApplicationStepsFooter
        onSubmit={props.handleSubmit}
        onBack={props.onBack}
        buttonUat={uatId(LoanDetailsUat.loanDetailsFormSubmitButton)}
      />
    </Form>
  );
};
