import { Address } from '@frontline/common';
import {
  FormatAddress,
  FormatDateOfBirth,
  FormatMoney,
  FormatMonths,
  FormatPercent,
  FormatPhone,
  FormatSin,
} from '@frontline/ui-library';
import React from 'react';

export const formattedPhone = (phone?: string) =>
  phone ? <FormatPhone phone={phone} /> : null;

export const formattedMoneyRounded = (income?: number | null) =>
  income ? <FormatMoney value={income} roundToDollar={true} /> : null;

export const formattedSin = (sin?: string) =>
  sin ? <FormatSin sin={sin} /> : null;

export const formattedMoney = (amount?: number) =>
  amount ? <FormatMoney value={amount} roundToDollar={false} /> : null;

export const formattedInterestRate = (percent?: number) =>
  percent ? <FormatPercent value={percent} /> : null;

export const formattedMonths = (months?: number) =>
  months ? <FormatMonths value={months} /> : null;

export const formattedDateOfBirth = (date?: Date) =>
  date ? <FormatDateOfBirth value={date} /> : null;

export const formattedAddress = (address?: Address) =>
  address ? <FormatAddress {...address} /> : null;

export const formattedEmail = (email?: string) =>
  email ? <span style={{ wordBreak: 'break-all' }}>{email}</span> : null;
