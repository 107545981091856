import { Colours } from '@frontline/ui-library';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useApplicationTakeoverAcceptedStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      fontSize: 32,
      color: Colours.Green,
      margin: '32px 0 24px 0',
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
      color: Colours.Green,
      marginBottom: 32,
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
        marginBottom: 24,
      },
    },
    description: {
      fontSize: 20,
      marginBottom: 62,
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
      },
    },
    referenceCodeContainer: {
      width: '100%',
    },
    nextStep: {
      fontSize: 24,
      fontWeight: 700,
      margin: '50px 0 24px 0',
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
        margin: '30px 0 24px 0',
      },
    },
  }),
);
