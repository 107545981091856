import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootState } from '../../../../../store';

import * as applicantApplicationOptionService from '../services/applicant-application-options.service';
import * as actions from './applicant-application-options.actions';

export const fetchApplicantApplicationOptionForApplication: Epic<
  Action,
  Action,
  RootState
> = (action$: Observable<Action>) =>
  action$.pipe(
    filter(isActionOf(actions.fetchApplicantApplicationOptionForApplication)),
    map(({ payload }) => payload),
    mergeMap(applicationId =>
      applicantApplicationOptionService
        .fetchOptionByApplicationId(applicationId)
        .pipe(
          map(option =>
            actions.fetchApplicantApplicationOptionSuccess(
              option,
              applicationId,
            ),
          ),
          catchError(error =>
            of(actions.fetchApplicantApplicationOptionFailure(error)),
          ),
        ),
    ),
  );

export default [fetchApplicantApplicationOptionForApplication];
