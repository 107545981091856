import { WorkflowStepType } from '@frontline/common';

export const ChecklistButtonUat = {
  [WorkflowStepType.AddOns]: 'postSubmission.page.workflowStep.addOns.tab',
  [WorkflowStepType.AdditionalDetails]:
    'postSubmission.page.workflowStep.additionalDetails.tab',
  [WorkflowStepType.Applicant]:
    'postSubmission.page.workflowStep.applicant.tab',
  [WorkflowStepType.CoApplicant]:
    'postSubmission.page.workflowStep.CoApplicant.tab',
  [WorkflowStepType.CoApplicantPersonalDetails]:
    'postSubmission.page.workflowStep.coApplicantPersonalDetails.tab',
  [WorkflowStepType.CoApplicantIncomeDetails]:
    'postSubmission.page.workflowStep.coApplicantIncomeDetails.tab',
  [WorkflowStepType.CoApplicantAdditionalDetails]:
    'postSubmission.page.workflowStep.coApplicantAdditionalDetails.tab',
  [WorkflowStepType.ComponentSelector]:
    'postSubmission.page.workflowStep.componentSelector.tab',
  [WorkflowStepType.AssetInformation]:
    'postSubmission.page.workflowStep.assetInformation.tab',
  [WorkflowStepType.AssetsAndAddOns]:
    'postSubmission.page.workflowStep.assetsAndAddOns.tab',
  [WorkflowStepType.Flinks]: 'postSubmission.page.workflowStep.flinks.tab',
  [WorkflowStepType.IncomeDetails]:
    'postSubmission.page.workflowStep.incomeDetails.tab',
  [WorkflowStepType.LoanDetails]:
    'postSubmission.page.workflowStep.loanDetails.tab',
  [WorkflowStepType.LoanAndFinancingDetails]:
    'postSubmission.page.workflowStep.loanAndFinancingDetails.tab',
  [WorkflowStepType.PaymentDetails]:
    'postSubmission.page.workflowStep.paymentDetails.tab',
  [WorkflowStepType.PersonalDetails]:
    'postSubmission.page.workflowStep.personalDetails.tab',
  [WorkflowStepType.PurchaseDetails]:
    'postSubmission.page.workflowStep.purchaseDetails.tab',
  [WorkflowStepType.Review]: 'postSubmission.page.workflowStep.review.tab',
  [WorkflowStepType.Result]: 'postSubmission.page.workflowStep.result.tab',
  [WorkflowStepType.SignDocuments]:
    'postSubmission.page.workflowStep.signDocuments.tab',
  [WorkflowStepType.UploadDocuments]:
    'postSubmission.page.workflowStep.uploadDocuments.tab',
  [WorkflowStepType.VerifyIdentity]:
    'postSubmission.page.workflowStep.verifyIdentity.tab',
  [WorkflowStepType.LoanProtection]:
    'postSubmission.page.workflowStep.loanProtection.tab',
  [WorkflowStepType.BankDocuments]:
    'postSubmission.page.workflowStep.bankDocuments.tab',
  [WorkflowStepType.InvoiceDetails]:
    'postSubmission.page.workflowStep.invoiceDetails.tab',
  [WorkflowStepType.RepairTechGetStarted]:
    'postSubmission.page.workflowStep.repairTechGetStarted.tab',
  [WorkflowStepType.RepairTechVehicleInformation]:
    'postSubmission.page.workflowStep.repairTechVehicleInformation.tab',
  [WorkflowStepType.PreAuthorizedDebit]:
    'postSubmission.page.workflowStep.preAuthorizedDebit.tab',
};
