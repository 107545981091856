import {
  DocusignRedirectParameters,
  Language,
  SignDocument,
  SignDocumentsErrors,
} from '@frontline/common';
import {
  convertRouteToDocuSignRedirectParameters,
  SignDocumentsState,
  WebCommonRootState,
} from '@frontline/web-common';
import { Dispatch } from 'react';
import { RouteComponentProps } from 'react-router';
import { createSelector, Selector } from 'reselect';
import { RootState } from '../../../../../../../../../store';
import * as postSubmissionReviewStore from '../../../store/post-submission-review.store';
import { PostSubmissionReviewState } from '../../../types/PostSubmissionReviewState';
import * as actions from './sign-documents.actions';

export const getSignDocumentsState: Selector<
  RootState,
  SignDocumentsState | undefined
> = createSelector(
  postSubmissionReviewStore.getPostSubmissionReviewState,
  (state?: PostSubmissionReviewState) => state?.signDocumentsState,
);

export const getSignDocumentsLanguage: Selector<
  RootState,
  Language
> = createSelector(
  getSignDocumentsState,
  (state?: SignDocumentsState) => state?.language ?? Language.EN,
);

export const getPrintingDocument: Selector<
  RootState,
  SignDocument | null
> = createSelector(
  getSignDocumentsState,
  (state?: SignDocumentsState) => state?.printing || null,
);

export const getDocumentOpenedForEmailing: Selector<
  RootState,
  SignDocument | null
> = createSelector(
  getSignDocumentsState,
  (state?: SignDocumentsState) => state?.documentOpenedForEmailing || null,
);

export const getDocumentOpenedForSigning: Selector<
  RootState,
  SignDocument | null
> = createSelector(
  getSignDocumentsState,
  (state?: SignDocumentsState) => state?.documentOpenForSigning || null,
);

export const isUpdating: Selector<RootState, boolean> = createSelector(
  getSignDocumentsState,
  (state?: SignDocumentsState) => state?.updating || false,
);

export const isEmailDialogOpen: Selector<
  RootState,
  boolean
> = createSelector(getSignDocumentsState, (state?: SignDocumentsState) =>
  Boolean(state?.documentOpenedForEmailing),
);

export const getRedirectParameters: Selector<
  RootState,
  DocusignRedirectParameters | null
> = createSelector(
  getSignDocumentsState,
  (state?: SignDocumentsState) => state?.redirectParameters || null,
);

export const getErrors: Selector<
  RootState,
  SignDocumentsErrors[] | null
> = createSelector(
  getSignDocumentsState,
  (state?: SignDocumentsState) => state?.error || null,
);

export const loadSignDocuments = (dispatch: Dispatch<any>) => {
  dispatch(actions.loadSignDocuments());
};

export const openEmailDialog = (dispatch: Dispatch<any>) => (
  signDocument: SignDocument,
) => {
  dispatch(actions.openEmailDialog(signDocument));
};

export const closeEmailDialog = (dispatch: Dispatch<any>) => () => {
  dispatch(actions.closeEmailDialog());
};

export const openSignDocumentDialog = (dispatch: Dispatch<any>) => (
  signDocument: SignDocument,
) => {
  dispatch(actions.openSignDocumentDialog(signDocument));
};

export const closeSignDocumentDialog = (dispatch: Dispatch<any>) => () => {
  dispatch(actions.closeSignDocumentDialog());
};

export const printSignDocument = (dispatch: Dispatch<any>) => (
  signDocument: SignDocument,
) => {
  dispatch(actions.printSignDocument(signDocument));
};

export const resetSignDocuments = (dispatch: Dispatch<any>) => () => {
  dispatch(actions.resetSignDocuments());
};

export const emailDocument = (dispatch: Dispatch<any>) => (
  signDocument: SignDocument | null,
) => {
  if (signDocument) {
    dispatch(actions.emailDocument(signDocument));
  }
};

export const signDocumentOnDevice = (dispatch: Dispatch<any>) => (
  signDocument: SignDocument | null,
  signerIndex: number,
) => {
  if (signDocument) {
    dispatch(actions.signDocumentOnDevice({ signDocument, signerIndex }));
  }
};

export const selectLanguage = (dispatch: Dispatch<any>) => (
  language: Language,
) => {
  dispatch(actions.selectLanguage(language));
};

export const updateRedirectParameters = (dispatch: Dispatch<any>) => (
  redirectParameters: DocusignRedirectParameters | null,
) => {
  dispatch(actions.updateRedirectParameters(redirectParameters));
};

export const updateRedirectParametersFromRoute = (dispatch: Dispatch<any>) => (
  routeComponents: RouteComponentProps,
) => {
  const redirectParameters = convertRouteToDocuSignRedirectParameters(
    routeComponents,
  );
  updateRedirectParameters(dispatch)(redirectParameters);
};

export const setIsEmailOrEmailAndSmsAction = (dispatch: Dispatch<any>) => (
  signingDocumentAction: SignDocumentsState['isEmailOrEmailAndSmsAction'],
) => {
  dispatch(actions.setIsEmailOrEmailAndSmsAction(signingDocumentAction));
};

export const getIsEmailOrEmailAndSmsAction: Selector<
  RootState,
  SignDocumentsState['isEmailOrEmailAndSmsAction'] | undefined
> = createSelector(
  getSignDocumentsState,
  (state?: SignDocumentsState) => state?.isEmailOrEmailAndSmsAction,
);

export const resendSignDocuments = (dispatch: Dispatch<any>) => () => {
  dispatch(actions.resendSignDocuments());
};

export const getResendEmailSuccess: Selector<RootState, any> = createSelector(
  getSignDocumentsState,
  (state?: SignDocumentsState) => state?.resendEmailSuccess,
);

export const getResendEmailError: Selector<RootState, any> = createSelector(
  getSignDocumentsState,
  (state?: SignDocumentsState) => state?.resendEmailError,
);

export const getUploadingDocuments = ({ application }: WebCommonRootState) =>
  application.uploadingDocuments;
