import { LoanDetailsRules } from '@frontline/common';

export function shouldShowCongratulationsMessage(
  rules: LoanDetailsRules | null,
  approvedAmount?: number,
): boolean {
  return Boolean(
    approvedAmount && rules?.showCongratulationsApprovedAmountMessage,
  );
}
