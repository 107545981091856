import { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import { RootState, Store } from '../../../store';
import { LendcareReportingField } from '../types/lendcare-reporting-field.enum';
import { LendCareState } from '../types/LendCareState/lendcare-state.interface';
import * as actions from './buy-lendcare.actions';

export const getBuyLendCareState: Selector<RootState, LendCareState> = (
  state: RootState,
) => state.buyLendCareState;

export const getApplicationId: Selector<
  RootState,
  string | null
> = createSelector(
  getBuyLendCareState,
  (state: LendCareState) => state.applicationId,
);

export const getAccessKey: Selector<RootState, string | null> = createSelector(
  getBuyLendCareState,
  (state: LendCareState) => state.accessKey,
);

export const getRefId: Selector<RootState, string | null> = createSelector(
  getBuyLendCareState,
  (state: LendCareState) => state.refId,
);

export const getReferrerId: Selector<RootState, string | null> = createSelector(
  getBuyLendCareState,
  (state: LendCareState) => state.referrerId,
);

export const getEventId: Selector<RootState, string | null> = createSelector(
  getBuyLendCareState,
  (state: LendCareState) => state.eventId,
);

export const getCampaignId: Selector<RootState, string | null> = createSelector(
  getBuyLendCareState,
  (state: LendCareState) => state.campaignId,
);

export const startNewApplication = (
  shareableLinkId: string,
  reportingFields: Map<LendcareReportingField, string | null>,
) => {
  Store.dispatch(actions.startNewApplication(shareableLinkId, reportingFields));
};
