import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  attachDocumentErrorMessage: {
    id: 'PaymentDetails.attachDocumentErrorMessage',
    description:
      'Message to show when cheque image failed to attach to application',
  },
  voidChequeRequired: {
    id: 'PaymentDetails.voidChequeRequired',
    description: 'error message when void cheque document is not uploaded',
  },
});
