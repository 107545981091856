import {
  Applicant,
  NetverifyState,
  NetverifyStatus,
  VerificationMessage,
} from '@frontline/common';
import { ApplicationState } from '@frontline/web-common';
import { Dispatch } from 'react';
import { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import { RootState } from '../../../../../../../../../store';
import * as rootApplicationActions from '../../../../../../../../../store/application/actions';
import * as rootApplicationSelectors from '../../../../../../../../../store/application/selectors';

export const getApplicationState = (state: RootState): ApplicationState =>
  state.application;

export const getApplicant: Selector<
  RootState,
  Applicant | undefined
> = createSelector(getApplicationState, (state: ApplicationState) =>
  rootApplicationSelectors.getApplicant(state),
);

export const getApplicantFirstName: Selector<
  RootState,
  string | undefined
> = createSelector(
  getApplicationState,
  (state: ApplicationState) =>
    rootApplicationSelectors.getApplicantPersonalDetails(state)?.firstName,
);

export const isLoading: Selector<
  RootState,
  boolean
> = createSelector(getApplicationState, (state: ApplicationState) =>
  rootApplicationSelectors.isSaving(state),
);

export const getNetverify: Selector<
  RootState,
  NetverifyState | undefined
> = createSelector(getApplicationState, (state: ApplicationState) =>
  rootApplicationSelectors.getNetverify(state),
);

export const getVerificationMessages: Selector<
  RootState,
  VerificationMessage[] | []
> = createSelector(getApplicationState, (state: ApplicationState) =>
  rootApplicationSelectors.getVerificationMessages(state),
);

export const updatesEnabled: Selector<
  RootState,
  boolean
> = createSelector(getApplicationState, (state: ApplicationState) =>
  rootApplicationSelectors.postSubmissionSelectors.enableApplicantUpdates(
    state,
  ),
);

export const getNetverifyUrl = (dispatch: Dispatch<any>) => () => {
  dispatch(rootApplicationActions.getNetverifyUrl());
};

export const setNetverifyStatus = (dispatch: Dispatch<any>) => (
  status: NetverifyStatus,
) => {
  dispatch(rootApplicationActions.setNetverifyStatus(status));
};
