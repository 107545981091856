import { AddOnsState } from './addons-state.interface';
import { AssetInformationState } from './asset-information-state.interface';
import { AssetsAndAddOnsState } from './assets-and-addons-state.interface';

export function createAssetsAndAddOnsState(): AssetsAndAddOnsState {
  return {
    assets: createAssetInformationState(),
    addOns: createAddOnsState(),
    showTaxCalculator: false,
  };
}
export function createAssetInformationState(): AssetInformationState {
  return {
    validations: null,
  };
}
export const createAddOnsState = (): AddOnsState => {
  return { types: [] };
};
