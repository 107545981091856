import { IncomeDetails, Language } from '@frontline/common';

export const createIncomeDetailsDefaultValues: () => IncomeDetails = () => ({
  totalMonthlyIncome: 0,
  netMonthlyIncome: null,
  authorizedCreditCheck: false,
  authorizedElectronicCommunication: false,
});

export const showSupplementalFrenchInstruction = (
  language: Language,
): boolean => language === Language.EN;
