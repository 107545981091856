import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'CoApplicantIncomeVerificationStep.title',
    description: 'Income Details Step Title',
    defaultMessage: "Co-applicant's Income Details",
  },
  description: {
    id: 'CoApplicantIncomeVerificationStep.description',
    description: 'Income Details Step Description',
    defaultMessage:
      'A couple quick steps to go! Please provide your income using the slider and provide the required consent to continue.',
  },
});

export default messages;
