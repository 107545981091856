import {
  muiTheme,
  styledTheme,
  ThemeProvider,
  ToastContainer,
} from '@frontline/ui-library';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import React, { Fragment } from 'react';
import { Router } from 'react-router-dom';
import { AppService } from './app-service';
import { Routes } from './routes';
import { history } from './store';

export const App = () => {
  AppService.initializeApp();
  return (
    <MuiThemeProvider theme={muiTheme}>
      <ThemeProvider theme={styledTheme}>
        <CssBaseline>
          <Fragment>
            <Router history={history}>
              <Routes />
            </Router>
            <ToastContainer />
          </Fragment>
        </CssBaseline>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};
