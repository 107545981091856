import { Colours } from '@frontline/ui-library';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useApplicationTakeoverConfirmStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 24,
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'start',
        marginTop: 31,
        fontSize: 14,
      },
    },
    confirmationText: {
      fontSize: 24,
      margin: '26px 0 50px 0',
      color: Colours.VenetianRed,
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
        fontWeight: 700,
        margin: '0px 0 20px 0',
      },
    },
    referenceCodeContainer: {
      width: '100%',
    },
    buttonContainer: {
      marginBottom: 77,
      width: '100%',
      '& :first-child': {
        marginBottom: 33,
      },
      '& >button': {
        margin: 0,
      },
    },
  }),
);
