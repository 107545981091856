import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { createLinkExpiredPath } from '../../modules/navigation/types';
import LinkExpired from './components/LinkExpired';
import NotAuthenticatedDefault from './components/NotAuthenticatedDefault';

export const NotAuthenticatedRoutes = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route
        path={createLinkExpiredPath()}
        exact={true}
        component={LinkExpired}
      />
      <Route
        path={match.url}
        exact={true}
        component={NotAuthenticatedDefault}
      />
      <Redirect to={match.url} />
    </Switch>
  );
};
