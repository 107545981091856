import { PersonalDetailsRules } from '@frontline/common';
import {
  ApplicationStepsHeader,
  PersonalDetailsForm,
  usePostSubmissionStepReference,
} from '@frontline/web-common';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as coApplicantStore from '../../../../../../../../modules/co-applicant-details/store/co-applicant-details.store';
import * as postSubmissionStore from '../../../../../../store/post-submission.store';
import { CoApplicantDetailsPageProps } from '../../types';
import { messages } from './co-applicant-personal-details.messages';

export const ApplicantPersonalDetails = (
  props: CoApplicantDetailsPageProps,
) => {
  const dispatch = useDispatch();
  const coApplicantDetails = useSelector(
    coApplicantStore.getCoApplicantPersonalDetails,
  );
  const intl = useIntl();
  const stepReference = usePostSubmissionStepReference();

  return (
    <>
      <ApplicationStepsHeader
        title={intl.formatMessage(messages.title)}
        description={intl.formatMessage(messages.description)}
      />
      <PersonalDetailsForm
        disabled={props.disabled}
        initialValues={coApplicantDetails}
        rules={stepReference?.step.rules as PersonalDetailsRules}
        onSubmit={postSubmissionStore.navigateToOverview(dispatch)}
        coApplicant={true}
      />
    </>
  );
};
