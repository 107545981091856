import { defineMessages } from 'react-intl';

export default defineMessages({
  assetDescriptionMaxLength: {
    id: 'FinalizeLoanDetails.assetDescriptionMaxLength',
    description: 'Finalize loan details description exceeds max char limit',
    defaultMessage: 'Description must not exceed {max} characters',
  },
  rateError: {
    id: 'FinalizeLoanDetails.rateError',
    description: 'Finalize loan details rate error or not selected',
    defaultMessage: 'Please select an interest rate',
  },
  termError: {
    id: 'FinalizeLoanDetails.termError',
    description: 'Finalize loan details term error or not selected',
    defaultMessage: 'Please select a term',
  },
  dateError: {
    id: 'FinalizeLoanDetails.dateError',
    description: 'Finalize loan details date error or not selected',
    defaultMessage: 'Please select a date',
  },
  assetDescriptionError: {
    id: 'FinalizeLoanDetails.assetDescription',
    description: 'Finalize loan details asset description not informed',
    defaultMessage: 'Please enter a description',
  },
  frequencyError: {
    id: 'FinalizeLoanDetails.frequencyError',
    description: 'Finalize loan details term error or not selected',
    defaultMessage: 'Please select a payment frequency',
  },
  deferralError: {
    id: 'FinalizeLoanDetails.deferralError',
    description: 'Finalize loan details deferral error or not selected',
  },
  payDayFrequencyError: {
    id: 'FinalizeLoanDetails.payDayFrequencyError',
    description: 'Finalize loan details pay day frequency error',
    defaultMessage: 'Please select a pay day frequency',
  },
  paymentMonthly: {
    id: 'FinalizeLoanDetails.paymentMonthly',
    description: 'Finalize loan details payment frequency monthly',
    defaultMessage: 'Monthly',
  },
  paymentSemiMonthly: {
    id: 'FinalizeLoanDetails.paymentSemiMonthly',
    description: 'Finalize loan details payment frequency semi-monthly',
    defaultMessage: 'Semi-Monthly',
  },
  paymentBiWeekly: {
    id: 'FinalizeLoanDetails.paymentBiWeekly',
    description: 'Finalize loan details payment frequency bi-weekly',
    defaultMessage: 'Bi-weekly',
  },
  paymentWeekly: {
    id: 'FinalizeLoanDetails.paymentWeekly',
    description: 'Finalize loan details payment frequency weekly',
    defaultMessage: 'Weekly',
  },
  invoiceEstimateAmountError: {
    id: 'FinalizeLoanDetails.invoiceEstimateAmountError',
    description: 'Estimated transaction price is not entered',
    defaultMessage: 'Please enter estimate',
  },
  confirmEstimateAmount: {
    id: 'FinalizeLoanDetails.confirmEstimateAmount',
    description: 'Merchant confirms the estimate amount',
    defaultMessage: 'I confirm the customer has accepted this estimate',
  },
  tgiInsuranceEnrollmentError: {
    id: 'FinalizeLoanDetails.tgiInsuranceEnrollmentError',
    description: 'Finalize loan details tgi insurance frequency error',
    defaultMessage: 'Please select an option',
  },
});
