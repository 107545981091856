import {
  AdditionalDetails,
  shouldSaveAdditionalDetails,
} from '@frontline/common';
import { LocaleContext } from '@frontline/web-common';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as yearsWorkedCategoryStore from '../../../../../../../../modules/lookup/years-worked-category/years-worked-category.store';
import * as applicantStore from '../../../../../../store/application.store';
import { PreSubmissionFormPropsType } from '../../../../types/PreSubmissionForm/PreSubmissionFormPropsType';
import { CoApplicantAdditionalDetailsView } from './components/co-applicant-additional-details-view.component';
import * as additionalDetailsStore from './store/coApplicantAdditionalDetails.store';

export const CoApplicantAdditionalDetailsFeature = (
  props: PreSubmissionFormPropsType<AdditionalDetails>,
) => {
  const localeContext = useContext(LocaleContext);
  const additionalDetails = useSelector(
    additionalDetailsStore.getCoApplicantAdditionalDetails,
  );
  const yearsWorkedCategories = useSelector(
    yearsWorkedCategoryStore.getYearsWorkedCategoriesDropdown,
  );

  useEffect(() => {
    yearsWorkedCategoryStore.fetchYearsWorkedCategories();
  }, [localeContext.locale]);

  const onSubmit = (changes: AdditionalDetails) => {
    props.onSubmit(
      changes,
      shouldSaveAdditionalDetails(props.stepReference, additionalDetails),
    );
  };

  return (
    <CoApplicantAdditionalDetailsView
      additionalDetails={useSelector(
        additionalDetailsStore.getCoApplicantAdditionalDetails,
      )}
      stepPosition={props.stepPosition}
      onSubmit={onSubmit}
      onBackButtonClick={props.onBack}
      yearsWorkedCategoriesLookup={yearsWorkedCategories || []}
      personalDetails={useSelector(applicantStore.getApplicantPersonalDetails)}
    />
  );
};
