import { ContactInformation } from '@frontline/common';
import { createSelector, Selector } from 'reselect';
import { RootState, Store } from '../../../../../../../../../store';
import * as preSubmissionStore from '../../../../../store/pre-submission.store';
import { PreSubmissionState } from '../../../../../types/PreSubmissionState/pre-submission.state';
import { ApplicationApprovedState } from '../types';
import * as actions from './application-approved.actions';

export const getApplicationApprovedState: Selector<
  RootState,
  ApplicationApprovedState | undefined
> = createSelector(
  preSubmissionStore.getPreSubmissionState,
  (state?: PreSubmissionState) => state?.applicationApprovedState,
);

export const getOwnerContactInfo: Selector<
  RootState,
  ContactInformation | null
> = createSelector(
  getApplicationApprovedState,
  (state?: ApplicationApprovedState) => state?.ownerContactInfo || null,
);

export const fetchOwnerContactInfo = (): void => {
  Store.dispatch(actions.fetchOwnerContactInfo());
};

export const isLoading: Selector<
  RootState,
  boolean | undefined
> = createSelector(
  getApplicationApprovedState,
  (state?: ApplicationApprovedState) => state?.isLoading,
);
