import { createSession } from '../ApplicantSession/ApplicantSession.functions';
import { AuthState } from './AuthState';

export function createAuthState(): AuthState {
  return {
    isLoading: false,
    session: createSession(),
    isInitialized: false,
    isInvalid: false,
  };
}
