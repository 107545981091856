import { ComponentChoice, WorkflowDecisionValue } from '@frontline/common';
import { ActionType, createAction } from 'typesafe-actions';

export enum ComponentSelectorActions {
  InitializeChoices = 'ComponentSelector/InitializeChoices',
  SaveDecision = 'ComponentSelector/SaveDecision',
  SelectDecision = 'ComponentSelector/SelectDecision',
}

export const initializeChoices = createAction(
  ComponentSelectorActions.InitializeChoices,
  action => ({
    decisionKey,
    choices,
  }: {
    decisionKey: string;
    choices: ComponentChoice[];
  }) => action({ decisionKey, choices }),
);

export const saveDecision = createAction(ComponentSelectorActions.SaveDecision);

export const selectDecision = createAction(
  ComponentSelectorActions.SelectDecision,
  action => (value: WorkflowDecisionValue) => action(value),
);

const actionCreators = {
  initializeChoices,
  saveDecision,
  selectDecision,
};

export type ComponentSelectorActionType = ActionType<typeof actionCreators>;
