import { Colours, FormatMoney } from '@frontline/ui-library';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { EstimatedPaymentSpan } from './EstimatedPaymentSpan';
import { GridContainer } from './GridContainer';
import messages from './MobileFundingDetails.messages';
import { MobileFundingDetailsProps } from './MobileFundingDetailsProps';

export const MobileFundingDetails: FunctionComponent<MobileFundingDetailsProps> = (
  props: MobileFundingDetailsProps,
) => {
  const intl = useIntl();

  const Paragraph = props.hideFundingDetails
    ? ParagraphHideText
    : ParagraphNormal;

  return (
    <GridContainer
      container={true}
      justify="space-between"
      alignItems="center"
      wrap="nowrap">
      <Grid item={true} xs={6}>
        <Paragraph variant="body1">
          <EstimatedPaymentSpan calculating={props.isCalculating}>
            <FormatMoney value={props.financeAmount} />
          </EstimatedPaymentSpan>
        </Paragraph>
        <Paragraph variant="body1">
          {intl.formatMessage(messages.loanAmount)}
        </Paragraph>
      </Grid>
      <Grid item={true} xs={6}>
        <Paragraph variant="body1">
          <FormatMoney value={props.paymentAmount} />
        </Paragraph>
        <Paragraph variant="body1">
          {intl.formatMessage(props.frequencyMessageDescriptor)}
        </Paragraph>
      </Grid>
    </GridContainer>
  );
};

const ParagraphHideText = styled(Typography)`
  color: ${Colours.DarkBlueGrey} !important;
`;

const ParagraphNormal = styled(Typography)`
  color: ${Colours.White} !important;
`;
