import { withShowIf } from '@frontline/ui-library';
import {
  AddressLink,
  OfficeIcon,
  PhoneLink,
  WebsiteLink,
} from '@frontline/ui-library';
import { Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formattedPhone } from '../../../application/types/formatApplicationData.functions';
import { ApplicationTakeoverMerchantInfoFooterMessages } from './application-takeover-merchant-info-footer.messages';
import { ApplicationTakeoverMerchantInfoFooterProps } from './application-takeover-merchant-info-footer.props';
import { useApplicationTakeoverMerchantInfoFooterStyles } from './application-takeover-merchant-info-footer.styles';

export const ApplicationTakeoverMerchantInfoFooter = withShowIf(
  (props: ApplicationTakeoverMerchantInfoFooterProps) => {
    const { applicationTakeoverDetails } = props;
    const classes = useApplicationTakeoverMerchantInfoFooterStyles();

    if (!applicationTakeoverDetails) {
      return null;
    }
    return (
      <div className={classes.container}>
        <Typography className={classes.title}>
          <FormattedMessage
            {...ApplicationTakeoverMerchantInfoFooterMessages.title}
          />
        </Typography>
        <div className={classes.details}>
          <Typography>
            <OfficeIcon className={classes.icon} />
            {applicationTakeoverDetails.merchant.name}
          </Typography>
          <AddressLink
            address={applicationTakeoverDetails.merchant.address}
            text={applicationTakeoverDetails.merchant.address}
          />
          <PhoneLink
            phoneNumber={applicationTakeoverDetails.merchant.phoneNumber}
            text={formattedPhone(
              applicationTakeoverDetails.merchant.phoneNumber,
            )}
          />
          <WebsiteLink
            websiteLink={applicationTakeoverDetails.merchant.websiteUrl}
            text={applicationTakeoverDetails.merchant.websiteUrl}
          />
        </div>
      </div>
    );
  },
);
