import { Application, LoanDetails, LoanDetailsDto } from '@frontline/common';
import { actions as webCommonActions } from '@frontline/web-common';
import { createSelector, Selector } from 'reselect';
import { RootState, Store } from '../../../../../../../../../store';
import * as applicationSelectors from '../../../../../../../../../store/application/selectors';
import * as postSubmissionStore from '../../../../../store/post-submission.store';
import { PostSubmissionReviewState } from '../../../types/PostSubmissionReviewState';
import { LoanDetailsState } from '../types';
import { createLoanDetailsState } from '../types/loan-details-state.functions';
import * as actions from './loan-details.actions';

export const getLoanDetailsState: Selector<
  RootState,
  LoanDetailsState
> = createSelector(
  postSubmissionStore.getPostSubmissionReviewState,
  (state?: PostSubmissionReviewState) =>
    state?.loanDetailsState || createLoanDetailsState(),
);

export const getApplication = (state: RootState): Application =>
  applicationSelectors.getApplication(state.application) || ({} as Application);

export const getLoanDetailsDto: Selector<
  RootState,
  LoanDetailsDto
> = createSelector(
  getLoanDetailsState,
  (state: LoanDetailsState) => state.loanDetails,
);

export const toNextStep = (): void => {
  return postSubmissionStore.navigateToNextStep(Store.dispatch)();
};

export const toPreviousStep = (): void => {
  return postSubmissionStore.navigateToPreviousStep(Store.dispatch)();
};

export const fetchLoanDetails = (): void => {
  Store.dispatch(actions.fetchLoanDetails());
};

export const saveLoanDetails = (details: Partial<LoanDetails>): void => {
  Store.dispatch(webCommonActions.applicationActions.saveLoanDetails(details));
};

export const setIsLoading = (): void => {
  Store.dispatch(actions.setIsLoading());
};

export const isLoading: Selector<
  RootState,
  boolean
> = createSelector(getLoanDetailsState, (state?: LoanDetailsState) =>
  Boolean(state?.isLoading),
);
