import {
  ComponentChoice,
  WorkflowDecision,
  WorkflowDecisionValue,
} from '@frontline/common';
import { isNil } from 'lodash';
import { Dispatch } from 'react';
import { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import { RootState } from '../../../../../../../../../store';
import * as postSubmissionStore from '../../../../../store/post-submission.store';
import { PostSubmissionReviewState } from '../../../types/PostSubmissionReviewState';
import { ComponentSelectorState } from '../types/ComponentSelectorState';
import * as actions from './componentSelector.actions';

export const getComponentSelectorState: Selector<
  RootState,
  ComponentSelectorState | undefined
> = createSelector(
  postSubmissionStore.getPostSubmissionReviewState,
  (state?: PostSubmissionReviewState) => state?.componentSelectorState,
);

export const getChoices: Selector<
  RootState,
  ComponentChoice[]
> = createSelector(
  getComponentSelectorState,
  (state?: ComponentSelectorState) => state?.choices || [],
);

export const getSelectedDecision: Selector<
  RootState,
  WorkflowDecisionValue | undefined
> = createSelector(
  getComponentSelectorState,
  (state?: ComponentSelectorState) => state?.selectedDecision,
);

export const hasSelectedDecision: Selector<RootState, boolean> = createSelector(
  getSelectedDecision,
  decision => !isNil(decision),
);

export const getSelectedChoiceDescription: Selector<
  RootState,
  string | undefined
> = createSelector(
  getChoices,
  getSelectedDecision,
  (choices, decision) =>
    choices.find(choice => choice?.value === decision)?.description,
);

export const getSelectedStepId: Selector<
  RootState,
  string | undefined
> = createSelector(
  getChoices,
  getSelectedDecision,
  (choices, decision) =>
    choices.find(choice => choice?.value === decision)?.step?.id,
);

export const getDecisionKey: Selector<
  RootState,
  string | undefined
> = createSelector(
  getComponentSelectorState,
  (state?: ComponentSelectorState) => state?.decisionsKey,
);

export const getDecision: Selector<
  RootState,
  WorkflowDecision | undefined
> = createSelector(getDecisionKey, getSelectedDecision, (key, value) => {
  if (key && value !== undefined) {
    return {
      key,
      value,
    };
  }
  return;
});

export const initializeChoices = (dispatch: Dispatch<any>) => (
  decisionKey: string,
  choices: ComponentChoice[],
) => {
  dispatch(actions.initializeChoices({ decisionKey, choices }));
};

export const saveDecision = (dispatch: Dispatch<any>) => () => {
  dispatch(actions.saveDecision());
};

export const selectDecision = (dispatch: Dispatch<any>) => (
  decision: WorkflowDecisionValue,
) => {
  dispatch(actions.selectDecision(decision));
};
