import { HttpService } from '@frontline/web-common';
import { AxiosResponse } from 'axios';
import * as jwt from 'jsonwebtoken';
import { AuthenticationQuery } from '../types/AuthenticationQuery/AuthenticationQuery';
import { LocalStorageKeys } from '../types/LocalStorageKeys.enum';
import { LoginResponse } from '../types/LoginResponse';
import { VerifyAuthenticationResponse } from '../types/VerifyAuthenticationResponse';

export interface AccessTokenPayload {
  expiresAt: number;
  accessToken: string;
  userId: string;
}

export const processAuthentication = (
  accessToken: string,
): AccessTokenPayload => {
  const decoded: any = jwt.decode(accessToken);
  if (!decoded) {
    throw new Error('Invalid accessToken');
  }

  const expiresAt = decoded.exp || 0;
  const userId = parseUserId(decoded.sub);

  localStorage.setItem(LocalStorageKeys.AccessToken, accessToken);
  localStorage.setItem(LocalStorageKeys.ExpiresAt, JSON.stringify(expiresAt));
  localStorage.setItem(LocalStorageKeys.UserId, userId);

  return {
    expiresAt,
    accessToken,
    userId,
  };
};

export const clearAuthenticationData = () => {
  localStorage.removeItem(LocalStorageKeys.AccessKey);
  localStorage.removeItem(LocalStorageKeys.AccessToken);
  localStorage.removeItem(LocalStorageKeys.ApplicationId);
  localStorage.removeItem(LocalStorageKeys.ExpiresAt);
  localStorage.removeItem(LocalStorageKeys.UserId);
};

export const verifyAuthentication = (): VerifyAuthenticationResponse => {
  const token = localStorage.getItem(LocalStorageKeys.AccessToken);
  const expirationDate = localStorage.getItem(LocalStorageKeys.ExpiresAt);
  const accessKey = localStorage.getItem(LocalStorageKeys.AccessKey);
  const applicationId = localStorage.getItem(LocalStorageKeys.ApplicationId);
  const userId = localStorage.getItem(LocalStorageKeys.UserId);

  if (expirationDate && token && accessKey) {
    const expiresAt = JSON.parse(expirationDate);

    if (!isExpired(expiresAt)) {
      return {
        applicationId,
        accessToken: token,
        expiresAt,
        accessKey,
        userId,
      };
    }
  }

  return false;
};

export const setApplicationId = (applicationId: string) => {
  localStorage.setItem(LocalStorageKeys.ApplicationId, applicationId);
};

export const login = async (
  authQuery: AuthenticationQuery,
): Promise<LoginResponse> => {
  clearAuthenticationData();

  localStorage.setItem(LocalStorageKeys.AccessKey, authQuery.accessKey);
  localStorage.setItem(LocalStorageKeys.ApplicationId, authQuery.applicationId);

  if (authQuery.merchantId) {
    localStorage.setItem(
      LocalStorageKeys.MerchantId,
      `${authQuery.merchantId}`,
    );
  }

  return HttpService.getHttpInstanceDeprecated()
    .get(`/applicant/login?accessKey=${authQuery.accessKey}`)
    .then((response: AxiosResponse<LoginResponse>) => response.data);
};

function isExpired(expiresAt: number): boolean {
  return unixTimestamp() >= expiresAt;
}

function unixTimestamp(): number {
  return Math.floor(new Date().getTime() / 1000);
}

function parseUserId(sub: string): string {
  return sub.split('|')?.[1];
}

export function getToken() {
  return localStorage.getItem(LocalStorageKeys.AccessToken);
}

export function getApplicationId() {
  return localStorage.getItem(LocalStorageKeys.ApplicationId);
}
