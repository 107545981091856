import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'AssetInformationStep.title',
    description: 'Asset Details Step Title',
    defaultMessage: 'Finalize Asset Details',
  },
  description: {
    id: 'AssetInformationStep.description',
    description: 'Asset Details Step Description',
    defaultMessage:
      'Review and finalize the details of the asset(s) being financed.',
  },
  editButton: {
    id: 'AssetInformationStep.editButton',
    description: 'Asset Details Step Edit Button',
    defaultMessage: 'Review asset details',
  },
});
