import { defineMessages } from 'react-intl';

export const ApplicationTakeoverViewMessages = defineMessages({
  title: {
    id: 'Applicant.ApplicationTakeover.View.title',
    description: 'Application Takeover page title',
    defaultMessage:
      '{merchantName} is requesting access to your LendCare pre-qualification.',
  },
  description: {
    id: 'Applicant.ApplicationTakeover.View.description',
    description: 'Application Takeover page description',
    defaultMessage:
      'Do you accept the request and authorize {merchantName} access to your pre-qualification?',
  },
  confirmButton: {
    id: 'Applicant.ApplicationTakeover.View.confirmButton',
    description: 'Application Takeover page Confirm Button',
    defaultMessage: 'Yes, I accept',
  },
  declineButton: {
    id: 'Applicant.ApplicationTakeover.View.declineButton',
    description: 'Application Takeover page decline Button',
    defaultMessage: 'No, I decline',
  },
});
