import {
  ApplicantApplicationOptionDto,
  WorkflowStep,
  WorkflowStepType,
} from '@frontline/common';

export function getEnabledWorkflowSteps(
  steps: WorkflowStep[],
  applicantOptions?: ApplicantApplicationOptionDto,
): WorkflowStep[] {
  if (!applicantOptions) {
    return steps;
  }

  return steps.filter(isStepVisible(applicantOptions));
}

export function isUploadDocumentsVisible(
  applicantOptions?: ApplicantApplicationOptionDto,
): boolean {
  return isStepTypeVisible(applicantOptions)(WorkflowStepType.UploadDocuments);
}

const isStepVisible = (options?: ApplicantApplicationOptionDto) => (
  step: WorkflowStep,
) => isStepTypeVisible(options)(step.type);

const isStepTypeVisible = (options?: ApplicantApplicationOptionDto) => (
  stepType: WorkflowStepType,
) => {
  if (!options?.applicantViewPermissions) {
    return true;
  }
  return options.applicantViewPermissions.includes(stepType);
};
