import calendarBiWeekly from '../../../../../../../../../assets/calendar-biweekly.svg';
import calendarMonthly from '../../../../../../../../../assets/calendar-monthly.svg';
import calendarSemiMonthly from '../../../../../../../../../assets/calendar-semimonthly.svg';
import calendarWeekly from '../../../../../../../../../assets/calendar-weekly.svg';

export const getNameAndIcon = (frequencyOption: number) => {
  switch (frequencyOption) {
    case 2:
      return {
        name: 'paymentMonthly',
        icon: calendarMonthly,
      };
    case 15:
      return {
        name: 'paymentBiWeekly',
        icon: calendarBiWeekly,
      };
    case 13:
      return {
        name: 'paymentSemiMonthly',
        icon: calendarSemiMonthly,
      };
    default:
      return {
        name: 'paymentWeekly',
        icon: calendarWeekly,
      };
  }
};
