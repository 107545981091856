import { WorkflowStepType } from '@frontline/common';
import React, { FunctionComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { createPostSubmissionReviewPath } from '../../../../../types/PostSubmissionReviewUrl.functions';
import { ApplicantAdditionalDetails } from '../features/applicant-additional-details.component';
import { ApplicantIncomeDetailsFeature } from '../features/ApplicantIncomeDetails/ApplicantIncomeDetailsFeature';
import ApplicantPersonalDetails from '../features/ApplicantPersonalDetails/ApplicantPersonalDetails';
import { ApplicantDetailsRoutesProps } from './ApplicantDetailsRoutesProps';

export const ApplicantDetailsRoutes: FunctionComponent<ApplicantDetailsRoutesProps> = (
  props: ApplicantDetailsRoutesProps,
) => {
  return (
    <Switch>
      <Route
        path={createPostSubmissionReviewPath(
          WorkflowStepType.IncomeDetails,
          props.applicationId,
        )}
        render={() => (
          <ApplicantIncomeDetailsFeature disabled={props.disabled} />
        )}
      />
      <Route
        path={createPostSubmissionReviewPath(
          WorkflowStepType.AdditionalDetails,
          props.applicationId,
        )}
        render={() => (
          <ApplicantAdditionalDetails
            disabled={props.disabled}
            yearsWorkedCategoriesLookup={props.yearsWorkedCategoriesLookup}
          />
        )}
      />
      <Route
        path={createPostSubmissionReviewPath(
          WorkflowStepType.PersonalDetails,
          props.applicationId,
        )}
        render={() => <ApplicantPersonalDetails disabled={props.disabled} />}
      />
      <Redirect
        to={createPostSubmissionReviewPath(
          WorkflowStepType.PersonalDetails,
          props.applicationId,
        )}
      />
    </Switch>
  );
};
