import { FlinksViewState } from '@frontline/web-common';
import { createSelector, Selector } from 'reselect';
import { RootState } from '../../../../../../../store';
import * as postSubmissionStore from '../../../store/post-submission.store';
import { PostSubmissionState } from '../../../types/PostSubmissionState';
import { PostSubmissionReviewState } from '../types/PostSubmissionReviewState';

export const getPostSubmissionReviewState: Selector<
  RootState,
  PostSubmissionReviewState | undefined
> = createSelector(
  postSubmissionStore.getPostSubmissionState,
  (postSubmissionState?: PostSubmissionState) =>
    postSubmissionState?.postSubmissionReviewState,
);

export const getFlinksViewState: Selector<
  RootState,
  FlinksViewState | undefined
> = createSelector(
  postSubmissionStore.getPostSubmissionReviewState,
  (postSubmissionReviewState?: PostSubmissionReviewState) =>
    postSubmissionReviewState?.flinksViewState,
);
