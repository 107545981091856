import { Grid } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { OverviewStepperProps } from '../../types/OverviewStepperProps';
import { ChecklistMobile } from './checklist-mobile.component';

const OverviewStepperMobile: FunctionComponent<OverviewStepperProps> = ({
  steps,
  defaultStepType,
  onClickAddCoApplicant,
  onClickRemoveCoApplicant,
  showRemoveCoApplicant,
  showAddCoApplicant,
  getStepNameByStepType,
  ...rest
}) => {
  const [selectedTab, setSelectedTab] = useState('');
  useEffect(() => {
    const activeStep = steps.find(s => s.type === defaultStepType);
    if (activeStep) {
      setSelectedTab(activeStep.id);
    }
  }, [defaultStepType, steps]);

  return (
    <Grid item={true} {...rest}>
      <Grid container={true}>
        <ChecklistMobile
          steps={steps}
          getStepNameByStepType={getStepNameByStepType}
          onTabSelect={setSelectedTab}
          activeTabId={selectedTab}
        />
      </Grid>
    </Grid>
  );
};
export default OverviewStepperMobile;
