import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'FinalizeApplicantStep.title',
    description: 'Applicant Step Title',
    defaultMessage: 'Review the applicant details',
  },
  description: {
    id: 'FinalizeApplicantStep.description',
    description: 'Applicant Step Description',
    defaultMessage:
      'Review or edit the applicant details to rescore the application',
  },
  editButton: {
    id: 'FinalizeApplicantStep.editButton',
    description: 'Applicant Step Edit Button',
    defaultMessage: 'Review applicant',
  },
  addCoApplicantButton: {
    id: 'FinalizeApplicantStep.addCoApplicantButton',
    description: 'Applicant Step Add Co-Applicant Button',
    defaultMessage: 'Add co-applicant',
  },
});
