import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'FlinksStep.title',
    description: 'Flinks Step Title',
    defaultMessage: 'Set up payment details',
  },
  description: {
    id: 'FlinksStep.description',
    description: 'Flinks Step Description',
    defaultMessage: 'Set up your bank account for recurring loan repayments',
  },
  editButton: {
    id: 'FlinksStep.editButton',
    description: 'Flinks Step Edit Button',
    defaultMessage: 'Set up payment details',
  },
});
