import {
  muiTheme,
  PoweredByFooter,
  ShowIfFragment,
} from '@frontline/ui-library';
import { isFullBrand, ScrollIntoView } from '@frontline/web-common';
import { useMediaQuery } from '@material-ui/core';
import React, { FunctionComponent, RefObject } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as applicationStore from '../../../features/application/store/application.store';
import { LayoutOptionProps } from '../layout-options.props';
import { isSecurityQuestionRoute } from './with-main-layout.functions';
import {
  LayoutContainer,
  LayoutNavBar,
  MainComponentContainer,
  Spacer,
} from './with-main-layout.styles';

export const withMainLayout = (
  DefaultDesktopToolbar: React.ComponentType<any>,
  Component: React.ComponentType<any>,
  options?: LayoutOptionProps,
): FunctionComponent => {
  return () => {
    const location = useLocation();
    const application = useSelector(applicationStore.getApplication);
    const isMediaExtraSmall = useMediaQuery(muiTheme.breakpoints.down('xs'));
    const navBarHeight =
      (isMediaExtraSmall && options && options.mobileExtraSmallNavBarHeight) ||
      '77px';
    const appBarSpacerElementRef: RefObject<HTMLDivElement> = React.createRef();
    const DesktopToolbar = options && options.desktopToolbar;
    const MobileToolBar = options && options.mobileToolBar;

    return (
      <LayoutContainer>
        <ShowIfFragment showIf={!isSecurityQuestionRoute(location.pathname)}>
          <LayoutNavBar color="inherit" height={navBarHeight}>
            {(() => {
              if (MobileToolBar && isMediaExtraSmall) {
                return <MobileToolBar />;
              } else if (DesktopToolbar) {
                return <DesktopToolbar />;
              } else {
                return <DefaultDesktopToolbar />;
              }
            })()}
          </LayoutNavBar>
        </ShowIfFragment>
        <ScrollIntoView element={appBarSpacerElementRef} />
        <MainComponentContainer
          marginBottom={isFullBrand(application) ? '94px' : '2em'}>
          <Spacer
            gutterBottom={isMediaExtraSmall ? '0' : '5vh'}
            minHeight={navBarHeight}
            ref={appBarSpacerElementRef}
          />
          <Component />
        </MainComponentContainer>
        <PoweredByFooter showIf={isFullBrand(application)} />
      </LayoutContainer>
    );
  };
};
