import { defineMessages } from 'react-intl';

export const PreSubmissionStepActionsMessages = defineMessages({
  backButtonLabel: {
    id: 'PreSubmissionStepActions.backButtonLabel',
    description: 'Back Button Label',
  },
  continueButtonLabel: {
    id: 'PreSubmissionStepActions.continueButtonLabel',
    description: 'Continue Button Label',
  },
  saveAndExitButtonLabel: {
    id: 'PreSubmissionStepActions.saveAndExitButtonLabel',
    description: 'Save & Exit Button Label',
  },
});
