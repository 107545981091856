import { styled, styledTheme } from '@frontline/ui-library';
import { ButtonBase, withStyles } from '@material-ui/core';

export const UploadButton = withStyles({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    padding: '1em',
    color: styledTheme.deepSkyBlue,
  },
})(ButtonBase);

export const UploadDocumentsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const IconWrapper = styled.div`
  display: block;
`;
