import { defineMessages } from 'react-intl';

export const AdditionalDetailsFormLayoutMessages = defineMessages({
  employmentDetails: {
    id: 'AdditionalDetailsFormLayout.employmentDetails',
    description: 'Employment Details Section Title',
  },
  residentialDetails: {
    id: 'AdditionalDetailsFormLayout.residentialDetails',
    description: 'Residential Details Section Title',
  },
});
