import {
  muiTheme,
  TypographyColor,
  TypographyVariant,
} from '@frontline/ui-library';
import { Typography, useMediaQuery } from '@material-ui/core';
import React from 'react';

export interface PreSubmissionTitleProps {
  applicationId: string;
}

export const PreSubmissionTitle = (props: PreSubmissionTitleProps) => {
  if (useMediaQuery(muiTheme.breakpoints.down('xs'))) {
    return null;
  }

  return (
    <Typography
      variant={TypographyVariant.Header2}
      color={TypographyColor.TextPrimary}>
      {props.applicationId}
    </Typography>
  );
};
