/* tslint:disable:ordered-imports */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { GenericLinkRouteProps } from '../types/GenericLinkRouteProps';
import * as store from './store/new-repair.store';
import { RepairTechReportingFieldService } from '../../modules/repair-tech/services/repair-tech-reporting-fields.service';

export const NewRepairFeature = (
  props: RouteComponentProps<GenericLinkRouteProps>,
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    store.login(dispatch)(props.match.params.shareableLinkId);
  }, [dispatch, props.match.params.shareableLinkId]);

  useEffect(() => {
    RepairTechReportingFieldService.clearReportingFields();
    RepairTechReportingFieldService.setReportingFields(window.location.search);
  }, []);
  return null;
};
