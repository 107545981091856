import { LoanDetailsState } from './loan-details-state';

export function createLoanDetailsState(): LoanDetailsState {
  return {
    loanDetails: {
      term: 0,
      amort: 0,
      deferral: 0,
      assetDescription: '',
      assetSubTypeName: '',
      financeAmount: 0,
      loanAmount: 0,
      frequencyName: '',
      frequencyId: 2,
      programName: '',
      fees: [],
      payment: 0,
      receipts: [],
    },
    isLoading: false,
  };
}
