import {
  ApplicationValidationResult,
  ThirdPartyAddOnTypeDto,
} from '@frontline/common';
import { HttpError } from '@frontline/web-common';
import { ActionType, createAction } from 'typesafe-actions';

export enum AssetsAndAddOnsActions {
  FetchValidations = 'AssetsAndAddOns/FetchValidations',
  FetchValidationsSuccess = 'AssetsAndAddOns/FetchValidationsSuccess',
  FetchValidationsFailure = 'AssetsAndAddOns/FetchValidationsError',
  FetchTypes = 'AssetsAndAddOns/FetchAddonTypes',
  FetchTypesSuccess = 'AssetsAndAddOns/FetchAddonTypesSuccess',
  FetchTypesFailure = 'AssetsAndAddOns/FetchAddonTypesFailure',
}

export const fetchTypes = createAction(AssetsAndAddOnsActions.FetchTypes);

export const fetchTypesSuccess = createAction(
  AssetsAndAddOnsActions.FetchTypesSuccess,
  action => (types: ThirdPartyAddOnTypeDto[]) => action(types),
);

export const fetchTypesFailure = createAction(
  AssetsAndAddOnsActions.FetchTypesFailure,
  action => (error: Error) => action(error),
);

export const fetchValidations = createAction(
  AssetsAndAddOnsActions.FetchValidations,
);

export const fetchValidationsSuccess = createAction(
  AssetsAndAddOnsActions.FetchValidationsSuccess,
  action => (validations: ApplicationValidationResult) => action(validations),
);

export const fetchValidationsError = createAction(
  AssetsAndAddOnsActions.FetchValidationsFailure,
  action => (error: HttpError) => action(error),
);

const actionCreators = {
  fetchValidations,
  fetchValidationsError,
  fetchValidationsSuccess,
  fetchTypes,
  fetchTypesSuccess,
  fetchTypesFailure,
};

export type AssetsAndAddOnsActionType = ActionType<typeof actionCreators>;
