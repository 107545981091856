import { PreAuthorizedDebitView } from '@frontline/web-common';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as applicationStore from '../../../../../../store/application.store';
import * as postSubmissionReviewStore from '../../../../store/post-submission.store';
import { PostSubmissionContentCard } from '../../components/post-submission-content-card';

export const PreAuthorizedDebitFeature: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const application = useSelector(applicationStore.getApplication);
  const applicationId = useSelector(applicationStore.getApplicationId);
  return (
    <PostSubmissionContentCard>
      <PreAuthorizedDebitView
        showIf={Boolean(application)}
        onNext={postSubmissionReviewStore.navigateToNextStep(dispatch)}
        getPreAuthorizedDebitState={
          postSubmissionReviewStore.getPreAuthorizedDebitState
        }
        applicationId={applicationId}
        disableContinue={!Boolean(application?.preAuthorizedDebit)}
        preAuthorizedDebit={application?.preAuthorizedDebit}
      />
    </PostSubmissionContentCard>
  );
};
