import {
  PreSubmissionFormType,
  WorkflowStep,
  WorkflowStepReference,
} from '@frontline/common';
import { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import { RootState, Store } from '../../../../../store';
import * as applicationStore from '../../../store/application.store';
import { updateStepsWithRoutes } from '../types/PreSubmissionRoutes.functions';
import { PreSubmissionState } from '../types/PreSubmissionState/pre-submission.state';
import * as actions from './pre-submission.actions';

export const getPreSubmissionState: Selector<RootState, PreSubmissionState> = (
  state: RootState,
) => state.applicationState.preSubmissionState;

export const getWorkflow: Selector<
  RootState,
  WorkflowStep[] | null
> = createSelector(
  getPreSubmissionState,
  (state: PreSubmissionState) => state.workflow,
);

export const isWorkflowLoaded: Selector<
  RootState,
  boolean
> = createSelector(getPreSubmissionState, (state: PreSubmissionState) =>
  Boolean(state.workflow),
);

export const getActiveStepIndex: Selector<
  RootState,
  number | undefined
> = createSelector(
  getPreSubmissionState,
  (state: PreSubmissionState) => state.activeStepIndex,
);

export const getCurrentWorkflowStep: Selector<
  RootState,
  WorkflowStep | undefined
> = createSelector(
  [getWorkflow, getActiveStepIndex],
  (workflow: WorkflowStep[] | null, index?: number) =>
    workflow && index !== undefined ? workflow[index] : undefined,
);

export const getWorkflowStepReference: Selector<
  RootState,
  WorkflowStepReference | null
> = createSelector(
  getPreSubmissionState,
  (state?: PreSubmissionState) => state?.workflowStepReference || null,
);

export const getPreWorkflowSteps: Selector<
  RootState,
  WorkflowStep[]
> = createSelector(applicationStore.getApplication, application => {
  const preSubmissionSteps = application?.preWorkflow?.steps;
  if (!application || !preSubmissionSteps) {
    return [];
  }
  return updateStepsWithRoutes(preSubmissionSteps, application);
});

export function createWorkflowStepReference(routeUrl: string): void {
  Store.dispatch(actions.createStepReference(routeUrl));
}

export function loadWorkflow(): void {
  Store.dispatch(actions.loadWorkflow());
}

export function setActiveStep(routeUrl: string): void {
  Store.dispatch(actions.setActiveStep(routeUrl));
}

export function nextStep(): void {
  Store.dispatch(actions.nextStep());
}

export function saveStep(form: PreSubmissionFormType): void {
  Store.dispatch(actions.saveStep(form));
}
