import {
  ApplicationStatus,
  isStatusInBookedOrFundingGroup,
} from '@frontline/common';

export function isBookedOrFundingGroupState(
  applicationStatus: ApplicationStatus,
): boolean {
  return isStatusInBookedOrFundingGroup(applicationStatus.id);
}
