import { faUserLock } from '@fortawesome/free-solid-svg-icons/faUserLock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useIntl } from 'react-intl';
import { defineMessages } from 'react-intl';
import { MainLayout } from '../../../modules/layout/components';
import { NotAuthenticatedLayout } from './NotAuthenticatedLayout';

const messages = defineMessages({
  securePage: {
    id: 'notAuthenticatedDefault.securePage',
    description: 'secured page message',
    defaultMessage: 'This is a secured page',
  },
  link: {
    id: 'notAuthenticatedDefault.link',
    description: 'check link sent by merchant',
    defaultMessage:
      'If you are trying to access your application, use the link provided by the merchant',
  },
});

const NotAuthenticatedDefault = () => {
  const intl = useIntl();
  return (
    <NotAuthenticatedLayout
      icon={<FontAwesomeIcon icon={faUserLock} />}
      primaryMessage={intl.formatMessage(messages.securePage)}
      secondaryMessage={intl.formatMessage(messages.link)}
    />
  );
};

export default MainLayout(NotAuthenticatedDefault);
