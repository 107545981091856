import {
  muiTheme,
  ShowIfFragment,
  TypographyVariant,
  useUatId,
} from '@frontline/ui-library';
import { WorkflowStepIcon } from '@frontline/web-common';
import { Typography, useMediaQuery } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { ChecklistButtonProps } from './check-list-button.props';
import {
  StyledButton,
  useChecklistButtonStyles,
} from './check-list-button.styles';
import { ChecklistButtonUat } from './check-list-button.uat';

export const ChecklistButton: FunctionComponent<ChecklistButtonProps> = (
  props: ChecklistButtonProps,
) => {
  const uatId = useUatId();
  const isMediaExtraSmall = useMediaQuery(muiTheme.breakpoints.down('xs'));
  const classes = useChecklistButtonStyles({
    active: props.active,
    mobile: isMediaExtraSmall,
  });

  return (
    <ShowIfFragment showIf={Boolean(props.stepName)}>
      <StyledButton
        classes={classes}
        disableRipple={true}
        onClick={props.onClick}
        {...uatId(ChecklistButtonUat[props.stepType ?? ''])}>
        <WorkflowStepIcon status={props.status} />
        <Typography variant={TypographyVariant.Subtitle2}>
          {props.stepName}
        </Typography>
      </StyledButton>
    </ShowIfFragment>
  );
};
