import { BankDetails, PaymentDetails, StoredDocument } from '@frontline/common';
import { actions, selectors, WebCommonRootState } from '@frontline/web-common';
import isEqual from 'lodash/isEqual';
import { Dispatch } from 'redux';
import { createSelector, Selector } from 'reselect';
import * as postSubmissionReviewStore from '../../../../../store/post-submission.store';
import { getFileNamesToAttach } from '../types';

const { applicationActions } = actions;
const { applicationSelectors, postSubmissionSelectors } = selectors;

export const getPaymentDetails: Selector<
  WebCommonRootState,
  PaymentDetails
> = createSelector(
  ({ application }) => applicationSelectors.getPaymentDetails(application),
  paymentDetails => {
    if (paymentDetails) {
      return paymentDetails;
    }
    return { bankDetails: {} as BankDetails } as PaymentDetails;
  },
);

export const getApplicationDocuments = ({ application }: WebCommonRootState) =>
  applicationSelectors.getApplicationDocuments(application);

export const getVoidChequeDocuments = ({ application }: WebCommonRootState) =>
  applicationSelectors.getVoidChequeDocuments(application);

export const getIsSaving = ({ application }: WebCommonRootState) =>
  applicationSelectors.isSaving(application);

export const getDisabled = ({ application }: WebCommonRootState) =>
  !postSubmissionSelectors.enablePaymentDetailUpdates(application);

export const getUploadingDocuments = ({ application }: WebCommonRootState) =>
  application.uploadingDocuments;

export const savePaymentDetails = (dispatch: Dispatch) => (
  bankDetails: BankDetails,
  initialValues: BankDetails,
  storedDocuments: StoredDocument[],
  errorMessage: string,
): void => {
  if (isEqual(bankDetails, initialValues)) {
    postSubmissionReviewStore.navigateToNextStep(dispatch)();
    return;
  }

  const dispatchSaveDetails = () => {
    dispatch(applicationActions.savePaymentDetails({ bankDetails }));
  };

  dispatch(
    applicationActions.attachPendingDocuments({
      fileNames: getFileNamesToAttach(storedDocuments),
      errorMessage,
      onSuccess: dispatchSaveDetails,
      onError: dispatchSaveDetails,
    }),
  );
};
