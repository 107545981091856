import {
  Application,
  ApprovalConditionLookupDto,
  Language,
} from '@frontline/common';
import { HttpError } from '@frontline/web-common';
import { ActionType, createAction } from 'typesafe-actions';
import { OverviewTab } from '../types';

export enum OverviewActions {
  ToggleSummaryCardDetails = 'Overview/ToggleSummaryCardDetails',
  GoToOverviewTab = 'Overview/GoToOverviewTab',
  SetLoading = 'Overview/SetLoading',
  InitConditionsLookup = 'Overview/InitConditionsLookup',
  InitConditionsLookupSuccess = 'Overview/InitConditionsLookupSuccess',
  InitConditionsLookupError = 'Overview/InitConditionsLookupError',
  OpenCongratulationsDialog = 'Overview/OpenCongratulationsDialog',
  SetPostSubmissionViewed = 'Overview/SetPostSubmissionViewed',
  SetPostSubmissionViewedSuccess = 'Overview/SetPostSubmissionViewedSuccess',
  SetPostSubmissionViewedFailure = 'Overview/SetPostSubmissionViewedFailure',
}

export const initConditionsLookup = createAction(
  OverviewActions.InitConditionsLookup,
  action => (locale: Language) => action(locale),
);

export const initConditionsLookupSuccess = createAction(
  OverviewActions.InitConditionsLookupSuccess,
  action => (
    approvalConditionsLookupList: ApprovalConditionLookupDto[],
    locale: Language,
  ) => action({ approvalConditionsLookupList, locale }),
);

export const initConditionsLookupError = createAction(
  OverviewActions.InitConditionsLookupError,
  action => (error: HttpError) => action(error),
);

export const toggleSummaryCardDetails = createAction(
  OverviewActions.ToggleSummaryCardDetails,
);

export const goToOverviewTab = createAction(
  OverviewActions.GoToOverviewTab,
  action => (tab: OverviewTab) => action(tab),
);

export const setLoading = createAction(
  OverviewActions.SetLoading,
  action => (status: boolean) => action(status),
);
export const openCongratulationsDialog = createAction(
  OverviewActions.OpenCongratulationsDialog,
);

export const setPostSubmissionViewed = createAction(
  OverviewActions.SetPostSubmissionViewed,
  action => (applicationId: string) => action(applicationId),
);

export const setPostSubmissionViewedSuccess = createAction(
  OverviewActions.SetPostSubmissionViewedSuccess,
  action => (application: Application) => action(application),
);

export const setPostSubmissionViewedFailure = createAction(
  OverviewActions.SetPostSubmissionViewedFailure,
  action => (error: HttpError) => action(error),
);

const OverviewActionCreators = {
  toggleSummaryCardDetails,
  goToOverviewTab,
  setLoading,
  initConditionsLookup,
  initConditionsLookupSuccess,
  initConditionsLookupError,
  openCongratulationsDialog,
  setPostSubmissionViewed,
  setPostSubmissionViewedSuccess,
  setPostSubmissionViewedFailure,
};

export type OverviewActionType = ActionType<typeof OverviewActionCreators>;
