import { defineMessages } from 'react-intl';

export const LoanDetailsPaymentPlanMessages = defineMessages({
  paymentPlanTitle: {
    id:
      'Applicant.PostSubmission.LoanDetails.Modules.PaymentPlan.paymentPlanTitle',
    description:
      'Payment Plan Label for the Payment Plan Section of Loan Details in Application Portal',
  },
  requestedAmountLabel: {
    id:
      'Applicant.PostSubmission.LoanDetails.Modules.PaymentPlan.requestedAmountLabel',
    description:
      'Requested Amount Label for the Requested Amount Section of Loan Details in Application Portal',
  },
  additionalFeesLabel: {
    id:
      'Applicant.PostSubmission.LoanDetails.Modules.PaymentPlan.additionalFeesLabel',
    description:
      'Additional Fees Label for the Additional Fees Section of Loan Details in Application Portal',
  },
  interestRateLabel: {
    id:
      'Applicant.PostSubmission.LoanDetails.Modules.PaymentPlan.interestRateLabel',
    description:
      'Interest Rate Label for the Interest Rate Section of Loan Details in Application Portal',
  },
  deferralLabel: {
    id:
      'Applicant.PostSubmission.LoanDetails.Modules.PaymentPlan.deferralLabel',
    description:
      'Payment Plan Label for the Payment Plan Section of Loan Details in Application Portal',
  },
  termLabel: {
    id: 'Applicant.PostSubmission.LoanDetails.Modules.PaymentPlan.termLabel',
    description:
      'Term Label for the Term Section of Loan Details in Application Portal',
  },
  amortizationLabel: {
    id:
      'Applicant.PostSubmission.LoanDetails.Modules.PaymentPlan.amortizationLabel',
    description:
      'Amortization Label for the Amortization Section of Loan Details in Application Portal',
  },
  paymentFrequencyLabel: {
    id:
      'Applicant.PostSubmission.LoanDetails.Modules.PaymentPlan.paymentFrequencyLabel',
    description:
      'Payment Frequency Label for the Payment Frequency Section of Loan Details in Application Portal',
  },
  contractStartDateLabel: {
    id:
      'Applicant.PostSubmission.LoanDetails.Modules.PaymentPlan.contractStartDateLabel',
    description:
      'Contact Start Date Label for the Contract Start Date Section of Loan Details in Application Portal',
  },
  firstPaymentDateLabel: {
    id:
      'Applicant.PostSubmission.LoanDetails.Modules.PaymentPlan.firstPaymentDateLabel',
    description:
      'First Payment Date Label for the First Payment Date Section of Loan Details in Application Portal',
  },
  invoiceEstimateAmountTitle: {
    id:
      'Applicant.PostSubmission.LoanDetails.Modules.PaymentPlan.invoiceEstimateAmountTitle',
    description: 'Title for estimated cost of services',
  },
  downPaymentAmountTitle: {
    id:
      'Applicant.PostSubmission.LoanDetails.Modules.PaymentPlan.downPaymentAmountTitle',
    description: 'Title for downpayment amount',
  },
  confirmEstimateAmount: {
    id:
      'Applicant.PostSubmission.LoanDetails.Modules.PaymentPlan.confirmEstimateAmount',
    description: 'Confirm estimate amount',
  },
  tgiInsuranceEnrollmentError: {
    id: 'FinalizeLoanDetails.tgiInsuranceEnrollmentError',
    description: 'Finalize loan details tgi insurance frequency error',
  },
  noReceipts: {
    id: 'Applicant.PostSubmission.LoanDetails.Modules.PaymentPlan.noReceipts',
    description: 'Message to display when there are no receipts',
  },
});
