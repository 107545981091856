import { PersonalDetailsForm, PersonalDetailsRules } from '@frontline/common';
import { createPersonalDetailsFormValues } from '@frontline/web-common';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as coApplicantDetailsStore from '../../../../common/CoApplicant/store/coApplicantDetails.store';
import * as personalDetailsStore from '../../../../common/personal-details/store/personal-details.store';
import { shouldSavePersonalDetails } from '../../../../common/personal-details/types/PersonalDetailsFormValues';
import { PreSubmissionFormPropsType } from '../../../../types/PreSubmissionForm/PreSubmissionFormPropsType';
import { getDisplayStepPosition } from '../../../../types/StepPosition.functions';
import { CoApplicantPersonalDetailsView } from './components/co-applicant-personal-details-view.component';
import * as store from './store/coApplicantPersonalDetails.store';

export const CoApplicantPersonalDetailsFeature = (
  props: PreSubmissionFormPropsType<PersonalDetailsForm>,
) => {
  const dispatch = useDispatch();

  const personalDetails = useSelector(
    coApplicantDetailsStore.getCoApplicantPersonalDetails,
  );

  const relationshipTypes = useSelector(store.getRelationshipTypes);

  useEffect(() => {
    if (relationshipTypes.length === 0) {
      store.fetchRelationshipTypes(dispatch)();
    }
  }, [dispatch, relationshipTypes]);

  const onSubmit = (changes: PersonalDetailsForm): void => {
    const rules = props.stepReference?.step.rules as PersonalDetailsRules;
    props.onSubmit(
      changes,
      shouldSavePersonalDetails(
        props.stepReference,
        createPersonalDetailsFormValues(
          true,
          personalDetails,
          rules?.hideResidentialLengthOfStayField,
        ),
      ),
    );
  };

  return (
    <CoApplicantPersonalDetailsView
      fastFillAttempts={useSelector(store.getCoApplicantFastFillAttempts)}
      saveFastFillDetails={store.saveCoApplicantDetailsFastFill(dispatch)}
      isCoApplicant={true}
      personalDetails={personalDetails}
      applicantAddress={useSelector(personalDetailsStore.getApplicantAddress)}
      relationshipTypes={useSelector(store.getRelationshipTypes)}
      stepPosition={getDisplayStepPosition(props.stepPosition)}
      stepReference={props.stepReference}
      onSubmit={onSubmit}
    />
  );
};
