import { defineMessages } from 'react-intl';

export const PersonalDetailsFormMessages = defineMessages({
  firstNameLabel: {
    id: 'personalDetails.firstName',
    description: 'First Name Label',
  },
  firstNamePlaceholder: {
    id: 'personalDetails.firstNamePlaceholder',
    description: 'First Name Placeholder',
  },
  lastNameLabel: {
    id: 'personalDetails.lastName',
    description: 'Last Name Label',
  },
  lastNamePlaceholder: {
    id: 'personalDetails.lastNamePlaceholder',
    description: 'Last Name Placeholder',
  },
  nameExceedsMaxLength: {
    id: 'personalDetails.nameExceedsMaxLength',
    description: 'Name exceeds max length',
  },
  firstNameRequired: {
    id: 'personalDetails.firstNameRequired',
    description: 'First Name Field Required',
  },
  lastNameRequired: {
    id: 'personalDetails.lastNameRequired',
    description: 'Last Name Field Required',
  },
  dobRequired: {
    id: 'personalDetails.dobRequired',
    description: 'Date of Birth Required',
  },
  relationshipTypePlaceholder: {
    id: 'personalDetails.relationshipTypePlaceholder',
    description: 'Relationship Type Placeholder',
  },
  relationshipTypeLabel: {
    id: 'personalDetails.relationshipTypeLabel',
    description: 'Relationship Type Label',
  },
  relationshipTypeIdRequired: {
    id: 'personalDetails.relationshipTypeIdRequired',
    description: 'Relationship Type Required',
  },
  proofOfIdHelperText: {
    id: 'personalDetails.proofOfIdHelperText',
    description: 'Proof of ID helper text',
  },
});
