import * as React from 'react';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

const OverviewStepDescription = (props: PropsWithChildren<any>) => (
  <Description>{props.children}</Description>
);
export default OverviewStepDescription;

const Description = styled.div<any>`
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  color: #203d54;
  margin-bottom: 20px;
`;
