import {
  DocusignRedirectParameters,
  Language,
  SignDocument,
  SignDocumentsErrors,
} from '@frontline/common';
import { SignDocumentsState } from '@frontline/web-common';
import { ActionType, createAction } from 'typesafe-actions';

export enum SignDocumentsActions {
  LoadSignDocuments = 'SignDocuments/LoadSignDocuments',
  LoadSignDocumentsSuccess = 'SignDocuments/LoadSignDocumentsSuccess',
  LoadSignDocumentsError = 'SignDocuments/LoadSignDocumentsError',
  PrintSignDocument = 'SignDocuments/PrintSignDocument',
  PrintSignDocumentSuccess = 'SignDocuments/PrintSignDocumentSuccess',
  PrintSignDocumentError = 'SignDocuments/PrintSignDocumentError',
  SelectLanguage = 'SignDocuments/SelectLanguage',
  UpdateRedirectParameters = 'SignDocuments/UpdateRedirectParameters',
  ResetSignDocuments = 'SignDocuments/ResetSignDocuments',
  ResetSignDocumentsSuccess = 'SignDocuments/ResetSignDocumentsSuccess',
  ResetSignDocumentsError = 'SignDocuments/ResetSignDocumentsError',
  OpenEmailDialog = 'SignDocuments/OpenEmailDialog',
  CloseEmailDialog = 'SignDocuments/CloseEmailDialog',
  OpenSignDocumentDialog = 'SignDocuments/OpenSignDocumentDialog',
  CloseSignDocumentDialog = 'SignDocuments/CloseSignDocumentDialog',
  SignDocumentOnDevice = 'SignDocuments/SignDocumentOnDevice',
  SignDocumentOnDeviceSuccess = 'SignDocuments/SignDocumentOnDeviceSuccess',
  SignDocumentOnDeviceError = 'SignDocuments/SignDocumentOnDeviceError',
  EmailDocument = 'SignDocuments/EmailDocument',
  EmailDocumentSuccess = 'SignDocuments/EmailDocumentSuccess',
  EmailDocumentError = 'SignDocuments/EmailDocumentError',
  SaveSignDocuments = 'SignDocuments/SaveSignDocuments',
  SaveSignDocumentsSuccess = 'SignDocuments/SaveSignDocumentsSuccess',
  SaveSignDocumentsError = 'SignDocuments/SaveSignDocumentsError',
  UpdatedSuccess = 'SignDocuments/UpdatedSuccess',
  SetSigningDocumentAction = 'SignDocuments/SetSigningDocumentAction',
  ResendSignDocuments = 'SignDocuments/ResendSignDocuments',
  ResendSignDocumentsSuccess = 'SignDocuments/ResendSignDocumentsSuccess',
  ResendSignDocumentsError = 'SignDocuments/ResendSignDocumentsError',
}

export const loadSignDocuments = createAction(
  SignDocumentsActions.LoadSignDocuments,
  resolve => () => resolve(),
);

export const loadSignDocumentsSuccess = createAction(
  SignDocumentsActions.LoadSignDocumentsSuccess,
  resolve => (signDocuments: SignDocument[]) => resolve(signDocuments),
);

export const loadSignDocumentsError = createAction(
  SignDocumentsActions.LoadSignDocumentsError,
  resolve => (error: SignDocumentsErrors[]) => resolve({ error }),
);

export const printSignDocument = createAction(
  SignDocumentsActions.PrintSignDocument,
  resolve => (signDocument: SignDocument) => resolve(signDocument),
);

export const printSignDocumentSuccess = createAction(
  SignDocumentsActions.PrintSignDocumentSuccess,
);

export const printSignDocumentError = createAction(
  SignDocumentsActions.PrintSignDocumentError,
  resolve => (error: SignDocumentsErrors[]) => resolve({ error }),
);

export const selectLanguage = createAction(
  SignDocumentsActions.SelectLanguage,
  resolve => (language: Language) => resolve(language),
);

export const updateRedirectParameters = createAction(
  SignDocumentsActions.UpdateRedirectParameters,
  resolve => (redirectParameters: DocusignRedirectParameters | null) =>
    resolve(redirectParameters),
);

export const resetSignDocuments = createAction(
  SignDocumentsActions.ResetSignDocuments,
);

export const resetSignDocumentsSuccess = createAction(
  SignDocumentsActions.ResetSignDocumentsSuccess,
  resolve => (signDocuments: SignDocument[]) => resolve(signDocuments),
);

export const resetSignDocumentsError = createAction(
  SignDocumentsActions.ResetSignDocumentsError,
  resolve => (error: any) => resolve(error),
);

export const openEmailDialog = createAction(
  SignDocumentsActions.OpenEmailDialog,
  resolve => (signDocument: SignDocument) => resolve(signDocument),
);

export const closeEmailDialog = createAction(
  SignDocumentsActions.CloseEmailDialog,
);

export const openSignDocumentDialog = createAction(
  SignDocumentsActions.OpenSignDocumentDialog,
  resolve => (signDocument: SignDocument) => resolve(signDocument),
);

export const closeSignDocumentDialog = createAction(
  SignDocumentsActions.CloseSignDocumentDialog,
);

export const signDocumentOnDevice = createAction(
  SignDocumentsActions.SignDocumentOnDevice,
  resolve => ({
    signDocument,
    signerIndex,
  }: {
    signDocument: SignDocument;
    signerIndex: number;
  }) => resolve({ signDocument, signerIndex }),
);

export const signDocumentOnDeviceSuccess = createAction(
  SignDocumentsActions.SignDocumentOnDeviceSuccess,
);

export const signDocumentOnDeviceError = createAction(
  SignDocumentsActions.SignDocumentOnDeviceError,
  resolve => (error: SignDocumentsErrors[]) => resolve({ error }),
);

export const emailDocument = createAction(
  SignDocumentsActions.EmailDocument,
  resolve => (signDocument: SignDocument) => resolve(signDocument),
);

export const emailDocumentSuccess = createAction(
  SignDocumentsActions.EmailDocumentSuccess,
);

export const emailDocumentError = createAction(
  SignDocumentsActions.EmailDocumentError,
  resolve => (error: SignDocumentsErrors[]) => resolve({ error }),
);

export const saveSignDocuments = createAction(
  SignDocumentsActions.SaveSignDocuments,
  resolve => ({
    signDocuments,
    routeAfterSaving,
  }: {
    signDocuments: SignDocument[];
    routeAfterSaving: string;
  }) => resolve({ signDocuments, routeAfterSaving }),
);

export const saveSignDocumentsSuccess = createAction(
  SignDocumentsActions.SaveSignDocumentsSuccess,
  resolve => (routeAfterSaving: string) => resolve(routeAfterSaving),
);

export const saveSignDocumentsError = createAction(
  SignDocumentsActions.SaveSignDocumentsError,
  resolve => (error: any) => resolve(error),
);

export const updatedSuccess = createAction(SignDocumentsActions.UpdatedSuccess);

export const setIsEmailOrEmailAndSmsAction = createAction(
  SignDocumentsActions.SetSigningDocumentAction,
  resolve => (
    signingDocumentAction: SignDocumentsState['isEmailOrEmailAndSmsAction'],
  ) => resolve(signingDocumentAction),
);

export const resendSignDocuments = createAction(
  SignDocumentsActions.ResendSignDocuments,
);

export const resendSignDocumentsSuccess = createAction(
  SignDocumentsActions.ResendSignDocumentsSuccess,
  resolve => () => resolve(),
);

export const resendSignDocumentsError = createAction(
  SignDocumentsActions.ResendSignDocumentsError,
  resolve => (error: any) => resolve(error),
);

const SignDocumentActionCreators = {
  emailDocument,
  emailDocumentSuccess,
  emailDocumentError,
  loadSignDocuments,
  loadSignDocumentsSuccess,
  loadSignDocumentsError,
  printSignDocument,
  printSignDocumentSuccess,
  printSignDocumentError,
  selectLanguage,
  updateRedirectParameters,
  resetSignDocuments,
  resetSignDocumentsSuccess,
  resetSignDocumentsError,
  openEmailDialog,
  closeEmailDialog,
  openSignDocumentDialog,
  closeSignDocumentDialog,
  saveSignDocuments,
  saveSignDocumentsSuccess,
  saveSignDocumentsError,
  signDocumentOnDevice,
  signDocumentOnDeviceSuccess,
  signDocumentOnDeviceError,
  updatedSuccess,
  setIsEmailOrEmailAndSmsAction,
  resendSignDocuments,
  resendSignDocumentsSuccess,
  resendSignDocumentsError,
};

export type SignDocumentsActionType = ActionType<
  typeof SignDocumentActionCreators
>;
