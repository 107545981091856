import {
  Application,
  ProgramType,
  SubmissionStatus,
  WorkflowStep,
  WorkflowStepStatus,
  WorkflowStepType,
} from '@frontline/common';

export const getApplicationProgressPercent = (
  steps: WorkflowStep[],
): number => {
  const progress = steps.filter(
    step => step.status !== WorkflowStepStatus.Pending,
  );
  return (progress.length / steps.length) * 100;
};

export function isSubmitted(status?: SubmissionStatus): boolean {
  return (
    Boolean(status) &&
    (status === SubmissionStatus.Pending ||
      status === SubmissionStatus.Submitted)
  );
}

export function shouldAdvanceToResultPage(
  application: Application,
  currentWorkflowStep: WorkflowStep,
  currentPath: string,
): boolean {
  return (
    application.submissionStatus === SubmissionStatus.Submitted &&
    currentWorkflowStep.type === WorkflowStepType.Review &&
    currentPath.indexOf('/result') === -1
  );
}

export function shouldGoToPostAdjustedForProgramType(
  programType: ProgramType = ProgramType.Default,
  applicantOptionGoToPostAfterPresubmission: boolean,
): boolean {
  return (
    programType === ProgramType.RepairTech ||
    applicantOptionGoToPostAfterPresubmission
  );
}
