export const PurchaseDetailsFormUat = {
  purchaseDetailFinancialProgramField:
    'newApplication.purchaseDetails.form.financialProgram.field',
  purchaseDetailFinancialProgramMenuItem:
    'newApplication.purchaseDetails.form.financialProgram.menuItem',
  purchaseDetailProductServiceTypeField:
    'newApplication.purchaseDetails.form.productServiceType.field',
  purchaseDetailProductServiceTypeMenuItem:
    'newApplication.purchaseDetails.form.productServiceType.menuItem',
  purchaseDetailRequestedAmount:
    'newApplication.purchaseDetails.form.requestedAmount.field',
};
