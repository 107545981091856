import { defineMessages } from 'react-intl';

export const AdditionalDetailsHeaderMessages = defineMessages({
  applicant: {
    id: 'AdditionalDetailsView.applicant',
    description: 'Applicant',
  },
  theApplicant: {
    id: 'AdditionalDetailsView.theApplicant',
    description: 'the applicant',
  },
  title: {
    id: 'AdditionalDetailsView.title',
    description: 'Additional Details Step Title',
  },
  description: {
    id: 'AdditionalDetailsView.description',
    description: 'Additional Details Step Description',
  },
});
