import { defineMessages } from 'react-intl';

export default defineMessages({
  primaryPhoneLabel: {
    id: 'contactDetails.primaryPhoneNumber',
    description: 'Primary Phone Label',
    defaultMessage: 'Primary Phone',
  },
  primaryPhoneRequired: {
    id: 'contactDetails.primaryPhoneRequired',
    description: 'Primary Phone Required',
    defaultMessage: 'Please enter a primary phone',
  },
  emailLabel: {
    id: 'contactDetails.email',
    description: 'Email Address Label',
    defaultMessage: 'Email Address',
  },
  emailRequired: {
    id: 'contactDetails.emailRequired',
    description: 'Email Address Required',
    defaultMessage: 'Please enter an email address',
  },
  emailInvalid: {
    id: 'contactDetails.emailInvalid',
    description: 'Email Address Invalid',
    defaultMessage: 'Please enter a valid email address',
  },
  emailExceedsMaxLength: {
    id: 'contactDetails.emailExceedsMaxLength',
    description: 'Email exceeds max length',
    defaultMessage: 'Please enter a maximum of {max} characters',
  },
  phoneNumberInvalid: {
    id: 'contactDetails.phoneNumberInvalid',
    description: 'Phone Invalid',
    defaultMessage: 'Please enter a valid phone number',
  },
  phoneExample: {
    id: 'contactDetails.phoneExample',
    description: 'Phone example',
  },
  emailExample: {
    id: 'contactDetails.emailExample',
    description: 'Email example',
  },
});
