export const PersonalDetailsFormViewUat = {
  personalDetailsPrimaryPhone:
    'newApplication.personalDetails.form.primaryPhone.field',
  personalDetailsEmail: 'newApplication.personalDetails.form.email.field',
  personalDetailsSameAddress:
    'newApplication.personalDetails.form.sameAddress.field',
  personalDetailsLengthOfStay:
    'newApplication.personalDetails.detailsGrid.form.lengthOfStay.field',
  personalDetailsLengthOfStayMenuItem:
    'newApplication.personalDetails.detailsGrid.form.lengthOfStay.menuItem',
  personalDetailsHouseholdExpenses:
    'newApplication.personalDetails.detailsGrid.form.householdExpenses.field',
};
