import * as React from 'react';

import { OverviewStepMenuPageGeneric } from '../OverviewStepMenuPageGeneric/overview-step-menu-item-generic.component';
import { OverviewStepMenuPage } from '../OverviewStepMenuPageGeneric/overview-step-menu-page.component';

import { PreAuthorizedDebitMessages } from './pre-authorized-debit-step.messages';
import { PreAuthorizedDebitStepProps } from './pre-authorized-debit-step.props';

export const PreAuthorizedDebitStep: React.FunctionComponent<PreAuthorizedDebitStepProps> = (
  props: PreAuthorizedDebitStepProps,
) => {
  if (props.step.overviewMessages) {
    return (
      <OverviewStepMenuPage
        routeUrl={props.step.routeUrl || ''}
        status={props.step.status}
        title={props.step.overviewMessages?.title || ''}
        description={props.step.overviewMessages?.description || ''}
        editButtonText={props.step.overviewMessages?.editButton || ''}
        editButtonUat={props.uat}
      />
    );
  }
  return (
    <OverviewStepMenuPageGeneric
      title={PreAuthorizedDebitMessages.title}
      description={PreAuthorizedDebitMessages.description}
      routeUrl={props.step.routeUrl || ''}
      editButtonText={PreAuthorizedDebitMessages.editButton}
      status={props.step.status}
      editButtonUat={props.uat}
    />
  );
};
