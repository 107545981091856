import { WorkflowStep } from '@frontline/common';
import { ApplicationProgress, LocaleContext } from '@frontline/web-common';
import { useContext, useEffect } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as yearsWorkedCategoryStore from '../../../../../../../../modules/lookup/years-worked-category/years-worked-category.store';
import { CannotEditPageMessage } from '../../../../common/CannotEditPageMessage';
import { getStepMessageDefinition } from '../../../../types';
import { PostSubmissionContentCard } from '../../components/post-submission-content-card';
import { CoApplicantRoutes } from './co-applicant-routes.component';

interface CoApplicantDetailsProps {
  applicationId: string;
  steps: WorkflowStep[];
}

export const CoApplicantDetailsFeature = (props: CoApplicantDetailsProps) => {
  const localeContext = useContext(LocaleContext);
  const intl = useIntl();
  // TODO: refactor ApplicationProgress to handle internationalization
  const applicantDetailsSteps = props.steps.map((step: WorkflowStep) => {
    step.name = intl.formatMessage(getStepMessageDefinition(step.type));
    return step;
  });

  const yearsWorkedCategories = useSelector(
    yearsWorkedCategoryStore.getYearsWorkedCategoriesDropdown,
  );

  useEffect(() => {
    yearsWorkedCategoryStore.fetchYearsWorkedCategories();
  }, [localeContext.locale]);

  return (
    <>
      <ApplicationProgress steps={applicantDetailsSteps} />
      <PostSubmissionContentCard>
        <CannotEditPageMessage />
        <CoApplicantRoutes
          applicationId={props.applicationId}
          disabled={true}
          yearsWorkedCategoriesLookup={yearsWorkedCategories || []}
        />
      </PostSubmissionContentCard>
    </>
  );
};
