import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'PaymentDetailsStep.title',
    description: 'Payment Details Step Title',
    defaultMessage: 'Set up payment details',
  },
  description: {
    id: 'PaymentDetailsStep.description',
    description: 'Payment Details Step Description',
    defaultMessage:
      'Add bank account details to set up the recurring loan repayments',
  },
  editButton: {
    id: 'PaymentDetailsStep.editButton',
    description: 'Payment Details Step Edit Button',
    defaultMessage: 'Set up payment details',
  },
});
