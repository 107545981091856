import {
  getAssetConditionsMessageDescriptor,
  SectionItem,
  SectionPanel,
} from '@frontline/ui-library';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import { AssetInformationSectionHeader } from '../AssetInformationSectionHeader/AssetInformationSectionHeader';
import messages from './AssetDetailList.messages';
import { AssetDetailListProps } from './AssetDetailListProps';

export const AssetDetailList = (props: AssetDetailListProps) => {
  const intl = useIntl();

  return (
    <React.Fragment>
      <AssetInformationSectionHeader
        title={intl.formatMessage(messages.assetDetailsTitle)}
      />

      {props.assets.map((asset, index) => (
        <SectionPanel title="" key={index}>
          <Grid container={true} spacing={3}>
            <SectionItem
              label={intl.formatMessage(messages.serialLabel)}
              data={asset.vinOrSerial}
            />
            <SectionItem
              label={intl.formatMessage(messages.conditionLabel)}
              data={intl.formatMessage(
                getAssetConditionsMessageDescriptor(asset.condition),
              )}
            />
            <SectionItem
              label={intl.formatMessage(messages.yearLabel)}
              data={asset.yearManufactured}
            />

            <SectionItem
              label={intl.formatMessage(messages.makeLabel)}
              data={asset.make}
            />
            <SectionItem
              label={intl.formatMessage(messages.modelLabel)}
              data={asset.model}
            />
            <SectionItem
              label={intl.formatMessage(messages.hoursOnMotorLabel)}
              data={asset.hoursOnMotor}
            />
            <SectionItem
              label={intl.formatMessage(messages.costLabel)}
              data={
                <NumberFormat
                  value={asset.cost}
                  displayType={'text'}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              }
            />
          </Grid>
        </SectionPanel>
      ))}
    </React.Fragment>
  );
};
