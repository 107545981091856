/* tslint:disable:ordered-imports */
import { Application } from '@frontline/common';
import { withShowIf } from '@frontline/ui-library';
import { HelpButton, isFullBrand } from '@frontline/web-common';
import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { LanguageSelectionMenu } from '../../../../common/localization';
import * as applicationStore from '../../../../features/application/store/application.store';
import ApplicantPortalLogo from '../applicant-portal-logo/applicant-portal-logo.component';
import { LayoutToolbar } from '../index';
import { ToolbarButtons } from './applicant-portal-desktop-toolbar.styles';
import { isShowCoBrand } from './types/applicant-portal-desktop-toolbar.functions';
import { ApplicantProfileMenu } from '../applicant-profile-menu/applicant-profile-menu.component';

const DesktopToolbar: FunctionComponent = () => {
  const application: Application | undefined = useSelector(
    applicationStore.getApplication,
  );
  return (
    <LayoutToolbar>
      <ApplicantPortalLogo
        isFullBrand={isFullBrand(application)}
        showCoBrandedLogo={isShowCoBrand(application)}
      />
      <ToolbarButtons>
        <HelpButton />
        <LanguageSelectionMenu />
        <ApplicantProfileMenu />
      </ToolbarButtons>
    </LayoutToolbar>
  );
};

export const ApplicantPortalDesktopToolbar = withShowIf(DesktopToolbar);
