import { WorkflowStepType } from '@frontline/common';

export const MenuPageSelectorUat = {
  [WorkflowStepType.AddOns]:
    'postSubmission.page.overview.stepSimple.addOns.edit.button',
  [WorkflowStepType.AssetInformation]:
    'postSubmission.page.overview.stepSimple.assetInformation.edit.button',
  [WorkflowStepType.AssetsAndAddOns]:
    'postSubmission.page.overview.stepSimple.assetsAndAddOns.edit.button',
  [WorkflowStepType.BankDocuments]:
    'postSubmission.page.overview.stepSimple.bankDocuments.edit.button',
  [WorkflowStepType.LoanAndFinancingDetails]:
    'postSubmission.page.overview.stepSimple.loanAndFinancingDetails.edit.button',
  [WorkflowStepType.Flinks]:
    'postSubmission.page.overview.stepSimple.edit.flinks.button',
  [WorkflowStepType.PreAuthorizedDebit]:
    'postSubmission.page.overview.stepSimple.preAuthorizedDebit.edit.button',
  [WorkflowStepType.PaymentDetails]:
    'postSubmission.page.overview.stepSimple.paymentDetails.edit.button',
  [WorkflowStepType.SignDocuments]:
    'postSubmission.page.overview.stepSimple.signDocuments.edit.button',
  [WorkflowStepType.LoanProtection]:
    'postSubmission.page.overview.stepSimple.loanProtection.edit.button',
  [WorkflowStepType.InvoiceDetails]:
    'postSubmission.page.overview.stepSimple.invoiceDetails.edit.button',
  [WorkflowStepType.VerifyIdentity]:
    'postSubmission.page.overview.stepSimple.verifyIdentity.edit.button',
  [WorkflowStepType.ComponentSelector]:
    'postSubmission.page.overview.stepSimple.componentSelector.edit.button',
  [WorkflowStepType.HomeAndAutoAncillaryProduct]:
    'postSubmission.page.overview.stepSimple.homeAndAutoAncillaryProduct.edit.button',
};
