import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withShowIf } from '@frontline/ui-library';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ApplicationTakeoverReferenceCode } from '../../../application-takeover-reference-code/application-takeover-reference-code.component';
import { ApplicationTakeoverAcceptedMessages } from './application-takeover-accepted.messages';
import { ApplicationTakeoverAcceptedProps } from './application-takeover-accepted.props';
import { useApplicationTakeoverAcceptedStyles } from './application-takeover-accepted.styles';

export const ApplicationTakeoverAccepted = withShowIf(
  (props: ApplicationTakeoverAcceptedProps) => {
    const classes = useApplicationTakeoverAcceptedStyles();
    const intl = useIntl();
    return (
      <>
        <Grid item>
          <FontAwesomeIcon
            icon={['fas', 'check-circle']}
            className={classes.icon}
          />
        </Grid>
        <Grid item xs={11} sm={7}>
          <Typography className={classes.title}>
            <FormattedMessage
              {...ApplicationTakeoverAcceptedMessages.title}
              values={{
                status: (
                  <b>
                    {intl.formatMessage(
                      ApplicationTakeoverAcceptedMessages.status,
                    )}
                  </b>
                ),
                merchantName: props.applicationTakeoverDetails?.merchant.name,
              }}
            />
          </Typography>
        </Grid>
        <Grid item className={classes.referenceCodeContainer}>
          <ApplicationTakeoverReferenceCode
            referenceCode={
              props.applicationTakeoverDetails?.appReferenceCode ?? ''
            }
          />
        </Grid>
        <Grid item>
          <Typography className={classes.nextStep}>
            <FormattedMessage
              {...ApplicationTakeoverAcceptedMessages.nextStep}
            />
          </Typography>
        </Grid>
        <Grid item xs={11} sm={7}>
          <Typography className={classes.description}>
            <FormattedMessage
              {...ApplicationTakeoverAcceptedMessages.description}
            />
          </Typography>
        </Grid>
      </>
    );
  },
);
