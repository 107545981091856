import { useUatId } from '@frontline/ui-library';
import {
  CollapsibleEmploymentDetails,
  EmploymentStatusFormValues,
  EmploymentStatusSection,
  HouseholdExpenses,
  PreSubmissionStepActions,
  preSubmitAdditionalDetailsHandler,
  ResidentialDetails,
} from '@frontline/web-common';

import {
  AdditionalDetailsFormValues,
  createAdditionalDetailsFormValues,
  getValidationSchema,
  prepareFormValues,
} from '@frontline/common';
import { Form, Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as store from '../../store/additionalDetails.store';
import { AdditionalDetailsFormLayout } from '../additional-details-form-layout';
import { AdditionalDetailsFormProps } from './additional-details-form.props';
import { AdditionalDetailsFormUat } from './additional-details-form.uat';

export const AdditionalDetailsForm = (props: AdditionalDetailsFormProps) => {
  const dispatch = useDispatch();
  const uatId = useUatId();
  const [initialValues, setInitialValues] = useState(
    createAdditionalDetailsFormValues(
      props.additionalDetails,
      props.personalDetails,
    ),
  );
  const onChangeEmploymentInfo = (newValues: EmploymentStatusFormValues) => {
    store.updateSelectedEmploymentStatus(dispatch)(newValues.employmentStatus);
    setInitialValues(prev => ({
      ...prev,
      ...newValues,
    }));
  };

  useEffect(() => {
    if (props.additionalDetails && props.additionalDetails.employmentStatus) {
      store.updateSelectedEmploymentStatus(dispatch)(
        props.additionalDetails.employmentStatus,
      );
    }
  }, [props.additionalDetails, dispatch]);

  const employmentStatusSection = (
    <EmploymentStatusSection
      selectedEmploymentStatus={initialValues.employmentStatus}
      employmentStatusCategory={initialValues.employmentStatusCategory}
      onChangeEmploymentInfo={onChangeEmploymentInfo}
      disabled={props.disabled}
    />
  );

  const employmentDetails = useMemo(
    () => (
      <CollapsibleEmploymentDetails
        employmentStatusCategory={initialValues.employmentStatusCategory}
        disabled={props.disabled}
        yearsWorkedCategories={props.yearsWorkedCategoriesLookup}
      />
    ),
    [
      initialValues.employmentStatusCategory,
      props.disabled,
      props.yearsWorkedCategoriesLookup,
    ],
  );

  const residentialDetails = <ResidentialDetails disabled={props.disabled} />;

  const onSubmitHandler = (values: AdditionalDetailsFormValues) => {
    props.onSubmit(
      preSubmitAdditionalDetailsHandler(
        prepareFormValues(values),
        props.additionalDetails,
      ),
    );
  };

  const householdExpenses = (
    <HouseholdExpenses
      showIf={initialValues.householdExpensesRequired}
      disabled={props.disabled}
      uat={uatId(AdditionalDetailsFormUat.additionalDetailsHouseholdExpenses)}
    />
  );

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={getValidationSchema()}
      onSubmit={onSubmitHandler}
      render={() => (
        <Form>
          <AdditionalDetailsFormLayout
            employmentStatusSection={employmentStatusSection}
            employmentDetails={employmentDetails}
            residentialDetails={residentialDetails}
            householdExpenses={householdExpenses}
          />
          <PreSubmissionStepActions
            onBackButtonClick={props.onBackButtonClick}
          />
        </Form>
      )}
    />
  );
};
