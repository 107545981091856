import {
  CalculatorValues,
  EstimatedPayment,
  WorkflowStep,
} from '@frontline/common';
import { PostSubmissionReviewError } from '@frontline/web-common';
import * as H from 'history';
import { ActionType, createAction } from 'typesafe-actions';
import { OverviewActionType } from '../features/Overview/store/overview.actions';
import { PostSubmissionReviewActionType } from '../features/PostSubmissionReview/store/post-submission-review.actions';

export enum PostSubmissionActions {
  FetchEstimatedPayment = 'PostSubmission/FetchEstimatedPayment',
  FetchEstimatedPaymentSuccess = 'PostSubmission/FetchEstimatedPaymentSuccess',
  FetchEstimatedPaymentFailure = 'PostSubmission/FetchEstimatedPaymentFailure',
  NavigateToNextStep = 'PostSubmission/NavigateToNextStep',
  NavigateToPreviousStep = 'PostSubmission/NavigateToPreviousStep',
  navigateToStepById = 'PostSubmission/navigateToStepById',
  SetActiveWorkflowStepFromRoute = 'PostSubmission/SetActiveWorkflowStepFromRoute',
  InitPostSubmissionWorkflowSteps = 'PostSubmission/InitPostSubmissionWorkflowSteps',
  SetWorkflowSteps = 'PostSubmission/SetWorkflowSteps',
  SetActiveWorkflowStep = 'PostSubmission/SetActiveWorkflowStep',
}

export const fetchEstimatedPayment = createAction(
  PostSubmissionActions.FetchEstimatedPayment,
  action => (form: CalculatorValues) => action(form),
);

export const fetchEstimatedPaymentSuccess = createAction(
  PostSubmissionActions.FetchEstimatedPaymentSuccess,
  action => (estimatedPayment: EstimatedPayment) => action(estimatedPayment),
);

export const fetchEstimatedPaymentError = createAction(
  PostSubmissionActions.FetchEstimatedPaymentFailure,
  action => (error: Error) =>
    action<PostSubmissionReviewError>({
      ...error,
      type: 'update-calculation',
    }),
);

export const navigateToNextStep = createAction(
  PostSubmissionActions.NavigateToNextStep,
);

export const navigateToPreviousStep = createAction(
  PostSubmissionActions.NavigateToPreviousStep,
);

export const navigateToStepById = createAction(
  PostSubmissionActions.navigateToStepById,
  action => (workflowStepId: string) => action(workflowStepId),
);

export const setActiveWorkflowStepFromRoute = createAction(
  PostSubmissionActions.SetActiveWorkflowStepFromRoute,
  action => (location: H.Location) => action(location),
);

export const initPostSubmissionWorkflowSteps = createAction(
  PostSubmissionActions.InitPostSubmissionWorkflowSteps,
);

export const setWorkflowSteps = createAction(
  PostSubmissionActions.SetWorkflowSteps,
  action => (workflowSteps?: WorkflowStep[]) => action(workflowSteps),
);

export const setActiveWorkflowStep = createAction(
  PostSubmissionActions.SetActiveWorkflowStep,
  action => (workflowStepId?: string) => action(workflowStepId),
);

const PostSubmissionReviewActionCreators = {
  fetchEstimatedPayment,
  fetchEstimatedPaymentSuccess,
  fetchEstimatedPaymentError,
  navigateToNextStep,
  navigateToPreviousStep,
  navigateToStepById,
  initPostSubmissionWorkflowSteps,
  setWorkflowSteps,
  setActiveWorkflowStepFromRoute,
  setActiveWorkflowStep,
};

export type PostSubmissionActionsType =
  | ActionType<typeof PostSubmissionReviewActionCreators>
  | PostSubmissionReviewActionType
  | OverviewActionType;
