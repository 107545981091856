import { TypographyVariant } from '@frontline/ui-library';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  contactYourSalesRepresentative: {
    id: 'cannotEditPageMessage.contactYourSalesRepresentative',
    description:
      'Message to applicant to contact their sales representative to edit page',
    defaultMessage:
      'Please contact your sales representative to edit this page.',
  },
});

export const CannotEditPageMessage = () => {
  const intl = useIntl();
  return (
    <Box marginBottom={3}>
      <Typography variant={TypographyVariant.Header6} gutterBottom={true}>
        {intl.formatMessage(messages.contactYourSalesRepresentative)}
      </Typography>
    </Box>
  );
};
