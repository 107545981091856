import { Application, StoredDocument } from '@frontline/common';
import { HttpError } from '@frontline/web-common';
import { ActionType, createAction } from 'typesafe-actions';

export enum BankDocumentsActions {
  AttachPendingDocuments = 'BankDocuments/AttachPendingDocuments',
  AttachPendingDocumentsSuccess = 'BankDocuments/AttachPendingDocumentsSuccess',
  AttachPendingDocumentsError = 'BankDocuments/AttachPendingDocumentsError',
  OpenConfirmAttachPendingDocumentsDialog = 'BankDocuments/OpenConfirmAttachPendingDocumentsDialog',
  CloseConfirmAttachPendingDocumentsDialog = 'BankDocuments/CloseConfirmAttachPendingDocumentsDialog',
}

export const attachPendingDocuments = createAction(
  BankDocumentsActions.AttachPendingDocuments,
  action => (applicationId: string, documents: StoredDocument[]) =>
    action({ applicationId, documents }),
);

export const attachPendingDocumentsSuccess = createAction(
  BankDocumentsActions.AttachPendingDocumentsSuccess,
  action => (application: Application) => action(application),
);

export const attachPendingDocumentsError = createAction(
  BankDocumentsActions.AttachPendingDocumentsError,
  action => (error: HttpError) => action(error),
);

export const openConfirmAttachPendingDocumentsDialog = createAction(
  BankDocumentsActions.OpenConfirmAttachPendingDocumentsDialog,
);

export const closeConfirmAttachPendingDocumentsDialog = createAction(
  BankDocumentsActions.CloseConfirmAttachPendingDocumentsDialog,
);

const actionCreators = {
  attachPendingDocuments,
  attachPendingDocumentsSuccess,
  attachPendingDocumentsError,
  openConfirmAttachPendingDocumentsDialog,
  closeConfirmAttachPendingDocumentsDialog,
};

export type BankDocumentsActionType = ActionType<typeof actionCreators>;
