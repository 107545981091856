import { RepairTechReportingFieldService } from '../../../modules/repair-tech/services/repair-tech-reporting-fields.service';
import { RepairTechReportingField } from '../../../modules/repair-tech/types/repair-tech-reporting-field.enum';
import { ReportingFieldsDto } from './reporting-fields-dto';

function create(): ReportingFieldsDto {
  const fieldMap = RepairTechReportingFieldService.getReportingFields();
  return {
    refId: fieldMap.get(RepairTechReportingField.REF_ID),
    eventId: fieldMap.get(RepairTechReportingField.EVENT_ID),
    campaignId: fieldMap.get(RepairTechReportingField.CAMPAIGN_ID),
    referrerId: fieldMap.has(RepairTechReportingField.REFERRER_ID)
      ? Number(fieldMap.get(RepairTechReportingField.REFERRER_ID))
      : undefined,
  };
}

export const ReportingFieldsDtoFactory = {
  create,
};
