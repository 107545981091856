import { getStoredDocumentFilenames } from '@frontline/common';
import { applicationServices as ApplicationApi } from '@frontline/web-common';
import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { RootState } from '../../../../../../../../../store';
import * as rootApplicationActions from '../../../../../../../../../store/application/actions';
import * as postSubmissionActions from '../../../../../store/post-submission.actions';
import * as actions from './bank-documents.actions';

export const attachPendingDocumentsEpic: Epic<Action, Action, RootState> = (
  action$: Observable<Action>,
) =>
  action$.pipe(
    filter(isActionOf(actions.attachPendingDocuments)),
    map(action => action.payload),
    mergeMap(({ applicationId, documents }) =>
      from(
        ApplicationApi.attachSelectedPendingDocuments(
          applicationId,
          getStoredDocumentFilenames(documents),
        ),
      ).pipe(
        map(application => actions.attachPendingDocumentsSuccess(application)),
        catchError(error => of(actions.attachPendingDocumentsError(error))),
      ),
    ),
  );

export const attachPendingDocumentsSuccessEpic: Epic<
  Action,
  Action,
  RootState
> = (action$: Observable<Action>) =>
  action$.pipe(
    filter(isActionOf(actions.attachPendingDocumentsSuccess)),
    map(action => action.payload),
    mergeMap(application => [
      rootApplicationActions.updateApplication(application),
      postSubmissionActions.navigateToNextStep(),
    ]),
  );

export default [attachPendingDocumentsEpic, attachPendingDocumentsSuccessEpic];
