import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ApplicationTakeoverViewMessages } from './application-takeover-error-view.messages';
import { useApplicationTakeoverErrorViewStyles } from './application-takeover-error-view.styles';

export const ApplicationTakeoverErrorView = () => {
  const classes = useApplicationTakeoverErrorViewStyles();

  return (
    <>
      <Grid
        container
        spacing={1}
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.container}>
        <Grid item>
          <Typography>
            <FormattedMessage {...ApplicationTakeoverViewMessages.title} />
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
