import {
  DropDownLookup,
  Lookup,
  YearsWorkedCategoryActionCreators,
  YearsWorkedCategoryMapService,
  YearsWorkedCategoryState,
} from '@frontline/web-common';
import { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import { RootState } from '../../../store/reducers';
import { Store } from '../../../store/Store';

export const getYearsWorkedCategory: Selector<
  RootState,
  YearsWorkedCategoryState
> = (state: RootState) => state.lookupState.yearsWorkedCategory;

export const getYearsWorkedCategoriesDropdown: Selector<
  RootState,
  DropDownLookup[] | undefined
> = createSelector(getYearsWorkedCategory, (state: YearsWorkedCategoryState) =>
  Boolean(state.yearsWorkedCategories)
    ? YearsWorkedCategoryMapService.mapLookupsToDropdownLookups(
        state.yearsWorkedCategories!,
      )
    : undefined,
);

export const getYearsWorkedCategoriesLookup: Selector<
  RootState,
  Lookup[] | undefined
> = createSelector(
  getYearsWorkedCategory,
  (state: YearsWorkedCategoryState) => state?.yearsWorkedCategories,
);

export const fetchYearsWorkedCategories = () => {
  Store.dispatch(
    YearsWorkedCategoryActionCreators.fetchYearsWorkedCategories(),
  );
};
