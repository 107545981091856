/* tslint:disable:ordered-imports */
import { repairTechActions } from '@frontline/web-common';
import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import * as authActions from '../../../modules/auth/store/auth.actions';
import { RootState } from '../../../store';
import { RepairTechActionCreators } from './repair-tech.actions';
import { RepairTechApiService } from '../../../modules/repair-tech/services/repair-tech-api.service';
import { ReportingFieldsDtoFactory } from '../types/reporting-fields-dto.factory';

const createApplicationSuccessEpic: Epic<Action, Action, RootState> = (
  action$: Observable<Action>,
) =>
  action$.pipe(
    filter(isActionOf(repairTechActions.applicationCreated)),
    map(action => action.payload),
    map(application => authActions.setApplicationId(application.id)),
  );

const createdApplicationEpic: Epic<Action, Action, RootState> = (
  action$: Observable<Action>,
) =>
  action$.pipe(
    filter(isActionOf(repairTechActions.applicationCreated)),
    map(action => action.payload),
    map(application =>
      RepairTechActionCreators.saveReportingFields(application.id),
    ),
  );

const saveReportingFieldsEpic: Epic<Action, Action, RootState> = (
  action$: Observable<Action>,
) =>
  action$.pipe(
    filter(isActionOf(RepairTechActionCreators.saveReportingFields)),
    map(action => action.payload),
    mergeMap(payload =>
      RepairTechApiService.saveReportingFields(
        payload.applicationId,
        ReportingFieldsDtoFactory.create(),
      ).pipe(
        map(() =>
          RepairTechActionCreators.saveReportingFieldsSuccess(
            payload.applicationId,
          ),
        ),
        catchError(error =>
          of(
            RepairTechActionCreators.saveReportingFieldsFailure(
              payload.applicationId,
              error,
            ),
          ),
        ),
      ),
    ),
  );

export default [
  createApplicationSuccessEpic,
  createdApplicationEpic,
  saveReportingFieldsEpic,
];
