import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { AdditionalDetailsForm } from '../../../../../../../../common/AdditionalDetails';
import { AdditionalDetailsHeader } from '../../../../../../../../common/AdditionalDetails/components';
import * as coApplicantStore from '../../../../../../../../modules/co-applicant-details/store/co-applicant-details.store';
import * as postSubmissionStore from '../../../../../../store/post-submission.store';
import { CoApplicantDetailsPageProps } from '../../types';
import { CoApplicantAdditionalDetailsMessages as messages } from './co-applicant-additional-details.messages';

export const CoApplicantAdditionalDetails: FunctionComponent<CoApplicantDetailsPageProps> = (
  props: CoApplicantDetailsPageProps,
) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const additionalDetails = useSelector(
    coApplicantStore.getCoApplicantAdditionalDetails,
  );

  const personalDetails = useSelector(
    coApplicantStore.getCoApplicantPersonalDetails,
  );

  return (
    <>
      <AdditionalDetailsHeader title={intl.formatMessage(messages.title)} />
      <AdditionalDetailsForm
        disabled={props.disabled}
        additionalDetails={additionalDetails}
        onSubmit={postSubmissionStore.navigateToOverview(dispatch)}
        yearsWorkedCategoriesLookup={props.yearsWorkedCategoriesLookup}
        personalDetails={personalDetails}
      />
    </>
  );
};
