import { HttpError } from '@frontline/web-common';
import { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import { RootState, Store } from '../../../store';
import { ApplicationTakeoverDetails } from '../../../types/application-takeover/application-takeover-details.interfaces';
import { ApplicationTakeoverRequestStatus } from '../../../types/application-takeover/application-takeover-request-status.enum';
import { ApplicationTakeoverState } from '../types/application-takeover-state.interface';
import { ApplicationTakeoverActions } from './application-takeover.actions';

const getApplicationTakeoverState = (rootState: RootState) =>
  rootState.applicationTakeoverState;

const getApplicationTakeoverDetails: Selector<
  RootState,
  ApplicationTakeoverDetails | null
> = createSelector(
  getApplicationTakeoverState,
  (state: ApplicationTakeoverState) => state.applicationTakeoverDetails,
);

const getError: Selector<RootState, HttpError | undefined> = createSelector(
  getApplicationTakeoverState,
  (state: ApplicationTakeoverState) => state.error,
);

const getApplicationTakeoverStatus: Selector<
  RootState,
  ApplicationTakeoverRequestStatus
> = createSelector(
  getApplicationTakeoverState,
  (state: ApplicationTakeoverState) => state.applicationTakeoverStatus,
);

const isLoading: Selector<RootState, boolean> = createSelector(
  getApplicationTakeoverState,
  (state: ApplicationTakeoverState) => state.isLoading,
);

const isConfirming: Selector<RootState, boolean> = createSelector(
  getApplicationTakeoverState,
  (state: ApplicationTakeoverState) => state.isConfirming,
);

function fetchApplicationTakeoverDetails(takeoverCode: string) {
  Store.dispatch(
    ApplicationTakeoverActions.fetchApplicationTakeoverDetails(takeoverCode),
  );
}

function answerApplicationTakeoverRequest(
  acceptTakeover: boolean,
  appTakeoverCode: string,
) {
  Store.dispatch(
    ApplicationTakeoverActions.answerApplicationTakeoverRequest(
      acceptTakeover,
      appTakeoverCode,
    ),
  );
}

function setIsConfirming(confirming: boolean) {
  Store.dispatch(ApplicationTakeoverActions.setIsConfirming(confirming));
}

export const ApplicationTakeoverStore = {
  getApplicationTakeoverState,
  getApplicationTakeoverDetails,
  getApplicationTakeoverStatus,
  getError,
  isLoading,
  isConfirming,
  setIsConfirming,
  fetchApplicationTakeoverDetails,
  answerApplicationTakeoverRequest,
};
