import { Form, Formik } from 'formik';
import React, { Fragment, FunctionComponent } from 'react';
import { PreSubmissionStepActions } from '../../../../../../components/pre-submission-step-actions/pre-submission-step-actions.component';
import { PurchaseDetailsFormFields } from './components/purchase-details-form-fields/purchase-details-form-fields.component';
import { PurchaseDetailsFormProps } from './purchase-details-form.props';

export const PurchaseDetailsForm: FunctionComponent<PurchaseDetailsFormProps> = (
  props: PurchaseDetailsFormProps,
) => {
  const initialValues = {
    assetTypeName: props.assetTypeName,
    assetType: props.assetTypeId,
    assetSubTypeName: props.assetSubTypeName,
    programName: props.programName,
    loanAmount: props.loanAmount,
  };

  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={props.onContinue}
        render={() => (
          <Form>
            <PurchaseDetailsFormFields
              assetSubTypeName={props.assetSubTypeName}
              assetTypeName={props.assetTypeName}
              allAssetTypes={props.allAssetTypes}
              programName={props.programName}
            />
            <PreSubmissionStepActions isStepComplete={true} />
          </Form>
        )}
      />
    </Fragment>
  );
};
