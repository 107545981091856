import styled from 'styled-components';

export const ToolbarButtons = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  tranlate: transform(-50%, 0);
  margin: 1em;
`;
