import { Application, Language } from '@frontline/common';
import { HttpError } from '@frontline/web-common';
import { Dispatch } from 'redux';
import { createSelector, Selector } from 'reselect';
import { RootState } from '../../../../../../../store';
import { getApplication } from '../../../../../store/application.store';
import { OverviewState, OverviewTab } from '../types';
import * as actions from './overview.actions';

const getOverviewState = (state: RootState): OverviewState | undefined =>
  state.applicationState?.postSubmissionState?.overviewState;

export const getMerchantName: Selector<
  RootState,
  string | undefined
> = createSelector(
  getApplication,
  (application?: Application) => application?.owner?.fullName,
);

export const getOverviewTab: Selector<
  RootState,
  OverviewTab | undefined
> = createSelector(
  getOverviewState,
  (overviewState?: OverviewState) => overviewState?.overviewPage,
);

export const getApprovalConditionLanguage: Selector<
  RootState,
  Language | undefined
> = createSelector(
  getOverviewState,
  (overviewState?: OverviewState) => overviewState?.approvalConditionLanguage,
);

export const getOverviewError: Selector<
  RootState,
  HttpError | undefined
> = createSelector(
  getOverviewState,
  (overviewState?: OverviewState) => overviewState?.error,
);

export const isCongratulationsDialogOpen: Selector<
  RootState,
  boolean
> = createSelector(getOverviewState, (state?: OverviewState) =>
  Boolean(state?.isCongratulationsDialogOpen),
);

export const isLoading: Selector<
  RootState,
  boolean
> = createSelector(getOverviewState, (state?: OverviewState) =>
  Boolean(state?.loading),
);

export const openCongratulationsDialog = (dispatch: Dispatch) => () => {
  dispatch(actions.openCongratulationsDialog());
};

export const goToOverviewTab = (dispatch: Dispatch) => (tab: OverviewTab) => {
  dispatch(actions.goToOverviewTab(tab));
};

export const setLoading = (dispatch: Dispatch) => (status: boolean) => {
  dispatch(actions.setLoading(status));
};

export const initConditionsLookup = (dispatch: Dispatch) => (
  language: Language,
) => {
  dispatch(actions.initConditionsLookup(language));
};

export const setPostSubmissionViewed = (dispatch: Dispatch) => (
  applicationId: string,
) => {
  dispatch(actions.setPostSubmissionViewed(applicationId));
};
