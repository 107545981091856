import { ActionType, createAction } from 'typesafe-actions';

export enum ResultSummaryActions {
  CompleteResult = 'Result/Complete',
  CompleteResultSuccess = 'Result/CompleteSuccess',
  CompleteResultError = 'Result/CompleteError',
}

export const completeResult = createAction(
  ResultSummaryActions.CompleteResult,
  action => (applicationId: string) => action(applicationId),
);

export const completeResultSuccess = createAction(
  ResultSummaryActions.CompleteResultSuccess,
  action => (applicationId: string) => action(applicationId),
);

export const completeResultError = createAction(
  ResultSummaryActions.CompleteResultError,
  action => (error: Error) => action(error),
);

const actionCreators = {
  completeResult,
  completeResultSuccess,
  completeResultError,
};

export type ResultSummaryActionsType = ActionType<typeof actionCreators>;
