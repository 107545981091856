import {
  muiTheme,
  TypographyColor,
  TypographyVariant,
} from '@frontline/ui-library';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import React, { Fragment, FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import * as applicationStore from '../../../../../store/application.store';
import { StepPosition } from './StepPosition';

export interface PreSubmissionStepHeaderProps {
  statusMole?: new () => React.Component;
  title: string;
  stepPosition?: string | number;
  description: string;
}

export const PreSubmissionStepHeader: FunctionComponent<PreSubmissionStepHeaderProps> = (
  props: PreSubmissionStepHeaderProps,
) => {
  const isMediaExtraSmall = useMediaQuery(muiTheme.breakpoints.down('xs'));
  const StatusMole = props.statusMole;
  const applicationId = useSelector(applicationStore.getApplicationId);

  return (
    <Fragment>
      <Grid container={true} justify="space-between">
        <StepPosition stepPosition={props.stepPosition} />
        {isMediaExtraSmall && (
          <Typography variant={TypographyVariant.Caption}>
            {applicationId}
          </Typography>
        )}
        {isMediaExtraSmall && StatusMole && (
          <Grid item={true} xs="auto">
            <StatusMole />
          </Grid>
        )}
      </Grid>
      <Typography
        variant={TypographyVariant.Header2}
        color={TypographyColor.TextPrimary}>
        {props.title}
      </Typography>
      <Typography
        variant={TypographyVariant.Body1}
        color={TypographyColor.TextPrimary}
        paragraph={true}>
        {props.description}
      </Typography>
    </Fragment>
  );
};

export default PreSubmissionStepHeader;
