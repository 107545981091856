import { ResultSummaryState } from '../types/result-summary-state/result-summary-state';
import { createResultSummaryState } from '../types/result-summary-state/result-summary-state.functions';
import {
  ResultSummaryActions,
  ResultSummaryActionsType,
} from './result-summary.actions';

export const resultReducer = (
  state: ResultSummaryState = createResultSummaryState(),
  action: ResultSummaryActionsType,
): ResultSummaryState => {
  switch (action.type) {
    case ResultSummaryActions.CompleteResult:
      return {
        ...state,
        isLoading: true,
      };
    case ResultSummaryActions.CompleteResultSuccess:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
