import { Grid } from '@material-ui/core';
import * as React from 'react';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

const OverviewStepActions = (props: PropsWithChildren<any>) => {
  const actions = React.Children.map(props.children, child => (
    <Grid item={true}>{child}</Grid>
  ));

  return (
    <Actions>
      <Grid container={true} justify="flex-start" spacing={2}>
        {actions}
      </Grid>
    </Actions>
  );
};

export default OverviewStepActions;

const Actions = styled.div<any>`
  button {
    margin-bottom: 1em;
  }
`;
