import { EmploymentStatus } from '@frontline/common';
import { ActionType, createAction } from 'typesafe-actions';

export enum AdditionalDetailsActions {
  updateSelectedEmploymentStatus = 'AdditionalDetails/updateSelectedEmploymentStatus',
}

export const updateSelectedEmploymentStatus = createAction(
  AdditionalDetailsActions.updateSelectedEmploymentStatus,
  action => (details: EmploymentStatus) => action(details),
);

const AdditionalDetailsActionCreators = {
  updateSelectedEmploymentStatus,
};

export type AdditionalDetailsActionType = ActionType<
  typeof AdditionalDetailsActionCreators
>;
