import { ActionType, createAction } from 'typesafe-actions';
import { Answer } from '../../../types/questions/answer.interface';
import { QuestionGroup } from '../../../types/questions/question-group.interface';

export interface AnswerSecurityQuestionsErrorPayload {
  error: Error;
  numberOfAttemptsMade: number;
}

export enum SecurityQuestionsActions {
  LoadSecurityQuestions = 'SecurityQuestions/LoadSecurityQuestions',
  LoadSecurityQuestionsSuccess = 'SecurityQuestions/LoadSecurityQuestionsSuccess',
  LoadSecurityQuestionsError = 'SecurityQuestions/LoadSecurityQuestionsError',
  AnswerSecurityQuestions = 'SecurityQuestions/AnswerSecurityQuestions',
  AnswerSecurityQuestionsError = 'SecurityQuestions/AnswerSecurityQuestionsError',
}

export const loadSecurityQuestions = createAction(
  SecurityQuestionsActions.LoadSecurityQuestions,
  action => (accessKey: string) => action(accessKey),
);

export const loadSecurityQuestionsSuccess = createAction(
  SecurityQuestionsActions.LoadSecurityQuestionsSuccess,
  action => (questions: QuestionGroup) => action(questions),
);

export const loadSecurityQuestionsError = createAction(
  SecurityQuestionsActions.LoadSecurityQuestionsError,
  action => (error: Error) => action(error),
);

export const answerSecurityQuestions = createAction(
  SecurityQuestionsActions.AnswerSecurityQuestions,
  action => (answers: Answer[], questionGroupId: string) =>
    action({
      answers,
      questionGroupId,
    }),
);

export const answerSecurityQuestionsError = createAction(
  SecurityQuestionsActions.AnswerSecurityQuestionsError,
  action => (error: AnswerSecurityQuestionsErrorPayload) => action(error),
);

const actionCreators = {
  loadSecurityQuestions,
  loadSecurityQuestionsSuccess,
  loadSecurityQuestionsError,
  answerSecurityQuestions,
  answerSecurityQuestionsError,
};

export type SecurityQuestionsActionsType = ActionType<typeof actionCreators>;
