import {
  createLoanProtectionStore,
  LoanProtectionState,
} from '@frontline/web-common';
import { createSelector, Selector } from 'reselect';
import { RootState, Store } from '../../../../../../../../../store';
import * as postSubmissionReviewStore from '../../../store/post-submission-review.store';
import { PostSubmissionReviewState } from '../../../types/PostSubmissionReviewState';

export const getLoanProtectionState: Selector<
  RootState,
  LoanProtectionState | undefined
> = createSelector(
  postSubmissionReviewStore.getPostSubmissionReviewState,
  (state?: PostSubmissionReviewState) => state?.loanProtectionState,
);

export const loanProtectionStore = {
  ...createLoanProtectionStore(getLoanProtectionState, Store.dispatch),
  getLoanProtectionState,
};
