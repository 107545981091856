import { PersonalDetailsForm, PersonalDetailsRules } from '@frontline/common';
import { createPersonalDetailsFormValues } from '@frontline/web-common';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as applicationStore from '../../../../store/application.store';
import * as store from '../../common/personal-details/store/personal-details.store';
import { shouldSavePersonalDetails } from '../../common/personal-details/types/PersonalDetailsFormValues';
import { PreSubmissionFormPropsType } from '../../types/PreSubmissionForm/PreSubmissionFormPropsType';
import { getDisplayStepPosition } from '../../types/StepPosition.functions';
import { PersonalDetailsView } from './components/personal-details-view/personal-details-view.component';

export const PersonalDetailsFeature = (
  props: PreSubmissionFormPropsType<PersonalDetailsForm>,
) => {
  const dispatch = useDispatch();

  const applicant = useSelector(applicationStore.getApplicant);
  const application = useSelector(applicationStore.getApplication);
  const isSalesRepIdDialogOpen = useSelector(store.getIsSalesRepIdDialogOpen);
  const getIsSalesRepIdComplete = useSelector(store.getIsSalesRepIdComplete);
  const isLoading = useSelector(store.getIsLoading);

  const onSubmit = (changes: PersonalDetailsForm): void => {
    const rules = props.stepReference?.step.rules as PersonalDetailsRules;
    props.onSubmit(
      changes,
      shouldSavePersonalDetails(
        props.stepReference,
        createPersonalDetailsFormValues(
          false,
          applicant?.personalDetails,
          rules?.hideResidentialLengthOfStayField,
          applicant?.authorizedElectronicCommunication,
        ),
      ),
    );
  };

  useEffect(() => {
    if (
      props.application?.salesRepIdRequired &&
      !getIsSalesRepIdComplete &&
      !application?.retailerSalesRepId
    ) {
      store.setIsSalesRepIdDialogOpen(true);
    }
  }, []);

  const handleSubmitSalesRepId = (salesRepId: string): void => {
    if (salesRepId !== '') {
      store.submitSalesRepId(salesRepId);
    }
  };

  return (
    <PersonalDetailsView
      fastFillAttempts={useSelector(store.getApplicantFastFillAttempts)}
      saveFastFillDetails={store.saveApplicantFastFillDetails(dispatch)}
      personalDetails={applicant?.personalDetails}
      authorizedElectronicCommunication={
        applicant?.authorizedElectronicCommunication
      }
      stepPosition={getDisplayStepPosition(props.stepPosition)}
      stepReference={props.stepReference}
      onSubmit={onSubmit}
      isSalesRepIdDialogOpen={isSalesRepIdDialogOpen}
      handleSubmitSalesRepId={handleSubmitSalesRepId}
      isLoading={isLoading}
    />
  );
};
