import { HttpError } from '@frontline/web-common';
import { ActionType, createAction } from 'typesafe-actions';
import { LendcareReportingField } from '../types/lendcare-reporting-field.enum';
import { StartNewApplicationResponse } from '../types/StartNewApplication';

export enum BuyLendCareActions {
  StartNewApplication = 'BuyLendCare/StartNewApplication',
  StartNewApplicationSuccess = 'BuyLendCare/StartNewApplicationSuccess',
  StartNewApplicationFailure = 'BuyLendCare/StartNewApplicationFailure',
  SetRefId = 'BuyLendCare/SetRefId',
  SetReferrerId = 'BuyLendCare/SetReferrerId',
  SetEventId = 'BuyLendCare/SetEventId',
  SetCampaignId = 'BuyLendCare/SetCampaignId',
}

export const startNewApplication = createAction(
  BuyLendCareActions.StartNewApplication,
  action => (
    shareableLinkId: string,
    reportingFields: Map<LendcareReportingField, string | null>,
  ) => action({ shareableLinkId, reportingFields }),
);

export const startNewApplicationSuccess = createAction(
  BuyLendCareActions.StartNewApplicationSuccess,
  action => (response: StartNewApplicationResponse) => action(response),
);

export const startNewApplicationFailure = createAction(
  BuyLendCareActions.StartNewApplicationFailure,
  action => (error: HttpError) => action(error),
);

export const BuyLendCareActionCreators = {
  startNewApplication,
  startNewApplicationSuccess,
  startNewApplicationFailure,
};

export type BuyLendCareActionType = ActionType<
  typeof BuyLendCareActionCreators
>;
