import { defineMessages } from 'react-intl';

export default defineMessages({
  complete: {
    id: 'ApplicationStatusBanner.complete',
    description:
      'Message telling the applicant that they have completed the application and can close the browser',
    defaultMessage:
      "Thank you! You have completed all steps for your loan application. Once we've reviewed and completed your application you'll be contacted by {merchantName} to let you know the final application status. It is now safe to close this browser.",
  },
  defaultMerchantName: {
    id: 'ApplicationStatusBanner.defaultMerchantName',
    description:
      'If sales representative name is not available, use this default phrase ie. a sales representative will call you',
    defaultMessage: 'a sales representative',
  },
  applicationIsIncomplete: {
    id: 'ApplicationStatusBanner.applicationIssIncomplete',
    description:
      'Message telling applicant that application is not complete yet',
    defaultMessage:
      'To finalize your application, please complete and check off each task on the checklist below.',
  },
});
