import {
  WorkflowStep,
  WorkflowStepStatus,
  WorkflowStepType,
} from '@frontline/common';

export function shouldShowAdditionalDetails(
  workflow: WorkflowStep[] | null,
): boolean {
  return (
    workflow?.find(x => {
      return x.type === WorkflowStepType.AdditionalDetails;
    })?.status === WorkflowStepStatus.Completed
  );
}
export function shouldShowIncomeDetails(
  workflow: WorkflowStep[] | null,
): boolean {
  return (
    workflow?.find(x => {
      return x.type === WorkflowStepType.IncomeDetails;
    })?.status === WorkflowStepStatus.Completed
  );
}
