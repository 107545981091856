import { defineMessages } from 'react-intl';

export default [
  defineMessages({
    message: {
      defaultMessage: 'Submitting your application...',
      id: 'Applicant.PreSubmission.Result.message1',
    },
  }),
  defineMessages({
    message: {
      defaultMessage: 'Reviewing your information...',
      id: 'Applicant.PreSubmission.Result.message2',
    },
  }),
  defineMessages({
    message: {
      defaultMessage: 'Finalizing your application...',
      id: 'Applicant.PreSubmission.Result.message3',
    },
  }),
];
