import { LookupState, yearsWorkedCategoryReducer } from '@frontline/web-common';
import { LookupAction } from './lookup.actions';
import { initLookupState } from './types/lookup.reducer.functions';

export function lookupReducer(
  state: LookupState = initLookupState(),
  action: LookupAction,
): LookupState {
  return {
    yearsWorkedCategory: yearsWorkedCategoryReducer(
      state?.yearsWorkedCategory,
      action,
    ),
  };
}

export default lookupReducer;
