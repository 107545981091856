import { ActionType, createAction } from 'typesafe-actions';
import { AccessTokenPayload } from '../services/auth.service';
import { AuthenticationQuery } from '../types/AuthenticationQuery/AuthenticationQuery';
import { LoginResponse } from '../types/LoginResponse';
import { SuccessfulAuthenticationResponse } from '../types/SuccessfulAuthenticationResponse';

export enum AuthActions {
  Init = 'Auth/Init',
  Login = 'Auth/Login',
  LoginSuccess = 'Auth/LoginSuccess',
  LoginError = 'Auth/LoginError',
  SetToken = 'Auth/SetToken',
  Validate = 'Auth/Validate',
  ValidateSuccess = 'Auth/ValidateSuccess',
  ValidateError = 'Auth/ValidateError',
  SetApplicationId = 'Auth/SetApplicationId',
  Invalidate = 'Auth/Invalidate',
}

export const init = createAction(
  AuthActions.Init,
  action => (query: AuthenticationQuery, redirectUrl: string) =>
    action({
      query,
      redirectUrl,
    }),
);

export const login = createAction(
  AuthActions.Login,
  action => (query: AuthenticationQuery, redirectUrl?: string) =>
    action({
      query,
      redirectUrl,
    }),
);

export const loginSuccess = createAction(
  AuthActions.LoginSuccess,
  action => (accessKey: string, result: LoginResponse, redirectUrl?: string) =>
    action({
      accessKey,
      result,
    }),
);

export const loginError = createAction(
  AuthActions.LoginError,
  action => (error: Error) => action(error),
);

export const setToken = createAction(
  AuthActions.SetToken,
  action => (accessTokenPayload: AccessTokenPayload) =>
    action(accessTokenPayload),
);

export const validate = createAction(
  AuthActions.Validate,
  action => (redirectUrl?: string) => action(redirectUrl),
);

export const validateSuccess = createAction(
  AuthActions.ValidateSuccess,
  action => (result: SuccessfulAuthenticationResponse, redirectUrl: string) =>
    action({ result, redirectUrl }),
);

export const validateError = createAction(AuthActions.ValidateError);

export const setApplicationId = createAction(
  AuthActions.SetApplicationId,
  action => (applicationId: string) => action(applicationId),
);

export const invalidate = createAction(AuthActions.Invalidate);

const actionCreators = {
  init,
  login,
  loginSuccess,
  loginError,
  setToken,
  validate,
  validateSuccess,
  validateError,
  setApplicationId,
  invalidate,
};

export type AuthActionsType = ActionType<typeof actionCreators>;
