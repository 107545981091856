import {
  filterInsuranceFees,
  isStatusInBookedOrFundingGroup,
} from '@frontline/common';
import {
  FormatMoney,
  FormatMonths,
  PaymentFrequencyName,
} from '@frontline/ui-library';
import {
  DownPaymentRequiredValue,
  FeeList,
  InvoiceEstimateAmount,
  TotalPurchaseCost,
} from '@frontline/web-common';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as store from '../../../../../../../../store/post-submission.store';
import { LoanDetailsFormPayDayDate } from '../../../../components/loan-details-form-pay-day-date/loan-details-form-pay-day-date.component';
import { LoanDetailsFormPayDayFrequency } from '../../../../components/loan-details-form-pay-day-frequency/loan-details-form-pay-day-frequency.component';
import { LoanDetailsFormTgiEnrollmentFieldContainer } from '../../../../components/loan-details-form-tgi-enrollment-field-container/loan-details-form-tgi-enrollment-field-container.component';
import { LoanDetailsItem } from '../../../../components/loan-details-item.component';
import { LoanDetailsSectionHeader } from '../../../../components/loan-details-section-header/loan-details-section-header';
import { showFeesList } from '../../types/loan-details-payment-plan.functions';
import { ContractDate } from '../contract-date';
import { FirstPaymentDate } from '../first-payment-date';
import { LoanDetailsRequestedAmount } from '../loan-details-requested-amount/loan-details-requested-amount.component';
import { LoanDetailsTotalFinanced } from '../loan-details-total-financed/loan-details-total-financed';
import { Rate } from '../rate';
import {
  LoanDetailsPaymentPlanMessages,
  LoanDetailsPaymentPlanMessages as messages,
} from './loan-details-payment-plan.messages';
import { LoanDetailsPaymentPlanProps } from './loan-details-payment-plan.props';

export const LoanDetailsPaymentPlan = (props: LoanDetailsPaymentPlanProps) => {
  const currentApplication = useSelector(store.getCurrentApplication);
  const intl = useIntl();

  const filteredFees = useMemo(() => filterInsuranceFees(props.fees), [
    props.fees,
  ]);

  return (
    <>
      <LoanDetailsSectionHeader
        title={intl.formatMessage(messages.paymentPlanTitle)}
      />
      <Box paddingY={1}>
        <Grid container={true}>
          <InvoiceEstimateAmount
            showIf={Boolean(props.rules?.showInvoiceEstimateAmount)}
            isInvoiceEstimateAmountEntered={Boolean(
              props.formValues.invoiceEstimateAmount,
            )}
            customerAcknowledgementDescription={intl.formatMessage(
              messages.confirmEstimateAmount,
            )}
          />
          <TotalPurchaseCost
            showIf={Boolean(props.rules?.showTotalPurchaseCost)}
            totalPurchaseCost={props.formValues.totalPurchaseCost}
          />
          <LoanDetailsItem
            labelMessage={messages.requestedAmountLabel}
            placeholder={intl.formatMessage(messages.noReceipts)}
            data={
              <LoanDetailsRequestedAmount
                setFieldValue={props.setFieldValue}
                loanAmount={props.formValues.loanAmount}
                approvedAmount={props.formValues.approvedAmount}
                invoiceEstimateAmount={props.formValues.invoiceEstimateAmount}
                receipts={props.receipts}
                rules={props.rules}
                noReceiptMessage={intl.formatMessage(
                  LoanDetailsPaymentPlanMessages.noReceipts,
                )}
              />
            }
          />
          <LoanDetailsItem
            showIf={showFeesList(filteredFees, props.rules?.showFees)}
            labelMessage={messages.additionalFeesLabel}
            data={<FeeList fees={filteredFees} />}
          />
          <LoanDetailsTotalFinanced
            showIf={Boolean(props.rules?.showFinanceAmount)}
            financeAmount={props.formValues.financeAmount}
          />
          <LoanDetailsItem
            showIf={Boolean(props.rules?.showInvoiceEstimateAmount)}
            labelMessage={messages.downPaymentAmountTitle}
            data={
              <DownPaymentRequiredValue
                loanAmount={props.formValues.loanAmount}
                invoiceEstimateAmount={props.formValues.invoiceEstimateAmount}
              />
            }
          />
          <LoanDetailsItem
            showIf={Boolean(props.rules?.showTotalPurchaseCost)}
            labelMessage={messages.downPaymentAmountTitle}
            data={
              <FormatMoney
                value={props.formValues.downPayment}
                roundToDollar={false}
              />
            }
          />

          <LoanDetailsItem
            labelMessage={messages.interestRateLabel}
            data={<Rate value={props.rate} />}
            xs={6}
          />
          <LoanDetailsItem
            showIf={Boolean(!props.rules?.hideDeferralField)}
            labelMessage={messages.deferralLabel}
            data={<FormatMonths value={props.deferral} />}
            xs={6}
          />
          <LoanDetailsItem
            labelMessage={messages.termLabel}
            data={<FormatMonths value={props.term} />}
            xs={6}
          />
          <LoanDetailsItem
            showIf={Boolean(!props.rules?.hideAmortizationField)}
            labelMessage={messages.amortizationLabel}
            data={<FormatMonths value={props.amort} />}
            xs={6}
          />
          <LoanDetailsItem
            showIf={Boolean(!props.rules?.enablePayDayFrequency)}
            labelMessage={messages.paymentFrequencyLabel}
            data={<PaymentFrequencyName frequencyId={props.frequencyId} />}
          />
          <LoanDetailsItem
            showIf={Boolean(!props.rules?.enablePayDayFrequency)}
            labelMessage={messages.contractStartDateLabel}
            data={<ContractDate value={props.contractDate} />}
            xs={6}
          />
          <LoanDetailsItem
            showIf={Boolean(!props.rules?.enablePayDayFrequency)}
            labelMessage={messages.firstPaymentDateLabel}
            data={<FirstPaymentDate value={props.firstPaymentDate} />}
            xs={6}
          />
          <LoanDetailsFormPayDayFrequency
            showIf={Boolean(props.rules?.enablePayDayFrequency)}
            errorMessage={props.errorMessage}
            disabled={isStatusInBookedOrFundingGroup(
              currentApplication?.statusId!,
            )}
          />
          <LoanDetailsFormPayDayDate
            showIf={Boolean(props.rules?.enablePayDayFrequency)}
            errorMessage={props.errorMessage}
            disableFirstPaymentDate={isStatusInBookedOrFundingGroup(
              currentApplication?.statusId!,
            )}
            enablePayDayFrequency={Boolean(props.rules?.enablePayDayFrequency)}
          />
          <LoanDetailsFormTgiEnrollmentFieldContainer
            showIf={Boolean(props.rules?.enableTgiInsurance)}
            tgiInsuranceEnrollment={props.tgiInsuranceEnrollment}
            errorMessage={props.errorMessage}
            disableProtectAssetButton={props.disableProtectAssetButton}
          />
        </Grid>
      </Box>
    </>
  );
};
