import React from 'react';
import { useSelector } from 'react-redux';
import * as store from '../../../../../../store/application.store';
import { ContactMerchantView } from './components/contact-merchant-view/contact-merchant-view.component';

export const ContactMerchantFeature = () => {
  const merchant = useSelector(store.getMerchant);

  return (
    <ContactMerchantView
      merchantEmail={merchant?.email || ''}
      merchantName={merchant?.name || ''}
      merchantPhone={merchant?.phone || ''}
    />
  );
};
