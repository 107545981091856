import { LoanProtectionRequest } from '@frontline/common';
import {
  getIsLoanAndFinancingDetailsComplete,
  isLoanProtectionEditable,
  LoanProtectionPage,
  LoanProtectionView,
  shouldDisableContinue,
  useAcceptLoanProtection,
  useDeclineLoanProtection,
  useFetchPaymentEstimateForLoanProtection,
  useInitializeLoanProtection,
  useInitializeLoanProtectionCalculation,
  useInitializeLoanProtectionPage,
  useInitializeNotAvailableForPurchasePage,
  useReCalculateLoanProtection,
  useResetTotalAmount,
} from '@frontline/web-common';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as applicationStore from '../../../../../../store/application.store';
import * as postSubmissionReviewStore from '../../../../store/post-submission.store';
import { PostSubmissionContentCard } from '../../components/post-submission-content-card';
import { loanProtectionStore } from './store/loan-protection.store';

export const LoanProtectionFeature = () => {
  const dispatch = useDispatch();
  const [selectedPage, setSelectedPage] = useState<LoanProtectionPage | null>(
    null,
  );
  const application = useSelector(applicationStore.getApplication);
  const hasCoApplicant = useSelector(applicationStore.hasCoApplicant);
  const nextRequest = useSelector(loanProtectionStore.getNextTotalPriceRequest);
  const currentRequest = useSelector(
    loanProtectionStore.getCurrentTotalPriceRequest,
  );
  const estimatedPayment = useSelector(
    postSubmissionReviewStore.getEstimatedPayment,
  );
  const activePostSubmissionWorkflow = useSelector(
    applicationStore.getActivePostSubmissionWorkflow,
  );
  const totalPrice = useSelector(loanProtectionStore.getTotalPrice);
  const estimatedPaymentCalculating = useSelector(
    postSubmissionReviewStore.isCalculating,
  );

  const loanProtectionState = useSelector(
    loanProtectionStore.getLoanProtectionState,
  );

  const applicationId = application?.id || '';
  const loanDetails = application?.loanDetails;

  const canEditLoanProtection = useMemo(
    () => (application ? isLoanProtectionEditable(application) : false),
    [application],
  );

  useInitializeLoanProtectionPage(
    useSelector(loanProtectionStore.getSelectionStatus),
    loanProtectionStore.changeLoanProtectionPage,
  );

  useInitializeLoanProtection(
    currentRequest,
    applicationId,
    loanProtectionStore.initLoanProtection,
  );

  useInitializeLoanProtectionCalculation(
    useSelector(loanProtectionStore.getSelectionStatus),
    currentRequest,
    applicationId,
    hasCoApplicant,
    loanProtectionStore.fetchLoanProtectionCalculation,
  );

  useInitializeNotAvailableForPurchasePage(
    useSelector(loanProtectionStore.getError),
    loanProtectionStore.changeLoanProtectionPage,
    loanProtectionStore.resetError,
  );

  useReCalculateLoanProtection(
    nextRequest,
    currentRequest,
    applicationId,
    loanProtectionStore.fetchLoanProtectionCalculation,
  );

  useEffect(() => {
    loanProtectionStore.initLoanProtection(applicationId);
  }, [applicationId]);

  const isCalculating = useSelector(
    loanProtectionStore.getLoanProtectionIsCalculating,
  );

  const estimatedPaymentAmount: number | null = useMemo(() => {
    if (estimatedPayment && estimatedPayment.payment) {
      return estimatedPayment.payment;
    }
    return null;
  }, [estimatedPayment]);

  useFetchPaymentEstimateForLoanProtection(
    postSubmissionReviewStore.fetchEstimatedPayment,
    loanDetails || null,
    totalPrice,
    currentRequest,
    nextRequest,
    selectedPage,
    loanProtectionState || null,
    dispatch,
  );

  const isLoanAndFinancingComplete = useMemo(() => {
    return getIsLoanAndFinancingDetailsComplete(activePostSubmissionWorkflow);
  }, [activePostSubmissionWorkflow]);

  useResetTotalAmount(
    currentRequest,
    nextRequest,
    loanProtectionStore.resetTotalAmount,
    loanProtectionStore.resetTotalPriceRequestFlag,
  );

  const handlePageClick = (loanProtectionPage: LoanProtectionPage) => {
    if (!canEditLoanProtection) {
      return;
    }
    loanProtectionStore.changeLoanProtectionPage(loanProtectionPage);
    setSelectedPage(loanProtectionPage);
    if (loanProtectionPage === 'notInterested' && loanDetails) {
      loanProtectionStore.resetTotalAmount();
    } else {
      loanProtectionStore.resetTotalPriceRequestFlag();
    }
  };

  const page = useSelector(loanProtectionStore.getLoanProtectionPageSelection);

  const isCalculatingMode = useMemo(
    () => isCalculating || estimatedPaymentCalculating,
    [isCalculating, estimatedPaymentCalculating],
  );

  const disableContinueButton = useMemo(
    () =>
      canEditLoanProtection &&
      shouldDisableContinue(
        nextRequest,
        page,
        isCalculatingMode,
        isLoanAndFinancingComplete,
      ),
    [
      canEditLoanProtection,
      nextRequest,
      page,
      isCalculatingMode,
      isLoanAndFinancingComplete,
    ],
  );

  const saveLoanProtection = (
    applicationIdToSave: string,
    request: LoanProtectionRequest,
  ) => {
    if (!canEditLoanProtection) {
      postSubmissionReviewStore.navigateToNextStep(dispatch)();
      return;
    }

    loanProtectionStore.saveLoanProtection(applicationIdToSave, request);
  };

  const declineLoanProtection = (applicationIdToDecline: string) => {
    if (!canEditLoanProtection) {
      postSubmissionReviewStore.navigateToNextStep(dispatch)();
      return;
    }

    loanProtectionStore.declineLoanProtection(applicationIdToDecline);
  };

  return (
    <PostSubmissionContentCard>
      <LoanProtectionView
        page={page}
        nextRequest={nextRequest}
        currentRequest={useSelector(
          loanProtectionStore.getCurrentTotalPriceRequest,
        )}
        application={application}
        totalPrice={totalPrice}
        paymentPrice={estimatedPaymentAmount}
        hasCoApplicant={hasCoApplicant}
        isCalculating={isCalculatingMode}
        isLoading={useSelector(loanProtectionStore.getLoanProtectionIsLoading)}
        error={useSelector(loanProtectionStore.getError)}
        onPageClick={handlePageClick}
        handleRequestToReCalculate={
          loanProtectionStore.updateCalculationRequest
        }
        acceptLoanProtection={useAcceptLoanProtection(
          nextRequest,
          applicationId,
          saveLoanProtection,
        )}
        declineLoanProtection={useDeclineLoanProtection(
          applicationId,
          declineLoanProtection,
        )}
        navigateBack={postSubmissionReviewStore.navigateToPreviousStep(
          useDispatch(),
        )}
        isLoanAndFinancingComplete={isLoanAndFinancingComplete}
        hideCostOfInsurance={true}
        disableContinueBtn={disableContinueButton}
        disabled={!canEditLoanProtection}
      />
    </PostSubmissionContentCard>
  );
};
