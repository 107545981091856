import { Language } from '@frontline/common';
import { ChangeLanguageButton, LocaleContext } from '@frontline/web-common';
import React from 'react';
import { useContext } from 'react';
import messages from './LanguageSelectionMenu.messages';

export const LanguageSelectionMenu = () => {
  const { locale, changeLocale } = useContext(LocaleContext);
  const supportedLanguages = [Language.EN, Language.FR];

  return (
    <ChangeLanguageButton
      changeLocale={changeLocale}
      currentLocale={locale}
      languages={supportedLanguages}
      messages={messages}
    />
  );
};
