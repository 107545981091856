import { Colours, muiTheme } from '@frontline/ui-library';
import { useMediaQuery } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

const Content = styled(Grid)<{ padding: string }>(
  (props: any) => `
    background-color: ${Colours.PrimaryColorInverted};
    padding: ${props.padding}
  `,
);

export interface PreSubmissionPageLayoutProps {
  applicationHeaderComponent?: ReactNode;
  formComponent?: ReactNode;
}

const PreSubmissionPageLayout: FunctionComponent<PreSubmissionPageLayoutProps> = ({
  applicationHeaderComponent,
  formComponent,
}) => {
  const isMediaSmall = useMediaQuery(muiTheme.breakpoints.down('sm'));
  return (
    <Content padding={isMediaSmall ? '1em' : '2em 5.7em'}>
      <Grid container={true} spacing={2}>
        {applicationHeaderComponent && (
          <Grid item={true} xs={12}>
            {applicationHeaderComponent}
          </Grid>
        )}
        {formComponent && (
          <Grid item={true} xs={12}>
            {formComponent}
          </Grid>
        )}
      </Grid>
    </Content>
  );
};

export default PreSubmissionPageLayout;
