import React from 'react';
import { OverViewStepMenuPageProps } from '../overview-step-menu-page.props';
import { OverviewStepMenuPageGeneric } from '../OverviewStepMenuPageGeneric/overview-step-menu-item-generic.component';
import { AssetsAndAddOnsStepMessages as messages } from './assets-and-addons-step.messages';

export const AssetsAndAddOnsStep = (props: OverViewStepMenuPageProps) => (
  <OverviewStepMenuPageGeneric
    title={messages.title}
    description={messages.description}
    routeUrl={props.routeUrl}
    status={props.status}
    editButtonText={messages.editButton}
    editButtonUat={props.uat}
  />
);
