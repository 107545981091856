import { defineMessages } from 'react-intl';

export default defineMessages({
  employersNameLabel: {
    id: 'AdditionalDetailsInfo.employersNameLabel',
    description: "Employer's name label",
    defaultMessage: "Employer's Name",
  },
  yearsWorkedLabel: {
    id: 'AdditionalDetailsInfo.yearsWorkedLabel',
    description: 'Years with Current Employer label',
    defaultMessage: 'Years with Current Employer',
  },
  occupationLabel: {
    id: 'AdditionalDetailsInfo.occupationLabel',
    description: 'Occupation label',
    defaultMessage: 'Position / Occupation',
  },
  cityLabel: {
    id: 'AdditionalDetailsInfo.cityLabel',
    description: 'City label',
    defaultMessage: 'City',
  },
  provinceLabel: {
    id: 'AdditionalDetailsInfo.provinceLabel',
    description: 'Province label',
    defaultMessage: 'Province',
  },
  employerPhoneLabel: {
    id: 'AdditionalDetailsInfo.employerPhone',
    description: 'Employer phone label',
    defaultMessage: 'Employer Phone',
  },
  residentialStatusLabel: {
    id: 'AdditionalDetailsInfo.residentialStatus',
    description: 'Residential status label',
    defaultMessage: 'Residential Status',
  },
  employmentStatusLabel: {
    id: 'AdditionalDetailsInfo.employmentStatusLabel',
    description: `Applicant's employment status label`,
  },
});
