import { Applicant } from '@frontline/common';
import { Selector } from 'react-redux';
import { RootState } from '../../../../../store';
import * as rootApplicationSelectors from '../../../../../store/application/selectors';

export const getApplicantDetails: Selector<RootState, Applicant | undefined> = (
  state: RootState,
) => rootApplicationSelectors.getApplicant(state.application);

export const hasAuthorizedElectronicCommunication = (
  state: RootState,
): boolean =>
  Boolean(getApplicantDetails(state)?.authorizedElectronicCommunication);
