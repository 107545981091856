import { createLoanDetailsState, LoanDetailsState } from '../types';
import {
  LoanDetailsActions,
  LoanDetailsActionType,
} from './loan-details.actions';

export const loanDetailsReducer = (
  state: LoanDetailsState = createLoanDetailsState(),
  action: LoanDetailsActionType,
) => {
  switch (action.type) {
    case LoanDetailsActions.FetchLoanDetailsSuccess:
      return {
        ...state,
        loanDetails: action.payload,
        isLoading: false,
      };
    case LoanDetailsActions.FetchLoanDetailsFailure:
      return {
        ...state,
        error: action.payload,
      };
    case LoanDetailsActions.SetIsLoading:
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
};
