import {
  Application,
  getFirstIncompleteStep,
  readWorkflowPath,
  WorkflowDecisions,
  WorkflowStep,
  WorkflowStepStatus,
  WorkflowStepType,
} from '@frontline/common';
import {
  ApprovedPath,
  DeclinedPath,
} from '../../../features/result-summary/types/result-summary-routes.functions';

import { getResultPageRoutePath } from '../../../types/PreSubmissionRoutes.functions';

export const getEnabledSteps = (
  workFlowSteps: WorkflowStep[],
  workflowDecisions?: WorkflowDecisions,
): WorkflowStep[] =>
  readWorkflowPath(workFlowSteps, workflowDecisions).filter(
    (step: WorkflowStep) => !step.disabled,
  );

export const addResultPageStep = (
  workflowSteps: WorkflowStep[],
  application?: Application,
): WorkflowStep[] => {
  if (!application || workflowHasResultStep(workflowSteps)) {
    return workflowSteps;
  }
  return workflowSteps.concat([
    {
      id: '7',
      type: WorkflowStepType.Result,
      status: application.submissionResultPageViewed
        ? WorkflowStepStatus.Completed
        : WorkflowStepStatus.Pending,
      routeUrl: `/applications/${application.id}/pre-submission/result`,
    },
  ]);
};

const workflowHasResultStep = (workFlowSteps: WorkflowStep[]): boolean =>
  workFlowSteps.some(
    (step: WorkflowStep) => step.type === WorkflowStepType.Result,
  );

export const findActiveStepIndex = (workflowSteps: WorkflowStep[]): number => {
  const activeStep = workflowSteps.find(
    step => step.status === WorkflowStepStatus.Active,
  );
  return activeStep ? workflowSteps.indexOf(activeStep) : -1;
};

export const setDefaultActiveStep = (
  workflowSteps: WorkflowStep[],
): WorkflowStep[] => {
  const activeStep = getFirstIncompleteStep(workflowSteps);

  if (!activeStep) {
    return workflowSteps;
  }

  return setActiveStep(workflowSteps, activeStep);
};

export const setActiveStep = (
  workflowSteps: WorkflowStep[] | null,
  activeStep?: WorkflowStep,
): WorkflowStep[] => {
  if (!workflowSteps) {
    return [];
  }
  if (!activeStep) {
    return workflowSteps;
  }
  return workflowSteps.map(step => {
    if (step.id === activeStep.id) {
      return {
        ...step,
        status: WorkflowStepStatus.Active,
      };
    }
    return step;
  });
};

export function getPrevWorkflowStepIndex(
  currentActiveStepIndex: number,
): number {
  if (currentActiveStepIndex < 1) {
    return 0;
  }
  return currentActiveStepIndex - 1;
}

export const findStepByUrl = (url: string, workflow: WorkflowStep[]) =>
  workflow.find(step => {
    const { subSteps } = step;
    const routeMatchesPath = step.routeUrl === url;

    const subStepMatchesPath = Boolean(
      subSteps && subSteps.some(s => s.routeUrl === url),
    );

    return routeMatchesPath || subStepMatchesPath;
  });

const convertResultSubRouteUrlToRouteUrl = (
  url: string,
  applicationId?: string,
): string => {
  if (!applicationId) {
    return url;
  }
  const resultUrl = getResultPageRoutePath(applicationId);

  const approvedUrl = `${resultUrl}/${ApprovedPath}`;
  const declinedUrl = `${resultUrl}/${DeclinedPath}`;

  if ([approvedUrl, declinedUrl].includes(url)) {
    return resultUrl;
  }
  return url;
};

export const setActiveStepInWorkflow = (
  workflow: WorkflowStep[],
  currentRouteUrl: string,
  application?: Application,
): WorkflowStep[] => {
  const workflowWithResultPage = addResultPageStep(
    getEnabledSteps(workflow, application?.workflowDecisions),
    application,
  );
  const url = convertResultSubRouteUrlToRouteUrl(
    currentRouteUrl,
    application?.id,
  );
  const step = findStepByUrl(url, workflowWithResultPage);
  return setActiveStep(workflowWithResultPage, step);
};
