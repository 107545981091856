import { defineMessages } from 'react-intl';

export const ApplicationTakeoverNewLinkFooterMessages = defineMessages({
  title: {
    id: 'Applicant.ApplicationTakeover.NewLinkFooter.title',
    description: 'Application Takeover New Link Footer title',
    defaultMessage: 'Need a new link?',
  },
  description: {
    id: 'Applicant.ApplicationTakeover.NewLinkFooter.description',
    description: 'Application Takeover New Link Footer description',
    defaultMessage: 'Contact the merchant to get a new link.',
  },
});
