import { TypographyVariant, withShowIf } from '@frontline/ui-library';
import { Typography } from '@material-ui/core';
import React from 'react';

import { ApplicationIdHeaderProps } from './application-id-header-props';

export const ApplicationIdHeader = withShowIf(
  (props: ApplicationIdHeaderProps) => (
    <Typography variant={TypographyVariant.Caption}>
      {props.applicationId}
    </Typography>
  ),
);
