import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { LendCare } from './components/LendCare/lendcare.component';
import { LendCareDisabled } from './components/LendCareDisabled/LendCareDisabled';

export interface RouteParams {
  shareableLinkId: string;
}

export const LendCareRoutes = (props: RouteComponentProps<RouteParams>) => {
  return (
    <Switch>
      <Route
        path="/lendcare/disabled"
        exact={true}
        component={LendCareDisabled}
      />
      <LendCare shareableLinkId={props.match.params.shareableLinkId} />
    </Switch>
  );
};
