import ButtonBase from '@material-ui/core/ButtonBase';
import styled from 'styled-components';

export const ExitButtonBase = styled(ButtonBase)`
  display: flex;
  align-items: center;
  height: 56px;
  width: 100%;
  && {
    padding: 0px 18px;
    justify-content: space-between;
  }
`;
