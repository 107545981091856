import {
  Language,
  prepareDocumentForSigning,
  SignDocument,
  SigningDocumentAction,
  updateSignDocumentsWithRedirectParameters,
} from '@frontline/common';
import {
  actions as webCommonApplicationActions,
  mapErrorToSignLoanDocumentsError,
  selectors,
  SignDocumentsApi as api,
} from '@frontline/web-common';
import { push } from 'connected-react-router';
import { Action } from 'redux';
import { Epic, StateObservable } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootState } from '../../../../../../../../../store';
import * as actions from './sign-documents.actions';
import * as store from './sign-documents.store';
import { getIsEmailOrEmailAndSmsAction } from './sign-documents.store';

function getAppId(state$: StateObservable<RootState>): string {
  return (
    selectors.applicationSelectors.getApplicationId(state$.value.application) ??
    ''
  );
}

const loadSignDocuments: Epic<Action, Action, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.loadSignDocuments)),
    switchMap(() => {
      const applicationId = getAppId(state$);
      return from(api.getSignDocuments$(applicationId)).pipe(
        mergeMap(signDocuments => {
          const params = store.getRedirectParameters(state$.value);
          if (params) {
            const updatedDocs = updateSignDocumentsWithRedirectParameters(
              signDocuments,
              params,
            );
            return from([
              actions.loadSignDocumentsSuccess(updatedDocs),
              actions.saveSignDocuments({
                signDocuments: updatedDocs,
                routeAfterSaving: params.routeAfterSaving,
              }),
              webCommonApplicationActions.applicationActions.updateSignDocuments(
                {
                  applicationId,
                  signDocuments: updatedDocs,
                },
              ),
              actions.updatedSuccess(),
            ]);
          } else {
            return from([
              actions.loadSignDocumentsSuccess(signDocuments),
              actions.updatedSuccess(),
            ]);
          }
        }),
        catchError(error =>
          of(
            actions.loadSignDocumentsError(
              mapErrorToSignLoanDocumentsError(error),
            ),
          ),
        ),
      );
    }),
  );

const loadSignDocumentsSuccess: Epic<Action, Action, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(actions.loadSignDocumentsSuccess)),
    map(action => action.payload),
    map(signDocuments =>
      createUpdateSignDocumentsAction(state$, signDocuments),
    ),
  );

const printDocumentEpic: Epic<Action, Action, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.printSignDocument)),
    map(action => action.payload),
    switchMap((signDocument: SignDocument) => {
      const applicationId = getAppId(state$);
      return from(printDocumentLocally(applicationId, signDocument)).pipe(
        map(() => actions.printSignDocumentSuccess()),
        catchError(error =>
          of(
            actions.printSignDocumentError(
              mapErrorToSignLoanDocumentsError(error),
            ),
          ),
        ),
      );
    }),
  );

const signDocumentOnDevice: Epic<Action, Action, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(actions.signDocumentOnDevice)),
    map(action => action.payload),
    map(({ signDocument, signerIndex }) => {
      const signingLanguage = store.getSignDocumentsLanguage(state$.value);
      return prepareDocumentForSigning(
        signDocument,
        SigningDocumentAction.Sign,
        signerIndex === 0,
        signingLanguage,
      );
    }),
    switchMap(preparedDocument => {
      const applicationId = getAppId(state$);
      return from(postSignDocument(applicationId, preparedDocument)).pipe(
        mergeMap((document: SignDocument) => {
          if (document?.link) {
            window.open(document.link, '_self');
          }
          return from([
            actions.signDocumentOnDeviceSuccess(),
            webCommonApplicationActions.applicationActions.updateSignDocument({
              applicationId,
              signDocument: document,
            }),
          ]);
        }),
        catchError(error =>
          of(
            actions.signDocumentOnDeviceError(
              mapErrorToSignLoanDocumentsError(error),
            ),
          ),
        ),
      );
    }),
  );

const emailDocument: Epic<Action, Action, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.emailDocument)),
    map(action => [
      action.payload,
      getIsEmailOrEmailAndSmsAction(state$.value),
    ]),
    map(([signDocument, isEmailOrEmailAndSmsAction]) => {
      const signingLanguage = store.getSignDocumentsLanguage(state$.value);
      return prepareDocumentForSigning(
        signDocument as SignDocument,
        isEmailOrEmailAndSmsAction as SigningDocumentAction,
        true,
        signingLanguage,
      );
    }),
    switchMap(preparedDocument => {
      const applicationId = getAppId(state$);
      return from(postSignDocument(applicationId, preparedDocument)).pipe(
        mergeMap((document: SignDocument) => {
          return from([
            actions.emailDocumentSuccess(),
            webCommonApplicationActions.applicationActions.updateSignDocument({
              applicationId,
              signDocument: document,
            }),
          ]);
        }),
        catchError(error => {
          return of(
            actions.emailDocumentError(mapErrorToSignLoanDocumentsError(error)),
          );
        }),
      );
    }),
  );

async function postSignDocument(
  applicationId: string,
  ...signDocuments: SignDocument[]
): Promise<SignDocument> {
  return api.postSignDocuments(applicationId, signDocuments);
}

const resetSignDocumentsEpic: Epic<Action, Action, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(actions.resetSignDocuments)),
    map(() => [getAppId(state$), store.getSignDocumentsLanguage(state$.value)]),
    switchMap(([applicationId, language]) =>
      from(api.resetSignDocuments(applicationId, language as Language)).pipe(
        map((signDocuments: SignDocument[]) =>
          actions.resetSignDocumentsSuccess(signDocuments),
        ),
        catchError(error =>
          of(
            actions.resetSignDocumentsError(
              mapErrorToSignLoanDocumentsError(error),
            ),
          ),
        ),
      ),
    ),
  );

const resetSignDocumentsSuccess: Epic<Action, Action, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(actions.resetSignDocumentsSuccess)),
    map(action => action.payload),
    map(signDocuments =>
      createUpdateSignDocumentsAction(state$, signDocuments),
    ),
  );

function createUpdateSignDocumentsAction(
  state: StateObservable<RootState>,
  signDocuments: SignDocument[],
) {
  const applicationId = getAppId(state);
  return webCommonApplicationActions.applicationActions.updateSignDocuments({
    applicationId,
    signDocuments,
  });
}

const saveSignDocuments: Epic<Action, Action, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.saveSignDocuments)),
    map(action => action.payload),
    switchMap(({ signDocuments, routeAfterSaving }) => {
      const applicationId = getAppId(state$);
      return from(api.saveSignDocuments(applicationId, signDocuments)).pipe(
        map(() => actions.saveSignDocumentsSuccess(routeAfterSaving)),

        catchError(error =>
          of(
            actions.saveSignDocumentsError(
              mapErrorToSignLoanDocumentsError(error),
            ),
          ),
        ),
      );
    }),
  );

const saveSignDocumentsSuccess: Epic<Action, Action, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.saveSignDocumentsSuccess)),
    map(action => action.payload),
    mergeMap(routeAfterSaving => of(push(routeAfterSaving))),
  );

export async function printDocumentLocally(
  applicationId: string,
  signDocument: SignDocument,
) {
  const response = await api.generateSignDocumentPdf(
    applicationId,
    signDocument,
  );
  const printUrl = URL.createObjectURL(response.data);
  if (printUrl) {
    window.open(`/pdf/${encodeURIComponent(printUrl)}`, '_blank');
  }
}

const setLanguageEpic: Epic<Action, Action, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.selectLanguage)),
    map(() => actions.resetSignDocuments()),
  );

const resendSignDocumentsEpic: Epic<Action, Action, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(actions.resendSignDocuments)),
    map(() => [getAppId(state$)]),
    switchMap(([applicationId]) =>
      from(api.resendSignDocuments(applicationId)).pipe(
        map(() => actions.resendSignDocumentsSuccess()),
        catchError(error =>
          of(
            actions.resendSignDocumentsError(
              mapErrorToSignLoanDocumentsError(error),
            ),
          ),
        ),
      ),
    ),
  );

export default [
  emailDocument,
  loadSignDocuments,
  loadSignDocumentsSuccess,
  printDocumentEpic,
  resetSignDocumentsEpic,
  signDocumentOnDevice,
  resetSignDocumentsSuccess,
  saveSignDocuments,
  saveSignDocumentsSuccess,
  setLanguageEpic,
  resendSignDocumentsEpic,
];
