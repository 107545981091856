import { Application, Language } from '@frontline/common';
import {
  getApprovalConditions,
  PostSubmissionService,
} from '@frontline/web-common';
import { push } from 'connected-react-router';
import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootState } from '../../../../../../../store';
import * as rootActions from '../../../../../../../store/application/actions';
import * as applicationStore from '../../../../../store/application.store';
import * as actions from './overview.actions';

export const initApprovalConditionsEpic: Epic<Action, Action, RootState> = (
  action$: Observable<Action>,
) =>
  action$.pipe(
    filter(isActionOf(actions.initConditionsLookup)),
    map(action => action.payload),
    switchMap((locale: Language) =>
      from(getApprovalConditions(locale)).pipe(
        map(response => actions.initConditionsLookupSuccess(response, locale)),
        catchError(error => of(actions.initConditionsLookupError(error))),
      ),
    ),
  );

export const setPostSubmissionViewedEpic: Epic<Action, Action, RootState> = (
  action$: Observable<Action>,
) =>
  action$.pipe(
    filter(isActionOf(actions.setPostSubmissionViewed)),
    map(action => action.payload),
    switchMap((applicationId: string) =>
      PostSubmissionService.setPostSubmissionPageViewed(applicationId).pipe(
        map(response =>
          actions.setPostSubmissionViewedSuccess(response as Application),
        ),
        catchError(error => of(actions.setPostSubmissionViewedFailure(error))),
      ),
    ),
  );

export const setPostSubmissionViewedSuccessEpic: Epic<
  Action,
  Action,
  RootState
> = (action$: Observable<Action>, state$: any) =>
  action$.pipe(
    filter(isActionOf(actions.setPostSubmissionViewedSuccess)),
    map(action => [
      action.payload,
      applicationStore.getNextPostSubmissionRoute(state$.value),
    ]),
    switchMap(([application, nextStepRoute]) => [
      rootActions.updateApplication(application as Application),
      push(nextStepRoute as string),
    ]),
  );

export default [
  initApprovalConditionsEpic,
  setPostSubmissionViewedEpic,
  setPostSubmissionViewedSuccessEpic,
];
