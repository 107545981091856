import { Colours } from '@frontline/ui-library';
import { createStyles, makeStyles } from '@material-ui/core';

export const useApplicationTakeoverErrorViewStyles = makeStyles(
  createStyles({
    container: {
      background: Colours.White,
    },
  }),
);
