import { Tabs } from '@frontline/ui-library';
import Tab from '@material-ui/core/Tab';
import React, { FunctionComponent } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { OverviewTabsProps } from './OverviewTabsProps';

const messages = defineMessages({
  overview: {
    id: 'Applicant.PostSubmission.Overview.OverviewTabsView.overview',
    description: 'title of tab showing overview summary',
    defaultMessage: 'Overview',
  },
});

export const OverviewTabs: FunctionComponent<OverviewTabsProps> = props => {
  const intl = useIntl();
  return (
    <Tabs value={props.value} onChange={props.onChange}>
      <Tab value="overview" label={intl.formatMessage(messages.overview)} />
    </Tabs>
  );
};
