import { Loader } from '@frontline/ui-library';
import { LocaleContext } from '@frontline/web-common';
import React, { Fragment, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yearsWorkedCategoryStore from '../../../../../../../../modules/lookup/years-worked-category/years-worked-category.store';
import * as applicantOptionsStore from '../../../../../../modules/applicant-application-options/store/applicant-application-options.store';
import * as applicationStore from '../../../../../../store/application.store';
import { shouldGoToPostAdjustedForProgramType } from '../../../../types/ApplicationProgress.functions';
import { ApprovedView } from './components/approved-view/approved-view';
import * as store from './store/application-approved.store';

export interface ApprovedFeatureProps {
  continueClicked: () => void;
  applicationId: string | null;
}

export const ApplicationApprovedFeature = (props: ApprovedFeatureProps) => {
  const dispatch = useDispatch();
  const localeContext = useContext(LocaleContext);
  const { applicationId } = props;

  const yearsWorkedCategories = useSelector(
    yearsWorkedCategoryStore.getYearsWorkedCategoriesLookup,
  );

  useEffect(() => {
    yearsWorkedCategoryStore.fetchYearsWorkedCategories();
  }, [localeContext.locale]);

  useEffect(() => {
    if (applicationId) {
      applicantOptionsStore.fetchApplicantApplicationOptionForApplication(
        applicationId,
      );
      store.fetchOwnerContactInfo();
    }
  }, [dispatch, applicationId]);

  return (
    <Fragment>
      <Loader show={useSelector(store.isLoading)} />
      <ApprovedView
        goToPostAfterPresubmission={shouldGoToPostAdjustedForProgramType(
          useSelector(applicationStore.getProgramType),
          useSelector(applicantOptionsStore.goToPostAfterPostSubmission),
        )}
        loanDetails={useSelector(applicationStore.getLoanDetails)}
        applicant={useSelector(applicationStore.getApplicant)}
        applicationId={applicationId}
        coApplicant={useSelector(applicationStore.getCoApplicant)}
        continueClicked={props.continueClicked}
        ownerContactInfo={useSelector(store.getOwnerContactInfo)}
        yearsWorkedCategoriesLookup={yearsWorkedCategories || []}
      />
    </Fragment>
  );
};
