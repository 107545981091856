import { faUnlink } from '@fortawesome/free-solid-svg-icons/faUnlink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Colours,
  TypographyColor,
  TypographyVariant,
} from '@frontline/ui-library';
import { HelpButton } from '@frontline/web-common';
import { Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { LanguageSelectionMenu } from '../../../../common/localization';
import { LayoutToolbar } from '../../../../modules/layout/components';
import ApplicantPortalLogo from '../../../../modules/layout/components/applicant-portal-logo/applicant-portal-logo.component';
import messages from './LendCareDisabled.messages';

export const LendCareDisabled = () => {
  return (
    <React.Fragment>
      <LayoutToolbar>
        <ApplicantPortalLogo />
        <ToolbarButtons>
          <HelpButton />
          <LanguageSelectionMenu />
        </ToolbarButtons>
      </LayoutToolbar>
      <Denied>
        <IconBox>
          <FontAwesomeIcon icon={faUnlink} />
        </IconBox>
        <Typography
          variant={TypographyVariant.Header2}
          color={TypographyColor.TextPrimary}
          paragraph={true}>
          <FormattedMessage {...messages.title} />
        </Typography>
        <Typography
          variant={TypographyVariant.Subtitle1}
          color={TypographyColor.TextPrimary}
          paragraph={true}>
          <FormattedMessage {...messages.subtitle} />
        </Typography>
      </Denied>
    </React.Fragment>
  );
};

const Denied = styled.div`
  text-align: center;
`;

const IconBox = styled.div`
  font-size: 6rem;
  color: ${Colours.Marine}
  text-align: center;
  margin: 48px 0;
`;

const ToolbarButtons = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  margin: 1em;
`;
