import { actions, WebCommonRootState } from '@frontline/web-common';
import { LOCATION_CHANGE } from 'connected-react-router';
import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootState } from '../../../../../../../store';
import { submitSalesRepId } from '../../../../../services/pre-submission.service';
import * as applicationStore from '../../../../../store/application.store';
import { PersonalDetailsActions } from './personal-details.actions';

const saveApplicantPersonalDetailsErrorEpic: Epic<
  Action,
  Action,
  WebCommonRootState
> = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([
        actions.applicationActions.saveApplicantPersonalDetailsError,
        actions.applicationActions.savePreSubmissionStepError,
      ]),
    ),
    map(action =>
      PersonalDetailsActions.saveApplicantPersonalDetailsError(
        action.payload.error,
      ),
    ),
  );

const resetErrorEpic: Epic<Action, Action, RootState> = action$ =>
  action$.pipe(
    filter(action => action.type === LOCATION_CHANGE),
    map(() => PersonalDetailsActions.resetApplicantPersonalDetailsError()),
  );

const submitSalesRepIdEpic: Epic<Action, Action, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(PersonalDetailsActions.submitSalesRepId)),
    map(action => [
      action.payload,
      applicationStore.getApplicationId(state$.value),
    ]),
    mergeMap(([salesRepId, applicationId]) =>
      submitSalesRepId(salesRepId, applicationId).pipe(
        map(() => PersonalDetailsActions.submitSalesRepIdSuccess()),
        catchError(error =>
          of(PersonalDetailsActions.submitSalesRepIdFailure(error)),
        ),
      ),
    ),
  );

export const personalDetailsStateEpics = [
  saveApplicantPersonalDetailsErrorEpic,
  resetErrorEpic,
  submitSalesRepIdEpic,
];
