import { NetverifyMessageType } from '@frontline/common';
import { MessageDescriptor } from 'react-intl';
import messages from '../components/verify-identity/verify-identity.messages';

export const getMessageDefinitionForVerifyIdentity = (
  type: NetverifyMessageType,
): MessageDescriptor => {
  switch (type) {
    case NetverifyMessageType.Success:
      return messages.success;
    case NetverifyMessageType.OutOfAttempts:
      return messages.outOfAttempts;
    case NetverifyMessageType.Pending:
      return messages.pending;
    case NetverifyMessageType.Disabled:
      return messages.disabled;
    case NetverifyMessageType.FailedAttempt:
      return messages.failedAttempt;
    case NetverifyMessageType.Description:
      return messages.description;
    case NetverifyMessageType.Title:
      return messages.title;
    default:
      return messages.success;
  }
};
