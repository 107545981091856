import { withShowIf } from '@frontline/ui-library';
import { Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ApplicationTakeoverNewLinkFooterMessages } from './application-takeover-new-link-footer.messages';
import { useApplicationTakeoverNewLinkFooterStyles } from './application-takeover-new-link-footer.styles';

export const ApplicationTakeoverNewLinkFooter = withShowIf(() => {
  const classes = useApplicationTakeoverNewLinkFooterStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>
        <FormattedMessage {...ApplicationTakeoverNewLinkFooterMessages.title} />
      </Typography>
      <Typography className={classes.description}>
        <FormattedMessage
          {...ApplicationTakeoverNewLinkFooterMessages.description}
        />
      </Typography>
    </div>
  );
});
