import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Applicant.PostSubmission.Overview.BankDocumentsStep.title',
    description: 'Bank Documents Step Title',
    defaultMessage: 'Income Verification',
  },
  description: {
    id: 'Applicant.PostSubmission.Overview.BankDocumentsStep.description',
    description: 'Bank Documents Step Description',
    defaultMessage:
      'Connect to a bank or upload income verification documents.',
  },
  editButton: {
    id: 'Applicant.PostSubmission.Overview.BankDocumentsStep.editButton',
    description: 'Bank Documents Step Edit Button',
    defaultMessage: 'Verify income',
  },
});
