import { GeoLocationState } from '../types/geo-location-state.interface';
import { createGeoLocationStateState } from '../types/geo-location-tab-state.functions';
import { GeoLocationAction, GeoLocationActions } from './geo-location.actions';

export function geoLocationReducer(
  state: GeoLocationState = createGeoLocationStateState(),
  action: GeoLocationAction,
): GeoLocationState {
  switch (action.type) {
    case GeoLocationActions.FetchProvince:
      return {
        ...state,
        isLoading: true,
      };
    case GeoLocationActions.FetchProvinceSuccess:
      return {
        ...state,
        province: action.payload,
        isLoading: false,
      };
    case GeoLocationActions.FetchProvinceFailure:
      return {
        ...state,
        isLoading: false,
      };
    case GeoLocationActions.SetConsumerCoordinates:
      return {
        ...state,
        coordinates: action.payload,
      };
    case GeoLocationActions.SetHighCostLicenseDetails:
      return {
        ...state,
        highCostLicenseDetails: action.payload,
      };
    default:
      return state;
  }
}
