import clsx from 'clsx';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './contact-merchant-view.messages';
import { ContactMerchantViewProps } from './contact-merchant-view.props';
import {
  ContactMerchantDeclined,
  ContactMerchantMerchantDetails,
  ContactMerchantParagraph,
  useContactMerchantStyles,
} from './contact-merchant-view.styles';

export const ContactMerchantView = (props: ContactMerchantViewProps) => {
  const classes = useContactMerchantStyles();

  return (
    <ContactMerchantDeclined>
      <ContactMerchantParagraph variant="body1" className={classes.largeFont}>
        <FormattedMessage
          {...messages.thankYou}
          values={{
            merchantName: <b>{props.merchantName}</b>,
          }}
        />
      </ContactMerchantParagraph>
      <ContactMerchantMerchantDetails>
        <ContactMerchantParagraph
          variant="body1"
          className={clsx(classes.largeFont, classes.bold)}>
          {props.merchantName}
        </ContactMerchantParagraph>
        <ContactMerchantParagraph variant="body1" className={classes.largeFont}>
          <FormattedMessage {...messages.email} />
        </ContactMerchantParagraph>
        <ContactMerchantParagraph
          variant="body1"
          className={clsx(classes.largeFont, classes.bold)}>
          {props.merchantEmail}
        </ContactMerchantParagraph>
        <ContactMerchantParagraph variant="body1" className={classes.largeFont}>
          <FormattedMessage {...messages.phone} />
        </ContactMerchantParagraph>
        <ContactMerchantParagraph
          variant="body1"
          className={clsx(classes.largeFont, classes.bold)}>
          {props.merchantPhone}
        </ContactMerchantParagraph>
      </ContactMerchantMerchantDetails>
    </ContactMerchantDeclined>
  );
};
