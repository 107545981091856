import {
  AdditionalDetails,
  Applicant,
  IncomeDetails,
  PersonalDetails,
} from '@frontline/common';
import { actions, ApplicationState, selectors } from '@frontline/web-common';
import { createSelector, Selector } from 'reselect';
import { RootState } from '../../../../../store';

const applicationStore = {
  ...actions.applicationActions,
  ...selectors.applicationSelectors,
};

export const getCoApplicant: Selector<RootState, Applicant | undefined> = (
  state: RootState,
) => applicationStore.getCoApplicant(state.application);

export const getAuthorizedElectronicCommunication: Selector<
  RootState,
  boolean
> = createSelector(
  getCoApplicant,
  (applicant?: Applicant) =>
    applicant?.authorizedElectronicCommunication || false,
);

export const getCoApplicantPersonalDetails: Selector<
  RootState,
  PersonalDetails | undefined
> = createSelector(
  (state: RootState): ApplicationState => state.application,
  application => applicationStore.getCoApplicant(application)?.personalDetails,
);

export const getCoApplicantAdditionalDetails: Selector<
  RootState,
  AdditionalDetails | undefined
> = createSelector(
  (state: RootState): ApplicationState => state.application,
  application =>
    applicationStore.getCoApplicant(application)?.additionalDetails,
);

export const getCoApplicantIncomeDetails: Selector<
  RootState,
  IncomeDetails | undefined
> = createSelector(
  (state: RootState): ApplicationState => state.application,
  application => applicationStore.getCoApplicant(application)?.incomeDetails,
);
