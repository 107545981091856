import React from 'react';
import { useIntl } from 'react-intl';
import { AdditionalDetailsForm } from '../../../../../../../common/AdditionalDetails/components/additional-details-form';
import { AdditionalDetailsHeader } from '../../../../../../../common/AdditionalDetails/components/additional-details-header';
import { PreSubmissionPageLayout } from '../../../../../common/components/PreSubmissionPageLayout';
import { CoApplicantAdditionalDetailsViewMessages as messages } from './co-applicant-additional-details-view.messages';
import { CoApplicantAdditionalDetailsViewProps } from './co-applicant-additional-details-view.props';

export const CoApplicantAdditionalDetailsView = (
  props: CoApplicantAdditionalDetailsViewProps,
) => {
  const intl = useIntl();

  return (
    <PreSubmissionPageLayout
      applicationHeaderComponent={
        <AdditionalDetailsHeader
          title={intl.formatMessage(messages.title)}
          stepPosition={props.stepPosition}
        />
      }
      formComponent={
        <AdditionalDetailsForm
          additionalDetails={props.additionalDetails}
          onSubmit={props.onSubmit}
          onBackButtonClick={props.onBackButtonClick}
          yearsWorkedCategoriesLookup={props.yearsWorkedCategoriesLookup}
          personalDetails={props.personalDetails}
        />
      }
    />
  );
};
