import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useIntl } from 'react-intl';
import SectionRowGridItem from '../SectionRowGrid/SectionRowGridItem';
import { AdditionalDetailsFormLayoutMessages as messages } from './additional-details-form-layout.messages';
import { AdditionalDetailsFormLayoutProps } from './additional-details-form-layout.props';

export const AdditionalDetailsFormLayout = (
  props: AdditionalDetailsFormLayoutProps,
) => {
  const { formatMessage } = useIntl();
  const { employmentDetails, residentialDetails } = messages;
  const employmentDetailsTitle = formatMessage(employmentDetails);
  const residentialDetailsTitle = formatMessage(residentialDetails);

  return (
    <>
      <Grid container={true} spacing={3}>
        <SectionRowGridItem title={employmentDetailsTitle}>
          {props.employmentStatusSection}
          {props.employmentDetails}
        </SectionRowGridItem>
        <SectionRowGridItem title={residentialDetailsTitle}>
          {props.residentialDetails}
        </SectionRowGridItem>
        {props.householdExpenses}
      </Grid>
    </>
  );
};
