import { IntlShape, MessageDescriptor } from 'react-intl';
import * as yup from 'yup';
import { messages } from './void-cheque-form.messages';

export function createVoidChequeFormValidator(intl: IntlShape) {
  return yup.object().shape({
    institution: yup
      .string()
      .matches(/^[0-9]+$/, intl.formatMessage(messages.invalidVoidChequeNumber))
      .min(3, setLengthMessage(3, messages.institution, intl))
      .max(3, setLengthMessage(3, messages.institution, intl))
      .required(intl.formatMessage(messages.required)),
    transit: yup
      .string()
      .matches(/^[0-9]+$/, intl.formatMessage(messages.invalidVoidChequeNumber))
      .min(5, setLengthMessage(5, messages.transit, intl))
      .max(5, setLengthMessage(5, messages.transit, intl))
      .required(intl.formatMessage(messages.required)),
    account: yup
      .string()
      .matches(/^[0-9]+$/, intl.formatMessage(messages.invalidVoidChequeNumber))
      .min(7, setLengthMessage('7-12', messages.account, intl))
      .max(12, setLengthMessage('7-12', messages.account, intl))
      .required(intl.formatMessage(messages.required)),
  });
}

const setLengthMessage = (
  length: number | string,
  numberType: MessageDescriptor,
  intl: IntlShape,
) =>
  intl.formatMessage(messages.length, {
    length,
    numberType: intl.formatMessage(numberType),
  });
