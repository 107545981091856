import * as React from 'react';
import { OverviewStepMenuPage } from '../OverviewStepMenuPageGeneric/overview-step-menu-page.component';
import { ComponentSelectorStepProps } from './component-selector-step.props';

export const ComponentSelectorStep = (props: ComponentSelectorStepProps) => (
  <OverviewStepMenuPage
    routeUrl={props.step.routeUrl || ''}
    status={props.step.status}
    title={props.step.overviewMessages?.title || ''}
    description={props.step.overviewMessages?.description || ''}
    editButtonText={props.step.overviewMessages?.editButton || ''}
    editButtonUat={props.uat}
  />
);
