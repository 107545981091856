import { applicationPath } from '../../../../types';
import { PostSubmissionPathType } from './PostSubmissionPathType';

export const postSubmissionPath = (
  type: PostSubmissionPathType,
  applicationId: string,
): string => {
  const rootPath = applicationPath('postSubmission', applicationId);
  switch (type) {
    case 'overview':
      return `${rootPath}/overview`;
    case 'review':
      return `${rootPath}/post-submission-review`;
    case 'co-applicant-personal-details':
      return `${rootPath}/post-submission-review/co-applicant/personal-details`;
    case 'applicant-personal-details':
      return `${rootPath}/post-submission-review/applicant/personal-details`;
    default:
      return '/';
  }
};
