import { Application, CalculatorValues, LoanDetails } from '@frontline/common';
import { createCalculatorValuesForApplicationExcludingLoanProtectionInsuranceFees } from '@frontline/web-common';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as postSubmissionReviewStore from '../../../store/post-submission.store';

export const usePaymentCalculatorEstimates = (
  loanDetails?: LoanDetails,
  application?: Application,
) => {
  const dispatch = useDispatch();
  const fetchEstimatedPayment = useCallback(
    debounce(postSubmissionReviewStore.fetchEstimatedPayment(dispatch), 500),
    [dispatch],
  );
  const [
    paymentCalculatorRequestValues,
    setPaymentCalculatorRequestValues,
  ] = useState<CalculatorValues | undefined>();

  useEffect(() => {
    if (loanDetails) {
      setPaymentCalculatorRequestValues(
        createCalculatorValuesForApplicationExcludingLoanProtectionInsuranceFees(
          loanDetails,
          application,
        ),
      );
    }
  }, [loanDetails, application]);

  return {
    fetchEstimatedPayment,
    paymentCalculatorRequestValues,
  };
};
