import { ContactInformation } from '@frontline/common';
import { HttpError } from '@frontline/web-common';
import { ActionType, createAction } from 'typesafe-actions';

export enum ApplicationApprovedActions {
  FetchOwnerContactInfo = 'ApplicationApproved/FetchOwnerContactInfo',
  FetchOwnerContactInfoSuccess = 'ApplicationApproved/FetchOwnerContactInfoSuccess',
  FetchOwnerContactInfoFailure = 'ApplicationApproved/FetchOwnerContactInfoFailure',
}

export const fetchOwnerContactInfo = createAction(
  ApplicationApprovedActions.FetchOwnerContactInfo,
);

export const fetchOwnerContactInfoSuccess = createAction(
  ApplicationApprovedActions.FetchOwnerContactInfoSuccess,
  action => (ownerContactInfo: ContactInformation) => action(ownerContactInfo),
);

export const fetchOwnerContactInfoFailure = createAction(
  ApplicationApprovedActions.FetchOwnerContactInfoFailure,
  action => (error: HttpError) => action(error),
);

const actionCreators = {
  fetchOwnerContactInfo,
  fetchOwnerContactInfoSuccess,
  fetchOwnerContactInfoFailure,
};

export type ApplicationApprovedActionType = ActionType<typeof actionCreators>;
