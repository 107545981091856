import * as React from 'react';
import messages from './payment-details.messages';

import { OverViewStepMenuPageProps } from '../overview-step-menu-page.props';
import { OverviewStepMenuPageGeneric } from '../OverviewStepMenuPageGeneric/overview-step-menu-item-generic.component';
import { PaymentDetailsStepUat } from './payment-details-step.uat';

import { useUatId } from '@frontline/ui-library';

export const PaymentDetailsStep = (props: OverViewStepMenuPageProps) => {
  const uatId = useUatId();
  return (
    <OverviewStepMenuPageGeneric
      routeUrl={props.routeUrl}
      status={props.status}
      title={messages.title}
      editButtonText={messages.editButton}
      description={messages.description}
      editButtonUat={uatId(PaymentDetailsStepUat.editButton)}
    />
  );
};
