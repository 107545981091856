import { Language } from '@frontline/common';
import { ActionType, createAction } from 'typesafe-actions';

export enum UserPreferencesActions {
  updateLanguagePreference = 'Feature/updateLanguagePreference',
}

export const updateLanguagePreference = createAction(
  UserPreferencesActions.updateLanguagePreference,
  action => (language: Language) => action({ language }),
);

const UserPreferencesActionCreators = {
  updateLanguagePreference,
};

export type UserPreferencesActionType = ActionType<
  typeof UserPreferencesActionCreators
>;
