import { ContactInformation } from '@frontline/common';
import { HttpService } from '@frontline/web-common';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export function fetchOwnerContactInfo(
  applicationId: string,
): Observable<ContactInformation> {
  return from(
    HttpService.getHttpInstanceDeprecated().get(endPoint(applicationId)),
  ).pipe(map(response => response.data));
}

function endPoint(applicationId: string): string {
  return `/applications/${applicationId}/owner-contact-info`;
}
