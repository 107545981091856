import { AddOn, ThirdPartyAddOnTypeDto } from '@frontline/common';

export const getThirdPartyAddOnType = (
  addOn: AddOn,
  thirdPartyAddOnTypes: ThirdPartyAddOnTypeDto[] = [],
): ThirdPartyAddOnTypeDto => {
  const foundType = thirdPartyAddOnTypes.find(type => type.id === addOn.typeId);

  return foundType ? foundType : createUnknownType(addOn.typeId);
};

export const createUnknownType = (typeId: number): ThirdPartyAddOnTypeDto => {
  return { id: typeId, name: 'Unkown', sortOrder: 0 };
};
