import { defineMessages } from 'react-intl';

export default defineMessages({
  relationshipTypeLabel: {
    id: 'personalDetails.relationshipTypeLabel',
    description: 'Relationship Type Label',
    defaultMessage: 'Relationship with applicant',
  },
  nameLabel: {
    id: 'PersonalDetailsInfo.nameLabel',
    description: 'Name Label',
    defaultMessage: 'Name',
  },
  dateOfBirthLabel: {
    id: 'PersonalDetailsInfo.dateOfBirthLabel',
    description: 'Date of birth Label',
    defaultMessage: 'Date of Birth',
  },
  addressLabel: {
    id: 'PersonalDetailsInfo.addressLabel',
    description: 'Address Label',
    defaultMessage: 'Address',
  },
  emailLabel: {
    id: 'PersonalDetailsInfo.emailLabel',
    description: 'Email Label',
    defaultMessage: 'Email Address',
  },
  primaryPhoneLabel: {
    id: 'PersonalDetailsInfo.primaryPhoneLabel',
    description: 'Primary Phone Label',
    defaultMessage: 'Primary Phone',
  },
});
