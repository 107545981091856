import { WorkflowStepStatus } from '@frontline/common';
import {
  PrimaryButton,
  SecondaryButton,
  ShowIfFragment,
} from '@frontline/ui-library';
import * as React from 'react';
import { Link } from 'react-router-dom';
import OverviewStepActions from './overview-step-actions.component';
import OverviewStepDescription from './overview-step-description.component';
import { OverviewStepMenuItemProps } from './overview-step-menu-page.props';
import { useOverviewStepMenuPageStyles } from './overview-step-menu-page.styles';
import OverviewStepTitle from './overview-step-title.component';

export const OverviewStepMenuPage = (props: OverviewStepMenuItemProps) => {
  const classes = useOverviewStepMenuPageStyles();

  return (
    <div className={classes.step}>
      <OverviewStepTitle>{props.title}</OverviewStepTitle>
      <OverviewStepDescription>{props.description}</OverviewStepDescription>
      <OverviewStepActions>
        <Link to={props.routeUrl}>
          <ShowIfFragment
            showIf={props.status === WorkflowStepStatus.Completed}>
            <SecondaryButton {...props.editButtonUat}>
              {props.editButtonText}
            </SecondaryButton>
          </ShowIfFragment>
          <ShowIfFragment
            showIf={props.status !== WorkflowStepStatus.Completed}>
            <PrimaryButton {...props.editButtonUat}>
              {props.editButtonText}
            </PrimaryButton>
          </ShowIfFragment>
        </Link>
        {props.children}
      </OverviewStepActions>
    </div>
  );
};
