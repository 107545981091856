import { Coordinates, Province } from '@frontline/common';
import { HttpService } from '@frontline/web-common';
import { stringify } from 'query-string';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export function fetchProvince(coordinates: Coordinates): Observable<Province> {
  const endPoint = `/geocode/province?${stringify(coordinates)}`;
  return from(HttpService.getHttpInstance().get(endPoint)).pipe(
    map(response => response.data),
  );
}
