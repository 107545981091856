import { defineMessages } from 'react-intl';

export default defineMessages({
  primaryApplicantTitle: {
    id: 'ReviewSummaryBody.primaryApplicantTitle',
    description: 'Applicant title',
    defaultMessage: 'Applicant',
  },
  coApplicantTitle: {
    id: 'ReviewSummaryBody.coApplicantTitle',
    description: 'Co-Applicant title',
    defaultMessage: 'Co-Applicant',
  },
  additionalDetailsTitle: {
    id: 'ReviewSummaryBody.additionalDetailsTitle',
    description: 'Additional Details Title',
    defaultMessage: 'Additional Details',
  },
  additionalDetailsTitleCoApplicant: {
    id: 'ReviewSummaryBody.additionalDetailsTitleCoApplicant',
    description: 'Co-Applicant Additional Details',
    defaultMessage: 'Co-Applicant Additional Details',
  },
  incomeDetailsTitle: {
    id: 'ReviewSummaryBody.incomeDetailsTitle',
    description: 'Income Details Title',
    defaultMessage: 'Income Details',
  },
  incomeDetailsTitleCoApplicant: {
    id: 'ReviewSummaryBody.incomeDetailsTitleCoApplicant',
    description: 'Co-Applicant Income Details',
    defaultMessage: 'Co-Applicant Income Details',
  },
  personalDetailsTitle: {
    id: 'ReviewSummaryBody.personalDetailsTitle',
    description: 'Personal Details Title',
    defaultMessage: 'Personal Details',
  },
  personalDetailsTitleCoApplicant: {
    id: 'ReviewSummaryBody.personalDetailsTitleCoApplicant',
    description: 'Personal Details Title Co-Applicant',
    defaultMessage: 'Co-Applicant Personal Details',
  },
});
