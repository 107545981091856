import { defineMessages } from 'react-intl';

export const workflowMessages = defineMessages({
  purchaseDetails: {
    id: 'Applicant.PostSubmission.Common.purchaseDetails',
    description: 'Purchase details Step Description',
  },
  applicant: {
    id: 'Applicant.PostSubmission.Common.applicant',
    description: 'Applicant Workflow Step Description',
  },
  bankDocuments: {
    id: 'Applicant.PostSubmission.Common.bankDocuments',
    description: 'Bank Documents Workflow Step Description',
  },
  coApplicant: {
    id: 'Applicant.PostSubmission.Common.coApplicant',
    description: 'Co-Applicant Workflow Step Description',
  },
  personalDetails: {
    id: 'Applicant.PostSubmission.Common.personalDetails',
    description: 'Personal Details Workflow Step Description',
  },
  incomeDetails: {
    id: 'Applicant.PostSubmission.Common.incomeDetails',
    description: 'Income details Workflow Step Description',
  },
  additionalDetails: {
    id: 'Applicant.PostSubmission.Common.additionalDetails',
    description: 'Additional details Workflow Step Description',
  },
  assetInformation: {
    id: 'Applicant.PostSubmission.Common.assetInformation',
    description: 'Asset Details Workflow Step Description',
  },
  assetsAndAddOns: {
    id: 'Applicant.PostSubmission.Common.assetsAndAddOns',
    description: 'Assets And AddOns Workflow Step Description',
  },
  loanDetails: {
    id: 'Applicant.PostSubmission.Common.loanDetails',
    description: 'Loan Details Workflow Step Description',
  },
  finalizePaymentPlan: {
    id: 'Applicant.PostSubmission.Common.finalizePaymentPlan',
    description: 'Finalize Loan Details Workflow Step Description',
  },
  addOns: {
    id: 'Applicant.PostSubmission.Common.addOns',
    description: 'Add-ons Workflow Stepe Description',
  },
  signDocuments: {
    id: 'Applicant.PostSubmission.Common.signDocuments',
    description: 'Sign Documents Workflow Step Description',
  },
  paymentDetails: {
    id: 'Applicant.PostSubmission.Common.paymentDetails',
    description: 'Payment Details Workflow Step Description',
  },
  uploadDocuments: {
    id: 'Applicant.PostSubmission.Common.uploadDocuments',
    description: 'Upload Documents Workflow Step Description',
  },
  verifyIdentity: {
    id: 'Applicant.PostSubmission.Common.verifyIdentity',
  },
  loanProtection: {
    id: 'Applicant.PostSubmission.Common.loanProtection',
    description: 'Loan Protection Workflow Step Description',
  },
  invoiceDetails: {
    id: 'Applicant.PostSubmission.Common.invoiceDetails',
    description: 'Invoice Details Workflow Step Description',
  },
  confirmInvoiceDetails: {
    id: 'Applicant.PostSubmission.Common.confirmInvoiceDetails',
  },
  preAuthorizedDebits: {
    id: 'Applicant.PostSubmission.Common.preAuthorizedDebits',
    description: 'Pre-authorized debits tab title',
  },
  homeAndAutoAncillaryProduct: {
    id: 'CheckListLabel.HomeAndAutoAncillaryProduct',
    description: 'Home and Auto Ancillary Product tab title',
  },
  unknown: {
    id: 'Applicant.PostSubmission.Common.unknown',
    description: 'Unknown Workflow Step Description',
  },
});
