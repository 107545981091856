import { createApplicationTakeoverState } from '../types/application-takeover-state.functions';
import { ApplicationTakeoverState } from '../types/application-takeover-state.interface';
import {
  ApplicationTakeoverAction,
  ApplicationTakeoverActionType,
} from './application-takeover.actions';

export const applicationTakeoverReducer = (
  state: ApplicationTakeoverState = createApplicationTakeoverState(),
  action: ApplicationTakeoverActionType,
): ApplicationTakeoverState => {
  switch (action.type) {
    case ApplicationTakeoverAction.FetchApplicationTakeoverDetails:
      return {
        ...state,
        isLoading: true,
      };

    case ApplicationTakeoverAction.FetchApplicationTakeoverDetailsSuccess:
      return {
        ...state,
        isLoading: false,
        applicationTakeoverDetails: action.payload,
      };
    case ApplicationTakeoverAction.FetchApplicationTakeoverDetailsError:
    case ApplicationTakeoverAction.AnswerApplicationTakeoverRequestError:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ApplicationTakeoverAction.AnswerApplicationTakeoverRequest:
      return {
        ...state,
        isLoading: true,
        error: undefined,
        isConfirming: false,
      };
    case ApplicationTakeoverAction.AnswerApplicationTakeoverRequestSuccess:
      return {
        ...state,
        applicationTakeoverStatus: action.payload,
        isLoading: false,
      };
    case ApplicationTakeoverAction.SetIsConfirming:
      return {
        ...state,
        isConfirming: action.payload,
        hasDeclined: false,
        hasAccepted: false,
      };
    default:
      return state;
  }
};
