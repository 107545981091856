import { SectionItem, SectionPanel, withShowIf } from '@frontline/ui-library';
import { getStatusDefinition, LookupServices } from '@frontline/web-common';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import { formattedPhone } from '../../../../../../types/formatApplicationData.functions';
import messages from './review-summary-additional-details.messages';
import { ReviewSummaryAdditionalDetailsProps as Props } from './review-summary-additional-details.prop';

export const ReviewSummaryAdditionalDetails = withShowIf((props: Props) => {
  const intl = useIntl();

  const title = intl.formatMessage(props.titleMessageDescriptor);

  const getEmploymentStatusValue = () => {
    if (!props.additionalDetails?.employmentStatus) {
      return null;
    }
    return intl.formatMessage(
      getStatusDefinition(props.additionalDetails?.employmentStatus || ''),
    );
  };

  const yearsWorkedValue = LookupServices.findNameById(
    props.additionalDetails?.employmentDetails?.yearsWorkedCategoryId || null,
    props.yearsWorkedCategoriesLookup,
  );
  return (
    <SectionPanel title={title} editUrl={props.editUrl} uat={props.uat}>
      <Grid container spacing={3}>
        <SectionItem
          label={intl.formatMessage(messages.employmentStatusLabel)}
          data={getEmploymentStatusValue()}
        />
        <SectionItem
          label={intl.formatMessage(messages.employersNameLabel)}
          data={props.additionalDetails?.employmentDetails?.employerName}
        />
        <SectionItem
          label={intl.formatMessage(messages.yearsWorkedLabel)}
          data={yearsWorkedValue}
        />
        <SectionItem
          label={intl.formatMessage(messages.occupationLabel)}
          data={props.additionalDetails?.employmentDetails?.jobRole}
        />
        <SectionItem
          label={intl.formatMessage(messages.cityLabel)}
          data={props.additionalDetails?.employmentDetails?.city}
        />
        <SectionItem
          label={intl.formatMessage(messages.provinceLabel)}
          data={props.additionalDetails?.employmentDetails?.province}
        />
        <SectionItem
          label={intl.formatMessage(messages.employerPhoneLabel)}
          data={formattedPhone(
            props.additionalDetails?.employmentDetails?.businessPhone,
          )}
        />
        <SectionItem
          label={intl.formatMessage(messages.residentialStatusLabel)}
          data={props.additionalDetails?.residentialStatus?.name}
        />
      </Grid>
    </SectionPanel>
  );
});
