import {
  getWorkflowStepReference,
  readWorkflowPathIncludingChildren,
  WorkflowDecisions,
  WorkflowStep,
  WorkflowStepReference,
  WorkflowStepStatus,
} from '@frontline/common';

export const createWorkflowStepReference = (
  workflowSteps: WorkflowStep[],
  pathname: string,
  workflowDecisions?: WorkflowDecisions,
): WorkflowStepReference | undefined =>
  getWorkflowStepReference(
    findActionableSteps(workflowSteps, workflowDecisions, pathname),
  );

const findActionableSteps = (
  steps?: WorkflowStep[],
  workflowDecisions?: WorkflowDecisions,
  pathname?: string,
) => {
  if (!steps || !pathname) {
    return [];
  }
  const workflowSteps = readWorkflowPathIncludingChildren(
    steps,
    workflowDecisions,
  );
  return workflowSteps.filter(step => stepIsActiveOrIncomplete(step, pathname));
};

const stepIsActiveOrIncomplete = (step: WorkflowStep, path: string) =>
  (step.routeUrl === path || step.status !== WorkflowStepStatus.Completed) &&
  !step.disabled;
