import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'SubmissionTiemoutDialog.title',
    defaultMessage: 'This is taking longer than usual.',
  },
  body: {
    id: 'SubmissionTimeoutDialog.body',
    defaultMessage:
      "It's taking longer than normal to process your application.  You can safely close your browser, and we'll email you a link to continue your application once it's been processed.  If you continue waiting, you'll be taken to the next step as soon as we're done.  Thanks for your patience.",
  },
  okButton: {
    id: 'SubmissionTimeoutDialog.okButton',
    defaultMessage: 'OK',
  },
});
