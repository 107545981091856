import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  type: {
    id: 'Applicant.PostSubmission.AddOnTableHeader.type',
    description:
      'Header for the table displaying add ons. The table has columns, this column refers to the type of Add On',
    defaultMessage: 'Type',
  },
  provider: {
    id: 'Applicant.PostSubmission.AddOnTableHeader.provider',
    description:
      'Header for the table displaying add ons. The table has columns, this column refers to the provider of the Add On',
    defaultMessage: 'Provider',
  },
  cost: {
    id: 'Applicant.PostSubmission.AddOnTableHeader.cost',
    description:
      'Header for the table displaying add ons. The table has columns, this column refers to the cost of the Add On',
    defaultMessage: 'Cost',
  },
});
