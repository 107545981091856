import {
  SectionItem,
  SectionPanel,
  ShowIfFragment,
} from '@frontline/ui-library';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import { AssetInformationSectionHeader } from '../assets-section-header/assets-section-header.component';
import { TradeInViewMessages as messages } from './trade-in-view.messages';
import { TradeInViewProps } from './trade-in-view.props';

export const TradeInView = (props: TradeInViewProps) => {
  const intl = useIntl();

  return (
    <React.Fragment>
      <AssetInformationSectionHeader
        title={intl.formatMessage(messages.tradeInTitle)}
      />
      <div style={{ width: '100%' }}>
        <SectionPanel title="">
          <Grid container={true}>
            <Grid
              container={true}
              spacing={3}
              style={{ paddingBottom: '20px' }}>
              <SectionItem
                label={intl.formatMessage(messages.tradeInAmount)}
                data={
                  <NumberFormat
                    value={props.tradeInAmount || 0}
                    displayType={'text'}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                }
              />
              <ShowIfFragment showIf={props.taxDetails?.enabled}>
                <SectionItem
                  label={intl.formatMessage(messages.taxRate)}
                  data={
                    <NumberFormat
                      value={props.taxDetails?.rate! * 100}
                      displayType={'text'}
                      fixedDecimalScale={true}
                      thousandSeparator={true}
                      suffix={'%'}
                    />
                  }
                />
              </ShowIfFragment>
            </Grid>
          </Grid>
        </SectionPanel>
      </div>
    </React.Fragment>
  );
};
