import { TypographyColor, TypographyVariant } from '@frontline/ui-library';
import { Typography } from '@material-ui/core';
import Radio from '@material-ui/core/Radio/Radio';
import * as React from 'react';
import { useMemo } from 'react';
import { getImageSrc } from './asset-types-choice.functions';
import { AssetTypesChoiceProps } from './asset-types-choice.props';
import {
  ChoiceBox,
  ChoiceField,
  ChoiceImage,
  ChoiceImageWrapper,
  ChoiceLabel,
} from './asset-types-choice.styles';

export const AssetTypesChoice = (props: AssetTypesChoiceProps) => {
  const inputProps = useMemo(
    () =>
      ({
        ...(props.uat ?? {}),
      } as React.InputHTMLAttributes<HTMLInputElement>),
    [props.uat],
  );

  const onCheck = () => {
    props.checked(props.value);
  };

  return (
    <ChoiceBox active={props.selected}>
      <ChoiceImageWrapper>
        <ChoiceImage src={getImageSrc(props.value, props.selected)} />
      </ChoiceImageWrapper>
      <ChoiceLabel>
        <Typography
          variant={TypographyVariant.Header6}
          color={TypographyColor.TextPrimary}>
          {props.label}
        </Typography>
      </ChoiceLabel>
      <ChoiceField>
        <Radio
          checked={props.selected}
          onChange={onCheck}
          value={props.value}
          name={`${props.value}`}
          aria-label={props.label}
          disabled={props.disabled}
          inputProps={inputProps}
        />
      </ChoiceField>
    </ChoiceBox>
  );
};
