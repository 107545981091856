import {
  ApplicationValidationResult,
  Language,
} from '@frontline/common';
import { HttpError } from '@frontline/web-common';
import { ActionType, createAction } from 'typesafe-actions';
import { AssetInformationReportDto } from '../types/asset-information-report-dto';

export enum AssetInformationActions {
  FetchAssetInformationReport = 'AssetInformation/FetchAssetInformationReport',
  FetchAssetInformationReportSuccess = 'AssetInformation/FetchAssetInformationReportSuccess',
  FetchAssetInformationReportFailure = 'AssetInformation/FetchAssetInformationReportFailure',
  FetchValidations = 'AssetInformation/FetchValidations',
  FetchValidationsSuccess = 'AssetInformation/FetchValidationsSuccess',
  FetchValidationsFailure = 'AssetInformation/FetchValidationsError',
  ResetAssetInformationReport = 'AssetInformation/ResetAssetInformationReport',
}

export const fetchAssetInformationReport = createAction(
  AssetInformationActions.FetchAssetInformationReport,
  action => (language: Language) => action(language),
);

export const fetchAssetInformationReportSuccess = createAction(
  AssetInformationActions.FetchAssetInformationReportSuccess,
  action => (assetInformation: AssetInformationReportDto) =>
    action(assetInformation),
);

export const fetchAssetInformationReportFailure = createAction(
  AssetInformationActions.FetchAssetInformationReportFailure,
  action => (error: HttpError) => action(error),
);

export const fetchValidations = createAction(
  AssetInformationActions.FetchValidations,
);

export const fetchValidationsSuccess = createAction(
  AssetInformationActions.FetchValidationsSuccess,
  action => (validations: ApplicationValidationResult) => action(validations),
);

export const fetchValidationsError = createAction(
  AssetInformationActions.FetchValidationsFailure,
  action => (error: HttpError) => action(error),
);

export const resetAssetInformationReport = createAction(
  AssetInformationActions.ResetAssetInformationReport,
);

const actionCreators = {
  fetchAssetInformationReport,
  fetchAssetInformationReportSuccess,
  fetchAssetInformationReportFailure,
  fetchValidations,
  fetchValidationsError,
  fetchValidationsSuccess,
  resetAssetInformationReport,
};

export type AssetInformationActionType = ActionType<typeof actionCreators>;
