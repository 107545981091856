import {
  TypographyColor,
  TypographyVariant,
  withShowIf,
} from '@frontline/ui-library';
import Typography from '@material-ui/core/Typography';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ApprovedContactInfoProps } from './approved-contact-info-props';
import messages from './approved-contact-info.messages';

export const ApprovedContactInfo = withShowIf(
  (props: ApprovedContactInfoProps) => {
    if (!props.ownerContactInfo) {
      return null;
    }

    return (
      <Fragment>
        <ContactYourMerchant>
          <Typography
            variant={TypographyVariant.Header5}
            color={TypographyColor.Secondary}
            align="center">
            <FormattedMessage {...messages.contactMerchantLabel} />
          </Typography>
        </ContactYourMerchant>
        <Typography
          variant={TypographyVariant.Header5}
          color={TypographyColor.Secondary}
          align="center">
          {props.ownerContactInfo.name}
        </Typography>
        <Typography
          variant={TypographyVariant.Header5}
          color={TypographyColor.Secondary}
          align="center">
          <FormattedMessage {...messages.contactPhoneLabel} />{' '}
          {props.ownerContactInfo.phoneNumber}
        </Typography>
        <Typography
          variant={TypographyVariant.Header5}
          color={TypographyColor.Secondary}
          align="center">
          <FormattedMessage {...messages.contactEmailLabel} />{' '}
          {props.ownerContactInfo.email}
        </Typography>
      </Fragment>
    );
  },
);

const ContactYourMerchant = styled.div`
  margin: 10px;
`;
