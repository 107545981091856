import {
  TypographyColor,
  TypographyVariant,
  useUatId,
} from '@frontline/ui-library';
import { Grid, Typography } from '@material-ui/core';
import React, { Fragment, FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { IncomeDetailsInfo } from '../income-details-info';
import { ReviewSummaryAdditionalDetails } from '../review-summary-additional-details';
import { ReviewSummaryPersonalDetails } from '../review-summary-personal-details';
import { ReviewSummaryViewUat } from '../review-summary-view/review-summary-view.uat';
import { ApplicantDetailsReviewProps as Props } from './applicant-details-review.props';

export const ApplicantDetailsReview: FunctionComponent<Props> = (
  props: Props,
) => {
  const uatId = useUatId();
  if (!props.applicantDetails) {
    return null;
  }
  return (
    <Fragment>
      <Grid container={true} justify="space-between" alignItems="center">
        <Typography
          variant={TypographyVariant.Header3}
          color={TypographyColor.TextPrimary}
          gutterBottom={true}>
          <FormattedMessage {...props.titleMessageDescriptor} />
        </Typography>
      </Grid>
      <ReviewSummaryPersonalDetails
        isCoApplicant={props.isCoApplicant}
        titleMessageDescriptor={props.personalDetailsTitle}
        personalDetails={props.applicantDetails?.personalDetails}
        editUrl={props.personalDetailsEditUrl}
        uat={uatId(ReviewSummaryViewUat.editPersonalDetails)}
      />
      <IncomeDetailsInfo
        titleMessageDescriptor={props.incomeDetailsTitle}
        incomeDetails={props.applicantDetails?.incomeDetails}
        editUrl={props.incomeDetailsEditUrl}
        showIf={Boolean(props.applicantDetails?.incomeDetails)}
        uat={uatId(ReviewSummaryViewUat.editIncomeDetails)}
      />
      <ReviewSummaryAdditionalDetails
        titleMessageDescriptor={props.additionalDetailsTitle}
        additionalDetails={props.applicantDetails?.additionalDetails}
        editUrl={props.additionalDetailsEditUrl}
        yearsWorkedCategoriesLookup={props.yearsWorkedCategoriesLookup}
        showIf={Boolean(props.applicantDetails?.additionalDetails)}
        uat={uatId(ReviewSummaryViewUat.editAdditionalDetails)}
      />
    </Fragment>
  );
};
