import { LoanDetailsDto } from '@frontline/common';
import { HttpService } from '@frontline/web-common';

export function fetchLoanDetails(
  applicationId: string,
): Promise<LoanDetailsDto> {
  return HttpService.getHttpInstanceDeprecated()
    .get(endPoint(applicationId))
    .then(response => response.data);
}

function endPoint(applicationId: string, path = ''): string {
  return `/applications/${applicationId}/loan-details${path}`;
}
