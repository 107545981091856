import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Applicant.PreSubmission.CoApplicant.IncomeDetails.title',
    description:
      'Applicant Portal Pre-Submission Co-Applicant Income Details Title',
    defaultMessage: "Co-Applicant's Income Details",
  },
});
