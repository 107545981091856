import { createBuyLendCareState } from '../types/LendCareState/lendcare-state.functions';
import { LendCareState } from '../types/LendCareState/lendcare-state.interface';
import {
  BuyLendCareActions,
  BuyLendCareActionType,
} from './buy-lendcare.actions';

export const buyLendCareReducer = (
  state: LendCareState = createBuyLendCareState(),
  action: BuyLendCareActionType,
) => {
  if (action.type === BuyLendCareActions.StartNewApplicationSuccess) {
    return {
      ...state,
      accessKey: action.payload.accessKey,
      applicationId: action.payload.applicationId,
    };
  }

  return state;
};
