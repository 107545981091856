import { PreSubmissionFormType } from '@frontline/common';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as applicationStore from '../../../../store/application.store';
import * as store from '../../common/CoApplicant/store/coApplicantDetails.store';
import * as preSubmissionStore from '../../store/pre-submission.store';
import { PreSubmissionFormPropsType } from '../../types/PreSubmissionForm/PreSubmissionFormPropsType';
import { CoApplicantRoutes } from './CoApplicantRoutes';
import {
  currentStepIndexByRoute,
  getPreviousStepRoute,
  shouldGoToDefaultRoute,
} from './types/WorkflowStep.functions';

export interface CoApplicantDetailsFeatureProps
  extends PreSubmissionFormPropsType<PreSubmissionFormType> {
  applicationId: string;
}

export const CoApplicantFeature = (props: CoApplicantDetailsFeatureProps) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const defaultRoute = useSelector(store.getDefaultCoApplicantRoute);

  const activeStepIndex = useSelector(store.getCoApplicantActiveStepIndex);

  const applicationId = useSelector(applicationStore.getApplicationId);

  const stepPosition = useSelector(preSubmissionStore.getActiveStepIndex) || -1;

  const stepReference = useSelector(
    preSubmissionStore.getWorkflowStepReference,
  );

  const coApplicantWorflow = useSelector(store.getCoApplicantWorflow);

  const currentRoute = history.location.pathname;

  useEffect(() => {
    store.loadCoApplicantWorkflow(dispatch)();
  }, [dispatch, stepReference]);

  useEffect(() => {
    if (shouldGoToDefaultRoute(currentRoute, defaultRoute, applicationId)) {
      history.push(defaultRoute);
    }
  }, [dispatch, history, applicationId, currentRoute, defaultRoute]);

  useEffect(() => {
    store.setCoApplicantActiveStepIndex(dispatch)(
      currentStepIndexByRoute(coApplicantWorflow, currentRoute),
    );
  }, [dispatch, currentRoute, coApplicantWorflow]);

  const goBack = () => {
    const routeUrl = getPreviousStepRoute(activeStepIndex, coApplicantWorflow);
    return routeUrl && history.push(routeUrl);
  };

  return (
    <CoApplicantRoutes
      applicationId={props.applicationId}
      onBack={goBack}
      onSubmit={props.onSubmit}
      stepPosition={stepPosition}
      currentStep={stepReference}
    />
  );
};
