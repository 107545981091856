import { muiTheme, useUatId } from '@frontline/ui-library';
import { useMediaQuery } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import PreSubmissionPageLayout from '../../../../common/components/PreSubmissionPageLayout/PreSubmissionPageLayout';
import { ReviewSummaryBody } from '../review-summary-body';
import { ReviewSummaryHeader } from '../review-summary-header';
import { ReviewSummaryViewProps } from './review-summary-view.props';
import { ReviewSummaryViewUat } from './review-summary-view.uat';

export const ReviewSummaryView: FunctionComponent<ReviewSummaryViewProps> = props => {
  const uatId = useUatId();
  const isMediaSmall = useMediaQuery(muiTheme.breakpoints.down('xs'));

  return (
    <PreSubmissionPageLayout
      applicationHeaderComponent={
        <ReviewSummaryHeader
          stepPosition={props.stepPosition}
          onSubmit={props.onSubmit}
          showSubmitButton={!isMediaSmall}
          uat={uatId(ReviewSummaryViewUat.submitButton)}
        />
      }
      formComponent={
        <ReviewSummaryBody
          onSubmit={props.onSubmit}
          showSubmitButton={isMediaSmall}
          applicantDetails={props.applicantDetails}
          coApplicantDetails={props.coApplicantDetails}
          displayInterestRate={props.displayInterestRate}
          additionalDetailsEditUrl={props.additionalDetailsEditUrl}
          incomeDetailsEditUrl={props.incomeDetailsEditUrl}
          personalDetailsEditUrl={props.personalDetailsEditUrl}
          coApplicantAdditionalDetailsEditUrl={
            props.coApplicantAdditionalDetailsEditUrl
          }
          coApplicantIncomeDetailsEditUrl={
            props.coApplicantIncomeDetailsEditUrl
          }
          coApplicantPersonalDetailsEditUrl={
            props.coApplicantPersonalDetailsEditUrl
          }
          yearsWorkedCategoriesLookup={props.yearsWorkedCategoriesLookup}
        />
      }
    />
  );
};
