import { RelationshipType } from '@frontline/common';
import { ActionType, createAction } from 'typesafe-actions';

export enum CoApplicantPersonalDetailsActions {
  FetchRelationshipTypes = 'CoApplicantPersonalDetails/FetchRelationshipTypes',
  FetchRelationshipTypesSuccess = 'CoApplicantPersonalDetails/FetchRelationshipTypesSuccess',
  FetchRelationshipTypesError = 'CoApplicantPersonalDetails/FetchRelationshipTypesError',
}

export const fetchRelationshipTypes = createAction(
  CoApplicantPersonalDetailsActions.FetchRelationshipTypes,
);

export const fetchRelationshipTypesSuccess = createAction(
  CoApplicantPersonalDetailsActions.FetchRelationshipTypesSuccess,
  action => (types: RelationshipType[]) => action(types),
);

export const fetchRelationshipTypesError = createAction(
  CoApplicantPersonalDetailsActions.FetchRelationshipTypesError,
  action => (error: Error) => action(error),
);

const actionCreators = {
  fetchRelationshipTypes,
  fetchRelationshipTypesSuccess,
  fetchRelationshipTypesError,
};

export type CoApplicantPersonalDetailsActionsType = ActionType<
  typeof actionCreators
>;
