import { ThirdPartyAddOnsState } from '../types/ThirdPartyAddOnsState/ThirdPartyAddOnsState';
import { createThirdPartyAddOnsState } from '../types/ThirdPartyAddOnsState/ThirdPartyAddOnsState.functions';
import {
  ThirdPartyAddOnsActions,
  ThirdPartyAddOnsActionType,
} from './thirdPartyAddOns.actions';

export const thirdPartyAddOnsReducer = (
  state: ThirdPartyAddOnsState = createThirdPartyAddOnsState(),
  action: ThirdPartyAddOnsActionType,
): ThirdPartyAddOnsState => {
  switch (action.type) {
    case ThirdPartyAddOnsActions.FetchTypes:
      return {
        ...state,
        isLoading: true,
      };
    case ThirdPartyAddOnsActions.FetchTypesSuccess:
      return {
        ...state,
        types: action.payload,
        isLoading: false,
      };
    case ThirdPartyAddOnsActions.FetchTypesFailure:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
