import { FormatDateOfBirth } from '@frontline/ui-library';
import React from 'react';
import { FirstPaymentDateStyle } from './first-payment-date-style';

interface FirstPaymentDateProps {
  value?: Date;
}

export const FirstPaymentDate = (props: FirstPaymentDateProps) =>
  props.value ? (
    <FirstPaymentDateStyle>
      <FormatDateOfBirth value={props.value} />
    </FirstPaymentDateStyle>
  ) : null;
