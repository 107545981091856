import { push } from 'connected-react-router';
import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootState } from '../../../../../../../store';
import { updateApplicationResultPageViewed } from '../../../../../services/application.service';
import { applicationPath } from '../../../../../types';
import * as actions from './result-summary.actions';

const completeResultEpic: Epic<Action, Action, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.completeResult)),
    map(action => [action.payload]),
    mergeMap(([applicationId]) =>
      from(updateApplicationResultPageViewed(applicationId)).pipe(
        map(() => actions.completeResultSuccess(applicationId)),
        catchError(error => of(actions.completeResultError(error))),
      ),
    ),
  );

const completeResultSuccessEpic: Epic<Action, Action, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.completeResultSuccess)),
    map(action => action.payload),
    map(applicationId =>
      push(applicationPath('postSubmission', applicationId)),
    ),
  );

export default [completeResultSuccessEpic, completeResultEpic];
