import styled from 'styled-components';

export const ChoiceBox = styled.div<any>`
  border-width: 1px;
  border-style: solid;
  border-color: ${props => (props.active ? '#017eff' : '#979797')}
  width: 160px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
`;

export const ChoiceImageWrapper = styled.div<any>`
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ChoiceImage = styled.img<any>`
  padding-top: 10px;
  width: 75px;
  height: 75px;
`;

export const ChoiceLabel = styled.div<any>`
  margin: 10px;
  text-align: center;
`;
export const ChoiceField = styled.div<any>``;
