import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Applicant.PostSubmission.LoanDetails.title',
    description:
      'Finalize Payment Plan Label for the Finalize Payment Plan Section of Loan Details in Application Portal',
    defaultMessage: 'Payment Plan Details',
  },
  loanDetailsHeaderdescription: {
    id: 'Applicant.PostSubmission.LoanDetails.loanDetailsHeaderDescription',
    description:
      'Finalize Payment Plan Subtitle description for the Finalize Payment Plan Section of Loan Details in Application Portal',
  },
  provideEstimateAndConfirm: {
    id: 'Applicant.PostSubmission.LoanDetails.provideEstimateAndConfirm',
    description:
      'Finalize Payment Plan Subtitle description for the Finalize Payment Plan Section of Loan Details when application has invoice estimate',
    defaultMessage:
      'Provide the estimate amount and acknowledge acceptance of the estimate.',
  },
  congratulations: {
    id: 'Applicant.PostSubmission.LoanDetails.congratulations',
    description: 'congratulations message on top of loan details page',
    defaultMessage:
      'Congratulations, you have been approved up to {approvedAmount}.',
  },
});
