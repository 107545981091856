import { LocaleContext } from '@frontline/web-common';
import React, { FunctionComponent, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as postSubmissionReviewStore from '../../../../../../store/post-submission.store';
import { PostSubmissionContentCard } from '../../../../components/post-submission-content-card';
import * as store from '../../store/assetInformation.store';
import { AssetInformationView } from '../asset-information-view/asset-information-view.component';

const AssetInformation: FunctionComponent = () => {
  const dispatch = useDispatch();

  const assetInformationReport = useSelector(store.getAssetInformationReport);
  const validations = useSelector(store.getValidations);
  const { locale } = useContext(LocaleContext);

  useEffect(() => {
    store.resetAssetInformationReport();
  }, [locale]);

  useEffect(() => {
    if (!assetInformationReport) {
      store.fetchAssetInformationReport(locale);
    }
  }, [assetInformationReport, locale]);

  useEffect(() => {
    if (!validations) {
      store.fetchValidations();
    }
  }, [validations]);

  return (
    <PostSubmissionContentCard>
      <AssetInformationView
        assets={assetInformationReport?.assets || []}
        validations={validations}
        assetSubTypeName={assetInformationReport?.assetSubTypeDescription || ''}
        onNextStep={postSubmissionReviewStore.navigateToNextStep(dispatch)}
        onPreviousStep={postSubmissionReviewStore.navigateToPreviousStep(
          dispatch,
        )}
      />
    </PostSubmissionContentCard>
  );
};

export default AssetInformation;
