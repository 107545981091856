import { Language } from '@frontline/common';
import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootState } from '../../../../../../../../../store';
import * as applicationStore from '../../../../../../../store/application.store';
import {
  fetchAssetInformationReport,
  fetchAssetInformationValidations,
} from '../services/assetInformation.service';
import * as actions from './assetInformation.actions';

export const fetchAssetInformationReportEpic: Epic<
  Action,
  Action,
  RootState
> = (action$: Observable<Action>, state$: any) =>
  action$.pipe(
    filter(isActionOf(actions.fetchAssetInformationReport)),
    map((action): [string, Language] => [
      applicationStore.getApplicationId(state$.value) as string,
      action.payload,
    ]),
    mergeMap(([applicationId, language]) =>
      from(fetchAssetInformationReport(applicationId, language)).pipe(
        map(assetInformationReport =>
          actions.fetchAssetInformationReportSuccess(assetInformationReport),
        ),
        catchError(error =>
          of(actions.fetchAssetInformationReportFailure(error)),
        ),
      ),
    ),
  );

export const fetchValidationsEpic: Epic<Action, Action, RootState> = (
  action$: Observable<Action>,
  state$: any,
) =>
  action$.pipe(
    filter(isActionOf(actions.fetchValidations)),
    map(() => applicationStore.getApplicationId(state$.value)),
    mergeMap(applicationId =>
      from(fetchAssetInformationValidations(applicationId as string)).pipe(
        map(validations => actions.fetchValidationsSuccess(validations)),
        catchError(error => of(actions.fetchValidationsError(error))),
      ),
    ),
  );

export default [fetchAssetInformationReportEpic, fetchValidationsEpic];
