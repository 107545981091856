import { PersonalDetailsForm, WorkflowStepReference } from '@frontline/common';
import { isPersonalDetailsFormModified } from '@frontline/web-common';

export const shouldSavePersonalDetails = (
  stepReference: WorkflowStepReference | null,
  personalDetails?: PersonalDetailsForm,
) => (changes: PersonalDetailsForm) =>
  Boolean(
    !personalDetails ||
      (stepReference &&
        isPersonalDetailsFormModified(changes, personalDetails)),
  );
