import {
  BankDetails,
  DocumentType,
  PaymentDetails,
  StoredDocument,
} from '@frontline/common';

export function initializeVoidChequeFormValues(
  paymentDetails: PaymentDetails,
): BankDetails {
  return {
    institution: paymentDetails.bankDetails.institution || '',
    transit: paymentDetails.bankDetails.transit || '',
    account: paymentDetails.bankDetails.account || '',
  };
}

export function getFileNamesToAttach(
  storedDocuments: StoredDocument[],
): string[] {
  return storedDocuments
    .filter(document => document.documentType === DocumentType.VoidCheque)
    .map(document => document.fileName);
}
