import { faUnlink } from '@fortawesome/free-solid-svg-icons/faUnlink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useIntl } from 'react-intl';
import { defineMessages } from 'react-intl';
import { MainLayout } from '../../../modules/layout/components';
import { NotAuthenticatedLayout } from './NotAuthenticatedLayout';

const messages = defineMessages({
  title: {
    id: 'LinkExpired.title',
    description: 'Link expired title',
    defaultMessage: 'Sorry, this link is no longer valid',
  },
  description: {
    id: 'LinkExpired.description',
    description: 'description to contact merchant',
    defaultMessage:
      'Please contact your merchant for more details, or to receive a new link.',
  },
});

const NotAuthenticatedDefault = () => {
  const intl = useIntl();
  return (
    <NotAuthenticatedLayout
      icon={<FontAwesomeIcon icon={faUnlink} />}
      primaryMessage={intl.formatMessage(messages.title)}
      secondaryMessage={intl.formatMessage(messages.description)}
    />
  );
};

export default MainLayout(NotAuthenticatedDefault);
