import { AssetInformationState } from '../types/asset-information-state';
import { createAssetInformationState } from '../types/asset-information-state.functions';
import {
  AssetInformationActions,
  AssetInformationActionType,
} from './assetInformation.actions';

export const assetInformationReducer = (
  state: AssetInformationState = createAssetInformationState(),
  action: AssetInformationActionType,
): AssetInformationState => {
  switch (action.type) {
    case AssetInformationActions.FetchValidationsSuccess:
      return {
        ...state,
        validations: action.payload,
      };
    case AssetInformationActions.ResetAssetInformationReport:
      return {
        ...state,
        assetInformationReport: null,
      };
    case AssetInformationActions.FetchAssetInformationReportSuccess:
      return {
        ...state,
        assetInformationReport: action.payload,
      };
    default:
      return state;
  }
};
