import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'GenericLinkDisabled.title',
    defaultMessage: 'Sorry, this link is no longer valid.',
  },
  subtitle: {
    id: 'GenericLinkDisabled.subtitle',
    defaultMessage:
      'Please contact your merchant for more details, or to receive a new link.',
  },
});
