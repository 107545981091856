import { HttpService } from '@frontline/web-common';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export function submitSalesRepId(
  salesRepId: string,
  applicationId: string,
): Observable<void> {
  const endPoint = `/applications/${applicationId}/retailerSalesRepId/${salesRepId}`;
  return from(HttpService.getHttpInstanceDeprecated().patch(endPoint)).pipe(
    map(response => response.data),
  );
}
