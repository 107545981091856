import { RelationshipType } from '@frontline/common';
import { Selector } from 'react-redux';
import { Dispatch } from 'redux';
import { createSelector } from 'reselect';
import { RootState } from '../../../../../../../../../store';
import * as rootApplicationActions from '../../../../../../../../../store/application/actions';
import * as rootApplicationSelectors from '../../../../../../../../../store/application/selectors';
import * as coApplicantDetailsStore from '../../../../../common/CoApplicant/store/coApplicantDetails.store';
import { CoApplicantDetailsState } from '../../../types/CoApplicantDetailsState';
import { CoApplicantPersonalDetailsState } from '../types/CoApplicantPersonalDetailsState';
import * as actions from './coApplicantPersonalDetails.actions';

export const getCoApplicantPersonalDetailsState: Selector<
  RootState,
  CoApplicantPersonalDetailsState | undefined
> = createSelector(
  coApplicantDetailsStore.getCoApplicantDetailsState,
  (coApplicantDetailsState?: CoApplicantDetailsState) =>
    coApplicantDetailsState?.coApplicantPersonalDetailsState,
);

export const getRelationshipTypes: Selector<
  RootState,
  RelationshipType[]
> = createSelector(
  getCoApplicantPersonalDetailsState,
  (state?: CoApplicantPersonalDetailsState) => state?.relationshipTypes || [],
);

export const getCoApplicantFastFillAttempts = (state: RootState) =>
  rootApplicationSelectors.getCoApplicantFastFillAttempts(state.application);

export const fetchRelationshipTypes = (dispatch: Dispatch<any>) => (): void => {
  dispatch(actions.fetchRelationshipTypes());
};

export const saveCoApplicantDetailsFastFill = (dispatch: Dispatch<any>) => (
  form: FormData,
): void => {
  dispatch(rootApplicationActions.saveApplicantDetailsFastFill(form));
};
