import { OverviewState } from './OverviewState';

export function createOverviewState(): OverviewState {
  return {
    isSummaryDetailsOpen: false,
    overviewPage: 'overview',
    loading: false,
    isCongratulationsDialogOpen: false,
  };
}
