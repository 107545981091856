import { HttpError } from '@frontline/web-common';
import { ActionType, createAction } from 'typesafe-actions';

enum RepairTechActions {
  SaveReportingFields = 'RepairTech/SaveReportingFields',
  SaveReportingFieldsSuccess = 'RepairTech/SaveReportingFieldsSuccess',
  SaveReportingFieldsFailure = 'RepairTech/SaveReportingFieldsFailure',
}

const saveReportingFields = createAction(
  RepairTechActions.SaveReportingFields,
  action => (applicationId: string) => action({ applicationId }),
);

const saveReportingFieldsSuccess = createAction(
  RepairTechActions.SaveReportingFieldsSuccess,
  action => (applicationId: string) => action({ applicationId }),
);

const saveReportingFieldsFailure = createAction(
  RepairTechActions.SaveReportingFieldsFailure,
  action => (applicationId: string, error: HttpError) =>
    action({ applicationId, error }),
);

export const RepairTechActionCreators = {
  saveReportingFields,
  saveReportingFieldsSuccess,
  saveReportingFieldsFailure,
};

export type RepairTechAction = ActionType<typeof RepairTechActionCreators>;
