import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  instruction: {
    id: 'PaymentDetails.instruction',
    description: 'Payment details step instruction',
    defaultMessage: 'Select the method for paying your loan repayments',
  },
  debit: {
    id: 'PaymentDetails.debit',
    description: 'Payment details choice: debit',
    defaultMessage: 'Debit',
  },
  institution: {
    id: 'PaymentDetails.institution',
    description: 'Title for financial institution number field',
    defaultMessage: 'Financial institution number',
  },
  transit: {
    id: 'PaymentDetails.transit',
    description: 'Title for branch transit number field',
    defaultMessage: 'Branch transit number',
  },
  account: {
    id: 'PaymentDetails.account',
    description: 'Title for account number field',
    defaultMessage: 'Account number',
  },
  required: {
    id: 'PaymentDetails.required',
    description:
      'Message to show when user does not enter a required field in thr form',
    defaultMessage: 'Required',
  },
  invalidVoidChequeNumber: {
    id: 'PaymentDetails.invalidVoidChequeNumber',
    description: 'error message when void cheque number has non-numeric values',
    defaultMessage: 'Only numeric values are accepted',
  },
  length: {
    id: 'PaymentDetails.length',
    description: 'error message when number type is too short/long',
    defaultMessage: 'Please enter {length} digit {numberType}',
  },
});
