import * as React from 'react';
import messages from './flinks-step.messages';
import { FlinksStepProps } from './flinks-step.props';

import { OverviewStepMenuPageGeneric } from '../OverviewStepMenuPageGeneric/overview-step-menu-item-generic.component';
import { OverviewStepMenuPage } from '../OverviewStepMenuPageGeneric/overview-step-menu-page.component';

export const FlinksStep = (props: FlinksStepProps) => {
  if (props.step.overviewMessages) {
    return (
      <OverviewStepMenuPage
        title={props.step.overviewMessages?.title || ''}
        description={props.step.overviewMessages?.description || ''}
        routeUrl={props.step.routeUrl || ''}
        editButtonText={props.step.overviewMessages?.editButton || ''}
        status={props.step.status}
        editButtonUat={props.uat}
      />
    );
  }
  return (
    <OverviewStepMenuPageGeneric
      title={messages.title}
      description={messages.description}
      routeUrl={props.step.routeUrl || ''}
      editButtonText={messages.editButton}
      status={props.step.status}
      editButtonUat={props.uat}
    />
  );
};
