import { styled, styledTheme } from '@frontline/ui-library';
import { GridTitle } from '@frontline/web-common';
import { Grid } from '@material-ui/core';
import React from 'react';
import { LoanDetailsSectionHeaderProps } from './loan-details-section-header.props';

const LoanDetailsSectionHeaderContainer = styled.div<any>`
  padding-top: 5px;
  border-bottom: 1px solid ${styledTheme.paleBlue};
`;

export const LoanDetailsSectionHeader = (
  props: LoanDetailsSectionHeaderProps,
) => (
  <LoanDetailsSectionHeaderContainer>
    <Grid item={true} xs={12}>
      <GridTitle>{props.title}</GridTitle>
    </Grid>
  </LoanDetailsSectionHeaderContainer>
);
