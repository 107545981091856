import { AddOn } from '@frontline/common';
import { ThirdPartyAddOnTypeDto } from '@frontline/common';
import { withShowIf } from '@frontline/ui-library';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import React, { FunctionComponent } from 'react';
import { AddOnTableBody } from './AddOnTableBody';
import { AddOnTableHeader } from './AddOnTableHeader';
import { EmptyAddOnsList } from './EmptyAddOnsList';

export interface ThirdPartyAddOnsViewProps {
  addOns: AddOn[];
  thirdPartyAddOnTypes?: ThirdPartyAddOnTypeDto[];
}

export const ThirdPartyAddOnsView: FunctionComponent<ThirdPartyAddOnsViewProps> = props => (
  <Box>
    <AddOnTable showIf={Boolean(props.addOns.length)}>
      <AddOnTableHeader />
      <AddOnTableBody
        addOns={props.addOns}
        thirdPartyAddOnTypes={props.thirdPartyAddOnTypes}
      />
    </AddOnTable>
    <EmptyAddOnsList showIf={!Boolean(props.addOns.length)} />
  </Box>
);

const AddOnTable = withShowIf(Table);
