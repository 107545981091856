import { BankDocumentsView } from '@frontline/web-common';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as applicationStore from '../../../../../../store/application.store';
import * as postSubmissionReviewStore from '../../../../store/post-submission.store';
import { PostSubmissionContentCard } from '../../components/post-submission-content-card';

import * as bankDocumentsStore from './store/bank-documents.store';

export const BankDocumentsFeature = () => {
  const dispatch = useDispatch();
  const applicationId = useSelector(applicationStore.getApplicationId);
  const canDeleteAttachedDocument = useSelector(
    bankDocumentsStore.canDeleteAttachedDocument,
  );
  const documents = useSelector(bankDocumentsStore.getBankDocuments);
  const errorAttachingDocuments = useSelector(
    bankDocumentsStore.errorAttachingDocuments,
  );
  const pendingDocuments = useSelector(
    bankDocumentsStore.getPendingBankDocuments,
  );
  const isConfirmAttachPendingDocumentsDialogOpen = useSelector(
    bankDocumentsStore.isConfirmAttachPendingDocumentsDialogOpen,
  );
  const isSaving = useSelector(bankDocumentsStore.isSaving);

  const onNext = () => {
    if (pendingDocuments?.length) {
      bankDocumentsStore.openConfirmAttachPendingDocumentsDialog(dispatch)();
    } else {
      postSubmissionReviewStore.navigateToNextStep(dispatch)();
    }
  };

  const onConfirmPendingDocumentAttachment = () => {
    if (applicationId) {
      bankDocumentsStore.attachPendingDocuments(dispatch)(
        applicationId,
        pendingDocuments,
      );
    }
  };

  return (
    <PostSubmissionContentCard>
      <BankDocumentsView
        documents={documents}
        onNext={onNext}
        onBack={postSubmissionReviewStore.navigateToPreviousStep(dispatch)}
        isSaving={isSaving}
        onCancelPendingDocumentAttachment={bankDocumentsStore.closeConfirmAttachPendingDocumentsDialog(
          dispatch,
        )}
        onConfirmPendingDocumentAttachment={onConfirmPendingDocumentAttachment}
        isConfirmingPendingDocumentAttachment={
          isConfirmAttachPendingDocumentsDialogOpen
        }
        showPendingDocumentAttachmentError={errorAttachingDocuments}
        canDeleteAttachedDocument={canDeleteAttachedDocument}
        uploadingDocuments={useSelector(
          bankDocumentsStore.getUploadingDocuments,
        )}
      />
    </PostSubmissionContentCard>
  );
};
