import { ApplicantApplicationOptionDto } from '@frontline/common';
import { HttpService } from '@frontline/web-common';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export function fetchOptionByApplicationId(
  applicationId: string,
): Observable<ApplicantApplicationOptionDto> {
  return from(
    HttpService.getHttpInstanceDeprecated().get(
      `/applications/${applicationId}/applicant-options`,
    ),
  ).pipe(map(response => response.data));
}
