import { push } from 'connected-react-router';
import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootState } from '../../../store';
import { Answer } from '../../../types/questions/answer.interface';
import { processAuthentication } from '../../auth/services/auth.service';
import * as authActions from '../../auth/store/auth.actions';
import * as authStore from '../../auth/store/auth.store';
import { createLinkExpiredPath } from '../../navigation/types';
import {
  answerSecurityQuestions,
  fetchSecurityQuestions,
} from '../services/security-questions.service';
import { numberOfAttemptsMadeDoesNotExceedMaximum } from '../types/security-questions.functions';
import * as actions from './security-questions.actions';
import * as store from './security-questions.store';

const loadSecurityQuestionsEpic: Epic<Action, Action, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.loadSecurityQuestions)),
    map(action => action.payload),
    mergeMap(accessKey =>
      fetchSecurityQuestions(accessKey).pipe(
        map(questions => actions.loadSecurityQuestionsSuccess(questions)),
        catchError(error => of(actions.loadSecurityQuestionsError(error))),
      ),
    ),
  );

const answerSecurityQuestionsEpic: Epic<Action, Action, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(actions.answerSecurityQuestions)),
    map(action => [
      action.payload.answers,
      action.payload.questionGroupId,
      store.getNumberOfAttemptsMade(state$.value),
      authStore.getAccessKey(state$.value),
    ]),
    mergeMap(([answers, questionGroupId, numberOfAttemptsMade, accessKey]) =>
      answerSecurityQuestions(
        answers as Answer[],
        questionGroupId as string,
        numberOfAttemptsMade as number,
        accessKey as string,
      ).pipe(
        map(result => authActions.setToken(processAuthentication(result.data))),
        catchError(error => of(actions.answerSecurityQuestionsError(error))),
      ),
    ),
  );

const answerSecurityQuestionsErrorEpic: Epic<
  Action,
  Action,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(actions.answerSecurityQuestionsError)),
    map(action => action.payload.numberOfAttemptsMade),
    filter(numberOfAttemptsMadeDoesNotExceedMaximum),
    map(() => push(createLinkExpiredPath())),
  );

export default [
  loadSecurityQuestionsEpic,
  answerSecurityQuestionsEpic,
  answerSecurityQuestionsErrorEpic,
];
