import { ApplicantApplicationOptionsState } from '../types/applicant-application-options-state';
import { createDefaultApplicantApplicationOptionsState } from '../types/applicant-application-options-state.functions';
import {
  ApplicantApplicationOptionsActions,
  ApplicantApplicationOptionsActionType,
} from './applicant-application-options.actions';

export const applicantApplicationOptionsReducer = (
  state: ApplicantApplicationOptionsState = createDefaultApplicantApplicationOptionsState(),
  action: ApplicantApplicationOptionsActionType,
): ApplicantApplicationOptionsState => {
  switch (action.type) {
    case ApplicantApplicationOptionsActions.FetchApplicantApplicationOptionForLoanCalculation:
    case ApplicantApplicationOptionsActions.FetchApplicantApplicationOptionForApplication:
      return {
        ...state,
        isLoading: true,
      };
    case ApplicantApplicationOptionsActions.FetchApplicantApplicationOptionSuccess:
      return {
        ...state,
        options: {
          ...state.options,
          [action.payload.applicationId]: action.payload.option,
        },
        isLoading: false,
      };
    case ApplicantApplicationOptionsActions.FetchApplicantApplicationOptionFailure:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};
