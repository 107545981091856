import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'AddOnsStep.title',
    description: 'Add-Ons Step Title',
    defaultMessage: 'Review add-on products',
  },
  description: {
    id: 'AddOnsStep.description',
    description: 'Add-Ons Step Description',
    defaultMessage: 'Review and select additional products to add to the loan',
  },
  editButton: {
    id: 'AddOnsStep.editButton',
    description: 'Add-Ons Step Edit Button',
    defaultMessage: 'Review add-ons',
  },
});
