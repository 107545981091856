import { OverviewActionType } from '../features/Overview/store/overview.actions';
import { overviewReducer } from '../features/Overview/store/overview.reducer';
import { PostSubmissionReviewActionType } from '../features/PostSubmissionReview/store/post-submission-review.actions';
import { postSubmissionReviewReducer } from '../features/PostSubmissionReview/store/post-submission-review.reducer';
import { createPostSubmissionState, PostSubmissionState } from '../types';
import {
  PostSubmissionActions,
  PostSubmissionActionsType,
} from './post-submission.actions';

export function postSubmissionReducer(
  state: PostSubmissionState = createPostSubmissionState(),
  action: PostSubmissionActionsType,
): PostSubmissionState {
  switch (action.type) {
    case PostSubmissionActions.FetchEstimatedPayment:
      return {
        ...state,
        estimatedFrequency: action.payload.frequencyId,
        isCalculating: true,
      };
    case PostSubmissionActions.FetchEstimatedPaymentSuccess:
      return {
        ...state,
        isCalculating: false,
        estimatedPayment: action.payload,
      };

    case PostSubmissionActions.SetWorkflowSteps:
      return {
        ...state,
        workflowSteps: action.payload,
      };

    case PostSubmissionActions.SetActiveWorkflowStep:
      return {
        ...state,
        activeWorkflowStepId: action.payload,
      };

    default:
      return {
        ...state,
        overviewState: overviewReducer(
          state.overviewState,
          action as OverviewActionType,
        ),
        postSubmissionReviewState: postSubmissionReviewReducer(
          state.postSubmissionReviewState,
          action as PostSubmissionReviewActionType,
        ),
      };
  }
}
