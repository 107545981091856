import { Loader } from '@frontline/ui-library';
import { ApplicationStepsHeader, MoreInfoTooltip } from '@frontline/web-common';
import React, { Fragment, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useAddOnsViewStyles } from './add-ons-view.styles';
import { ThirdPartyAddonsHeaderMessages } from './third-party-add-ons-header.messages';
import { ThirdPartyAddOnsHeaderProps } from './third-party-add-ons-header.props';

export const ThirdPartyAddOnsHeader: FunctionComponent<ThirdPartyAddOnsHeaderProps> = (
  props: ThirdPartyAddOnsHeaderProps,
) => {
  const intl = useIntl();
  const classes = useAddOnsViewStyles();

  return (
    <Fragment>
      <Loader show={props.isLoading} />
      <ApplicationStepsHeader
        title={intl.formatMessage(ThirdPartyAddonsHeaderMessages.title)}
        description={intl.formatMessage(
          ThirdPartyAddonsHeaderMessages.description,
        )}>
        <div className={classes.container}>
          <MoreInfoTooltip
            description={[
              intl.formatMessage(
                ThirdPartyAddonsHeaderMessages.toolTipDescription1,
              ),
              intl.formatMessage(
                ThirdPartyAddonsHeaderMessages.toolTipDescription2,
              ),
            ]}
          />
        </div>
      </ApplicationStepsHeader>
    </Fragment>
  );
};
