import React from 'react';
import { OverViewStepMenuPageProps } from '../overview-step-menu-page.props';
import { OverviewStepMenuPageGeneric } from '../OverviewStepMenuPageGeneric/overview-step-menu-item-generic.component';
import { HomeAndAutoAncillaryProductStepMessages as messages } from './home-and-auto-ancillary-product-step.messages';

export const HomeAndAutoAncillaryProductStep = (
  props: OverViewStepMenuPageProps,
) => (
  <OverviewStepMenuPageGeneric
    routeUrl={props.routeUrl}
    title={messages.title}
    description={messages.description}
    status={props.status}
    editButtonText={messages.editButton}
    editButtonUat={props.uat}
  />
);
