import { defineMessages } from 'react-intl';

export default defineMessages({
  success: {
    id: 'Applicant.VerifyIdentity.successfullyVerified',
    description: 'Message to show when successfully verified',
    defaultMessage: 'Identity has been successfully verified!',
  },
  outOfAttempts: {
    id: 'Applicant.VerifyIdentity.outOfAttempts',
    description: 'Message displayed when user has run out of attempts',
    defaultMessage:
      'Unfortunately, we were unable to verify your identity and you are out of attempts. Please contact your merchant for next steps.',
  },
  pending: {
    id: 'Applicant.VerifyIdentity.pending',
    description: 'Message to display while validation is pending',
    defaultMessage:
      'Your identity is being verified. This may take a few minutes, so you can continue completing steps.',
  },
  disabled: {
    id: 'Applicant.VerifyIdentity.disabled',
    description: 'Message when application is under review',
    defaultMessage:
      'You cannot verify your identity while your application is under review.',
  },
  failedAttempt: {
    id: 'Applicant.VerifyIdentity.failedAttempt',
    description: 'Message to display when a failed verficiation attempt occurs',
    defaultMessage:
      'Your personal details do not match the ID provided. Please ensure your first name, last name and DOB on the application match your ID. If there is a correction required, please contact your merchant to update the application and retry if required. You have up to 2 attempts to verify.',
  },
  description: {
    id: 'Applicant.VerifyIdentity.description',
    description: 'Verify Identity Description to be displayed on step header',
    defaultMessage:
      'Please scan your government issued photo ID and take a selfie to verify your identity. You can use your driver’s license, passport or identity card. If using desktop, you can send a link to your mobile device after you start and select an ID type.',
  },
  title: {
    id: 'Applicant.VerifyIdentity.title',
    description: 'Verify Identity Title to be displayed on step header',
    defaultMessage: 'Verify Your Identity',
  },
});
