import { defineMessages } from 'react-intl';

export const AssetsAndAddOnViewMessages = defineMessages({
  assetsAccordionTitle: {
    id:
      'Applicant.PostSubmission.AssetsAndAddOns.AssetsAndAddOnsView.assetsAccordionTitle',
  },
  addOnsAccordionTitle: {
    id:
      'Applicant.PostSubmission.AssetsAndAddOns.AssetsAndAddOnsView.addOnsAccordionTitle',
  },
  title: {
    id: 'Applicant.PostSubmission.AssetsAndAddOns.title',
  },
  description: {
    id: 'Applicant.PostSubmission.AssetsAndAddOns.description',
  },
  toolTipDescription1: {
    id:
      'Applicant.PostSubmission.AssetsAndAddOns.AssetsAndAddOnsView.toolTip.description1',
  },
  toolTipDescription2: {
    id:
      'Applicant.PostSubmission.AssetsAndAddOns.AssetsAndAddOnsView.toolTip.description2',
  },
  toolTipDescription3: {
    id:
      'Applicant.PostSubmission.AssetsAndAddOns.AssetsAndAddOnsView.toolTip.description3',
  },
  totalAddOns: {
    id:
      'Applicant.PostSubmission.AssetsAndAddOns.AssetsAndAddOnsView.totalAddOns',
  },
  totalAddOnsPreTax: {
    id:
      'Applicant.PostSubmission.AssetsAndAddOns.AssetsAndAddOnsView.totalAddOnsPreTax',
  },
  totalAddOnsPostTax: {
    id:
      'Applicant.PostSubmission.AssetsAndAddOns.AssetsAndAddOnsView.totalAddOnsPostTax',
  },
  totalAssetsAndAddOns: {
    id:
      'Applicant.PostSubmission.AssetsAndAddOns.AssetsAndAddOnsView.totalAssetsAndAddOns',
  },
  waitingForMerchant: {
    id:
      'Applicant.PostSubmission.AssetsAndAddOns.AssetsAndAddOnsView.waitingForMerchant',
  },
});
