import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootState } from '../../../store';
import {
  fetchApplicationTakeoverDetails,
  submitApplicationTakeoverResponse,
} from '../services/application-takeover.service';
import { ApplicationTakeoverActions } from './application-takeover.actions';

const fetchApplicationTakeoverDetailsEpic: Epic<
  Action,
  Action,
  RootState
> = action$ =>
  action$.pipe(
    filter(
      isActionOf(ApplicationTakeoverActions.fetchApplicationTakeoverDetails),
    ),
    map(action => action.payload),
    mergeMap(takeoverCode =>
      fetchApplicationTakeoverDetails(takeoverCode).pipe(
        map(applicationTakeoverDetails =>
          ApplicationTakeoverActions.fetchApplicationTakeoverDetailsSuccess(
            applicationTakeoverDetails,
          ),
        ),
        catchError(error =>
          of(
            ApplicationTakeoverActions.fetchApplicationTakeoverDetailsError(
              error,
            ),
          ),
        ),
      ),
    ),
  );

const answerApplicationTakeoverRequestEpic: Epic<Action, Action, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(
      isActionOf(ApplicationTakeoverActions.answerApplicationTakeoverRequest),
    ),
    map(action => [
      action.payload.acceptTakeover,
      action.payload.appTakeoverCode,
    ]),
    mergeMap(([acceptTakeover, appTakeoverCode]) =>
      submitApplicationTakeoverResponse(
        Boolean(acceptTakeover),
        appTakeoverCode as string,
      ).pipe(
        map(result =>
          ApplicationTakeoverActions.answerApplicationTakeoverRequestSuccess(
            result.takeoverStatus,
          ),
        ),
        catchError(error =>
          of(
            ApplicationTakeoverActions.answerApplicationTakeoverRequestError(
              error,
            ),
          ),
        ),
      ),
    ),
  );

export default [
  fetchApplicationTakeoverDetailsEpic,
  answerApplicationTakeoverRequestEpic,
];
