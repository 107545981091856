import { Colours, StylesProps } from '@frontline/ui-library';
import { ButtonBase, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const useChecklistButtonStyles = makeStyles({
  root: {
    border: `1px solid ${Colours.PaleBlue}`,
    backgroundColor: (props: StylesProps) =>
      props.active ? Colours.White : Colours.PrimaryColorInverted,
    borderRight: (props: StylesProps) =>
      props.active ? 'none' : `1px solid ${Colours.PaleBlue}`,
    justifyContent: 'flex-start',
    flexWrap: (props: StylesProps) => (props.mobile ? 'wrap' : 'nowrap'),
  },
});

export const StyledButton = styled(ButtonBase)`
  && {
    width: 100%;
    border-bottom: none
  &:last-child {
      border-bottom: 1px solid ${Colours.PaleBlue};
    }
    display: flex;
    padding: 1em;
  }
`;
