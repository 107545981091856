import { library } from '@fortawesome/fontawesome-svg-core';
import { faList as falList } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Colours,
  TypographyColor,
  TypographyVariant,
  withShowIf,
} from '@frontline/ui-library';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useIntl } from 'react-intl';
import { AddOnEmptyListMessages as messages } from './addons-empty-list.messages';

library.add(falList);

export const EmptyAddOnsList = withShowIf(() => {
  const intl = useIntl();
  return (
    <Box margin={4} color={Colours.OffWhite}>
      <Typography
        component="div"
        align="center"
        color={TypographyColor.Inherit}>
        <FontAwesomeIcon icon={['fal', 'list']} size="7x" />
      </Typography>
      <Typography
        variant={TypographyVariant.Header4}
        align="center"
        color={TypographyColor.Inherit}>
        {intl.formatMessage(messages.noAddOnMessage)}
      </Typography>
    </Box>
  );
});
