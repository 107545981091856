import { Colours } from '@frontline/ui-library';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useApplicationTakeoverExpiredStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      [theme.breakpoints.down('xs')]: {
        background: Colours.White,
      },
    },
    expiredContainer: {
      display: 'flex',
      flexDirection: 'column',
      background: Colours.White,
    },
    icon: {
      margin: '360px 0 40px 0',
      height: 100,
      [theme.breakpoints.down('xs')]: {
        margin: '70px 0 35px 0',
        height: 42,
      },
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
      marginBottom: 153,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 98,
        fontSize: 20,
        fontWeight: 700,
      },
    },
  }),
);
