import * as React from 'react';
import messages from './bank-documents-step.messages';

import { OverViewStepMenuPageProps } from '../overview-step-menu-page.props';
import { OverviewStepMenuPageGeneric } from '../OverviewStepMenuPageGeneric/overview-step-menu-item-generic.component';

export const BankDocumentsStep = (props: OverViewStepMenuPageProps) => (
  <OverviewStepMenuPageGeneric
    title={messages.title}
    description={messages.description}
    routeUrl={props.routeUrl || ''}
    editButtonText={messages.editButton}
    status={props.status}
    editButtonUat={props.uat}
  />
);
