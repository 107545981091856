import { ShowIfFragment, TypographyVariant } from '@frontline/ui-library';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useIntl } from 'react-intl';
import { AddOnTableHeaderMessages as messages } from './addons-table-header.messages';
import { AddOnTableHeaderProps } from './addons-table-header.props';

export const AddOnTableHeader = (props: AddOnTableHeaderProps) => {
  const intl = useIntl();
  return (
    <TableHead>
      <TableRow>
        <TableCell />
        <TableCell>
          <Typography variant={TypographyVariant.Header5}>
            {intl.formatMessage(messages.type)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant={TypographyVariant.Header5}>
            {intl.formatMessage(messages.provider)}
          </Typography>
        </TableCell>
        <ShowIfFragment showIf={props.taxEnabled}>
          <TableCell>
            <Typography variant={TypographyVariant.Header5}>
              {intl.formatMessage(messages.preTaxcost)}
            </Typography>
          </TableCell>
        </ShowIfFragment>
        <ShowIfFragment showIf={props.taxEnabled}>
          <TableCell>
            <Typography variant={TypographyVariant.Header5}>
              {intl.formatMessage(messages.taxRate)}
            </Typography>
          </TableCell>
        </ShowIfFragment>
        <TableCell>
          <Typography variant={TypographyVariant.Header5}>
            {props.taxEnabled
              ? intl.formatMessage(messages.postTaxcost)
              : intl.formatMessage(messages.cost)}
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
