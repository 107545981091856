import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Applicant.PostSubmission.Overview.InvoiceDetailsStep.title',
    defaultMessage: 'Invoice Details',
  },
  description: {
    id: 'Applicant.PostSubmission.Overview.InvoiceDetailsStep.description',
    defaultMessage: 'Review and finalize the invoice details',
  },
  editButton: {
    id: 'Applicant.PostSubmission.Overview.InvoiceDetailsStep.editButton',
    defaultMessage: 'Review invoice details',
  },
});
