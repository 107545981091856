import { WorkflowStepType } from '@frontline/common';
import { stepRouteParams } from '../../../../../modules/navigation/types';
import { postSubmissionPath } from './PostSubmissionPathType';

export const createPostSubmissionReviewPath = (
  type: WorkflowStepType,
  applicationId: string,
): string => {
  switch (type) {
    case WorkflowStepType.PersonalDetails:
    case WorkflowStepType.IncomeDetails:
    case WorkflowStepType.AdditionalDetails:
      return makeReviewPath(applicationId, type, WorkflowStepType.Applicant);
    case WorkflowStepType.CoApplicantPersonalDetails:
    case WorkflowStepType.CoApplicantIncomeDetails:
    case WorkflowStepType.CoApplicantAdditionalDetails:
      return makeReviewPath(applicationId, type, WorkflowStepType.CoApplicant);
    default:
      return makeReviewPath(applicationId, type);
  }
};
const makeReviewPath = (
  applicationId: string,
  workFlowType: WorkflowStepType,
  applicantType?: WorkflowStepType,
): string => {
  const reviewPath = postSubmissionPath('review', applicationId);
  return applicantType
    ? `${reviewPath}${stepRouteParams[applicantType]}${stepRouteParams[workFlowType]}`
    : `${reviewPath}${stepRouteParams[workFlowType]}`;
};
