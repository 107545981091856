import { Colours } from '@frontline/ui-library';
import { makeStyles, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const useContactMerchantStyles = makeStyles({
  largeFont: {
    fontSize: '1.2rem',
  },
  bold: {
    fontWeight: 'bold',
  },
});

export const ContactMerchantDeclined = styled.div<any>`
  text-align: center;
  max-width: 60%;
  margin: 75px auto;
`;

export const ContactMerchantParagraph = styled(Typography)`
  color: ${Colours.Marine};
  margin: 8px;
`;

export const ContactMerchantMerchantDetails = styled.div<any>`
  margin-top: 16px;
`;
