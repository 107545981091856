import {
  IncomeDetails,
  PreSubmissionFormType,
  WorkflowStepReference,
} from '@frontline/common';
import isEqual from 'lodash/isEqual';

export const isIncomeDetailsFormModified = (
  values1: IncomeDetails,
  values2: IncomeDetails,
): boolean => !isEqual(values1, values2);

export const shouldSaveIncomeDetails = (
  incomeDetails: IncomeDetails,
  stepReference: WorkflowStepReference | null,
) => (changes: PreSubmissionFormType) =>
  Boolean(
    !incomeDetails ||
      (stepReference &&
        isIncomeDetailsFormModified(changes as IncomeDetails, incomeDetails)),
  );
