import { PersonalDetailsSchemaValues } from '@frontline/common';
import { DropDownField, InputField, useUatId } from '@frontline/ui-library';
import { DateOfBirthFields } from '@frontline/web-common';
import { Grid } from '@material-ui/core';
import { Field, FormikErrors } from 'formik';
import React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { PersonalDetailsFormMessages as messages } from '../personal-details-form/personal-details-form.messages';
import { PersonalDetailsBasicProfileSectionProps } from './personal-details-basic-profile-section.props';
import { PersonalDetailsBasicProfileSectionUat } from './personal-details-basic-profile-section.uat';

export const PersonalDetailsBasicProfileSection = (
  props: PersonalDetailsBasicProfileSectionProps,
) => {
  const intl = useIntl();
  const uatId = useUatId();

  const relationshipTypes = props.relationshipTypes;

  const message = (
    messageId: MessageDescriptor,
    values?: { [key: string]: any },
  ) => {
    return intl.formatMessage(messageId, values);
  };

  type Messages = typeof messages;

  const errorMessage = (
    error: FormikErrors<keyof Messages>,
    values?: { [key: string]: any },
  ) => {
    const msg = messages[error];
    return msg ? message(msg, values) : '';
  };

  return (
    <>
      <Grid container={true} spacing={1}>
        {props.isCoApplicant && (
          <>
            <Grid container={true} item={true} xs={12} md={6}>
              <Field
                name="relationshipTypeId"
                label={message(messages.relationshipTypeLabel)}
                placeholder={message(messages.relationshipTypePlaceholder)}
                component={DropDownField}
                options={relationshipTypes}
                optionValue="id"
                optionDisplay="name"
                errorMessage={errorMessage}
                disabled={props.disabled}
              />
            </Grid>
            <Grid item={true} xs={12} md={6} />
          </>
        )}

        <Grid container={true} item={true} xs={12} md={6}>
          <Field
            name="firstName"
            label={message(messages.firstNameLabel)}
            placeholder={message(messages.firstNamePlaceholder)}
            helperText={message(messages.proofOfIdHelperText)}
            component={InputField}
            errorMessage={(error: any) =>
              errorMessage(error, {
                max: PersonalDetailsSchemaValues.maxNameLength,
              })
            }
            disabled={props.disabled}
          />
        </Grid>
        <Grid container={true} item={true} xs={12} md={6}>
          <Field
            name="lastName"
            label={message(messages.lastNameLabel)}
            placeholder={message(messages.lastNamePlaceholder)}
            helperText={message(messages.proofOfIdHelperText)}
            component={InputField}
            errorMessage={(error: any) =>
              errorMessage(error, {
                max: PersonalDetailsSchemaValues.maxNameLength,
              })
            }
            disabled={props.disabled}
          />
        </Grid>
        <Field
          name="dob"
          component={DateOfBirthFields}
          disabled={props.disabled ?? false}
          uatDay={{
            select: uatId(
              PersonalDetailsBasicProfileSectionUat.personalDetailsDobDay,
            ),
            menuItem: uatId(
              PersonalDetailsBasicProfileSectionUat.personalDetailsDobDayMenuItem,
            ),
          }}
          uatMonth={{
            select: uatId(
              PersonalDetailsBasicProfileSectionUat.personalDetailsDobMonth,
            ),
            menuItem: uatId(
              PersonalDetailsBasicProfileSectionUat.personalDetailsDobMonthMenuItem,
            ),
          }}
          uatYear={{
            select: uatId(
              PersonalDetailsBasicProfileSectionUat.personalDetailsDobYear,
            ),
            menuItem: uatId(
              PersonalDetailsBasicProfileSectionUat.personalDetailsDobYearMenuItem,
            ),
          }}
        />
      </Grid>
    </>
  );
};
