import { PaperCard } from '@frontline/ui-library';
import Box from '@material-ui/core/Box';
import React from 'react';
import { PostSubmissionContentProps } from './post-submission-content-card.props';

export const PostSubmissionContentCard = ({
  children,
}: PostSubmissionContentProps) => {
  return (
    <PaperCard>
      <Box padding={3}>{children}</Box>
    </PaperCard>
  );
};
