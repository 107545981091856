import {
  Separator,
  TypographyColor,
  TypographyVariant,
  withShowIf,
} from '@frontline/ui-library';
import Typography from '@material-ui/core/Typography';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './applicant-will-be-contacted-message.messages';

export const ApplicantWillBeContactedMessage = withShowIf(() => (
  <Fragment>
    <Separator />
    <Typography
      variant={TypographyVariant.Header5}
      color={TypographyColor.Secondary}
      align="center">
      <FormattedMessage {...messages.applicantWillBeContacted} />
    </Typography>
  </Fragment>
));
