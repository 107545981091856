import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Applicant.VerifyIdentityStep.title',
    description: 'Verify Identity Step Title',
    defaultMessage: 'Verify Your Identity',
  },
  description: {
    id: 'Applicant.VerifyIdentityStep.description',
    description: 'Verify Identity Step Description',
    defaultMessage:
      'Automatically verify your identity by scanning your government issued photo ID and taking a selfie.',
  },
  editButton: {
    id: 'Applicant.VerifyIdentityStep.editButton',
    description: 'Verify Identity Link',
    defaultMessage: 'Verify Your ID',
  },
  success: {
    id: 'Applicant.VerifyIdentityStep.successfullyVerified',
    description: 'Message to show when successfully verified',
    defaultMessage:
      'You have completed ID verification. If there are any discrepancies between the application and the ID provided, LendCare will review at funding.',
  },
  outOfAttempts: {
    id: 'Applicant.VerifyIdentityStep.outOfAttemps',
    description: 'Message displayed when user has run out of attempts',
    defaultMessage:
      'Unfortunately, we were unable to verify your identity and you are out of attempts. Please contact your merchant for next steps.',
  },
  review: {
    id: 'Applicant.VerifyIdentityStep.review',
    description: 'Message displayed when application status is under review',
    defaultMessage:
      'You cannot verify your identity while your application is under review.',
  },
  error: {
    id: 'Applicant.VerifyIdentityStep.error',
    description: 'Fallback message in case of an error',
    defaultMessage:
      "This applicant's personal details must be complete before identity verification!",
  },
  failedAttempts: {
    id: 'Applicant.VerifyIdentityStep.failedAttempts',
    description: 'Message displayed when verification has been attempted.',
    defaultMessage:
      'Your personal details do not match the ID provided. Please ensure your first name, last name and DOB on the application match your ID. If there is a correction required, please contact your merchant to update the application and retry if required. You have up to 2 attempts to verify.',
  },
});
