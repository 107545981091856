import { IncomeDetails } from '@frontline/common';
import { RootState } from '../../../../../../../../../store';
import * as rootApplicationSelectors from '../../../../../../../../../store/application/selectors';

export const getCoApplicantIncomeDetails = (
  state: RootState,
): IncomeDetails | undefined =>
  rootApplicationSelectors.getCoApplicantIncomeDetails(state.application);

export const getCoApplicantAuthorizedElectronicCommunication = (
  state: RootState,
): boolean =>
  Boolean(
    rootApplicationSelectors.getCoApplicant(state.application)
      ?.authorizedElectronicCommunication,
  );
