import { IncomeDetails } from '@frontline/common';
import * as React from 'react';
import { createIncomeDetailsDefaultValues } from '../../../../../../modules/income-details/types/income-details-form.functions';
import { getDisplayStepPosition } from '../../../../types/StepPosition.functions';
import { shouldSaveIncomeDetails } from '../../types/IncomeDetails';
import { IncomeDetailsView } from '../IncomeDetailsView/IncomeDetailsView';
import { IncomeDetailsGenericProps } from './IncomeDetailsGenericProps';

export const IncomeDetailsGeneric = (props: IncomeDetailsGenericProps) => {
  const incomeDetails =
    props.incomeDetails || createIncomeDetailsDefaultValues();
  return (
    <IncomeDetailsView
      incomeDetails={incomeDetails}
      authorizedElectronicCommunication={
        props.authorizedElectronicCommunication
      }
      title={props.title}
      stepPosition={getDisplayStepPosition(props.stepPosition)}
      onSubmit={(changes: IncomeDetails) => {
        props.onSubmit(
          changes,
          shouldSaveIncomeDetails(incomeDetails, props.stepReference),
        );
      }}
      onBackButtonClick={() => {
        if (props.onBack) {
          props.onBack();
        }
      }}
    />
  );
};
