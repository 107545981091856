import { createStyles, makeStyles } from '@material-ui/core';

export const useOverviewStepMenuPageStyles = makeStyles(
  createStyles({
    step: {
      padding: '40px',
      width: '100%',
    },
  }),
);
