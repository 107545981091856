import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ApplicationRoutesProps } from './application-routes.props';
import { PostSubmissionFeature } from './features/postSubmission/PostSubmissionFeature';
import { PreSubmissionFeature } from './features/preSubmission/pre-submission.feature';
import * as actions from './store/application.actions';
import { applicationPath, defaultApplicationPath } from './types';

export const ApplicationRoutes = (props: ApplicationRoutesProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.initApplication());
  }, [dispatch]);

  if (!props.application) {
    return null;
  }

  return (
    <Switch>
      <Route
        path={applicationPath('preSubmission', props.application.id)}
        component={PreSubmissionFeature}
      />
      <Route
        path={applicationPath('postSubmission', props.application.id)}
        component={PostSubmissionFeature}
      />
      <Redirect to={defaultApplicationPath(props.application)} />
    </Switch>
  );
};
