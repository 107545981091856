import { withShowIf } from '@frontline/ui-library';
import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LoanDetailsPayDayFrequencyCard } from '../loan-details-form-pay-day-frequency-card/loan-details-form-pay-day-frequency-card.component';
import messages from './loan-details-form-pay-day-frequency.messages';
import { LoanDetailsFormPayDayFrequencyProps } from './loan-details-form-pay-day-frequency.props';
import { Label } from './loan-details-form-pay-day-frequency.styles';

export const LoanDetailsFormPayDayFrequency = withShowIf(
  (props: LoanDetailsFormPayDayFrequencyProps) => {
    return (
      <Grid
        container={true}
        direction="row"
        justify="space-between"
        alignItems="center">
        <Grid item={true} xs={12}>
          <Label>
            <FormattedMessage {...messages.payDayFrequency} />
          </Label>
        </Grid>
        <Grid item={true} xs={12}>
          <Field
            name="payDayFrequencyId"
            component={LoanDetailsPayDayFrequencyCard}
            errorMessage={props.errorMessage}
            disabled={props.disabled}
          />
        </Grid>
      </Grid>
    );
  },
);
