import { withShowIf } from '@frontline/ui-library';
import { Box, Table } from '@material-ui/core';
import React from 'react';
import { EmptyAddOnsList } from '../addons-empty-list/addons-empty-list.component';
import { AddOnTableBody } from '../addons-table-body/addons-table-body.component';
import { AddOnTableHeader } from '../addons-table-header/addons-table-header.component';
import { AddonsViewProps } from './addons-view-desktop.props';

export const AddonsViewDesktop = (props: AddonsViewProps) => {
  return (
    <Box>
      <AddOnTable showIf={Boolean(props.addOns.length)}>
        <AddOnTableHeader taxEnabled={props.taxEnabled} />
        <AddOnTableBody
          taxEnabled={props.taxEnabled}
          addOns={props.addOns}
          addOnTypes={props.addOnTypes}
        />
      </AddOnTable>
      <EmptyAddOnsList showIf={!Boolean(props.addOns.length)} />
    </Box>
  );
};

const AddOnTable = withShowIf(Table);
