import { FraudDetectionDataPoints } from '@frontline/web-common';
import { ApplicantFraudDetectionDataPoints } from './applicant-fraud-detection-data-points';

const createAplicantFraudDetectionDataPoints = (
  dataPoints: FraudDetectionDataPoints,
  accessKey: string,
): ApplicantFraudDetectionDataPoints => {
  return {
    dataPoints,
    accessKey,
  };
};

export const ApplicantFraudDetectionDataPointsBuilder = {
  createAplicantFraudDetectionDataPoints,
};
