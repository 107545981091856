import { SecondaryButton, useUatId } from '@frontline/ui-library';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { OverviewStepMenuPageGeneric } from '../OverviewStepMenuPageGeneric/overview-step-menu-item-generic.component';
import messages from './applicant-step.messages';
import { ApplicantStepProps } from './applicant-step.props';
import { ApplicantStepUat } from './applicant-step.uat';

export const ApplicantStep = (props: ApplicantStepProps) => {
  const uatId = useUatId();

  return (
    <OverviewStepMenuPageGeneric
      routeUrl={props.routeUrl || ''}
      title={messages.title}
      status={props.status}
      description={messages.description}
      editButtonText={messages.editButton}
      editButtonUat={uatId(ApplicantStepUat.editButton)}>
      {props.showAddCoApplicant && props.onClickAddCoApplicant && (
        <SecondaryButton
          onClick={props.onClickAddCoApplicant}
          {...uatId(ApplicantStepUat.addCoApplicantButton)}>
          <FormattedMessage {...messages.addCoApplicantButton} />
        </SecondaryButton>
      )}
    </OverviewStepMenuPageGeneric>
  );
};
