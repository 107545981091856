import { AuthState } from '../types/AuthState/AuthState';
import { createAuthState } from '../types/AuthState/AuthState.functions';
import { AuthActions, AuthActionsType } from './auth.actions';

export const authReducer = (
  state: AuthState = createAuthState(),
  action: AuthActionsType,
): AuthState => {
  switch (action.type) {
    case AuthActions.Init:
    case AuthActions.ValidateError:
      return {
        ...createAuthState(),
        isInitialized: true,
      };

    case AuthActions.Invalidate:
      return {
        ...state,
        isInvalid: true,
      };

    case AuthActions.Login:
      return {
        ...state,
        isLoading: true,
        session: {
          ...state.session,
          accessKey: action.payload.query.accessKey,
          applicationId: action.payload.query.applicationId,
          merchantId: action.payload.query.merchantId,
        },
        redirectUrl: action.payload.redirectUrl,
      };

    case AuthActions.LoginSuccess:
      return {
        ...state,
        isLoading: false,
      };

    case AuthActions.LoginError:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case AuthActions.SetToken:
      return {
        ...state,
        isLoading: false,
        session: {
          ...state.session,
          ...action.payload,
        },
      };
    case AuthActions.ValidateSuccess:
      return {
        ...state,
        isLoading: false,
        session: {
          ...state.session,
          ...action.payload.result,
          isAuthenticated: true,
        },
      };
    case AuthActions.SetApplicationId:
      return {
        ...state,
        session: {
          ...state.session,
          applicationId: action.payload,
        },
      };

    default:
      return state;
  }
};
