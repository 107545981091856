import { Dispatch } from 'react';
import { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import { RootState } from '../../../../../../../store';
import * as rootApplicationSelectors from '../../../../../../../store/application/selectors';
import * as preSubmissionStore from '../../../store/pre-submission.store';
import { PreSubmissionState } from '../../../types/PreSubmissionState/pre-submission.state';
import { ReviewSummaryState } from '../types/ReviewSummaryState';
import * as actions from './reviewSummary.actions';

export const shouldDisplayInterestRate = (state: RootState) =>
  rootApplicationSelectors.shouldDisplayInterestRate(state.application);

export const getReviewSummaryState: Selector<
  RootState,
  ReviewSummaryState | undefined
> = createSelector(
  preSubmissionStore.getPreSubmissionState,
  (state?: PreSubmissionState) => state?.reviewSummaryState,
);

export const isSubmitting: Selector<RootState, boolean> = createSelector(
  getReviewSummaryState,
  (state?: ReviewSummaryState) => state?.isSubmitting || false,
);

export const isTimeoutDialogOpen: Selector<RootState, boolean> = createSelector(
  getReviewSummaryState,
  (state?: ReviewSummaryState) => state?.isSubmissionTimeoutDialogOpen || false,
);

export const openTimeoutDialog = (dispatch: Dispatch<any>) => (): void => {
  dispatch(actions.openTimeoutDialog());
};

export const closeTimeoutDialog = (dispatch: Dispatch<any>) => (): void => {
  dispatch(actions.closeTimeoutDialog());
};

export const submitApplication = (dispatch: Dispatch<any>) => (
  applicationId: string,
): void => {
  dispatch(actions.submitApplication(applicationId));
};
