import { isEnabledFastFill, PersonalDetailsRules } from '@frontline/common';
import { Loader } from '@frontline/ui-library';
import { RetailerSalesRepDialog } from '@frontline/web-common';
import capitalize from 'lodash/capitalize';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as preSubmissionStore from '../../../../store/pre-submission.store';
import PreSubmissionPageLayout from '../../../components/PreSubmissionPageLayout/PreSubmissionPageLayout';
import PreSubmissionStepHeader from '../../../components/StepHeader/StepHeader';
import { PersonalDetailsFormComponent } from '../personal-details-form/personal-details-form.component';
import { PersonalDetailsFastFill } from '../PersonalDetailsFastFill/personal-details-fast-fill';
import { PersonalDetailsViewGenericMessages as messages } from './personal-details-view-generic.messages';
import { PersonalDetailsViewGenericProps } from './personal-details-view-generic.props';

export const PersonalDetailsViewGeneric = (
  props: PersonalDetailsViewGenericProps,
) => {
  const intl = useIntl();
  const [enableFastFill, setEnableFastFill] = useState<boolean>(false);

  const applicantName = capitalize(props.personalDetails?.firstName);

  const description = applicantName
    ? intl.formatMessage(props.messages.description, {
        applicantName,
      })
    : intl.formatMessage(messages.description);

  const title = applicantName
    ? intl.formatMessage(props.messages.title, { applicantName })
    : intl.formatMessage(messages.title);

  const workflowStep = useSelector(preSubmissionStore.getCurrentWorkflowStep);

  const handleSubmitSalesRepId = (salesRepId: string) => {
    if (props.handleSubmitSalesRepId) {
      props.handleSubmitSalesRepId(salesRepId);
    }
  };

  useEffect(() => {
    if (workflowStep) {
      setEnableFastFill(isEnabledFastFill(workflowStep));
    }
  }, [workflowStep]);

  return (
    <>
      <Loader show={Boolean(props.isLoading)} />
      <RetailerSalesRepDialog
        open={Boolean(props.isSalesRepIdDialogOpen)}
        onSubmit={handleSubmitSalesRepId}
        disableValidation
      />
      <PreSubmissionPageLayout
        applicationHeaderComponent={
          <PreSubmissionStepHeader
            title={title}
            description={description}
            stepPosition={props.stepPosition}
          />
        }
        formComponent={
          <>
            <PersonalDetailsFastFill
              fastFillAttempts={props.fastFillAttempts}
              saveFastFillDetails={props.saveFastFillDetails}
              coapplicantIndex={props.coapplicantIndex}
              enableFastFill={enableFastFill}
            />
            <PersonalDetailsFormComponent
              initialValues={props.personalDetails}
              applicantAddress={props.applicantAddress}
              onSubmit={props.onSubmit}
              coApplicant={props.isCoApplicant}
              relationshipTypes={props.relationshipTypes}
              authorizedElectronicCommunication={
                props.authorizedElectronicCommunication
              }
              rules={props.stepReference?.step.rules as PersonalDetailsRules}
            />
          </>
        }
      />
    </>
  );
};
