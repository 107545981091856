import { ActionType, createAction } from 'typesafe-actions';

export enum ReviewSummaryActions {
  SubmitApplication = 'ReviewSummary/Complete',

  OpenTimeoutDialog = 'ReviewSummary/OpenTimeoutDialog',
  CloseTimeoutDialog = 'ReviewSummary/CloseTimeoutDialog',
}

export const submitApplication = createAction(
  ReviewSummaryActions.SubmitApplication,
  action => (applicationId: string) => action(applicationId),
);

export const openTimeoutDialog = createAction(
  ReviewSummaryActions.OpenTimeoutDialog,
);

export const closeTimeoutDialog = createAction(
  ReviewSummaryActions.CloseTimeoutDialog,
);

const actionCreators = {
  submitApplication,
  openTimeoutDialog,
  closeTimeoutDialog,
};

export type ReviewSummaryActionsType = ActionType<typeof actionCreators>;
