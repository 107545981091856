import * as React from 'react';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

const OverviewStepTitle = (props: PropsWithChildren<any>) => (
  <Title>{props.children}</Title>
);
export default OverviewStepTitle;

const Title = styled.div<any>`
  font-size: 16px;
  font-weight: bold;
  color: #203d54;
  margin-bottom: 10px;
`;
