import { AddOn, ThirdPartyAddOnTypeDto } from '@frontline/common';
import { FormatMoney, TypographyVariant } from '@frontline/ui-library';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';
import { getThirdPartyAddOnType } from './AddOnTableBody.functions';

export interface AddOnTableBodyProps {
  addOns: AddOn[];
  thirdPartyAddOnTypes?: ThirdPartyAddOnTypeDto[];
}

export const AddOnTableBody: FunctionComponent<AddOnTableBodyProps> = props => {
  return (
    <TableBody>
      {props.addOns.map((addOn: AddOn, index: number) => (
        <TableRow key={`${addOn.typeId}-${index}`}>
          <TableCell component="th">
            <Typography variant={TypographyVariant.Body1}>
              {index + 1}
            </Typography>
          </TableCell>
          <TableCell component="th">
            <Typography variant={TypographyVariant.Body1}>
              {getThirdPartyAddOnType(addOn, props.thirdPartyAddOnTypes).name}
            </Typography>
          </TableCell>
          <TableCell component="th">
            <Typography variant={TypographyVariant.Body1}>
              {addOn.description}
            </Typography>
          </TableCell>
          <TableCell component="th">
            <Typography variant={TypographyVariant.Body1}>
              <FormatMoney value={addOn.amount} />
            </Typography>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
