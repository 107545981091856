import { WorkflowStepType } from '@frontline/common';
import React from 'react';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import { AdditionalDetailsFeature } from '../features/AdditionalDetails/additional-details-feature.component';
import { CoApplicantFeature } from '../features/CoApplicant/CoApplicantFeature';
import { IncomeDetailsFeature } from '../features/income-details/income-details-feature';
import { PersonalDetailsFeature } from '../features/personal-details/personal-details-feature.component';
import { PurchaseDetailsRoutes } from '../features/purchase-details/purchase-details.routes';
import { ResultFeature } from '../features/result-summary/result-summary-feature';
import { ReviewSummaryFeature } from '../features/ReviewSummary/ReviewSummaryFeature';
import { getPreSubmissionRoutePath } from '../types/PreSubmissionRoutes.functions';
import { PreSubmissionRoutesProps } from './pre-submission-routes.props';

export const PreSubmissionRoutes = (props: PreSubmissionRoutesProps) => (
  <Switch>
    <Route
      path={`${getPreSubmissionRoutePath(
        props.applicationId,
        WorkflowStepType.PurchaseDetails,
      )}`}
      render={() => <PurchaseDetailsRoutes />}
    />
    <Route
      path={`${getPreSubmissionRoutePath(
        props.applicationId,
        WorkflowStepType.PersonalDetails,
      )}`}
      render={() => (
        <PersonalDetailsFeature
          onSubmit={props.onSubmit}
          onBack={props.onBack}
          stepPosition={props.stepPosition}
          stepReference={props.stepReference}
          application={props.application}
        />
      )}
    />
    <Route
      path={`${getPreSubmissionRoutePath(
        props.applicationId,
        WorkflowStepType.CoApplicant,
      )}`}
      render={() => (
        <CoApplicantFeature
          applicationId={props.applicationId}
          onSubmit={props.onSubmit}
          onBack={props.onBack}
          stepPosition={props.stepPosition}
          stepReference={props.stepReference}
        />
      )}
    />
    <Route
      path={`${getPreSubmissionRoutePath(
        props.applicationId,
        WorkflowStepType.IncomeDetails,
      )}`}
      render={() => (
        <IncomeDetailsFeature
          onSubmit={props.onSubmit}
          onBack={props.onBack}
          stepPosition={props.stepPosition}
          stepReference={props.stepReference}
        />
      )}
    />
    <Route
      path={`${getPreSubmissionRoutePath(
        props.applicationId,
        WorkflowStepType.AdditionalDetails,
      )}`}
      render={() => (
        <AdditionalDetailsFeature
          onSubmit={props.onSubmit}
          onBack={props.onBack}
          stepPosition={props.stepPosition}
          stepReference={props.stepReference}
        />
      )}
    />
    <Route
      path={`${getPreSubmissionRoutePath(
        props.applicationId,
        WorkflowStepType.Review,
      )}`}
      render={() => (
        <ReviewSummaryFeature applicationId={props.applicationId} />
      )}
    />
    <Route
      path="/applications/:id/pre-submission/result"
      render={() => <ResultFeature />}
    />
  </Switch>
);
