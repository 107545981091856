import { defineMessages } from 'react-intl';

export const SignLoanDocumentsMessages = defineMessages({
  resendEmailSuccess: {
    id: 'SignLoanDocuments.ResendEmail.Success',
    defaultMessage: 'Contract Successfully Resent.',
  },
  resendEmailError: {
    id: 'SignLoanDocuments.ResendEmail.Error',
    defaultMessage: 'Failed to resend contract.',
  },
});
