import { createSecurityQuestionsState } from '../types/security-questions-state/security-questions-state.functions';
import { SecurityQuestionsState } from '../types/security-questions-state/security-questions-state.interface';
import {
  SecurityQuestionsActions,
  SecurityQuestionsActionsType,
} from './security-questions.actions';

export const securityQuestionsReducer = (
  state: SecurityQuestionsState = createSecurityQuestionsState(),
  action: SecurityQuestionsActionsType,
): SecurityQuestionsState => {
  switch (action.type) {
    case SecurityQuestionsActions.LoadSecurityQuestions:
      return {
        ...state,
        isLoading: true,
      };

    case SecurityQuestionsActions.LoadSecurityQuestionsSuccess:
      return {
        ...state,
        isLoading: false,
        securityQuestions: action.payload,
      };

    case SecurityQuestionsActions.LoadSecurityQuestionsError:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SecurityQuestionsActions.AnswerSecurityQuestions:
      return {
        ...state,
        error: undefined,
      };
    case SecurityQuestionsActions.AnswerSecurityQuestionsError:
      return {
        ...state,
        numberOfAttemptsMade: action.payload.numberOfAttemptsMade,
        isLoading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
