import { ProvinceAbbreviation } from '@frontline/common';
import { MessageDescriptor } from 'react-intl';
import messages from './high-cost-license-details.messages';
import { HighCostLicenseDetails } from './types/high-cost-license-details.interface';
import { LicenseDetails } from './types/license-details.interface';
import { ReactAppHighCostLicenseDetail } from './types/react-app-high-cost-license-details.type';

const getHighCostLicenseDetails = (): ReactAppHighCostLicenseDetail | null => {
  if (Boolean(process.env.REACT_APP_HIGH_COST_LICENSE_DETAILS)) {
    return JSON.parse(process.env.REACT_APP_HIGH_COST_LICENSE_DETAILS!);
  }

  return null;
};

const createHighCostLoanDetails = (
  applicantProvince: ProvinceAbbreviation,
  licenseDetails: LicenseDetails,
): HighCostLicenseDetails => {
  const expiryUtcDate = new Date(licenseDetails.expiry);

  return {
    province: applicantProvince,
    licenseNumber: licenseDetails.license,
    licenseExpiryDate: new Date(
      expiryUtcDate.getTime() + expiryUtcDate.getTimezoneOffset() * 60000,
    ),
  };
};

export const getHighCostLicenseDetailsForProvince = (
  applicantProvince: ProvinceAbbreviation,
): HighCostLicenseDetails | null => {
  const highCostLicenseDetailsObject = getHighCostLicenseDetails();

  if (
    !Boolean(highCostLicenseDetailsObject) ||
    !Object.keys(highCostLicenseDetailsObject ?? {}).includes(applicantProvince)
  ) {
    return null;
  }

  return createHighCostLoanDetails(
    applicantProvince,
    highCostLicenseDetailsObject![applicantProvince],
  );
};

export const getHighCostTitleMessage = (
  applicantProvince: ProvinceAbbreviation,
): MessageDescriptor => {
  /* tslint:disable-next-line */
  switch (applicantProvince) {
    case ProvinceAbbreviation.Newfoundland:
      return messages.newfoundlandHighCostProduct;
    default:
      return messages.defaultHighCostProduct;
  }
};
