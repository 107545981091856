import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import ApprovedIconSrc from '../../assets/application-approved.svg';

export const ApprovedIconDesktop = () => (
  <ApprovedIconDesktopDiv>
    <ApprovedIconBox>
      <img src={ApprovedIconSrc} alt="Approved" />
      <CheckIcon icon={['fas', 'check-circle']} size="4x" />
    </ApprovedIconBox>
  </ApprovedIconDesktopDiv>
);

const CheckIcon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 75%;
  top: 80%;
  color: #32ba43;
`;

const ApprovedIconBox = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 104px;
`;

export const ApprovedIconDesktopDiv = styled.div`
  display: block;
  margin-bottom: 200px;
`;
