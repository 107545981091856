import { Colours } from '@frontline/ui-library';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useApplicationTakeoverViewStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      background: Colours.White,
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        marginTop: 31,
        fontSize: 14,
      },
    },
    description: {
      fontSize: 24,
      fontWeight: 600,
      margin: '26px 0 50px 0',
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
        fontWeight: 700,
        margin: '6px 0 48px 0',
      },
    },
    referenceCodeContainer: {
      width: '100%',
    },
    buttonContainer: {
      marginBottom: 77,
      width: '100%',
      '& :first-child': {
        marginBottom: 33,
      },
      '& >button': {
        margin: 0,
      },
    },
  }),
);
