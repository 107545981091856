import { WorkflowStep } from '@frontline/common';
import { ActionType, createAction } from 'typesafe-actions';
import { CoApplicantPersonalDetailsActionsType } from '../../../features/CoApplicant/features/CoApplicantPersonalDetails/store/coApplicantPersonalDetails.actions';

export enum CoApplicantDetailsActions {
  LoadCoApplicantWorkflow = 'CoApplicantDetails/LoadCoApplicantWorkflow',
  UpdateCoApplicantWorkflow = 'CoApplicantDetails/UpdateCoApplicantWorkflow',
  SetCoApplicantActiveStepIndex = 'CoApplicantDetails/SetActiveStepIndex',
}

export const loadCoApplicantWorkflow = createAction(
  CoApplicantDetailsActions.LoadCoApplicantWorkflow,
);

export const setCoApplicantActiveStepIndex = createAction(
  CoApplicantDetailsActions.SetCoApplicantActiveStepIndex,
  action => (index: number) => action(index),
);

export const updateCoApplicantWorkflow = createAction(
  CoApplicantDetailsActions.UpdateCoApplicantWorkflow,
  action => (workflow: WorkflowStep[]) => action(workflow),
);

export const actionCreators = {
  loadCoApplicantWorkflow,
  updateCoApplicantWorkflow,
  setCoApplicantActiveStepIndex,
};

export type CoApplicantDetailsActionType =
  | ActionType<typeof actionCreators>
  | CoApplicantPersonalDetailsActionsType;
