import * as React from 'react';
import { useIntl } from 'react-intl';
import { IncomeDetailsGeneric } from '../../../../../../common/IncomeDetails/components/IncomeDetailsGeneric/IncomeDetailsGeneric';
import messages from './CoApplicantIncomeDetails.messages';
import { CoApplicantIncomeDetailsProps } from './CoApplicantIncomeDetailsProps';

export const CoApplicantIncomeDetails = (
  props: CoApplicantIncomeDetailsProps,
) => {
  const intl = useIntl();

  return (
    <IncomeDetailsGeneric
      incomeDetails={props.incomeDetails}
      title={intl.formatMessage(messages.title)}
      {...props}
    />
  );
};
