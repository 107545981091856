import {
  CalculatorValues,
  createDefaultInvoiceDetails,
  Fee,
  InvoiceDetails,
  LoanDetails,
  LoanDetailsDto,
} from '@frontline/common';
import { LoanDetailsFormValues } from './loan-details-form-values';

export const createLoanDetailsFormValues = (
  loanDetailsDto: LoanDetailsDto,
): LoanDetailsFormValues => ({
  financeAmount: loanDetailsDto.financeAmount,
  loanAmount: loanDetailsDto.loanAmount,
  payment: loanDetailsDto?.payment,
  approvedAmount: loanDetailsDto?.approvedAmount,
  invoiceEstimateAmount: loanDetailsDto?.invoice?.estimateAmount,
  customerAcknowledgement: Boolean(
    loanDetailsDto?.invoice?.customerConfirmedEstimate,
  ),
  totalPurchaseCost: loanDetailsDto?.totalPurchaseCost,
  downPayment: loanDetailsDto?.downPayment,
  payDayFrequencyId: loanDetailsDto.payDayFrequencyId,
  contractDate: loanDetailsDto.contractDate,
  firstPaymentDate: loanDetailsDto.firstPaymentDate,
  frequencyId: loanDetailsDto.frequencyId,
  deferral: loanDetailsDto.deferral,
  payDayDate: loanDetailsDto.payDayDate,
  tgiInsuranceEnrollment: loanDetailsDto.tgiInsuranceEnrollment,
});

export function createLoanDetailsCalculatorValues(
  loanAmount: number,
  loanDetailsDto: LoanDetailsDto,
): CalculatorValues {
  return {
    loanAmount,
    term: loanDetailsDto.term,
    rate: Number(loanDetailsDto.rate),
    fees: loanDetailsDto.fees
      ? loanDetailsDto.fees.map((fee: Fee) => fee.fee).toString()
      : '',
    deferral: Number(loanDetailsDto.deferral),
    amort: loanDetailsDto.amort,
    frequencyId: loanDetailsDto.frequencyId,
    contractDate: loanDetailsDto.contractDate,
    firstPaymentDate: loanDetailsDto.firstPaymentDate,
  };
}

export function mapFormValuesToLoanDetails(
  formValues: LoanDetailsFormValues,
  invoice?: InvoiceDetails,
): Partial<LoanDetails> {
  return {
    financeAmount: formValues.financeAmount,
    loanAmount: formValues.loanAmount,
    payment: formValues.payment,
    approvedAmount: formValues.approvedAmount,
    invoice: mapFormValuesToInvoice(formValues, invoice),
    firstPaymentDate: formValues.firstPaymentDate,
    payDayFrequencyId: formValues.payDayFrequencyId,
    frequencyId: formValues.frequencyId,
    payDayDate: formValues.payDayDate,
    tgiInsuranceEnrollment: formValues.tgiInsuranceEnrollment,
  };
}

function mapFormValuesToInvoice(
  formValues: LoanDetailsFormValues,
  invoice?: InvoiceDetails,
): InvoiceDetails | undefined {
  let details;
  if (formValues?.invoiceEstimateAmount || formValues.customerAcknowledgement) {
    details = {
      ...createDefaultInvoiceDetails(),
      ...invoice,
      estimateAmount: formValues?.invoiceEstimateAmount || 0,
      customerConfirmedEstimate: formValues.customerAcknowledgement,
    };
  }
  return details;
}
