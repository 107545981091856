import React, { Fragment, FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as applicantApplicationOptionsStore from '../../../../modules/applicant-application-options/store/applicant-application-options.store';
import * as applicationStore from '../../../../store/application.store';
import { PostSubmissionReviewFeatureFunctions } from '../../features/PostSubmissionReview/types/PostSubmissionReviewFeature.functions';
import * as postSubmissionStore from '../../store/post-submission.store';
import { ExitButton, MobileFundingDetails } from './components';
import { getFrequencyMessageDescriptor } from './types';

export const ReviewPageMobileNavigation: FunctionComponent = () => {
  const dispatch = useDispatch();

  const applicationId = useSelector(applicationStore.getApplicationId);
  const loanDetails = useSelector(applicationStore.getLoanDetails);
  const isCalculating = useSelector(postSubmissionStore.isCalculating);
  const estimatedPayment = useSelector(postSubmissionStore.getEstimatedPayment);
  const frequencyCode = useSelector(postSubmissionStore.getEstimatedFrequency);
  const workflowStep = useSelector(postSubmissionStore.getActiveWorkflowStep);
  const applicantApplicationOption = useSelector(
    applicantApplicationOptionsStore.getApplicantApplicationOption,
  );

  if (!applicationId) {
    return null;
  }
  const financeAmount =
    estimatedPayment?.financedAmount || loanDetails?.financeAmount || 0;
  const paymentAmount = estimatedPayment?.payment || loanDetails?.payment || 0;

  return (
    <Fragment>
      <ExitButton onClick={postSubmissionStore.navigateToOverview(dispatch)} />
      <MobileFundingDetails
        isCalculating={isCalculating}
        financeAmount={financeAmount}
        paymentAmount={paymentAmount}
        frequencyMessageDescriptor={getFrequencyMessageDescriptor(
          frequencyCode,
        )}
        hideFundingDetails={PostSubmissionReviewFeatureFunctions.hideFundingDetails(
          applicantApplicationOption,
          workflowStep,
        )}
      />
    </Fragment>
  );
};
