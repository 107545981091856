import { ThirdPartyAddOnTypeDto } from '@frontline/common';
import { ActionType, createAction } from 'typesafe-actions';

export enum ThirdPartyAddOnsActions {
  FetchTypes = 'ThirdPartyAddons/FetchThirdPartyAddonTypes',
  FetchTypesSuccess = 'ThirdPartyAddons/FetchThirdPartyAddonTypesSuccess',
  FetchTypesFailure = 'ThirdPartyAddons/FetchThirdPartyAddonTypesFailure',
}

export const fetchTypes = createAction(ThirdPartyAddOnsActions.FetchTypes);

export const fetchTypesSuccess = createAction(
  ThirdPartyAddOnsActions.FetchTypesSuccess,
  action => (types: ThirdPartyAddOnTypeDto[]) => action(types),
);

export const fetchTypesFailure = createAction(
  ThirdPartyAddOnsActions.FetchTypesFailure,
  action => (error: Error) => action(error),
);

const actionCreators = {
  fetchTypes,
  fetchTypesSuccess,
  fetchTypesFailure,
};

export type ThirdPartyAddOnsActionType = ActionType<typeof actionCreators>;
