import { UrlSearchParamsUtil } from '@frontline/web-common';
import { LendcareReportingField } from '../types/lendcare-reporting-field.enum';

function getReportingFields(
  url: string,
): Map<LendcareReportingField, string | null> {
  const map = new Map();
  Object.keys(LendcareReportingField).forEach(key => {
    map.set(
      LendcareReportingField[key],
      getReportingField(url, LendcareReportingField[key]),
    );
  });

  return map;
}

function getReportingField(
  url: string,
  key: LendcareReportingField,
): string | null {
  const result = UrlSearchParamsUtil.getParamFromUrl(url, key);

  if (result === undefined || result === null || result.trim().length === 0) {
    return null;
  }

  return result;
}

export const LendcareReportingFieldService = {
  getReportingFields,
};
