import { withShowIf } from '@frontline/ui-library';
import { Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import expiredIcon from '../../../../../../assets/expired.svg';
import { ApplicationTakeoverNewLinkFooter } from '../../../application-takeover-new-link-footer/application-takeover-new-link-footer.component';
import { ApplicationTakeoverExpiredMessages } from './application-takeover-expired.messages';
import { useApplicationTakeoverExpiredStyles } from './application-takeover-expired.styles';

export const ApplicationTakeoverExpired = withShowIf(() => {
  const classes = useApplicationTakeoverExpiredStyles();

  return (
    <div className={classes.container}>
      <div className={classes.expiredContainer}>
        <img src={expiredIcon} alt="" className={classes.icon} />
        <Typography className={classes.title}>
          <FormattedMessage {...ApplicationTakeoverExpiredMessages.title} />
        </Typography>
      </div>
      <ApplicationTakeoverNewLinkFooter />
    </div>
  );
});
