import { Grid } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import PreSubmissionStepHeader from '../../../../common/components/StepHeader/StepHeader';
import { SubmitButton } from '../submit-button';
import messages from './review-summary-header.messages';
import { ReviewSummaryHeaderProps as Props } from './review-summary.props';

export const ReviewSummaryHeader: FunctionComponent<Props> = (props: Props) => {
  const intl = useIntl();
  const title = intl.formatMessage(messages.title);
  const description = intl.formatMessage(messages.description);
  return (
    <Grid container={true} wrap="nowrap">
      <Grid item={true} xs="auto">
        <PreSubmissionStepHeader
          title={title}
          description={description}
          stepPosition={props.stepPosition}
        />
      </Grid>
      <Grid item={true} xs="auto">
        <SubmitButton
          onSubmit={props.onSubmit}
          showSubmitButton={props.showSubmitButton}
          uat={props.uat}
        />
      </Grid>
    </Grid>
  );
};
