import { UserPreferencesState } from '../types/UserPreferencesState';
import { createUserPreferencesState } from '../types/UserPreferencesState.functions';
import {
  UserPreferencesActions,
  UserPreferencesActionType,
} from './userPreferences.actions';

export const userPreferencesReducer = (
  state: UserPreferencesState = createUserPreferencesState(),
  action: UserPreferencesActionType,
): UserPreferencesState => {
  if (action.type === UserPreferencesActions.updateLanguagePreference) {
    return {
      ...state,
      ...action.payload,
      loading: false,
    };
  }

  return state;
};
