import { WorkflowDecision } from '@frontline/common';
import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootState } from '../../../../../../../../../store';
import * as rootApplicationActions from '../../../../../../../../../store/application/actions';
import * as applicationStore from '../../../../../../../store/application.store';
import * as postSubmissionActions from '../../../../../store/post-submission.actions';
import * as actions from './componentSelector.actions';
import * as store from './componentSelector.store';

export const saveDecisionEpic: Epic<Action, Action, RootState> = (
  action$: Observable<Action>,
  state$: any,
) =>
  action$.pipe(
    filter(isActionOf(actions.saveDecision)),
    map(() => [
      applicationStore.getApplicationId(state$.value),
      store.getDecision(state$.value),
    ]),
    mergeMap(([applicationId, workflowDecision]) =>
      of(
        rootApplicationActions.saveWorkflowDecision({
          applicationId: applicationId as string,
          workflowDecision: workflowDecision as WorkflowDecision,
        }),
      ),
    ),
  );

const saveDecisionSuccessEpic: Epic<Action, Action, Action, RootState> = (
  action$: Observable<Action>,
  state$: any,
) =>
  action$.pipe(
    filter(isActionOf(rootApplicationActions.saveWorkflowDecisionSuccess)),
    map(() => store.getSelectedStepId(state$.value)),
    map(stepId => postSubmissionActions.navigateToStepById(stepId as string)),
  );

export default [saveDecisionEpic, saveDecisionSuccessEpic];
