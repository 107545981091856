import { ApplicationTakeoverRequestStatus } from '../../../types/application-takeover/application-takeover-request-status.enum';
import { ApplicationTakeoverState } from './application-takeover-state.interface';

export function createApplicationTakeoverState(): ApplicationTakeoverState {
  return {
    applicationTakeoverDetails: null,
    isLoading: false,
    applicationTakeoverStatus: ApplicationTakeoverRequestStatus.Pending,
    isConfirming: false,
    hasAccepted: false,
    hasDeclined: false,
  };
}
