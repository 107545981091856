import { Coordinates, Province } from '@frontline/common';
import { createSelector, Selector } from 'reselect';
import { RootState, Store } from '../../../../../store';
import { HighCostLicenseDetails } from '../../../common/high-cost-license-details/types/high-cost-license-details.interface';
import { GeoLocationState } from '../types/geo-location-state.interface';
import * as actions from './geo-location.actions';

export const getGeolocationState: Selector<
  RootState,
  GeoLocationState | undefined
> = createSelector(
  (state: RootState) => state.geoLocationState,
  (state?: GeoLocationState) => state,
);

export const fetchProvince = (coordinates: Coordinates): void => {
  Store.dispatch(actions.fetchProvince(coordinates));
};

export const getProvince: Selector<RootState, Province | null> = createSelector(
  getGeolocationState,
  (state?: GeoLocationState) => state?.province ?? null,
);

export const setConsumerCoordinates = (coordinates: Coordinates) =>
  Store.dispatch(actions.setConsumerCoordinates(coordinates));

export const getCoordinates: Selector<
  RootState,
  Coordinates | null
> = createSelector(
  getGeolocationState,
  (state?: GeoLocationState) => state?.coordinates ?? null,
);

export const setHighCostLicenseDetails = (
  highCostLicenseDetails: HighCostLicenseDetails | null,
) => Store.dispatch(actions.setHighCostLicenseDetails(highCostLicenseDetails));

export const getHighCostLicenseDetails: Selector<
  RootState,
  HighCostLicenseDetails | null
> = createSelector(
  getGeolocationState,
  (state?: GeoLocationState) => state?.highCostLicenseDetails ?? null,
);
