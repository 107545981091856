import {
  Application,
  ApplicationStatus,
  CalculatorValues,
  EstimatedPayment,
  findWorkflowStepById,
  isUploadDocumentStep,
  LoanProtection,
  PersonalDetails,
  updateWorkflowStepStatus,
  WorkflowStep,
  WorkflowStepType,
} from '@frontline/common';
import {
  ApplicationState as WebCommonApplicationState,
  PostSubmissionReviewError,
  PreAuthorizedDebitState,
  selectors,
} from '@frontline/web-common';
import { VerifyIdentityState } from '@frontline/web-common';
import * as H from 'history';
import { Dispatch } from 'react';
import { createSelector, Selector } from 'reselect';
import { RootState } from '../../../../../store';
import * as applicationActions from '../../../../../store/application/actions';
import * as applicationStore from '../../../store/application.store';
import { PostSubmissionReviewState } from '../features/PostSubmissionReview/types/PostSubmissionReviewState';
import { PostSubmissionState } from '../types/PostSubmissionState';
import * as actions from './post-submission.actions';

const applicationSelectors = selectors.applicationSelectors;

export const getPostSubmissionState: Selector<
  RootState,
  PostSubmissionState
> = createSelector(
  applicationStore.getApplicationState,
  applicationState => applicationState.postSubmissionState,
);
export const getPostSubmissionReviewState: Selector<
  RootState,
  PostSubmissionReviewState | undefined
> = createSelector(
  getPostSubmissionState,
  postSubmissionState => postSubmissionState?.postSubmissionReviewState,
);

const getWebCommonApplicationState: Selector<
  RootState,
  WebCommonApplicationState
> = (state: RootState) => state.application;

const getApplication: Selector<RootState, Application> = createSelector(
  getWebCommonApplicationState,
  (state: WebCommonApplicationState) =>
    applicationSelectors.getApplication(state) || ({} as Application),
);

export const getCurrentApplication: Selector<
  RootState,
  Application | undefined
> = createSelector(
  (state: RootState): WebCommonApplicationState => state.application,
  applicationState =>
    applicationSelectors.getCurrentApplication(applicationState),
);

export const getWorkflow: Selector<RootState, WorkflowStep[]> = createSelector(
  getPostSubmissionState,
  (postSubmissionState: PostSubmissionState) =>
    postSubmissionState.workflowSteps || [],
);

export const getPostSubmissionStepsForStepper: Selector<
  RootState,
  WorkflowStep[]
> = createSelector(getApplication, getWorkflow, (application, workflow) => {
  if (!application.id) {
    return [];
  }
  return (workflow || [])
    .filter((step: WorkflowStep) => !isUploadDocumentStep(step))
    .map(step => {
      updateWorkflowStepStatus(step, application);
      return step;
    });
});

export const getUploadDocumentsStep: Selector<
  RootState,
  WorkflowStep | undefined
> = createSelector(getWorkflow, (workflow: WorkflowStep[]) =>
  workflow.find(isUploadDocumentStep),
);

export const getApplicationStatus: Selector<
  RootState,
  ApplicationStatus
> = createSelector(
  getApplication,
  (application: Application) => application.status,
);

export const getApplicantPersonalDetails: Selector<
  RootState,
  PersonalDetails | undefined
> = (state: RootState) =>
  applicationSelectors.getApplicantPersonalDetails(state.application);

export const getCoApplicantPersonalDetails: Selector<
  RootState,
  PersonalDetails | undefined
> = (state: RootState) =>
  applicationSelectors.getCoApplicantPersonalDetails(state.application);

export const updatesDisabled = (state: RootState): boolean =>
  !selectors.postSubmissionSelectors.enableLoanDetailUpdates(state.application);

export const getError: Selector<
  RootState,
  PostSubmissionReviewError | null
> = createSelector(
  getPostSubmissionState,
  (state: PostSubmissionState) => state.error,
);

export const getEstimatedPayment: Selector<
  RootState,
  EstimatedPayment | null
> = createSelector(
  getPostSubmissionState,
  (state: PostSubmissionState) => state.estimatedPayment,
);

export const getEstimatedFrequency: Selector<
  RootState,
  number
> = createSelector(
  getPostSubmissionState,
  (state: PostSubmissionState) => state.estimatedFrequency,
);

export const isCalculating: Selector<RootState, boolean> = createSelector(
  getPostSubmissionState,
  (state: PostSubmissionState) => state.isCalculating,
);

export const isWorkflowLoaded: Selector<RootState, boolean> = createSelector(
  getWorkflow,
  Boolean,
);

export const getVerifyIdentityState: Selector<
  RootState,
  VerifyIdentityState | undefined
> = createSelector(
  getPostSubmissionReviewState,
  (state?: PostSubmissionReviewState) => state?.verifyIdentityState,
);

export const getActiveWorkflowStepId: Selector<
  RootState,
  string | undefined
> = createSelector(
  getPostSubmissionState,
  (state: PostSubmissionState) => state.activeWorkflowStepId,
);

export const getActiveWorkflowStep: Selector<
  RootState,
  WorkflowStep | null
> = createSelector(
  getWorkflow,
  getActiveWorkflowStepId,
  (steps, activeStepId) => findWorkflowStepById(steps, activeStepId as string),
);

export const areApplicantUpdatesEnabled = (state: RootState): boolean =>
  selectors.postSubmissionSelectors.enableApplicantUpdates(state.application);

export const navigateToOverview = (dispatch: Dispatch<any>) => (): void => {
  dispatch(applicationActions.goToOverview());
};

export const navigateToPreviousStep = (dispatch: Dispatch<any>) => (): void => {
  dispatch(actions.navigateToPreviousStep());
};

export const navigateToNextStep = (dispatch: Dispatch<any>) => (): void => {
  dispatch(actions.navigateToNextStep());
};

export const navigateToStepById = (dispatch: Dispatch<any>) => (
  stepId: string,
): void => {
  dispatch(actions.navigateToStepById(stepId));
};

export const fetchEstimatedPayment = (dispatch: Dispatch<any>) => (
  values: CalculatorValues,
) => {
  dispatch(actions.fetchEstimatedPayment(values));
};

export const attachPendingDocuments = (dispatch: Dispatch<any>) => (values: {
  fileNames: string[];
  successMessage?: string;
  errorMessage?: string;
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  dispatch(applicationActions.attachPendingDocuments(values));
};

export const savePostSubmissionStep = (
  dispatch: Dispatch<any>,
  data: LoanProtection,
): void => {
  dispatch(
    applicationActions.savePostSubmissionStep({
      stepType: WorkflowStepType.AddOns,
      data,
    }),
  );
};

export const getPreAuthorizedDebitState: Selector<
  RootState,
  PreAuthorizedDebitState | undefined
> = createSelector(
  getPostSubmissionReviewState,
  (state?: PostSubmissionReviewState) => state?.preAuthorizedDebitState,
);

export const setActiveWorkflowStepFromRoute = (dispatch: Dispatch<any>) => (
  location: H.Location,
) => dispatch(actions.setActiveWorkflowStepFromRoute(location));

export const initPostSubmissionWorkflowSteps = (
  dispatch: Dispatch<any>,
) => () => dispatch(actions.initPostSubmissionWorkflowSteps());
