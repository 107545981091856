import { styledTheme } from '@frontline/ui-library';
import styled from 'styled-components';

export const Label = styled.span<any>`
  color: ${styledTheme.windowsBlue};
  font-size: 14px;
  font-weight: 900;
  line-height: 2.23;
  letter-spacing: 0.2px;
  margin-left: 9px;
`;
