import { actions as webCommonActions } from '@frontline/web-common';
import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootState } from '../../../../../../../../../store';
import * as applicationStore from '../../../../../../../store/application.store';
import { fetchLoanDetails } from '../services/loan-details.service';
import * as actions from './loan-details.actions';

export const fetchLoanDetailsEpic: Epic<Action, Action, RootState> = (
  action$: Observable<Action>,
  state$: any,
) =>
  action$.pipe(
    filter(
      isActionOf([
        actions.fetchLoanDetails,
        webCommonActions.applicationActions.saveLoanDetailsSuccess,
      ]),
    ),
    map(() => applicationStore.getApplicationId(state$.value)),
    mergeMap(applicationId =>
      from(fetchLoanDetails(applicationId as string)).pipe(
        map(loanDetails => actions.fetchLoanDetailsSuccess(loanDetails)),
        catchError(error => of(actions.fetchLoanDetailsFailure(error))),
      ),
    ),
  );

export default [fetchLoanDetailsEpic];
