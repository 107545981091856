import { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import { RootState, Store } from '../../../store';
import { Answer } from '../../../types/questions/answer.interface';
import { QuestionGroup } from '../../../types/questions/question-group.interface';
import { SecurityQuestionsState } from '../types/security-questions-state/security-questions-state.interface';
import * as actions from './security-questions.actions';

export const getSecurityQuestionsState = (rootState: RootState) =>
  rootState.securityQuestionsState;

export const getSecurityQuestions: Selector<
  RootState,
  QuestionGroup | null
> = createSelector(
  getSecurityQuestionsState,
  (state: SecurityQuestionsState) => state.securityQuestions,
);

export const getError: Selector<RootState, Error | undefined> = createSelector(
  getSecurityQuestionsState,
  (state: SecurityQuestionsState) => state.error,
);

export const getNumberOfAttemptsMade: Selector<
  RootState,
  number
> = createSelector(
  getSecurityQuestionsState,
  (state: SecurityQuestionsState) => state.numberOfAttemptsMade,
);

export function answerSecurityQuestions(
  answers: Answer[],
  questionGroupId: string,
) {
  Store.dispatch(actions.answerSecurityQuestions(answers, questionGroupId));
}
