import {
  Colours,
  TypographyVariant,
  useIsMobile,
  useUatId,
  withShowIf,
} from '@frontline/ui-library';
import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import { getHighCostTitleMessage } from './high-cost-license-details.functions';
import messages from './high-cost-license-details.messages';
import { HighCostLicenseDetailsProps } from './high-cost-license-details.props';
import { useHighCostLicenseDetailsStyles } from './high-cost-license-details.styles';
import { HighCostLicenseDetailsUat } from './high-cost-license-details.uat';

export const HighCostLicenseDetails = withShowIf(
  (props: HighCostLicenseDetailsProps) => {
    const intl = useIntl();
    const uatId = useUatId();
    const isSmallMedia = useIsMobile('sm');
    const isExtraSmallMedia = useIsMobile('xs');
    const classes = useHighCostLicenseDetailsStyles({
      isSmallMedia,
      isExtraSmallMedia,
    });

    return (
      <Box
        className={classes.wrapper}
        color={Colours.BarneyPurple}
        bgcolor={Colours.White}
        border={3}
        borderRadius={'6px'}>
        <Typography
          className={classes.blackText}
          variant={TypographyVariant.Subtitle2}
          {...uatId(HighCostLicenseDetailsUat.highCostDetialsTitleText)}>
          {intl.formatMessage(
            getHighCostTitleMessage(props.highCostLicenseDetails.province),
          )}
        </Typography>
        <Typography
          className={classes.blackText}
          variant={TypographyVariant.Caption}
          {...uatId(
            HighCostLicenseDetailsUat.highCostDetialsLicenseDetailsText,
          )}>
          {intl.formatMessage(messages.licenseNumberAndExpiryDate, {
            licenseNumber: props.highCostLicenseDetails.licenseNumber,
            licenseExpiryDate: props.highCostLicenseDetails.licenseExpiryDate.toLocaleDateString(
              intl.locale,
              {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              },
            ),
          })}
        </Typography>
      </Box>
    );
  },
);
