import { HttpError } from '@frontline/web-common';
import { SecurityQuestionsError } from './security-questions-error.interface';

export function createSecurityQuestionsError(
  previousNumberOfAttempts: number,
  error: HttpError,
): SecurityQuestionsError {
  return {
    numberOfAttemptsMade: previousNumberOfAttempts + 1,
    error,
  };
}
