import { Colours, useIsMobile, withShowIf } from '@frontline/ui-library';
import Box from '@material-ui/core/Box';
import React from 'react';
import { MenuPageSelector } from '../../menu-page-selector/menu-page-selector.component';
import { OverviewMenuProps } from './overview-menu.props';

export const OverviewMenu = withShowIf((props: OverviewMenuProps) => {
  const isMobile = useIsMobile();
  if (!props.step) {
    return null;
  }
  return (
    <Box
      height={isMobile ? 'auto' : '100%'}
      bgcolor={Colours.White}
      border={1}
      borderLeft={0}
      borderColor={Colours.PaleBlue}>
      <MenuPageSelector
        onClickAddCoApplicant={props.onClickAddCoApplicant}
        onClickRemoveCoApplicant={props.onClickRemoveCoApplicant}
        showRemoveCoApplicant={props.showRemoveCoApplicant}
        showAddCoApplicant={props.showAddCoApplicant}
        activeTab={props.step.type}
        step={props.step}
      />
    </Box>
  );
});
