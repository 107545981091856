import {
  AddOn,
  ApplicationTaxDetails,
  ApplicationValidationResult,
  Asset,
  LoanDetails,
  ThirdPartyAddOnTypeDto,
} from '@frontline/common';
import { selectors } from '@frontline/web-common';
import { Dispatch } from 'redux';
import { createSelector, Selector } from 'reselect';
import { RootState, Store } from '../../../../../../../../../store';
import * as postSubmissionStore from '../../../../../store/post-submission.store';
import { PostSubmissionReviewState } from '../../../types/PostSubmissionReviewState';

import { AssetsAndAddOnsState } from '../types/assets-and-addons-state.interface';
import * as actions from './assets-and-addons.actions';

const rootApplicationSelectors = selectors.applicationSelectors;

export const getAssetsAndAddOnsState: Selector<
  RootState,
  AssetsAndAddOnsState | undefined
> = createSelector(
  postSubmissionStore.getPostSubmissionReviewState,
  (state?: PostSubmissionReviewState) => state?.assetsAndAddOnsState,
);

export const getValidations: Selector<
  RootState,
  ApplicationValidationResult | null
> = createSelector(
  getAssetsAndAddOnsState,
  (state?: AssetsAndAddOnsState) => state?.assets.validations || null,
);

export const getPurchaseDetails = (
  rootState: RootState,
): Partial<LoanDetails> =>
  rootApplicationSelectors.getLoanDetails(rootState.application) || {};

export const getAssetInformation = (rootState: RootState): Asset[] =>
  rootApplicationSelectors.getAssetInformation(rootState.application);

export const getTaxDetails = (rootState: RootState): ApplicationTaxDetails =>
  rootApplicationSelectors.getTaxInformation(rootState.application);

export const getAddOns = (rootState: RootState): AddOn[] =>
  rootApplicationSelectors.getThirdPartyAddOns(rootState.application);

export const getAddOnTypes: Selector<
  RootState,
  ThirdPartyAddOnTypeDto[] | undefined
> = createSelector(
  getAssetsAndAddOnsState,
  (state?: AssetsAndAddOnsState) => state?.addOns.types,
);

export const fetchAddOnTypes = (dispatch: Dispatch<any>) => () => {
  dispatch(actions.fetchTypes());
};

export const fetchValidations = (): void => {
  Store.dispatch(actions.fetchValidations());
};
