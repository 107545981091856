import { styledTheme } from '@frontline/ui-library';
import { makeStyles } from '@material-ui/styles';

export const usePayDayDateStyles = makeStyles({
  inputPadding: {
    paddingRight: 16,
  },
  includePaddingAndHeight: {
    paddingRight: 16,
    height: 94,
  },
  datePickerValuesLeft: {
    width: '100%',
    paddingRight: 18,
    paddingLeft: 9,
    '& *': {
      width: '100%',
    },
  },
  disabledField: {
    '& .Mui-disabled': {
      color: styledTheme.windowsBlue,
      '& .MuiInputBase-input, & .Mui-disabled': {
        color: styledTheme.color12,
      },
    },
  },
});
