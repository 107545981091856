import { defineMessages } from 'react-intl';

export default defineMessages({
  en: {
    id: 'ChangeLanguageButton.english',
    description: 'the English language',
    defaultMessage: 'English',
  },
  fr: {
    id: 'ChangeLanguageButton.french',
    description: 'the French language',
    defaultMessage: 'French',
  },
});
