import * as React from 'react';
import { useIntl } from 'react-intl';
import { IncomeDetailsForm } from '../../../../../../modules/income-details';
import PreSubmissionPageLayout from '../../../components/PreSubmissionPageLayout/PreSubmissionPageLayout';
import PreSubmissionStepHeader from '../../../components/StepHeader/StepHeader';
import messages from './IncomeDetailsView.messages';
import { IncomeDetailsViewProps } from './IncomeDetailsViewProps';

export const IncomeDetailsView = (props: IncomeDetailsViewProps) => {
  const intl = useIntl();

  return (
    <PreSubmissionPageLayout
      applicationHeaderComponent={
        <PreSubmissionStepHeader
          title={props.title}
          description={intl.formatMessage(messages.description)}
          stepPosition={props.stepPosition}
        />
      }
      formComponent={
        <React.Fragment>
          <IncomeDetailsForm
            incomeDetails={props.incomeDetails}
            authorizedElectronicCommunication={
              props.authorizedElectronicCommunication
            }
            onSubmit={props.onSubmit}
            onBackButtonClick={props.onBackButtonClick}
          />
        </React.Fragment>
      }
    />
  );
};
