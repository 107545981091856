import { defineMessages } from 'react-intl';

export default defineMessages({
  text: {
    id:
      'Applicant.PostSubmissionReview.LoanDetails.LoanDetailsWaitingForMerchant.text',
    description:
      'This section is completed by the merchant, Please click Continuel',
    defaultMessage:
      'This section is completed by the merchant, Please click Continue',
  },
});
