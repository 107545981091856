import { FormatMoney, withShowIf } from '@frontline/ui-library';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LoanDetailsItem } from '../../../../components/loan-details-item.component';
import messages from './loan-details-total-financed.messages';
import { LoanDetailsTotalFinancedProps } from './loan-details-total-financed.props';

export const LoanDetailsTotalFinanced = withShowIf(
  (props: LoanDetailsTotalFinancedProps) => {
    return (
      <>
        <LoanDetailsItem
          showIf={props.financeAmount !== 0}
          labelMessage={messages.financeAmountLabel}
          data={
            <FormatMoney value={props.financeAmount} roundToDollar={false} />
          }
        />
        <LoanDetailsItem
          showIf={props.financeAmount === 0}
          labelMessage={messages.financeAmountLabel}
          data={<FormattedMessage {...messages.financeAmountNoAssetAdded} />}
        />
      </>
    );
  },
);
