import { Selector } from 'react-redux';
import { Dispatch } from 'redux';
import { createSelector } from 'reselect';
import { RootState } from '../../../../../../../store';
import * as preSubmissionStore from '../../../store/pre-submission.store';
import { PreSubmissionState } from '../../../types/PreSubmissionState/pre-submission.state';
import { ResultSummaryState } from '../types/result-summary-state/result-summary-state';
import * as actions from './result-summary.actions';

export const getResultSummaryState: Selector<
  RootState,
  ResultSummaryState | undefined
> = createSelector(
  preSubmissionStore.getPreSubmissionState,
  (state: PreSubmissionState) => state.resultSummaryState,
);

export const isLoading: Selector<RootState, boolean> = createSelector(
  getResultSummaryState,
  (state?: ResultSummaryState) => state?.isLoading || false,
);

export const completeResult = (dispatch: Dispatch<any>) => (
  applicationId: string,
): void => {
  dispatch(actions.completeResult(applicationId));
};
