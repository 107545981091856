import { IncomeDetailsForm } from '@frontline/common';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { PreSubmissionFormPropsType } from '../../../../types/PreSubmissionForm/PreSubmissionFormPropsType';
import { CoApplicantIncomeDetails } from './components/CoApplicantIncomeDetails/CoApplicantIncomeDetails';
import * as store from './store/coApplicantIncomeDetails.store';

export const CoApplicantIncomeDetailsFeature = (
  props: PreSubmissionFormPropsType<IncomeDetailsForm>,
) => (
  <CoApplicantIncomeDetails
    incomeDetails={useSelector(store.getCoApplicantIncomeDetails)}
    authorizedElectronicCommunication={useSelector(
      store.getCoApplicantAuthorizedElectronicCommunication,
    )}
    {...props}
  />
);
