export function getRepairTechRoute(): string {
  return '/repair-tech';
}

export function getVehicleInformationRoute(): string {
  return `${getRepairTechRoute()}/vehicle-information`;
}

export function getRepairTechPostLoginRoute(
  accessKey: string,
  merchantId: number,
): string {
  return `/repair-tech?accessKey=${accessKey}&merchantId=${merchantId}`;
}
