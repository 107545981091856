import { ApplicantSession } from './ApplicantSession';

export function createSession(): ApplicantSession {
  return {
    applicationId: null,
    merchantId: null,
    accessKey: null,
    accessToken: null,
    expiresAt: null,
    userId: null,
    isAuthenticated: false,
  };
}
