import { useUatId } from '@frontline/ui-library';
import { Grid } from '@material-ui/core';
import * as React from 'react';
import { AssetTypesChoicesProps } from './asset-types-choices.props';
import { Choices } from './asset-types-choices.styles';
import { AssetTypeChoicesUat } from './asset-types-choices.uat';
import { AssetTypesChoice } from './components/asset-type-choice/asset-types-choice.component';

export const AssetTypesChoices = (props: AssetTypesChoicesProps) => {
  const uatId = useUatId();

  const handleChecked = (choice: number) => {
    props.form.setFieldValue(props.field.name, choice);
  };

  return (
    <Choices container={true} spacing={2}>
      {props.options.map(option => (
        <Grid
          container={true}
          item={true}
          xs={6}
          sm={6}
          md={4}
          lg={3}
          key={option.id}>
          <AssetTypesChoice
            label={option.name}
            value={option.id}
            checked={handleChecked}
            selected={props.field.value === option.id}
            disabled={props.disabled}
            uat={uatId(AssetTypeChoicesUat.assetTypeChoice)}
          />
        </Grid>
      ))}
    </Choices>
  );
};
